import { Holiday } from '@carsayo/types';
import { api } from 'apis';
import dayjs from 'dayjs';
import SentryErrorReturns from 'utils/sentryErrorReturns';

/** 전체 공업사 리스트 조회 */
export const getHoliday = async () => {
  const currentYear = dayjs().year();

  try {
    const response = await api.get(`holiday?year=${currentYear}`);
    const data: Holiday[] = response.data;
    return data;
  } catch (error: any) {
    SentryErrorReturns(error.response);
    throw new Error(error);
  }
};
