'use client';

import { Suspense, useEffect, useRef } from 'react';
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

export type DialogWrapperProps = {
  children?: React.ReactNode;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  onClose?: () => void;
};

/** useSearchParams 사용 시 Suspense 사용 필요 */
function DialogWrapperContent({
  children,
  isOpen,
  setIsOpen,
  onClose,
}: DialogWrapperProps) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const key = useRef(uuidv4()).current;

  /**
   * 뒤로가기할 경우 setIsOpen(false)를 유발
   * 아래 있는 useEffect를 유발합니다.
   */
  useEffect(() => {
    if (!isOpen) return;

    if (!searchParams.get(key)) {
      setIsOpen(false);
    }
  }, [searchParams]);

  /** setIsOpen(false)로 닫힐 경우 */
  useEffect(() => {
    if (!isOpen) return;

    const newSearchParams = new URLSearchParams(window.location.search);
    newSearchParams.set(key, 'true');

    navigate(`${window.location.pathname}?${newSearchParams.toString()}`, {
      preventScrollReset: true,
    });

    return () => {
      const updatedSearchParams = new URLSearchParams(window.location.search);

      if (updatedSearchParams.get(key)) {
        navigate(-1);
      } else {
        updatedSearchParams.delete(key);
        navigate(
          `${window.location.pathname}?${updatedSearchParams.toString()}`,
          {
            replace: true,
            preventScrollReset: true,
          },
        );
      }

      /** 동시에 돌면 문제가 생김 */
      if (onClose) {
        setTimeout(() => {
          onClose();
        }, 100);
      }
    };
  }, [isOpen]);

  return <React.Fragment>{children}</React.Fragment>;
}

/** 뒤로가기 연동 기능을 위한 래퍼
 * @note 뒤로가기를 통한 종료 기능을 위해서는 모든 다이얼로그 컴포넌트에 적용해야 함
 */
export default function DialogWrapper(props: DialogWrapperProps) {
  return (
    <Suspense>
      <DialogWrapperContent {...props} />
    </Suspense>
  );
}
