import React, { useEffect, useLayoutEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { getMember, isLogin } from 'apis/authentication';

import useOnResumeStore from 'store/useOnResumeStore';
import useSystemStore from 'store/useSystemStore';
import BottomTabbar from 'components/common/global/BottomTabbar';
import useMemberStore from 'store/useMemberStore';
import ReactGA from 'react-ga4';
import SideMenu from 'components/common/global/SideMenu';
import TopTabbar from 'components/common/global/TopTabbar';
import FileUploader from 'components/common/global/FileUploader';
import { Toaster } from 'sonner';
import LoadingDim from 'components/common/global/LoadingDim';
import CarsayoPopup from 'components/common/global/CarsayoPopup';
import ViewPoint from 'components/view/ViewPoint';
import InitRestore from 'hooks/restore/initRestore';
import CarsayoPayment from 'components/common/global/CarsayoPayment';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useDataStore } from 'store/useDataStore';

const CarsayoRouter: React.FC = () => {
  const { isStatic } = useSystemStore();
  const { runOnResume } = useOnResumeStore();
  const { loginMember, setLoginMember } = useMemberStore();
  const { fetchHoliday } = useDataStore();

  const navigate = useNavigate();

  /** 앱 처음 실행 시 회원 상태를 확인하고 필요할 경우 리다이렉트합니다.
   * @notice replace: true 옵션을 활성화하여야 합니다!! (백버튼으로 못도망가게)
   */
  const setInitLoginMember = async () => {
    const isLoginResponse = await isLogin();

    if (isLoginResponse.data.isLogined) {
      setLoginMember(await getMember());
    } else {
      if (isStatic === false) navigate('/auth/signup', { replace: true });
    }
  };
  /** Native단에서 호출할 수 있는 메소드를 사전 정의합니다
   * @description Android, iOS 앱에서 특정 기능(ex. Push) 동작 시 리액트단과 상호작용하기 위해 특정 기능을 사전 정의하여야 합니다.
   */
  const setNativeBridge = () => {
    if (!window.native) {
      window.native = {
        navigate: (path: string) => {
          navigate(path);
          return;
        },
        refreshMember: async () => {
          await setLoginMember(await getMember());
        },
        onResume: runOnResume,
        onBackPressed: () => {},
        onLocationReceived: () => {},
        onLocationError: () => {},
      };
    }
  };

  /** 비 로그인시 refreshMember 메소드 동작을 방지합니다.
   * 로그인 시 refreshMember 메소드를 재정의합니다.
   */
  useEffect(() => {
    if (!window.native) return;
    if (loginMember) {
      window.native.refreshMember = async () => {
        await setLoginMember(await getMember());
      };
    } else {
      window.native.refreshMember = () => {};
    }
  }, [loginMember]);

  useLayoutEffect(() => {
    fetchHoliday();
    setInitLoginMember();
    setNativeBridge();

    dayjs.extend(duration);
    dayjs.extend(utc);
    dayjs.extend(timezone);

    if (
      process.env.REACT_APP_NODE_ENV === 'production' &&
      process.env.REACT_APP_GOOGLE_TAG_ID
    )
      ReactGA.initialize(process.env.REACT_APP_GOOGLE_TAG_ID);
  }, []);

  return (
    <div className='App flex flex-col h-full overflow-hidden'>
      <>
        <FileUploader />
        <CarsayoPayment />
        <Toaster
          theme={'dark'}
          className='!bottom-[var(--toastBottomPosition)]'
        />
        <LoadingDim />
        <CarsayoPopup />
      </>
      <TopTabbar />
      <ViewPoint>
        <SideMenu />
        <Outlet />
      </ViewPoint>
      <BottomTabbar />
      <InitRestore />
    </div>
  );
};

export default CarsayoRouter;
