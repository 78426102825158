import React from 'react';
import AskCardLayout from '../component/AskCardLayout';
import {
  InsuranceCompany,
  InsuranceCompanyData,
} from 'pages/static/insurance/data/company';
import { cn } from 'utils';
import { CustomerActionInterface } from '../ask';
import { InsuranceBannerOrder } from '../data';
import { useNavigate } from 'react-router-dom';
interface CarkeyMenuCardInterface {
  customerAction: CustomerActionInterface | null;
  setCustomerAction: (data: CustomerActionInterface) => void;
  nowTime: Date;
}

const CarkeyMenuCard = ({
  customerAction,
  setCustomerAction,
  nowTime,
}: CarkeyMenuCardInterface) => {
  const navigate = useNavigate();
  return (
    <AskCardLayout
      chatIcon='/assets/images/v2/chat-carkey-icon.png'
      chatName='카키'
      time={nowTime}
      title='안녕하세요, 카키입니다!'
    >
      <div className='w-full'>
        <p className='mb-[14px] text-[15px] leading-[22.5px] text-[#555555]'>
          궁금하신 사항의 메뉴를 선택해주세요.
        </p>
        <div className='w-full flex flex-col justify-start items-start gap-[10px]'>
          {/* 신차 구매 박스 */}
          <div className='w-full bg-[#E3E3F6] rounded-[10px] p-[12px]'>
            <h4 className='text-[16px] font-[600] mb-4 pl-[4px] pt-[4px]'>
              신차 구매
            </h4>
            <ul className='w-full flex flex-col gap-[6px] text-[15px] leading-[15px] font-[400]'>
              <li className='w-full'>
                <button
                  className='w-full pr-7 flex justify-start items-center relative bg-white p-[10px] rounded-[6px] gap-[10px] shadow-[0px_6px_10px_#1111110F]'
                  onClick={() => navigate('/purchase/brand?purchaseType=lease')}
                >
                  <div className='shrink-0 bg-[#2B3E7F33] w-[28px] h-[28px] border border-[#2B3E7F33] rounded-full flex justify-center items-center'>
                    <img
                      src='/assets/images/v2/Frame.png'
                      alt='자동차차고아이콘'
                      className='w-[18px] h-[18px] object-contain'
                    />
                  </div>
                  <span>리스로 상담 신청</span>
                  <img
                    src='/assets/images/v2/export.png'
                    alt='내보내기아이콘'
                    className='absolute right-[16px] bottom-0 top-0 my-auto w-[16px] object-contain'
                  />
                </button>
              </li>
              <li className='w-full'>
                <button
                  className='w-full pr-7 flex justify-start items-center relative bg-white p-[10px] rounded-[6px] gap-[10px] shadow-[0px_6px_10px_#1111110F]'
                  onClick={() => navigate('/purchase/brand?purchaseType=rent')}
                >
                  <div className='shrink-0 bg-[#7273F933] w-[28px] h-[28px] border border-[#7273F933] rounded-full flex justify-center items-center'>
                    <img
                      src='/assets/images/v2/rent.png'
                      alt='렌트아이콘'
                      className='w-[18px] h-[18px] object-contain'
                    />
                  </div>
                  <span>장기렌트로 상담 신청</span>
                  <img
                    src='/assets/images/v2/export.png'
                    alt='내보내기아이콘'
                    className='absolute right-[16px] bottom-0 top-0 my-auto w-[16px] object-contain'
                  />
                </button>
              </li>
              <li className='w-full'>
                <button
                  className='w-full pr-7 flex justify-start items-center relative bg-white p-[10px] rounded-[6px] gap-[10px] shadow-[0px_6px_10px_#1111110F]'
                  onClick={() =>
                    navigate('/purchase/brand?purchaseType=newCar')
                  }
                >
                  <div className='shrink-0 bg-[#FFD14233] w-[28px] h-[28px] border border-[#BF992333] rounded-full flex justify-center items-center'>
                    <img
                      src='/assets/images/v2/newCar.png'
                      alt='일시불신차아이콘'
                      className='w-[18px] h-[18px] object-contain'
                    />
                  </div>
                  <span>일시불 · 할부 상담 신청</span>
                  <img
                    src='/assets/images/v2/export.png'
                    alt='내보내기아이콘'
                    className='absolute right-[16px] bottom-0 top-0 my-auto w-[16px] object-contain'
                  />
                </button>
              </li>
            </ul>
          </div>
          {/* 내 차 팔기 박스 */}
          <div
            className='w-full bg-[#DFE8FB] rounded-[10px] p-[12px]'
            onClick={() => {
              navigate('/selling/landing');
            }}
          >
            <h4 className='text-[16px] font-[600] mb-4 pl-[4px] pt-[4px]'>
              내 차 팔기
            </h4>
            <div className='relative bg-gradient-to-b from-[#040709] to-[#1F3447] min-h-[120px] rounded-[6px] py-4 px-[14px] pb-[68px] overflow-hidden'>
              <h5 className='text-white text-[15px] leading-[15px] font-[600] mb-[8px]'>
                신차 구매에서 내 차 판매까지
              </h5>
              <p className='text-white text-[13px] leading-[13px] font-[300]'>
                판매 / 매입 통합 쇼핑 플랫폼을 경험하세요
              </p>
              <img
                src='/assets/images/v2/ask-sellCarImg.png'
                alt='차량이미지'
                className='absolute right-0 bottom-[4px] h-[52px] object-contain'
              />
            </div>
          </div>
          {/* 자동차 다이렉트보험 박스 */}
          <div
            className='w-full bg-[#D4F2E3] rounded-[10px] p-[12px]'
            onClick={() =>
              setCustomerAction({
                id: 'insuranceMenu',
                textShow: true,
                text: '자동차 다이렉트 보험',
                actionReturn: 'card',
                time: new Date(),
              })
            }
          >
            <h4 className='text-[16px] leading-[16px] font-[600] mb-4 pl-[4px] pt-[4px]'>
              다이렉트 자동차 보험
            </h4>
            <div className='w-full flex justify-end items-center mb-[8px]'>
              <ul>
                {InsuranceBannerOrder.map((el) => {
                  const targetCompanyName = el as InsuranceCompany;
                  const targetCompanyData =
                    InsuranceCompanyData[targetCompanyName];
                  return (
                    <img
                      key={targetCompanyName}
                      className={cn('ml-[-16px] float-left w-8 h-8')}
                      src={targetCompanyData.company.logo.png}
                      alt='보험사 로고'
                    />
                  );
                })}
              </ul>
            </div>
            <div className='w-full bg-white p-[10px] rounded-[6px] flex justify-start items-center gap-4'>
              <div className='bg-[#D4F2E3] p-4 flex justify-center items-center rounded-[4px]'>
                <img
                  src='/assets/images/v2/shield.png'
                  alt='방패이미지'
                  className='w-[32px] object-contain'
                />
              </div>
              <div className='w-full flex-1'>
                <p className='text-[13px] leading-[13px] mb-[6px]'>
                  9개 보험사 한번에 비교 후
                </p>
                <h5 className='text-[15px] leading-[15px] font-[600]'>
                  최대 혜택으로 가입하기
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AskCardLayout>
  );
};
export default CarkeyMenuCard;
