import ConfirmV2 from 'components/common/v3/confirm';
import Star from 'components/common/v3/icons/Star';
import { InputV2 } from 'components/common/v3/inputv2';
import { Button } from 'components/ui/button';
import { X } from 'lucide-react';
import { ChangeEvent, useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';
import { cn } from 'utils';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { FileInfo, ReviewOrderDTO } from '@carsayo/types';
import ImageUploadBox from 'components/common/v3/fileUploadBox';
import useOrderStore from './store';
import { convertDealerType } from 'utils/convert';
import { reviewOrder } from 'apis/order';
import { toast } from 'sonner';

export interface ImagesInterface {
  image: string;
  imageName: string;
}

export default function CarReviewAddPage() {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const { setTopTabbarState } = useSystemStore();
  const [subImages, setSubImages] = useState<FileInfo[]>([]);
  const [subImagePage, setSubImagePage] = useState(1);
  const [isSubImagesPopup, setSubImagesPopup] = useState(false);
  const [mainImages, setMainImages] = useState<FileInfo[]>([]);
  const [isValid, setIsValid] = useState(false);
  const navigate = useNavigate();
  const order = useOrderStore((state) => state.order);
  const clearOrder = useOrderStore((state) => state.clearOrder);

  const [reviewInfo, setReviewInfo] = useState<ReviewOrderDTO>({
    orderId: order?.id || '',
    rating: 0,
    title: '',
    content: '',
    file_1_id: undefined,
    file_2_id: undefined,
    file_3_id: undefined,
    file_4_id: undefined,
  });

  const handleImageUpload = (item: FileInfo, id?: string) => {
    if (id === 'file_1_id') {
      setMainImages([item]);
      setReviewInfo({ ...reviewInfo, [id as string]: item.id });
    } else {
      setSubImages((prev) => [...prev, item]);
    }
  };

  const handleRemoveImage = (index: number, isMainImage: boolean) => {
    if (isMainImage) {
      setMainImages([]);
      setReviewInfo({ ...reviewInfo, file_1_id: undefined });
    } else {
      setSubImages(subImages.filter((_, i) => i !== index));
    }
  };

  const openPopupAtSlide = (index: number) => {
    setSubImagePage(index + 1);
    setSubImagesPopup(true);
  };

  const handleAddReview = async () => {
    try {
      const fileIds = subImages.map((file) => file.id);
      const submitData = {
        ...reviewInfo,
        file_2_id: fileIds[0] || undefined,
        file_3_id: fileIds[1] || undefined,
        file_4_id: fileIds[2] || undefined,
      };

      await reviewOrder(submitData);
      setIsConfirmOpen(true);
    } catch (error) {
      toast.error((error as any).message);
    }
  };

  const checkValidForm = () => {
    return (
      reviewInfo.orderId &&
      reviewInfo.rating > 0 &&
      reviewInfo.title &&
      reviewInfo.content &&
      reviewInfo.file_1_id
    );
  };

  useEffect(() => {
    const res = checkValidForm();
    setIsValid(res as boolean);
  }, [reviewInfo]);

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      rightElement: (
        <button onClick={() => navigate(-1)}>
          <X width={28} height={28} strokeWidth={1.5} />
        </button>
      ),
      leftElement: undefined,
      title: '리뷰 작성',
    });
  }, []);

  if (!order) return <></>;
  return (
    <div className='w-full mb-[10px]'>
      <div className='pt-[20px] pb-[50px]'>
        <div className='w-[calc(100%-40px)] mx-auto flex justify-start items-center gap-4 pb-[20px] border-b border-[#E5EAEE] mb-[20px]'>
          <div className='bg-[#F3F6F9] flex justify-center items-center w-[140px] h-[110px] rounded-[10px] shrink-0 overflow-hidden'>
            <img
              src={order.imageUrl}
              alt='예시 이미지'
              className='w-[90%] object-contain'
            />
          </div>
          <div className='flex flex-col justify-start items-start gap-1 flex-1 w-full'>
            <span className='inline-block mb-[2px] text-[14px] leading-[14px] font-[500] text-[#555]'>
              {order.type === 'newCar'
                ? order.order_newCar.installmentTerm > 0
                  ? '할부'
                  : '일시불'
                : order.type === 'leaseRent'
                  ? convertDealerType[
                      order?.order_leaseRent?.type as 'lease' | 'rent'
                    ]
                  : ''}
            </span>
            <strong className='inline-block text-[16px] leading-[25.6px] font-[600] text-[#222] break-keep whitespace-pre-line'>
              {order.title}
            </strong>
            <p className='break-keep whitespace-pre-line text-[14px] leading-[22.4px] font-[500] text-[#555]'>
              {order.type === 'newCar'
                ? order.order_newCar.car.car_trim.name
                : order.type === 'leaseRent'
                  ? order?.order_leaseRent?.car.car_trim.name
                  : order?.order_sellCar?.coocon_carInfo.carName}
            </p>
          </div>
        </div>
        <div className='bg-[#F3F6F9] rounded-[16px] w-[calc(100%-40px)] mx-auto px-4 py-[20px] flex justify-start items-center gap-4 mb-[30px]'>
          <div className='w-[56px] h-[56px] flex justify-center items-center overflow-hidden rounded-full'>
            <img
              src='/assets/images/png/hospital-ex-img01.png'
              alt=''
              className='w-full h-full object-cover'
            />
          </div>
          <div className='flex flex-col justify-start items-start gap-2'>
            <strong className='text-[15px] leading-[21px] font-[600]'>
              {order.member_order_manager_idTomember?.name_nick}
            </strong>
            <div className='flex justify-start items-center'>
              <Star fill={'#A6ADB4'} width={'12'} height={'12'} />
              <span className='inline-block ml-[2px] text-[14px] leading-[14px] font-[500] text-[#555]'>
                {order.member_order_manager_idTomember?.member_dealer.rating}
              </span>
              <span className='inline-block px-1 text-[14px] leading-[14px] text-[#D5DADF]'>
                •
              </span>
              <span className='text-[14px] leading-[14px] font-[500] text-[#555555]'>
                {order.type === 'newCar'
                  ? '신차 매니저'
                  : order.type === 'leaseRent'
                    ? order?.order_leaseRent?.type === 'lease'
                      ? '리스 매니저'
                      : '렌트 매니저'
                    : '카 매니저'}
              </span>
            </div>
          </div>
        </div>
        <div className='border-t-[8px] border-[#F3F6F9] px-[20px] pt-[50px]'>
          <div className='flex flex-col justify-start items-center gap-6 mb-[50px]'>
            <p className='max-w-[184px] text-center break-keep text-[20px] leading-[30px] font-[600]'>
              상담부터 출고까지 원활하게 진행되었나요?
            </p>
            <div className='flex items-center justify-center gap-[2px]'>
              {[...Array(5)].map((_, index) => (
                <div
                  key={index}
                  onClick={async () => {
                    setReviewInfo({ ...reviewInfo, rating: index + 1 });
                  }}
                >
                  {reviewInfo.rating ? (
                    <Star
                      fill={index < reviewInfo.rating ? '#FFC93E' : '#DDDDDD'}
                      width={'32'}
                      height={'32'}
                    />
                  ) : (
                    <Star
                      fill={index < 0 ? '#FFC93E' : '#DDDDDD'}
                      width={'32'}
                      height={'32'}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className='w-full flex flex-col justify-start items-start gap-[12px]'>
            <label
              htmlFor='reviewTitle'
              className='block text-[15px] leading-[15px]'
            >
              리뷰 제목
            </label>
            <InputV2
              id='reviewTitle'
              value={reviewInfo.title}
              placeholder='제목을 입력해주세요.'
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setReviewInfo({ ...reviewInfo, title: e.target.value });
              }}
              className='mb-[12px]'
            />
            <strong className='text-[15px] leading-[15px] font-[400]'>
              리뷰 내용
            </strong>
            <div className='relative pb-[44px] pt-[12px] px-4 border border-[#E5E5EC] rounded-[8px] h-[300px] overflow-hidden w-full'>
              <textarea
                name=''
                id=''
                value={reviewInfo.content}
                onChange={(e) =>
                  setReviewInfo({ ...reviewInfo, content: e.target.value })
                }
                className='w-full text-[16px] leading-[25.6px] font-[400] h-[234px] placeholder:text-[16px] placeholder:font-[400] placeholder:text-[#999] overflow-y-auto resize-none focus:outline-none focus:ring-0 focus:border-none'
                placeholder='내용을 입력해 주세요.'
              ></textarea>
              <span className='text-[14px] leading-[22.4px] font-[500] text-[#999999] absolute right-4 bottom-[12px]'>
                {reviewInfo.content.length ?? 0}/1,000
              </span>
            </div>
          </div>
          <div>
            <div className='w-full'>
              <div
                className={cn(
                  'flex w-full items-center justify-between mb-[16px] mt-[24px]',
                )}
              >
                <strong className=' inline-block text-[15px] leading-[15px] font-[400]'>
                  필수 사진
                </strong>
              </div>
              <div className='mb-6 w-full'>
                <div className='relative flex min-h-[178px] items-center justify-start gap-[6px]'>
                  {mainImages && (
                    <ul className='flex w-full items-center justify-start gap-[6px]'>
                      {mainImages.map((item, index) => (
                        <li
                          className='relative h-[178px] w-full overflow-hidden rounded-[6px]'
                          key={`${item.name}_${index}`}
                        >
                          <img
                            src={item.url}
                            alt={item.name}
                            className='h-full w-full object-cover'
                          />
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              handleRemoveImage(index, true);
                            }}
                            className='absolute right-2.5 top-2.5 z-10 flex h-[26px] w-[26px] items-center justify-center rounded-full bg-[#000000CC]'
                          >
                            <X width={15.6} height={15.6} color='#fff' />
                          </button>
                        </li>
                      ))}
                    </ul>
                  )}
                  <ImageUploadBox
                    id='file_1_id'
                    onUpload={handleImageUpload}
                    type='dashed'
                    width={'100%'}
                    height={'178px'}
                    maxCount={1}
                    currentCount={mainImages.length}
                    className={cn(
                      'absolute left-0 top-0',
                      mainImages && mainImages.length > 0 && 'opacity-0',
                    )}
                  />
                </div>
              </div>
            </div>
            <div className='w-full'>
              <div
                className={cn(
                  'flex w-full items-center justify-between',
                  subImages.length > 0
                    ? 'mb-[9px] mt-[17px]'
                    : 'mb-[16px] mt-[24px]',
                )}
              >
                <strong className=' inline-block text-[15px] leading-[15px] font-[400]'>
                  추가 사진
                </strong>
                {subImages.length > 0 && (
                  <button
                    type='button'
                    onClick={() => setSubImagesPopup(true)}
                    className='rounded-[8px] bg-[#F3F6F9] border border-[#E5EAEE] p-2 text-[13px] font-[500] leading-[13px]'
                  >
                    전체보기
                  </button>
                )}
              </div>
              <div className='w-full overflow-x-auto'>
                <div className='flex items-center justify-start gap-[6px]'>
                  {subImages.length < 3 && (
                    <ImageUploadBox
                      id='file_id'
                      onUpload={handleImageUpload}
                      type='dashed'
                      width={'107px'}
                      height={'107px'}
                      maxCount={3}
                      currentCount={subImages.length}
                    />
                  )}
                  <ul className='flex items-center justify-start gap-[7px]'>
                    {subImages &&
                      subImages.map((item, index) => (
                        <li
                          className='w-full h-full flex justify-center items-center'
                          key={`${item.name}_${index}`}
                        >
                          <div
                            className='relative h-[107px] w-[107px] overflow-hidden rounded-[6px]'
                            onClick={(e) => {
                              e.preventDefault();
                              openPopupAtSlide(index);
                            }}
                          >
                            <img
                              src={item.url}
                              alt={item.name}
                              className='h-full w-full object-cover'
                            />
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                handleRemoveImage(index, false);
                              }}
                              className='absolute right-1 top-1 z-10 flex h-[20px] w-[20px] items-center justify-center rounded-full bg-[#000000CC]'
                            >
                              <X width={12} height={12} color='#fff' />
                            </button>
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Button
        onClick={handleAddReview}
        type='button'
        disabled={!isValid}
        className='w-[calc(100%-40px)] rounded-[10px] mx-auto flex justify-center items-center h-[54px] p-[19px] text-[16px] leading-[16px] font-[500]'
      >
        등록하기
      </Button>
      <ConfirmV2
        inImg='/assets/images/png/review_done_img.png'
        title={'리뷰 작성 완료'}
        desc={`작성해 주셔서 감사합니다.
해당 리뷰는 같은 고민이신 분들께
큰 도움이 될 예정이에요.`}
        isOpen={isConfirmOpen}
        setIsOpen={setIsConfirmOpen}
        onConfirm={() => {
          clearOrder();
          navigate('/mypage/review');
        }}
      />
      {subImages && subImages.length > 0 && (
        <Dialog
          sx={{
            '& .MuiDialog-paper': {
              height: '100dvh',
              width: '100dvw',
              maxWidth: 'none',
              margin: 0,
              padding: 0,
            },
            '& .MuiPaper-root': {
              height: '100dvh',
              width: '100dvw',
              maxHeight: '100dvh',
            },
          }}
          open={isSubImagesPopup}
          onClose={() => {
            setSubImagesPopup(false);
            setSubImagePage(1);
          }}
          fullWidth
          maxWidth={'lg'}
          className='bg-[#111111]'
        >
          <DialogTitle className='!relative !flex !min-h-[56px] !items-center !justify-center !bg-[#111] !px-[48px] !py-[12px] !text-[16px] !font-[500] !leading-[16px] text-white'>
            <span>
              {subImagePage}/{subImages.length}
            </span>
            <button
              onClick={() => {
                setSubImagesPopup(false);
                setSubImagePage(1);
              }}
              className='!absolute !bottom-0 !right-4 !top-0 !my-auto !flex !h-8 !w-8 !items-center !justify-center'
            >
              <X width={28} height={28} color='#fff' strokeWidth='1.5' />
            </button>
          </DialogTitle>
          <DialogContent className='!flex !w-full scale-100 !items-center !justify-center bg-[#111111] !p-0'>
            <Swiper
              slidesPerView={1}
              className='w-full'
              spaceBetween={10}
              onSlideChange={(swiper: SwiperClass) => {
                setSubImagePage(Math.ceil(swiper.activeIndex + 1));
              }}
              modules={[Pagination]}
              centeredSlides={true}
              initialSlide={subImagePage - 1}
            >
              {subImages.map((item, index) => (
                <SwiperSlide key={`slide_${index}`} className='!h-auto w-full'>
                  <img
                    src={item.url}
                    alt={item.name}
                    className={cn('relative h-full w-full object-contain')}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}
