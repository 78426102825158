import { Box } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import React, { useRef } from 'react';
import { isAndroid } from 'react-device-detect';

import { FileInfo } from '@carsayo/types';

import { useFileUpload } from 'hooks_v3/useFileUpload';
import { cn } from 'utils';

type AcceptType =
  | 'image/*'
  | 'audio/*'
  | 'video/*'
  | 'text/*'
  | 'application/pdf'
  | '.jpg'
  | '.jpeg'
  | '.png'
  | '.gif';

export interface FileInputProps
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    'accept' | 'onChange' | 'type'
  > {
  accept: AcceptType | AcceptType[];
  onUpload: (fileInfo: FileInfo) => void;

  /** 초기 파일 아이디값 */
  initialFileId?: string;
  /**
   * 파일명을 직접 설정할 수 있습니다.
   * @default 업로드 파일명
   */
  fileName?: string;
  error?: boolean;
  helperText?: string;
}

interface ImageFileUploadBoxProps extends FileInputProps {
  /** 컴포넌트 설정 */
  id: string;
  maxCount: number;
  currentCount?: number;
  width?: string;
  height?: string;
  className?: string;

  /** 에러 처리용 */
  error?: boolean;
}

export default function ImageFileUploadBox({
  accept,
  onUpload,

  initialFileId,
  fileName,
  error,

  id,
  maxCount,
  currentCount,
  width,
  height,
  className,

  ...rest
}: ImageFileUploadBoxProps) {
  const queryClient = useQueryClient();
  const acceptValue = Array.isArray(accept) ? accept.join(',') : accept;

  const { handleFileUpload, isUploading } = useFileUpload();

  const ref = useRef<HTMLInputElement>(null);

  return (
    <>
      <Box
        sx={{
          width: width ? width : '100%',
          aspectRatio: '1/1',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          border: error ? '2px dashed #FF4D4D' : '2px dashed #E5EAEE',
          borderRadius: '8px',
          backgroundColor: rest.disabled ? '#F1F4F7' : undefined,
          boxSizing: 'border-box',
          maxWidth: '100%',
          cursor: 'pointer',
          flexShrink: '0',
        }}
        className={cn(className)}
      >
        <label
          htmlFor={id}
          className='flex h-full w-full items-center justify-center'
        >
          <input
            {...rest}
            id={id}
            ref={ref}
            type='file'
            /** 안드로이드 웹뷰일 때에만 true */
            capture={isAndroid && Boolean(window.android) ? true : false}
            accept={acceptValue}
            disabled={isUploading || rest.disabled}
            style={{
              display: 'none',
            }}
            onChange={(e) =>
              handleFileUpload(
                e,
                (fileInfo: FileInfo) => {
                  queryClient.setQueryData(['fileInfo', fileInfo.id], fileInfo);
                  onUpload(fileInfo);

                  // 업로드 완료 후 input 초기화
                  if (ref.current) {
                    ref.current.value = ''; // input 값 초기화
                  }
                },
                {
                  name: fileName ?? undefined,
                },
              )
            }
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '6px',
              width: '32px',
              minHeight: '53px',
            }}
          >
            <img
              src='/assets/images/png/camera.png'
              alt='카메라 아이콘'
              className='w-full object-contain'
            />
            <div className='text-[15px] font-[500] leading-[15px] text-[#7D848A]'>
              <span>{currentCount ?? 0}</span>/<span>{maxCount}</span>
            </div>
          </div>
        </label>
      </Box>
    </>
  );
}
