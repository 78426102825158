/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import useSystemStore from 'store/useSystemStore';
import { cn } from 'utils';
import 'swiper/swiper-bundle.css';
import UnAnsweredReviewItem from './components/review/UnAnsweredReviewItem';
import { ReviewProps, ReviewStatusType } from 'apis/review';
import { useGetReviewList } from './query';
import InfiniteScroll from 'react-infinite-scroll-component';
import AnsweredReviewItem from './components/review/AnsweredReviewItem';
import { Loader2 } from 'lucide-react';
import NoData from 'components/common/v3/NoData';

export default function ReviewList() {
  const { setTopTabbarState, setIsBottomTabbar } = useSystemStore();
  const tabs = [
    {
      id: 'not_written',
      title: '작성 가능한 리뷰',
    },
    {
      id: 'written',
      title: '작성한 리뷰',
    },
  ];

  const [searchInfo, setSearchInfo] = useState<ReviewProps>({
    status: 'not_written',
    filter: 'new',
    skip: 0,
    take: 10,
  });

  const tabRefs = useRef<(HTMLButtonElement | null)[]>([]);
  const scrollDivRef = useRef<HTMLDivElement>(null);

  const {
    data: reviewListData,
    hasNextPage,
    isFetching,
    fetchNextPage,
    refetch,
  } = useGetReviewList(searchInfo);

  useEffect(() => {
    refetch();
    if (scrollDivRef.current) {
      scrollDivRef.current.scrollTo({
        top: 0,
        behavior: 'auto',
      });
    }
  }, [searchInfo]);

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      title: '리뷰 관리',
      rightElement: undefined,
      leftElement: 'back',
    });
    setIsBottomTabbar(false);
  }, []);
  return (
    <div className='h-full overflow-y-hidden'>
      {/* 상단 탭 메뉴 */}
      <div className='w-full overflow-x-auto shrink-0'>
        <div
          className={cn(
            'tab-menu w-full flex justify-start items-center border-b border-[#E5E5EC]',
          )}
        >
          {tabs.map((menu, index) => (
            <button
              key={`reviewPage_${menu.id}_${index}`}
              ref={(el) => {
                tabRefs.current[index] = el;
              }}
              className={cn(
                `w-[50%] ab-button shrink-0 flex justify-center items-center text-[15px] leading-[15px] pt-[18px] pb-[17px] px-[25px] transition-all`,
                searchInfo.status === menu.id
                  ? 'font-[500] relative text-[#000] after:transition-all after:w-full after:h-[2px] after:content-[""] after:bg-[#222] after:absolute after:bottom-[-1px] after:left-0 after:right-0 after:mx-auto'
                  : 'font-[400] text-[#999]',
              )}
              onClick={() => {
                setSearchInfo({
                  ...searchInfo,
                  status: menu.id as ReviewStatusType,
                  filter: 'new',
                });
              }}
            >
              {menu.title}
            </button>
          ))}
        </div>
      </div>
      {/* 컨텐츠 영역 */}
      <div className='w-full h-full bg-[#F3F6F9] pt-[20px] pb-[100px]'>
        <div className='px-[20px] pb-[20px]'>
          <ul className='flex justify-start items-center gap-1.5'>
            <li
              onClick={() => setSearchInfo({ ...searchInfo, filter: 'new' })}
              className={cn(
                'inline-flex justify-center items-center transition-all h-[34px] py-[10px] px-[14px] rounded-[100px] text-[14px] leading-[14px] font-[400]',
                searchInfo.filter === 'new'
                  ? 'bg-[#E6E6F6] border text-[#7273F9] border-[#7273F9] font-[600]'
                  : 'bg-white text-[#222] font-[400]',
              )}
            >
              신차 구매
            </li>
            <li
              onClick={() => setSearchInfo({ ...searchInfo, filter: 'sell' })}
              className={cn(
                'inline-flex justify-center items-center transition-all h-[34px] py-[10px] px-[14px] rounded-[100px] text-[14px] leading-[14px] font-[400]',
                searchInfo.filter === 'sell'
                  ? 'bg-[#E6E6F6] border text-[#7273F9] border-[#7273F9] font-[600]'
                  : 'bg-white text-[#222] font-[400]',
              )}
            >
              내 차 팔기
            </li>
            <li
              onClick={() => setSearchInfo({ ...searchInfo, filter: 'repair' })}
              className={cn(
                'inline-flex justify-center items-center transition-all h-[34px] py-[10px] px-[14px] rounded-[100px] text-[14px] leading-[14px] font-[400]',
                searchInfo.filter === 'repair'
                  ? 'bg-[#E6E6F6] border text-[#7273F9] border-[#7273F9] font-[600]'
                  : 'bg-white text-[#222] font-[400]',
              )}
            >
              공업사
            </li>
          </ul>
        </div>
        {isFetching ? (
          <div className='w-full items-center justify-center text-center'>
            <Loader2 className='animate-spin mx-auto my-2' />
          </div>
        ) : reviewListData && reviewListData.pages[0].totalCnt > 0 ? (
          <div
            className='w-full h-full overflow-y-auto'
            id='reviewScroll'
            ref={scrollDivRef}
          >
            <InfiniteScroll
              next={fetchNextPage}
              hasMore={hasNextPage}
              loader={
                <div className='font-semibold text-center py-4'>
                  정보를 불러오는 중입니다..
                </div>
              }
              dataLength={
                reviewListData.pages.flatMap((page) => page.orders).length || 0
              }
              scrollableTarget='reviewScroll'
            >
              {reviewListData.pages
                .flatMap((page) => page.orders)
                .map((item, index) => (
                  <div
                    className='grid grid-cols-1 gap-[20px] pb-[20px]'
                    key={`reviewList_${item.id}`}
                  >
                    {searchInfo.status === 'not_written' ? (
                      <UnAnsweredReviewItem
                        type={searchInfo.filter}
                        item={item}
                      />
                    ) : (
                      <AnsweredReviewItem
                        type={searchInfo.filter}
                        item={item}
                        refetch={refetch}
                      />
                    )}
                  </div>
                ))}
            </InfiniteScroll>
          </div>
        ) : (
          <div className='w-full px-[20px] min-h-[calc(100dvh-180px)] flex justify-center items-center pb-[30%]'>
            <NoData text='등록된 리뷰가 없어요' />
          </div>
        )}
      </div>
    </div>
  );
}
