import { MemberGender } from '@carsayo/types';
import { z } from 'zod';

export const AccidentReportSchema = z.object({
  info_name: z.string().min(1, {
    message: '이름을 입력해주세요',
  }),
  info_phoneNumber: z.string().min(1, {
    message: '연락처를 입력해주세요',
  }),
  info_birth: z.string().min(1, {
    message: '생년월일을 입력해주세요',
  }),
  info_gender: z.nativeEnum(MemberGender, {
    message: '성별을 선택해주세요',
  }),
  carName: z.string().min(1, {
    message: '차량번호를 입력해주세요',
  }),
  insuranceCompany: z
    .number({
      required_error: '보험사를 선택해주세요',
    })
    .positive({
      message: '보험사를 선택해주세요',
    }),
  option_traction: z.boolean(),
  info_address: z.string(),
  latitude: z
    .number({
      required_error: '위도를 입력해주세요',
    })
    .refine((val) => val !== null, {
      message: '위도를 입력해주세요',
    }),
  longitude: z
    .number({
      required_error: '경도를 입력해주세요',
    })
    .refine((val) => val !== null, {
      message: '경도를 입력해주세요',
    }),
  partner_repairshop_id: z.string().uuid({
    message: '공업사가 선택되지 않았습니다',
  }),

  /** 추가 사진 URL */
  pictures: z.array(z.string()),
});

export type AccidentReportForm = z.infer<typeof AccidentReportSchema>;
