/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button } from 'components/ui/button';
import InsuranceBanner from './component/banner';
import { InsuranceCompany, InsuranceCompanyData } from './data/company';
import {
  ArrowUp,
  ArrowUpToLine,
  ChevronLeft,
  ChevronRight,
} from 'lucide-react';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { InterestFreeSubpageState } from './data/type';
import { InsuranceCard } from './data/card';
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';
import {
  addHistoryInsuranceAdLinkClick,
  addHistoryInsuranceCooconClick,
} from 'apis/history';
import useDeviceStore from 'store/useDeviceStore';

export default function InsuranceMain() {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const insuranceKey = location.state?.key ?? null;
  // 카사요 앱 외부에서 접근 시 : ex. insurance
  const externalAccessKey = searchParams.get('type');
  const { isBottomTabbar, setTopTabbarState, setIsBottomTabbar } =
    useSystemStore();
  const { myDevice } = useDeviceStore();

  /** Subpage is opened or not */
  const [interestFreeSubpageState, setInterestFreeSubpageState] = useState<
    InterestFreeSubpageState | undefined
  >();

  const outerDivRef = useRef<HTMLDivElement>(null);
  const scrollTopRef = useRef<SVGSVGElement>(null);
  const companySelecteRef = useRef<null[] | HTMLDivElement[]>([]);

  const handleClickEvents = {
    moveToCompany: (insuranceCompany: InsuranceCompany) => {
      const targetIndex =
        Object.keys(InsuranceCompanyData).indexOf(insuranceCompany);

      companySelecteRef.current[targetIndex]?.scrollIntoView({
        inline: 'center',
        block: 'center',
        behavior: 'smooth',
      });
    },
    openCooconInsurance: () => {
      addHistoryInsuranceCooconClick({
        from: `app${location.pathname}`,
        target: '쿠콘',
      });
      if (process.env.REACT_APP_NODE_ENV === 'development') {
        window.open(
          'https://insu-dev.coocon.co.kr/bridge.act?org_cd=RUQBBRKU&browser_type=2',
        );
        return;
      }
      window.open(
        'https://insu.coocon.net/bridge.act?org_cd=RUQBBRKU&browser_type=2',
      );
    },
    showInterestFree: (data: InterestFreeSubpageState) => {
      navigate({ search: 'interestFree' });
      setInterestFreeSubpageState(data);
    },
    redirectExternalPage: (insuranceCompany: InsuranceCompany) => {
      handleClickEvents.moveToCompany(insuranceCompany);
    },
  };

  useEffect(() => {
    if (location.search === '' && interestFreeSubpageState)
      setInterestFreeSubpageState(undefined);
  }, [location.search]);

  useEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      leftElement: (
        <ChevronLeft
          strokeWidth={1.5}
          onClick={() => {
            navigate(-1);
          }}
          className='h-7 w-7 cursor-pointer'
        ></ChevronLeft>
      ),
      title: interestFreeSubpageState ? '무이자 혜택' : '자동차 보험',
    });
  }, [interestFreeSubpageState]);

  useEffect(() => {
    if (!outerDivRef.current) return;

    function handleScrollTopButton() {
      if (!outerDivRef.current || !scrollTopRef.current) return;
      if (outerDivRef.current.scrollTop > 2500) {
        if (scrollTopRef.current.style.display !== 'block')
          scrollTopRef.current.style.display = 'block';
      } else {
        if (scrollTopRef.current.style.display !== 'none') {
          scrollTopRef.current.style.display = 'none';
        }
      }
    }

    outerDivRef.current.addEventListener('scroll', handleScrollTopButton);
    return () => {
      if (!outerDivRef.current) return;
      outerDivRef.current.removeEventListener(
        'scrollend',
        handleScrollTopButton,
      );
    };
  }, []);

  useEffect(() => {
    if (insuranceKey) {
      handleClickEvents.redirectExternalPage(insuranceKey);
    }
  }, [insuranceKey]);

  useLayoutEffect(() => {
    setIsBottomTabbar(false);

    if (isBottomTabbar === true) {
      return () => {
        setIsBottomTabbar(true);
      };
    }
  }, []);

  return (
    <div
      ref={outerDivRef}
      className='flex flex-col h-full overflow-y-scroll bg-[#F3F6F9] pb-[50px]'
    >
      {/* 자동차 보험 비교하기 */}
      {externalAccessKey !== 'insurance' && (
        <section className='flex items-center flex-col pt-[40px]  pb-2bg-[#F3F6F9]'>
          <div className='flex w-full px-[20px] mb-[54px]'>
            <div className='text-[22px] leading-[33px] font-[600] max-w-[238px] break-keep'>
              보험사 한 번에 비교하고 부담을 덜어보세요.
            </div>
          </div>

          {/* <div className='flex flex-col items-center'>
          <div className='mt-8 text-white bg-[#3A474E] px-4 py-2 font-medium tracking-tight rounded shadow-all-select-btn'>
            <span className='text-[#EDAA46]'>9개 보험사 한번에 비교하고</span>{' '}
            부담을 덜어보세요 !
          </div>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='11'
            height='6'
            viewBox='0 0 11 6'
            fill='none'
          >
            <path d='M0.5 0L5.5 6L10.5 0H0.5Z' fill='#3A474E' />
          </svg>
        </div> */}
          <div className='relative w-full flex flex-col justify-start items-center min-h-[150px] px-[20px] bg-[#E5EAEE] pt-[37px]'>
            <Button
              className='rounded-[10px] bg-[#7273F9]'
              onClick={handleClickEvents.openCooconInsurance}
            >
              자동차 보험 비교하기
            </Button>
            <div className='w-full pt-3 flex gap-2 justify-between text-[11px] leading-[16.5px] font-[400] text-[#999999]'>
              <div>손해보험협회 심의필 제129622호(2024.08.01~2025.07.31)</div>
              <div className='shrink-0'>쿠콘</div>
            </div>
            <div className='absolute top-[-45px] right-[30px] flex justify-center items-center gap-[20px]'>
              <img
                src='/assets/images/png/insurance_ad_carImg01.png'
                alt='파란 자동차'
                className='w-[75px] object-contain'
              />
              <img
                src='/assets/images/png/insurance_ad_carImg02.png'
                alt='빨간 자동차'
                className='w-[75px] object-contain'
              />
            </div>
          </div>
        </section>
      )}

      {/* 다이렉트 자동차보험 가입 */}
      <section className='flex items-center flex-col pt-[30px]'>
        <div className='flex items-center justify-between w-full px-[20px]'>
          <div className='flex gap-2 items-center'>
            <span className='text-[20px] leading-[30px] font-[600] tracking-[-0.02em]'>
              다이렉트 자동차보험 가입
            </span>
          </div>
          <Button className='w-[54px] h-[32px] text-[#6100FF] bg-[#EEE3FF] rounded-[4px] text-[16px] font-[600] leading-[16px]'>
            AD
          </Button>
        </div>

        <div className='flex flex-none px-[17px] py-6 h-fit overflow-x-auto max-w-full'>
          {Object.keys(InsuranceCompanyData)
            .filter((item) => item === '하나손해보험' || item === '메리츠화재')
            .map((el) => {
              const targetCompanyName = el as InsuranceCompany;
              const targetCompany = InsuranceCompanyData[targetCompanyName];

              if (targetCompany.products.length === 0) return null;

              return (
                <div
                  key={targetCompanyName}
                  onClick={() => {
                    handleClickEvents.redirectExternalPage(targetCompany.id);
                  }}
                  className={
                    'flex flex-none cursor-pointer bg-white hover:bg-gray-50 h-fit gap-2.5 mx-[3px] px-4 py-3 rounded-full border border-[#E5E5EC] flex-shrink-0 justify-center items-center'
                  }
                >
                  <img
                    className='w-5 h-5'
                    src={targetCompany.company.logo.png}
                    alt='보험사 로고'
                  />
                  <div className='text-[14px] leading-[14px] font-[500]'>
                    {targetCompanyName}
                  </div>
                </div>
              );
            })}
        </div>
      </section>

      {/* 보험사별 혜택 영역 */}
      <section className='flex items-center flex-col px-5 gap-5 mb-[30px]'>
        {Object.keys(InsuranceCompanyData)
          //  하나손해보험만 허용
          .filter((item) => item === '하나손해보험' || item === '메리츠화재')
          .map((el, index) => {
            const targetCompanyName = el as InsuranceCompany;
            const targetCompany = InsuranceCompanyData[targetCompanyName];

            const targetIndex =
              Object.keys(InsuranceCompanyData).indexOf(targetCompanyName);

            if (targetCompany.products.length === 0) return null;

            return targetCompany.products.map((el2, index_2) => {
              return (
                <div
                  key={targetCompanyName + index_2}
                  ref={(el) => (companySelecteRef.current[targetIndex] = el)}
                  className='bg-white p-4 w-full rounded-[16px] shadow-[0px_6px_20px_#0000000D]'
                  onClick={() => {
                    addHistoryInsuranceAdLinkClick({
                      from: `app${location.pathname}`,
                      target: targetCompanyName,
                    });

                    if (!el2.data.url || !el2.data.mUrl) return;

                    window.open(
                      myDevice === 'ios' || myDevice === 'android'
                        ? el2.data.mUrl
                        : el2.data.url,
                    );
                  }}
                >
                  <InsuranceBanner
                    className='w-full mb-5'
                    company={targetCompany}
                    product={el2}
                  ></InsuranceBanner>

                  {/* Description */}
                  <div className='flex border-b pb-4 border-[#E5E5EC]'>
                    <div className={`flex flex-col justify-between flex-grow`}>
                      {el2.data.description.title && (
                        <span
                          className={`text-[18px] font-semibold tracking-[-0.65px] pb-2`}
                        >
                          {el2.data.description.title}
                        </span>
                      )}
                      <span
                        className={`text-[14px] leading-[22.4px] font-normal tracking-[-0.025em] text-[#555555] break-all`}
                      >
                        {el2.data.description.content}
                      </span>
                    </div>
                  </div>

                  {/* InterestFree */}
                  {el2.data.interestFree && (
                    <div className='flex flex-col py-4 border-b'>
                      <div className='flex justify-between pb-4'>
                        <span
                          className={`text-[16px] font-medium tracking-tight`}
                        >
                          무이자 혜택
                        </span>
                        <div
                          className='flex items-center cursor-pointer'
                          onClick={() => {
                            if (!el2.data.interestFree) return;
                            handleClickEvents.showInterestFree({
                              card: Object.keys(
                                el2.data.interestFree,
                              )?.[0] as InsuranceCard,
                              company: targetCompanyName,
                            });
                          }}
                        >
                          <span className='text-[14px] font-normal tracking-tight'>
                            자세히 보기
                          </span>
                          <ChevronRight className='h-5 w-5' />
                        </div>
                      </div>

                      <div className='py-0.5 flex flex-wrap text-[13px] gap-2'>
                        {Object.keys(el2.data.interestFree).map((el) => {
                          return (
                            <div
                              key={el}
                              className='bg-[#F7F7FB] w-fit flex justify-center items-center py-1 px-2'
                            >
                              {el}
                            </div>
                          );
                        })}
                      </div>

                      <Button
                        className='mt-6 mb-2 rounded-none bg-[#6100FF]'
                        onClick={() => {
                          handleClickEvents.redirectExternalPage(
                            targetCompanyName,
                          );
                        }}
                      >
                        자동차 보험 가입하기
                      </Button>
                    </div>
                  )}

                  <div className='mt-[14px] flex items-center justify-between w-full gap-6'>
                    <span
                      className={`text-[11px] text-[#999999] font-[400] leading-[16.5px] tracking-[-0.025em] break-keep whitespace-pre-line`}
                    >
                      {el2.data.footer.content}
                    </span>

                    <Button className='w-[54px] h-[32px] text-[#6100FF] bg-[#EEE3FF] rounded-[4px] text-[16px] font-[600] leading-[16px]'>
                      AD
                    </Button>
                  </div>
                </div>
              );
            });
          })}
      </section>

      {/* 안내사항 영역 */}
      <section className='flex flex-col p-4 pb-[20px] bg-[#E7EBEF] tracking-[-0.025em] w-[calc(100%-40px)] mx-auto rounded-[10px]'>
        <span className='text-[15px] font-[500] leading-[22.5px] tracking-[-0.02em] pb-4 mb-5 border-b border-[#F3F6F9]'>
          확인해 주세요
        </span>

        <ul className='list-decimal pl-4 text-[#555555] break-all font-[400] text-[13px] leading-[19.5px]'>
          <li className='pl-0.5 mb-3'>
            차 보험 광고 (차 보험 가입하기)는 카사요(주)가 제휴 보험회사의
            다이렉트 자동차 보험 상품을 광고하는 서비스입니다. 본 광고 서비스를
            통하여 카사요(주)는 상품 판매 및 중개에 관여하지 않습니다.
          </li>
          <li className='pl-0.5 mb-3'>
            차 보험 가입 혜택을 받으려면 아래 조건을 충족해야합니다.
            <ul className='list-disc text-[12px] leading-[18px] font-[500] mt-2'>
              <li className='mb-1'>
                캐롯손해보험, 악사다이렉트자동차보험 가입이력이 없는 신규 가입자
              </li>
              <li className='mb-1'>연보험료 30만원 이상 결제자</li>
            </ul>
          </li>

          <li className='pl-0.5 mb-3'>
            한 보험사의 차 보험 가입 광고로 받을 수 있는 혜택은 최대
            30,000원입니다.
            <ul className='list-disc text-[12px] leading-[18px] font-[500] mt-2'>
              <li>
                예를 들어, 삼성화재의 차 보험료 조회를 통해 7,000원 상당의
                혜택을 이미 수령했다면, 삼성화재 다이렉트 차 보험 가입 광고를
                통해서는 최대 23,000원의 혜택을 수령하실 수 있습니다.
              </li>
            </ul>
          </li>
          <li className='pl-0.5 mb-3'>
            차 보험 가입 광고의 상세 내용은 각 보험사(캐롯손해보험,
            악사다이렉트, 삼성화재 다이렉트) 페이지에서 확인할 수 있습니다.
          </li>
          <li className='pl-0.5'>
            차 보험 가입 이후 포인트 지급 관련 문의는 각 보험사(캐롯손해보험,
            악사다이렉트, 삼성화재다이렉트)에 문의해주세요.
          </li>
        </ul>
      </section>

      {!interestFreeSubpageState && (
        <ArrowUp
          ref={scrollTopRef}
          onClick={() => {
            outerDivRef.current?.scroll({
              top: 0,
              behavior: 'smooth',
            });
          }}
          style={{
            display: 'none',
          }}
          className='p-2.5 w-10 h-10 active:bg-[#00000080] rounded-full mb-[var(--safeAreaBottomPadding)] fixed right-5 bottom-5 bg-[#00000080] text-white'
        >
          맨위로가기
        </ArrowUp>
      )}
    </div>
  );
}
