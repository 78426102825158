import { useNavigate } from 'react-router-dom';
import { cn } from 'utils';

export default function ModelTopBox({ type }: { type: string }) {
  const navigate = useNavigate();
  return (
    <div
      className={cn(
        'bg-[#222] w-full flex flex-col justify-start items-start px-5 pt-2.5 pb-[30px] text-white',
        type === 'imported' && 'bg-[#F3F6F9] text-[#222]',
      )}
    >
      <div
        className={cn(
          'w-full flex justify-between items-center gap-4 pb-2.5 border-b border-[#FFFFFF80]',
          type === 'imported' && 'border-[#E5EAEE]',
        )}
      >
        <div className='w-[80px] h-[80px] flex justify-center items-center'>
          {type === 'domestic' ? (
            <img
              src={'/assets/images/png/brand/kia.png'}
              alt='로고 이미지'
              className='w-[80px] object-contain'
            />
          ) : type === 'imported' ? (
            <img
              src={'/assets/images/png/brand/bmw.png'}
              alt='로고 이미지'
              className='w-[72px] object-contain'
            />
          ) : (
            <img
              src={'/assets/images/png/brand/porsche02.png'}
              alt='로고 이미지'
              className='w-[80px] object-contain'
            />
          )}
        </div>

        <span
          className={cn(
            'text-[15px] leading-[15px] font-[500] pl-2.5 border-l-[2px] border-white',
            type === 'imported' && 'border-[#222]',
          )}
        >
          모델 13종
        </span>
      </div>
      <div className='pt-4 w-full flex flex-col justify-start items-start gap-[14px]'>
        <div className='w-full flex justify-between items-center gap-4'>
          <span className='text-[16px] leading-[16px] font-[400]'>
            견적 요청 가능 횟수
            <strong className='font-[600] ml-1.5 inline-block'>2회</strong>
          </span>
          <button
            onClick={() => navigate('/newcar/quote-request/purchase')}
            className={cn(
              'text-[14px] leading-[14px] font-[500] py-2.5 px-2 border border-white rounded-full flex justify-center items-center min-w-[106px] h-[34px]',
              type === 'imported' && 'border-[#222]',
            )}
          >
            견적 요청권 구매
          </button>
        </div>
        <div
          onClick={() => navigate('/newcar/quote-request/about')}
          className={cn(
            'bg-[#000000] py-[8px] pl-[12px] pr-[18px] w-full flex justify-between items-center rounded-[8px]',
            type === 'imported' && 'bg-[#E5EAEE]',
          )}
        >
          <span className='text-[14px] leading-[14px] font-[400]'>
            견적 요청권이란?
          </span>
          {type === 'imported' ? (
            <img
              src='/assets/images/png/info_gray.png'
              alt='정보아이콘'
              className='w-6 object-contain'
            />
          ) : (
            <img
              src='/assets/images/png/info.png'
              alt='정보아이콘'
              className='w-6 object-contain'
            />
          )}
        </div>
      </div>
    </div>
  );
}
