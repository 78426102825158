import { useEffect, useState } from 'react';
import { ArrowUp } from 'lucide-react';
import { cn } from 'utils';

export default function GoTopBtn() {
  const [isShow, setIsShow] = useState(false);

  const scrollToTop = () => {
    const mainPageElement = document.getElementById(
      'viewPoint',
    ) as HTMLElement | null;

    if (mainPageElement) {
      mainPageElement.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const mainPageElement = document.getElementById(
      'viewPoint',
    ) as HTMLElement | null;

    const handleScroll = () => {
      if (mainPageElement) {
        const scrollTop = mainPageElement.scrollTop;
        const scrollHeight = mainPageElement.scrollHeight;
        const clientHeight = mainPageElement.clientHeight;

        setIsShow(
          scrollTop > 500 && scrollTop + clientHeight < scrollHeight - 100,
        );
      }
    };

    if (mainPageElement) {
      mainPageElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (mainPageElement) {
        mainPageElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <>
      {isShow && (
        <div
          onClick={scrollToTop}
          className={cn(
            'fixed bottom-[70px] right-5 cursor-pointer z-10 flex items-center justify-center rounded-full bg-[#00000080] p-2.5 mb-[var(--safeAreaBottomPadding)]',
          )}
        >
          <ArrowUp width={22} height={22} className='text-white' />
        </div>
      )}
    </>
  );
}
