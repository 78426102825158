/* eslint-disable @typescript-eslint/no-unused-vars */
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import { Button } from 'components/ui/button';
import { Star, X } from 'lucide-react';
import { cn } from 'utils';
import V3_Confirm from 'components_v3/dialog/Confirm';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { convertDealerType } from 'utils/convert';
import { removeReview } from 'apis/review';
import { toast } from 'sonner';

interface infoProps {
  orderId: string;
  rating: number;
  title?: string;
  content: string;
  created_at: string;
  deleted_at: string | null;
  file_order_review_file_picture_1_idTofile: { url: string } | null;
  file_order_review_file_picture_2_idTofile: { url: string } | null;
  file_order_review_file_picture_3_idTofile: { url: string } | null;
  file_order_review_file_picture_4_idTofile: { url: string } | null;
  file_order_review_file_picture_5_idTofile: { url: string } | null;
}

export default function AnsweredReviewItem({
  type,
  item,
  refetch,
}: {
  type: string;
  item: any;
  refetch: () => void;
}) {
  const [info, setInfo] = useState<infoProps | null>(null);
  const [imgsPage, setImgsPage] = useState(1);
  const [isImgsPopupOpen, setImgsPopupOpen] = useState(false);
  const [isConfirmOpen, setConfirmOpen] = useState(false);
  const openPopupAtSlide = (index: number) => {
    setImgsPage(index + 1);
    setImgsPopupOpen(true);
  };

  const imgUrls = Object.entries(
    type === 'repair' ? item.accidentReport_review : item.order_review,
  )
    .filter(
      ([key, value]) =>
        key.startsWith(
          type === 'repair'
            ? 'file_accidentReport_review_file_picture_'
            : 'file_order_review_file_picture_',
        ) && (value as { url: string })?.url,
    )
    .map(([_, value]) => (value as { url: string }).url);

  const handleRemoveReview = async () => {
    const response = await removeReview({ orderId: item.id, type: type });

    if (response && response.status === 'success') {
      toast.success('리뷰가 삭제 되었습니다.');
      refetch();
      setConfirmOpen(false);
    }
  };

  useEffect(() => {
    if (item) {
      if (type === 'repair') {
        setInfo(item.accidentReport_review);
      } else {
        setInfo(item.order_review);
      }
    }
  }, [type]);

  if (!info) return <></>;

  return (
    <div className='w-full bg-white py-[30px] border-b-[8px] last:border-b-0 border-[#F3F6F9]'>
      <div className='w-[calc(100%-40px)] mx-auto flex flex-col justify-start items-start pb-[20px] border-b border-[#E5EAEE] mb-[20px]'>
        <div
          className={cn(
            'w-full flex justify-start items-center gap-4',
            type === 'repair' ? 'gap-[14px]' : 'gap-4 mb-4 ',
          )}
        >
          {type === 'repair' ? (
            <div className='bg-[#F3F6F9] flex justify-center items-center w-[74px] h-[74px] rounded-[6px] shrink-0 overflow-hidden'>
              <img
                src={item.info_repair_finished.pictures}
                alt='공업사 이미지'
                className='w-full object-contain'
              />
            </div>
          ) : (
            <div className='bg-[#F3F6F9] flex justify-center items-center w-[120px] h-[90px] rounded-[10px] shrink-0 overflow-hidden'>
              <img
                src={item.imageUrl}
                alt='상품 이미지'
                className='w-[90%] object-contain'
              />
            </div>
          )}

          <div
            className={cn(
              'flex flex-col justify-start items-start gap-1',
              type === 'repair'
                ? 'w-[calc(100%-88px)]'
                : 'w-[calc(100%-136px)]',
            )}
          >
            {type === 'repair' ? (
              <>
                <strong className='inline-block text-[15px] leading-[21px] font-[500] text-[#222] break-keep whitespace-pre-line'>
                  {item.partner_repairshop.business_name}
                </strong>
                <p className='inline-block text-[13px] leading-[13px] font-[400] text-[#555] break-keep whitespace-pre-line mb-[8px]'>
                  {item.partner_repairshop.business_main_address
                    .split(']')[1]
                    .trim()}{' '}
                  {item.partner_repairshop.business_detail_address}
                </p>
                <strong className='inline-block text-[15px] leading-[15px] font-[600] text-[#222] break-keep whitespace-pre-line'>
                  {item.info_repair_finished.price.repairCost.labor.toLocaleString()}
                  원
                </strong>
              </>
            ) : (
              <>
                <span className='w-full inline-block mb-[2px] text-[14px] leading-[14px] font-[500] text-[#555]'>
                  {item.type === 'newCar'
                    ? item.order_newCar.installmentTerm > 0
                      ? '할부'
                      : '일시불'
                    : item.type === 'leaseRent'
                      ? convertDealerType[
                          item.order_leaseRent.type as 'lease' | 'rent'
                        ]
                      : ''}
                </span>
                <strong className='w-full block text-[14px] leading-[22.4px] font-[600] text-[#222] truncate'>
                  {item.title}
                </strong>
                <p className='w-full block truncate text-[13px] leading-[20.8px] font-[500] text-[#555]'>
                  {item.type === 'newCar'
                    ? item.order_newCar.car.car_trim.name
                    : item.type === 'leaseRent'
                      ? item.order_leaseRent.car.car_trim.name
                      : item.order_sellCar.coocon_carInfo.carName}
                </p>
              </>
            )}
          </div>
        </div>
        {item.member_order_manager_idTomember && (
          <div className='bg-[#F3F6F9] rounded-[16px] w-full p-3 flex justify-start items-center gap-2.5'>
            {item.member_order_manager_idTomember.profileImage_url && (
              <div className='w-[36px] h-[36px] flex justify-center items-center overflow-hidden rounded-full'>
                <img
                  src={item.member_order_manager_idTomember.profileImage_url}
                  alt=''
                  className='w-full h-full object-cover'
                />
              </div>
            )}
            <div className='flex justify-start items-center'>
              <strong className='text-[15px] leading-[15px] font-[500]'>
                {item.member_order_manager_idTomember.name_nick}
              </strong>
              <span className='inline-block px-1.5 text-[14px] leading-[14px] text-[#D5DADF]'>
                •
              </span>
              <span className='text-[14px] leading-[14px] font-[500] text-[#555555]'>
                {item.type === 'newCar'
                  ? '신차 매니저'
                  : item.type === 'leaseRent'
                    ? item.order_leaseRent.type === 'lease'
                      ? '리스 매니저'
                      : '렌트 매니저'
                    : '카 매니저'}
              </span>
            </div>
          </div>
        )}
      </div>
      <div className='w-full px-5'>
        <div className='flex justify-between items-center mb-[30px]'>
          <div className='flex justify-start items-center'>
            {[...Array(info.rating)].map((_, index) => (
              <Star
                key={index}
                fill={'#FFC93E'}
                width='16'
                height='16'
                stroke='0'
              />
            ))}
            {[...Array(5 - info.rating)].map((_, index) => (
              <Star
                key={index}
                fill={'#DDDDDD'}
                width='16'
                height='16'
                stroke='0'
              />
            ))}
          </div>
          <span className='text-[13px] leading-[13px] font-[500] text-[#555]'>
            {dayjs(info.created_at).format('YYYY.MM.DD')} 작성
          </span>
        </div>
        <div className='flex flex-col justify-start items-start gap-1.5'>
          {info.title && (
            <div className='w-full text-[15px] leading-[24px] font-[600] whitespace-pre-line break-keep'>
              {info.title}
            </div>
          )}
          <div className='w-full text-[15px] leading-[22.5px] font-[400]  whitespace-pre-line'>
            {info.content}
          </div>
          {imgUrls && (
            <div className='w-full overflow-x-auto mt-[14px]'>
              <div className='flex items-center justify-start'>
                <ul className='flex items-center justify-start gap-[7px]'>
                  {imgUrls.map((url, index) => (
                    <li
                      className='w-full h-full flex justify-center items-center'
                      key={`reviewImg_${index}`}
                    >
                      <button
                        className='relative h-[107px] w-[107px] overflow-hidden rounded-[6px]'
                        onClick={(e) => {
                          e.preventDefault();
                          openPopupAtSlide(index);
                        }}
                      >
                        <img
                          src={url}
                          alt={`reviewImg_${index}`}
                          className='h-full w-full object-cover'
                        />
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}

          {!info.deleted_at && (
            <div className='w-full flex justify-end items-center mt-[14px]'>
              <Button
                onClick={() => setConfirmOpen(!isConfirmOpen)}
                variant={'outline'}
                className='w-[80px] h-[44px] font-[500]'
              >
                삭제
              </Button>
            </div>
          )}
        </div>
      </div>
      {imgUrls && (
        <Dialog
          sx={{
            '& .MuiDialog-paper': {
              height: '100dvh',
              width: '100dvw',
              maxWidth: 'none',
              margin: 0,
              padding: 0,
            },
            '& .MuiPaper-root': {
              height: '100dvh',
              width: '100dvw',
              maxHeight: '100dvh',
            },
          }}
          open={isImgsPopupOpen}
          onClose={() => {
            setImgsPopupOpen(false);
            setImgsPage(1);
          }}
          fullWidth
          maxWidth={'lg'}
          className='bg-[#111111]'
        >
          <DialogTitle className='!relative !flex !min-h-[56px] !items-center !justify-center !bg-[#111] !px-[48px] !py-[12px] !text-[16px] !font-[500] !leading-[16px] text-white'>
            <span>
              {imgsPage}/{imgUrls.length}
            </span>
            <button
              onClick={() => {
                setImgsPopupOpen(false);
                setImgsPage(1);
              }}
              className='!absolute !bottom-0 !right-4 !top-0 !my-auto !flex !h-8 !w-8 !items-center !justify-center'
            >
              <X width={28} height={28} color='#fff' strokeWidth='1.5' />
            </button>
          </DialogTitle>
          <DialogContent className='!flex !w-full scale-100 !items-center !justify-center bg-[#111111] !p-0'>
            <Swiper
              slidesPerView={1}
              className='w-full'
              spaceBetween={10}
              onSlideChange={(swiper: SwiperClass) => {
                setImgsPage(Math.ceil(swiper.activeIndex + 1));
              }}
              modules={[Pagination]}
              centeredSlides={true}
              initialSlide={imgsPage - 1}
            >
              {imgUrls.map((url, index) => (
                <SwiperSlide
                  key={`리뷰사진_slide_${index}`}
                  className='!h-auto w-full'
                >
                  <img
                    src={url}
                    alt={`reviewImgs_${index}`}
                    className={cn('relative h-full w-full object-contain')}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </DialogContent>
        </Dialog>
      )}
      <V3_Confirm
        onClose={() => setConfirmOpen(!isConfirmOpen)}
        onConfirm={handleRemoveReview}
        isOpen={isConfirmOpen}
        setIsOpen={setConfirmOpen}
        disableBackdropClose={true}
        title={''}
        agreeText={'삭제'}
        className='!pb-[50px] !pt-[44px]'
      >
        <div className='flex flex-col items-center justify-center gap-5'>
          <div className='flex flex-col items-center justify-center gap-1.5'>
            <div className='text-[#222] text-center font-[700] text-[16px] leading-[25.6px] tracking-[-0.32px] [font-feature-settings: "liga" off, "clig" off]'>
              리뷰 삭제
            </div>
            <div className='text-[#222] text-center font-[500] text-[15px] leading-[24px] tracking-[-0.3px] [font-feature-settings: "liga" off, "clig" off]'>
              작성하신 리뷰를 삭제하시겠습니까?
              <br />
              삭제 후 복구 불가능합니다.
            </div>
          </div>
        </div>
      </V3_Confirm>
    </div>
  );
}
