import { cn } from 'utils';
import { InsuranceCompanyInfo, InsuranceProductInfo } from '../data/company';
import useDeviceStore from 'store/useDeviceStore';

export default function InsuranceBanner({
  className,
  product,
  company,
}: {
  className?: string;
  product: InsuranceProductInfo;
  company: InsuranceCompanyInfo;
}) {
  const { myDevice } = useDeviceStore();
  console.info('device', myDevice);
  return (
    <div
      className={cn(
        'flex justify-center py-[23px] px-4 rounded-t-[10px]',
        product.design.color.bg,
        className,
      )}
    >
      <div className='flex items-center justify-between w-full'>
        <div className='flex flex-col justify-start gap-3'>
          <span
            className={`text-[14px] leading-[14px] tracking-[-0.02em] font-[300] ${(company.id as string) === '한화손해보험' && (myDevice === 'browserM' || myDevice === 'ios') ? 'font-HanwhaM' : (company.id as string) === '한화손해보험' && 'font-HanwhaW'}`}
          >
            {product.data.banner.title}
          </span>
          <span
            className={`text-[18px] tracking-[-0.02em] font-[600] break-keep ${(company.id as string) === '한화손해보험' && (myDevice === 'browserM' || myDevice === 'ios') ? 'font-HanwhaM' : (company.id as string) === '한화손해보험' && 'font-HanwhaW'}`}
          >
            {product.data.banner.subtitle}
          </span>
        </div>
        <img
          className='w-12 h-12'
          src={company.company.logo.png}
          alt='보험사 로고'
        />
      </div>
    </div>
  );
}
