/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useEffect, useState } from 'react';
import { cn } from 'utils';
import DomesticList from './DomesticList';
import ImportedList from './ImportedList';
import SupercarList from './SupercarList';
import { SelectedBrandInterface } from '../main';

export default function BrandStep({
  setStep,
  setSelectedBrand,
}: {
  setStep: (state: number) => void;
  setSelectedBrand: (value: SelectedBrandInterface) => void;
}) {
  const [currentTab, setCurrentTab] = useState<
    'domestic' | 'imported' | 'supercar'
  >('domestic');

  useEffect(() => {
    setSelectedBrand({
      type: currentTab,
      isSelectedModel: true,
    });
  }, [currentTab]);

  return (
    <div className='w-full pb-[50px]'>
      <ul className='sticky top-0 left-0 flex justify-center items-center w-[calc(100%-40px)] mx-auto rounded-full bg-[#E5EAEE] bg-opacity-[80%] backdrop-blur-[50px] p-1.5 mb-[20px] z-10'>
        <li
          onClick={() => setCurrentTab('domestic')}
          className={cn(
            'py-2.5 px-3 w-[calc(100%/3)] h-[40px] inline-flex justify-center items-center text-[16px] leading-[16px] font-[600] text-[#666] transition-all',
            currentTab === 'domestic' && 'bg-white rounded-full text-[#222]',
          )}
        >
          국산
        </li>
        <li
          onClick={() => setCurrentTab('imported')}
          className={cn(
            'py-2.5 px-3 w-[calc(100%/3)] h-[40px] inline-flex justify-center items-center text-[16px] leading-[16px] font-[600] text-[#666] transition-all',
            currentTab === 'imported' && 'bg-white rounded-full text-[#222]',
          )}
        >
          수입
        </li>
        <li
          onClick={() => setCurrentTab('supercar')}
          className={cn(
            'py-2.5 px-3 w-[calc(100%/3)] h-[40px] inline-flex justify-center items-center text-[16px] leading-[16px] font-[600] text-[#666] transition-all',
            currentTab === 'supercar' && 'bg-white rounded-full text-[#222]',
          )}
        >
          슈퍼카
        </li>
      </ul>
      <div className='w-full'>
        {currentTab === 'domestic' && <DomesticList setStep={setStep} />}
        {currentTab === 'imported' && <ImportedList setStep={setStep} />}
        {currentTab === 'supercar' && <SupercarList setStep={setStep} />}
      </div>
    </div>
  );
}
