import React, { useState, useCallback } from 'react';
import { toast } from 'sonner';
import { FileInfo } from '@carsayo/types';
import { api } from 'apis';
import axios from 'axios';

export const getUploadFilePresignedUrl = async ({
  fileName,
  extension,
  isPublic = true,
  fileType = 'file',
}: {
  fileName: string;
  extension: string;
  isPublic?: boolean;
  fileType?: string;
}) => {
  try {
    const response = await api.get('file/uploadUrl', {
      params: {
        fileName,
        extension,
        isPublic,
        fileType,
      },
    });
    return response.data;
  } catch (error: any) {
    throw new Error('Presigned URL 요청 실패');
  }
};

export const getFileInfo = async (fileId: string): Promise<FileInfo | null> => {
  try {
    const response = await api.get('file/info', {
      params: {
        fileId,
      },
    });
    return response.data;
  } catch (error: any) {
    throw new Error('파일 정보를 가져오는 데 실패했습니다.');
  }
};

export const uploadFile = async (
  file: File,
  presignedUrl: string,
  contentType: string,
) => {
  console;
  try {
    const options = {
      headers: {
        'Content-Type': contentType,
      },
    };
    await axios.put(presignedUrl, file, options);
  } catch (error: any) {
    throw new Error('파일 업로드 실패');
  }
};

interface UseFileUploadResult {
  handleFileUpload: (
    e: React.ChangeEvent<HTMLInputElement>,
    onSuccess: (fileInfo: FileInfo) => void,
    options?: {
      isPublic?: boolean;
      type?: string;
      name?: string;
    },
  ) => Promise<void>;
  isUploading: boolean;
}

export function useFileUpload(): UseFileUploadResult {
  const [isUploading, setIsUploading] = useState(false);

  const fileValidation = (file: File) => {
    if (file.size > 1024 * 1024 * 20) {
      toast.error('20MB 이하의 파일만 첨부 가능합니다.');
      return false;
    }
    return true;
  };

  const handleFileUpload = useCallback(
    async (
      e: React.ChangeEvent<HTMLInputElement>,
      onSuccess: (fileInfo: FileInfo) => void,
      options?: {
        isPublic?: boolean;
        type?: string;
        name?: string;
      },
    ) => {
      const file = e.target.files?.[0];
      if (!file) return;

      if (!fileValidation(file)) return;

      const fileNameParts = file.name.split('.');
      const extension = fileNameParts.pop() ?? '';
      const fileName = fileNameParts.join('.');

      setIsUploading(true);
      const loadingToast = toast.loading('파일 업로드중...', {
        duration: Infinity,
      });

      try {
        const presignedUrl = await getUploadFilePresignedUrl({
          isPublic: options?.isPublic !== undefined ? options?.isPublic : true,
          fileType: options?.type ?? 'file',
          fileName: options?.name ?? fileName,
          extension: extension,
        });

        await uploadFile(file, presignedUrl.presignedUrl, file.type);

        const fileInfo = await getFileInfo(presignedUrl.fileId);
        if (!fileInfo) throw new Error('파일 정보를 찾을 수 없습니다.');
        onSuccess(fileInfo);
      } catch (err: any) {
        toast.error(err?.message ?? '파일 업로드 실패');
        throw new Error(err);
      } finally {
        setIsUploading(false);
        toast.dismiss(loadingToast);
      }
    },
    [],
  );

  return { handleFileUpload, isUploading };
}
