import Button from 'components_v3/ui/Button';
import { useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';
import { cn } from 'utils';

export default function AboutQuoteRequestPage() {
  const { setTopTabbarState, setIsBottomTabbar } = useSystemStore();
  const navigate = useNavigate();
  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      title: '견적 요청권이란?',
      leftElement: 'back',
      rightElement: undefined,
    });
    setIsBottomTabbar(false);
  }, []);
  return (
    <div className='w-full flex flex-col justify-start items-start min-h-[calc(100dvh-60px)]'>
      <div className='flex justify-start items-center gap-4 py-[30px] px-5'>
        <div className='flex justify-center items-center p-2.5 bg-[#F3F6F9] rounded-[10px]'>
          <img
            src='/assets/images/png/carsayo_card.png'
            alt='카사요 기프트카드 이미지'
            className='w-[70px] object-contain'
          />
        </div>
        <div className='flex flex-col justify-start items-start gap-[14px]'>
          <div className='flex flex-col justify-start items-start gap-0.5'>
            <strong className='text-[15px] leading-[24px] font-[600]'>
              견적 요청권
            </strong>
            <p className='text-[14px] leading-[22.4px] font-[500] text-[#555555]'>
              신차 구매, 내 차 팔기
            </p>
          </div>
          <strong className='text-[16px] leading-[16px] font-[600]'>
            10,000원
          </strong>
        </div>
      </div>
      <div className='px-5 pt-[30px] border-t-[8px] border-[#F3F6F9] flex-1 h-full flex flex-col justify-between items-start gap-[50px]'>
        <div>
          <div className='mb-6 text-[16px] leading-[25.6px] font-[500] max-w-[292px] break-keep'>
            무분별한 구매·판매 요청으로 각 2대로 제한하며, 2대 초과 요청은
            유료임을 알려 드립니다.
          </div>
          <div className='w-full rounded-[10px] bg-[#F3F6F9] px-4 py-5'>
            <ul className='flex w-full list-none flex-col items-start justify-start gap-3'>
              <li
                className={cn(
                  'relative break-keep pl-5 text-[15px] leading-[24px] font-[400] text-[#222]',
                  'after:absolute after:left-[9px] after:top-[10px] after:h-[3px] after:w-[3px] after:rounded-full after:bg-[#555555] after:content-[""]',
                )}
              >
                무분별한 구매 또는 판매 요청으로 실 고객들과 실 딜러들의 피해
                방지를 위함을 알립니다.
              </li>
              <li
                className={cn(
                  'relative break-keep pl-5 text-[15px] leading-[24px] font-[400] text-[#222]',
                  'after:absolute after:left-[9px] after:top-[10px] after:h-[3px] after:w-[3px] after:rounded-full after:bg-[#555555] after:content-[""]',
                )}
              >
                구매 또는 판매 요청은 각 2대로 제한합니다. 단 카사요(주)에서
                신차 주문 또는 중고차 판매가 성사되신 고객 회원은 각 2대까지는
                구매 또는 판매 요청을 할 수 있습니다.
              </li>
              <li
                className={cn(
                  'relative break-keep pl-5 text-[15px] leading-[24px] font-[400] text-[#222]',
                  'after:absolute after:left-[9px] after:top-[10px] after:h-[3px] after:w-[3px] after:rounded-full after:bg-[#555555] after:content-[""]',
                )}
              >
                1대씩 추가 요청할 때마다, 정보 이용료 1만원을 지불합니다. 지불
                방법은 &lsquo;회사&rsquo;가 지정한 카사요(주) 계좌로 입금합니다.
              </li>
              <li
                className={cn(
                  'relative break-keep pl-5 text-[15px] leading-[24px] font-[400] text-[#222]',
                  'after:absolute after:left-[9px] after:top-[10px] after:h-[3px] after:w-[3px] after:rounded-full after:bg-[#555555] after:content-[""]',
                )}
              >
                정보 이용료를 입금하지 않을 시, 발생하게 되는 불이익은 전부
                &lsquo;일반 고객 회원&lsquo;이 부담하며{' '}
                <span className='whitespace-nowrap'>민/형사상의</span> 모든
                책임을 부담합니다.
              </li>
              <li
                className={cn(
                  'relative break-keep pl-5 text-[15px] leading-[24px] font-[400] text-[#222]',
                  'after:absolute after:left-[9px] after:top-[10px] after:h-[3px] after:w-[3px] after:rounded-full after:bg-[#555555] after:content-[""]',
                )}
              >
                깨끗하고 투명한 자동차 문화를 위해 노력하는 카사요가 되겠습니다.
              </li>
            </ul>
          </div>
        </div>
        <div className='w-full flex justify-center items-center gap-[7px] pb-[10px]'>
          <Button onClick={() => navigate(-1)}>확인</Button>
          <Button
            onClick={() => navigate('/newcar/quote-request/purchase')}
            theme='primary'
          >
            견적 요청권 구매
          </Button>
        </div>
      </div>
    </div>
  );
}
