import { Link } from 'react-router-dom';
import HomeIcon from './icons/Home';
import MyIcon from './icons/My';
import QuoteIcon from './icons/Quote';
import SettlementIcon from './icons/Settlement';

export default function BottomTabbarDealer() {
  return (
    <div className='fixed bottom-0 left-0 w-full z-[40] bg-white h-[50px] border-t border-[#EEEEEE] shadow-[0px_-2px_15px_#00000014]'>
      <ul className='flex justify-center items-center w-full'>
        <li className='w-[25%] h-full p-[5px] pb-[4px] box-border'>
          <Link
            to={'/dealer/main'}
            className='w-full h-full flex flex-col items-center gap-[1px]'
          >
            <HomeIcon
              width='30'
              height='30'
              fill={
                location.pathname === '/dealer/main' ? '#7273F9' : '#B2B7BB'
              }
            />
            <span
              className={`w-full text-center text-[11px] leading-[11px] font-[600] ${location.pathname === '/dealer/main' ? 'text-[#7273F9]' : 'text-[#A1A7AC]'}`}
            >
              홈
            </span>
          </Link>
        </li>
        <li className='w-[25%] p-[5px] pb-[4px]'>
          {/* /newcar/init */}
          <Link
            to='/dealer/request'
            className='w-full h-full flex flex-col items-center gap-[1px]'
          >
            <SettlementIcon
              width='30'
              height='30'
              fill={
                location.pathname.includes('/dealer/request')
                  ? '#7273F9'
                  : '#B2B7BB'
              }
            />
            <span
              className={`w-full text-center text-[11px] leading-[11px] font-[600] ${location.pathname.includes('/dealer/request') ? 'text-[#7273F9]' : 'text-[#A1A7AC]'}`}
            >
              견적 요청
            </span>
          </Link>
        </li>
        <li className='w-[25%] p-[5px] pb-[4px]'>
          <Link
            to={'/dealer/quote'}
            className='w-full h-full flex flex-col items-center gap-[1px]'
          >
            <QuoteIcon
              width='30'
              height='30'
              fill={
                location.pathname.includes('/dealer/quote')
                  ? '#7273F9'
                  : '#B2B7BB'
              }
            />
            <span
              className={`w-full text-center text-[11px] leading-[11px] font-[600] ${location.pathname.includes('/dealer/quote') ? 'text-[#7273F9]' : 'text-[#A1A7AC]'}`}
            >
              견적함
            </span>
          </Link>
        </li>
        <li className='w-[25%] p-[5px] pb-[4px]'>
          <Link
            to={'/mypage'}
            className='w-full h-full flex flex-col items-center gap-[1px]'
          >
            <MyIcon
              width='30'
              height='30'
              fill={
                location.pathname.includes('/mypage') ? '#7273F9' : '#B2B7BB'
              }
            />
            <span
              className={`w-full text-center text-[11px] leading-[11px] font-[600] ${location.pathname.includes('/mypage') ? 'text-[#7273F9]' : 'text-[#A1A7AC]'}`}
            >
              마이
            </span>
          </Link>
        </li>
      </ul>
    </div>
  );
}
