import { InsuranceCompany } from 'pages/static/insurance/data/company';

export const carkeyDescription = [
  '신차 구매, 중고차 판매',
  '자동차 금융 및 다이렉트 보험 전문가',
];
export const carkeyMenuList = [
  {
    imgUrl: '/assets/images/png/driving.png',
    text: '리스 · 장기렌트 · 일시불 · 할부',
  },
  {
    imgUrl: '/assets/images/png/shield-tick.png',
    text: '자동차 금융',
  },
];
export const carGongDescription = ['자동차 사고 수리(덴트)'];
export const carGongMenuList = [
  {
    imgUrl: '/assets/images/png/building-4.png',
    text: '공업사(사고 수리, 덴트)',
  },
  {
    imgUrl: '/assets/images/png/mirroring-screen.png',
    text: '공식 서비스센터',
  },
];
export const carGipsaDescription = [
  '1:1문의, 이용문의, 사고 접수',
  '대차(렌트) 전문가',
];
export const carGipsaMenuList = [
  {
    imgUrl: '/assets/images/png/messages-3.png',
    text: '1:1문의 · 고객센터',
  },
  {
    imgUrl: '/assets/images/png/clipboard-tick.png',
    text: '사고 접수 · 고장(긴급)출동',
  },
];

export const InsuranceBannerOrder: InsuranceCompany[] = [
  '삼성화재',
  'AXA 손해보험',
  '메리츠화재',
  '한화손해보험',
  '현대해상',
  'DB손해보험',
  'KB손해보험',
  '하나손해보험',
  '캐롯손해보험',
];

export const AskInsuranceOrder: InsuranceCompany[] = [
  '삼성화재',
  '메리츠화재',
  '한화손해보험',
  '현대해상',
  'DB손해보험',
  'AXA 손해보험',
  '하나손해보험',
  '캐롯손해보험',
  'KB손해보험',
];

export const InquiryTypeList: string[] = [
  '신차(리스) 구입',
  '신차(장기렌트) 구입',
  '신차(일시불/할부) 구입',
  '다이렉트 보험',
  '자동차 사고 수리(덴트 등)',
  '이용관련',
];
