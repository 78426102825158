export default function Star({
  fill,
  width,
  height,
  strokeWidth,
}: {
  fill?: string;
  width?: string;
  height?: string;
  strokeWidth?: string;
}) {
  return (
    <svg
      width={width || '32'}
      height={height || '32'}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_378_26182)'>
        <path
          d='M16.3706 23.8636C16.1374 23.7407 15.8587 23.7406 15.6254 23.8632L9.20359 27.2395C8.61668 27.5481 7.93071 27.0497 8.04282 26.3962L9.26957 19.2448C9.31412 18.9851 9.22792 18.7201 9.03909 18.5363L3.83719 13.4728C3.36177 13.01 3.62385 12.2029 4.28046 12.1078L11.4576 11.0676C11.7183 11.0298 11.9437 10.8661 12.0603 10.6299L15.2713 4.12353C15.5648 3.52893 16.4127 3.52893 16.7061 4.12353L19.9172 10.6299C20.0338 10.8661 20.2591 11.0298 20.5198 11.0676L27.697 12.1078C28.3536 12.2029 28.6157 13.01 28.1403 13.4728L22.9384 18.5363C22.7496 18.7201 22.6634 18.9851 22.7079 19.2448L23.9343 26.3943C24.0465 27.0482 23.3598 27.5466 22.7729 27.2373L16.3706 23.8636Z'
          fill={fill || '#DDDDDD'}
          stroke={fill || '#DDDDDD'}
          strokeWidth={strokeWidth || '2'}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_378_26182'>
          <rect width='32' height='32' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
