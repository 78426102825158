import CustomDatePicker from 'components/common/v3/customDatePicker';
import { ChangeEvent, useEffect, useLayoutEffect, useState } from 'react';
import { cn } from 'utils';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import ImageUploadBox from 'components/common/v3/imageUpload';
import { Input } from 'components/ui/input';
import { X } from 'lucide-react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { useLocation, useNavigate } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';
export interface ImagesInterface {
  image: string;
  imageName: string;
}

export default function IncidentDetail() {
  const { setTopTabbarState, setIsBottomTabbar } = useSystemStore();
  const navigate = useNavigate();
  const [fixImages, setFixImages] = useState<ImagesInterface[]>([]);
  const [fixedImages, setFixedImages] = useState<ImagesInterface[]>([]);
  const [isFixImagesPopup, setIsFixImagesPopup] = useState(false);
  const [page, setPage] = useState<number>(1);

  const [testValue01, setTestValue01] = useState<number>(442200);
  const [testValue02, setTestValue02] = useState<number>(617006);
  const [testValue03, setTestValue03] = useState<number>(0);
  const [testValue04, setTestValue04] = useState<number>(500000);

  const location = useLocation();
  const [status, setStatus] = useState('');

  const handleImagesAdd = (item: ImagesInterface) => {
    setFixImages((prev) => [
      ...prev,
      {
        image: item.image,
        imageName: item.imageName,
      },
    ]);
  };
  const handleFixedImagesAdd = (item: ImagesInterface) => {
    setFixedImages((prev) => [
      ...prev,
      {
        image: item.image,
        imageName: item.imageName,
      },
    ]);
  };
  const removeFixImage = (index: number) => {
    const updatedImages = fixImages.filter((_, i) => i !== index);
    setFixImages(updatedImages);
  };
  const removeFixedImage = (index: number) => {
    const updatedImages = fixedImages.filter((_, i) => i !== index);
    setFixedImages(updatedImages);
  };

  const openPopupAtSlide = (index: number) => {
    setPage(index + 1);
    setIsFixImagesPopup(true);
  };

  const handleChange1 = (e: ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value.replace(/,/g, '');
    const numericValue = Number(rawValue);
    if (!isNaN(numericValue)) {
      setTestValue01(numericValue);
    }
  };
  const handleChange2 = (e: ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value.replace(/,/g, '');
    const numericValue = Number(rawValue);
    if (!isNaN(numericValue)) {
      setTestValue02(numericValue);
    }
  };
  const handleChange3 = (e: ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value.replace(/,/g, '');
    const numericValue = Number(rawValue);
    if (!isNaN(numericValue)) {
      setTestValue03(numericValue);
    }
  };
  const handleChange4 = (e: ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value.replace(/,/g, '');
    const numericValue = Number(rawValue);
    if (!isNaN(numericValue)) {
      setTestValue04(numericValue);
    }
  };
  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      rightElement: undefined,
      leftElement: 'back',
      title: ' ',
    });
    setIsBottomTabbar(false);
  }, []);

  useEffect(() => {
    console.info('수리사진 이미지', fixImages);
  }, [fixImages]);
  useEffect(() => {
    console.info('수리완료사진 이미지', fixedImages);
  }, [fixedImages]);
  useEffect(() => {
    const segments = location.pathname.split('/');
    const lastSegment = segments[segments.length - 1];
    setStatus(lastSegment);
  }, [location.pathname]);
  return (
    <div className='pt-[30px] pb-[20px] px-[20px] bg-[#F3F6F9]'>
      <div className='w-full bg-white p-[20px] pt-[21px] rounded-[16px] shadow-[0px_6px_20px_#0000000D]'>
        <div className='flex justify-start items-center pb-[15px] gap-[6px] border-b border-[#E5EAEE] mb-[20px]'>
          <img
            src='/assets/images/v2/blue_check.png'
            alt='체크이미지'
            className='w-[24px] object-contain'
          />
          <span className='text-[18px] leading-[18px] font-[500] text-[#005CE6]'>
            {status === 'assigned'
              ? '배정 완료'
              : status === 'received'
                ? '입고 완료'
                : '취소'}
          </span>
        </div>
        <div>
          <ul className='flex flex-col items-start justify-start gap-[14px] text-[15px] font-[400] leading-[15px]'>
            <li className='flex items-start justify-start'>
              <span className='min-w-[75px]'>요청일</span>
              <strong className='font-[500]'>2024.12.21 13:10</strong>
            </li>
            <li className='flex items-start justify-start'>
              <span className='min-w-[75px]'>공업사</span>
              <strong className='font-[500]'>제일 1급 정비 공업사</strong>
            </li>
            <li className='flex items-start justify-start'>
              <span className='min-w-[75px]'>연락처</span>
              <strong className='font-[500]'>02-2323-2222</strong>
            </li>
          </ul>
          <button
            onClick={() => navigate('/incident/review')}
            className='mt-[22px] w-full p-[19px]  bg-[#F0F0FD] text-[#494AF1] h-[54px] flex justify-center items-center text-[16px] leading-[16px] font-[500] rounded-[10px]'
          >
            리뷰 작성
          </button>
        </div>
      </div>
      <div className='flex justify-center items-center gap-[7px] mt-[20px]'>
        {status !== 'canceled' && (
          <button className='w-full p-[19px] border border-[#E5E5EC] bg-white h-[54px] flex justify-center items-center text-[16px] leading-[16px] font-[500] rounded-[10px]'>
            접수 취소
          </button>
        )}

        <button className='w-full p-[19px] text-white bg-[#7273F9] h-[54px] flex justify-center items-center text-[16px] leading-[16px] font-[500] rounded-[10px]'>
          공업사 통화
        </button>
      </div>
      <div className='w-full pt-[30px]'>
        <div className='mb-[30px]'>
          <strong className='mb-4 flex items-center justify-start text-[18px] font-[600] leading-[18px] text-[#000]'>
            고객 정보
          </strong>
          <div className='mb-[20px] w-full rounded-[16px] bg-white px-4 py-[20px] shadow-[0px_6px_20px_#0000000D]'>
            <ul className='flex flex-col items-start justify-start gap-[20px]'>
              <li className='flex w-full items-center justify-between gap-[10px] text-[15px] font-[500] leading-[15px]'>
                <span className='whitespace-nowrap text-[#7D848A]'>이름</span>
                <strong className='font-[500] text-[#222222]'>홍길동</strong>
              </li>
              <li className='flex w-full items-center justify-between gap-[10px] text-[15px] font-[500] leading-[15px]'>
                <span className='whitespace-nowrap text-[#7D848A]'>
                  차량번호
                </span>
                <strong className='font-[500] text-[#222222]'>
                  123머 1232
                </strong>
              </li>
              <li className='flex w-full items-center justify-between gap-[10px] text-[15px] font-[500] leading-[15px]'>
                <span className='whitespace-nowrap text-[#7D848A]'>보험사</span>
                <strong className='font-[500] text-[#222222]'>삼성화재</strong>
              </li>
              <li className='flex w-full items-center justify-between gap-[10px] text-[15px] font-[500] leading-[15px]'>
                <span className='whitespace-nowrap text-[#7D848A]'>
                  견인 여부
                </span>
                <strong className='font-[500] text-[#222222]'>필요</strong>
              </li>
              <li className='flex w-full items-center justify-between gap-[10px] text-[15px] font-[500] leading-[15px]'>
                <span className='whitespace-nowrap text-[#7D848A]'>
                  배정 여부
                </span>
                <span className='flex flex-wrap items-center justify-end gap-[10px]'>
                  <span className='text-[#005CE6]'>
                    {status === 'assigned'
                      ? '배정 완료'
                      : status === 'received'
                        ? '입고 완료'
                        : '취소'}
                  </span>
                  <strong className='font-[500] text-[#222222]'>
                    2024.12.31 13:31
                  </strong>
                </span>
              </li>
            </ul>
          </div>
        </div>
        {status === 'canceled' && (
          <div className='mb-[30px]'>
            <strong className='mb-4 flex items-center justify-start text-[18px] font-[600] leading-[18px] text-[#000]'>
              취소 정보
            </strong>
            <div className='mb-[16px] w-full rounded-[16px] bg-white px-4 py-[20px] shadow-[0px_6px_20px_#0000000D]'>
              <ul className='flex flex-col items-start justify-start gap-[20px]'>
                <li className='flex w-full items-center justify-between gap-[10px] text-[15px] font-[500] leading-[15px]'>
                  <span className='whitespace-nowrap text-[#7D848A]'>
                    취소 구분
                  </span>
                  <strong className='font-[500] text-[#222222]'>고객</strong>
                </li>
                <li className='flex w-full items-center justify-between gap-[10px] text-[15px] font-[500] leading-[15px]'>
                  <span className='whitespace-nowrap text-[#7D848A]'>
                    취소 일자
                  </span>
                  <strong className='font-[500] text-[#222222]'>
                    2024.12.22 14:20
                  </strong>
                </li>
                <li className='flex w-full items-center justify-between gap-[10px] text-[15px] font-[500] leading-[15px]'>
                  <span className='whitespace-nowrap text-[#7D848A]'>
                    취소 사유
                  </span>
                  <strong className='font-[500] text-[#222222]'>
                    단순 변심
                  </strong>
                </li>
              </ul>
            </div>
          </div>
        )}

        <div className='mb-[30px]'>
          <strong className='mb-[16px] flex items-center justify-start text-[18px] font-[600] leading-[18px] text-[#000]'>
            입고 정보
          </strong>
          <div className='mb-[20px] w-full rounded-[16px] bg-white px-4 py-[20px] shadow-[0px_6px_20px_#0000000D]'>
            <form className='flex flex-col items-start justify-start gap-6'>
              <div className='w-full'>
                <span className='mb-[12px] inline-block text-[15px] leading-[15px] text-[#7D848A] font-[500]'>
                  입고일
                </span>
                <CustomDatePicker />
              </div>
              <div className='w-full'>
                <div
                  className={cn(
                    'flex w-full items-center justify-between',
                    fixImages.length > 0 ? 'mb-[9px] mt-[-7px]' : 'mb-[16px]',
                  )}
                >
                  <span className='inline-block text-[15px] leading-[15px] text-[#7D848A] font-[500]'>
                    입고 사진
                  </span>
                  {fixImages.length > 0 && (
                    <button
                      type='button'
                      onClick={() => setIsFixImagesPopup(true)}
                      className='rounded-[8px] bg-[#F3F6F9] border border-[#E5EAEE] p-2 text-[13px] font-[500] leading-[13px]'
                    >
                      전체보기
                    </button>
                  )}
                </div>
                <div className='w-full overflow-x-auto'>
                  <div className='flex items-center justify-start gap-[6px]'>
                    {fixImages.length < 3 && (
                      <ImageUploadBox
                        id='수리사진'
                        handleImagesAdd={handleImagesAdd}
                        currentCount={fixImages.length}
                        maxCount={3}
                      />
                    )}
                    <ul className='flex items-center justify-start gap-[6px]'>
                      {fixImages &&
                        fixImages.map((item, index) => (
                          <li
                            className='w-full h-full flex justify-center items-center'
                            key={`${item.imageName}_${index}`}
                          >
                            <button
                              className='relative h-[97px] w-[97px] overflow-hidden rounded-[6px]'
                              onClick={(e) => {
                                e.preventDefault();
                                openPopupAtSlide(index);
                              }}
                            >
                              <img
                                src={item.image}
                                alt={item.imageName}
                                className='h-full w-full object-cover'
                              />
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  removeFixImage(index);
                                }}
                                className='absolute right-1 top-1 z-10 flex h-[20px] w-[20px] items-center justify-center rounded-full bg-[#000000CC]'
                              >
                                <X width={12} height={12} color='#fff' />
                              </button>
                            </button>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className='w-full'>
                <span className='mb-[12px] inline-block text-[15px] leading-[15px] text-[#7D848A] font-[500]'>
                  수리 시작 예정일
                </span>
                <CustomDatePicker />
              </div>
              <div className='w-full'>
                <span className='mb-[12px] inline-block text-[15px] leading-[15px] text-[#7D848A] font-[500]'>
                  예상 수리비
                </span>
                <div className='relative'>
                  <Input
                    className='bg-[#F1F4F7] rounded-[10px] h-[44px] text-[#7D848A] text-[15px] leading-[15px] text-right pr-[34px]'
                    type='text'
                    value={testValue04.toLocaleString()}
                    onChange={handleChange4}
                  />
                  <span className='absolute inline-block right-4 top-[50%] -translate-y-[50%] text-[15px] leading-[15px] font-[500] text-[#7D848A]'>
                    원
                  </span>
                </div>
              </div>
              <div className='w-full'>
                <span className='mb-[12px] inline-block text-[15px] leading-[15px] text-[#7D848A] font-[500]'>
                  출고 예정일
                </span>
                <CustomDatePicker />
              </div>
            </form>
          </div>
        </div>
        <div className='mb-[30px]'>
          <strong className='mb-[20px] flex items-center justify-start text-[18px] font-[600] leading-[18px] text-[#000]'>
            수리 정보
          </strong>
          <div
            className={cn(
              'mb-[20px] w-full rounded-[16px] bg-white py-[20px] shadow-[0px_6px_20px_#0000000D]',
              fixImages.length && 'pt-[13px]',
            )}
          >
            <form
              action=''
              className='flex flex-col items-start justify-start gap-6 px-4'
            >
              <div className='w-full'>
                <div
                  className={cn(
                    'flex w-full items-center justify-between',
                    fixImages.length > 0 ? 'mb-[9px]' : 'mb-[16px]',
                  )}
                >
                  <span className='inline-block text-[15px] leading-[15px] text-[#7D848A] font-[500]'>
                    수리 사진
                  </span>
                  {fixImages.length > 0 && (
                    <button
                      type='button'
                      onClick={() => setIsFixImagesPopup(true)}
                      className='rounded-[8px] bg-[#F3F6F9] border border-[#E5EAEE] p-2 text-[13px] font-[500] leading-[13px]'
                    >
                      전체보기
                    </button>
                  )}
                </div>
                <div className='w-full overflow-x-auto'>
                  <div className='flex items-center justify-start gap-[6px]'>
                    {fixImages.length < 3 && (
                      <ImageUploadBox
                        id='수리사진'
                        handleImagesAdd={handleImagesAdd}
                        currentCount={fixImages.length}
                        maxCount={3}
                      />
                    )}
                    <ul className='flex items-center justify-start gap-[6px]'>
                      {fixImages &&
                        fixImages.map((item, index) => (
                          <li
                            className='w-full h-full flex justify-center items-center'
                            key={`${item.imageName}_${index}`}
                          >
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                openPopupAtSlide(index);
                              }}
                              className='relative h-[97px] w-[97px] overflow-hidden rounded-[6px]'
                            >
                              <img
                                src={item.image}
                                alt={item.imageName}
                                className='h-full w-full object-cover'
                              />
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  removeFixImage(index);
                                }}
                                className='absolute right-1 top-1 z-10 flex h-[20px] w-[20px] items-center justify-center rounded-full bg-[#000000CC]'
                              >
                                <X width={12} height={12} color='#fff' />
                              </button>
                            </button>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
            </form>
            {fixImages && fixImages.length > 0 && (
              <Dialog
                sx={{
                  '& .MuiDialog-paper': {
                    height: '100dvh',
                    width: '100dvw',
                    maxWidth: 'none',
                    margin: 0,
                    padding: 0,
                  },
                  '& .MuiPaper-root': {
                    height: '100dvh',
                    width: '100dvw',
                    maxHeight: '100dvh',
                  },
                }}
                open={isFixImagesPopup}
                onClose={() => {
                  setIsFixImagesPopup(false);
                  setPage(1);
                }}
                fullWidth
                maxWidth={'lg'}
                className='bg-[#111111]'
              >
                <DialogTitle className='!relative !flex !min-h-[56px] !items-center !justify-center !bg-[#111] !px-[48px] !py-[12px] !text-[16px] !font-[500] !leading-[16px] text-white'>
                  <span>
                    {page}/{fixImages.length}
                  </span>
                  <button
                    onClick={() => {
                      setIsFixImagesPopup(false);
                      setPage(1);
                    }}
                    className='!absolute !bottom-0 !right-4 !top-0 !my-auto !flex !h-8 !w-8 !items-center !justify-center'
                  >
                    <X width={28} height={28} color='#fff' strokeWidth='1.5' />
                  </button>
                </DialogTitle>
                <DialogContent className='!flex !w-full scale-100 !items-center !justify-center bg-[#111111] !p-0'>
                  <Swiper
                    slidesPerView={1}
                    className='w-full'
                    spaceBetween={10}
                    onSlideChange={(swiper: SwiperClass) => {
                      setPage(Math.ceil(swiper.activeIndex + 1));
                    }}
                    modules={[Pagination]}
                    centeredSlides={true}
                    initialSlide={page - 1}
                  >
                    {fixImages.map((item, index) => (
                      <SwiperSlide
                        key={`수리사진_slide_${index}`}
                        className='!h-auto w-full'
                      >
                        <img
                          src={item.image}
                          alt={item.imageName}
                          className={cn(
                            'relative h-full w-full object-contain',
                          )}
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </DialogContent>
              </Dialog>
            )}
          </div>
        </div>
        <div className='mb-[30px]'>
          <strong className='mb-[20px] flex items-center justify-start text-[18px] font-[600] leading-[18px] text-[#000]'>
            수리 완료 정보
          </strong>
          <div className='mb-[20px] w-full rounded-[16px] bg-white py-[20px] shadow-[0px_6px_20px_#0000000D]'>
            <form
              action=''
              className='flex flex-col items-start justify-start gap-6'
            >
              <div className='w-full px-4'>
                <div
                  className={cn(
                    'flex w-full items-center justify-between ',
                    fixedImages.length > 0 ? 'mb-[9px] -mt-[7px]' : 'mb-[16px]',
                  )}
                >
                  <span className='inline-block text-[15px] leading-[15px] text-[#7D848A] font-[500]'>
                    수리 완료 사진
                  </span>
                  {fixedImages.length > 0 && (
                    <button
                      type='button'
                      onClick={() => setIsFixImagesPopup(true)}
                      className='rounded-[8px] bg-[#F3F6F9] border border-[#E5EAEE] p-2 text-[13px] font-[500] leading-[13px]'
                    >
                      전체보기
                    </button>
                  )}
                </div>
                <div className='w-full overflow-x-auto'>
                  <div className='flex items-center justify-start gap-[6px]'>
                    {fixedImages.length < 5 && (
                      <ImageUploadBox
                        id='수리완료사진'
                        handleImagesAdd={handleFixedImagesAdd}
                        currentCount={fixedImages.length}
                        maxCount={5}
                      />
                    )}
                    <ul className='flex items-center justify-start gap-[6px]'>
                      {fixedImages &&
                        fixedImages.map((item, index) => (
                          <li
                            className='relative h-[97px] w-[97px] overflow-hidden rounded-[6px]'
                            key={`${item.imageName}_${index}`}
                          >
                            <img
                              src={item.image}
                              alt={item.imageName}
                              className='h-full w-full object-cover'
                            />
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                removeFixedImage(index);
                              }}
                              className='absolute right-1 top-1 z-10 flex h-[20px] w-[20px] items-center justify-center rounded-full bg-[#000000CC]'
                            >
                              <X width={12} height={12} color='#fff' />
                            </button>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className='w-full px-4 pt-[6px]'>
                <div className='w-full'>
                  <div className='mb-6 flex w-full items-center justify-between border-b border-[#222222] pb-[22px] font-[500]'>
                    <span className='text-[16px] leading-[16px] font-[500]'>
                      수리 금액
                    </span>
                    <strong className='text-[15px] font-[500] leading-[15px] text-[#7D848A]'>
                      <span className='text-[16px] font-[600] leading-[16px] text-[#222]'>
                        1,059,206{' '}
                      </span>
                      원
                    </strong>
                  </div>
                  <div className='mb-[6px] flex w-full flex-col items-start justify-start gap-[10px]'>
                    <div className='flex w-full items-center justify-start gap-[12px]'>
                      <span className='block shrink-0 text-[15px] font-[500] leading-[15px] text-[#7D848A]'>
                        부품
                      </span>
                      <div className='w-full relative'>
                        <Input
                          className='bg-[#F1F4F7] rounded-[10px] h-[44px] text-[#7D848A] text-[15px] leading-[15px] text-right pr-[34px]'
                          type='text'
                          value={testValue01.toLocaleString()}
                          onChange={handleChange1}
                        />
                        <span className='absolute inline-block right-4 top-[50%] -translate-y-[50%] text-[15px] leading-[15px] font-[500] text-[#7D848A]'>
                          원
                        </span>
                      </div>
                    </div>
                    <div className='flex w-full items-center justify-start gap-[12px]'>
                      <span className='block shrink-0 text-[15px] font-[500] leading-[15px] text-[#7D848A]'>
                        공임
                      </span>
                      <div className='w-full relative'>
                        <Input
                          className='bg-[#F1F4F7] rounded-[10px] h-[44px] text-[#7D848A] text-[15px] leading-[15px] text-right pr-[34px]'
                          type='text'
                          value={testValue02.toLocaleString()}
                          onChange={handleChange2}
                        />
                        <span className='absolute inline-block right-4 top-[50%] -translate-y-[50%] text-[15px] leading-[15px] font-[500] text-[#7D848A]'>
                          원
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='w-full px-4'>
                <div className='w-full'>
                  <div className='mb-6 flex w-full items-center justify-between border-b border-[#222222] pb-[22px] font-[500]'>
                    <span className='text-[16px] leading-[16px] font-[500]'>
                      차감 금액
                    </span>
                    <strong className='text-[15px] font-[500] leading-[15px] text-[#7D848A]'>
                      <span className='text-[16px] font-[600] leading-[16px] text-[#222]'>
                        0{' '}
                      </span>
                      원
                    </strong>
                  </div>
                  <div className='mb-[6px] flex w-full flex-col items-start justify-start gap-[10px]'>
                    <div className='flex w-full items-center justify-start gap-[12px]'>
                      <span className='block shrink-0 text-[15px] font-[500] leading-[15px] text-[#7D848A]'>
                        보험
                      </span>
                      <div className='w-full relative'>
                        <Input
                          className='bg-[#F1F4F7] rounded-[10px] h-[44px] text-[#7D848A] text-[15px] leading-[15px] text-right pr-[34px]'
                          type='text'
                          value={testValue03.toLocaleString()}
                          onChange={handleChange3}
                        />
                        <span className='absolute inline-block right-4 top-[50%] -translate-y-[50%] text-[15px] leading-[15px] font-[500] text-[#7D848A]'>
                          원
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='w-full px-4'>
                <div className='w-full'>
                  <div className='mb-[20px] flex w-full items-center justify-between border-b border-[#222222] pb-[22px] font-[500]'>
                    <span className='text-[16px] leading-[16px] font-[500]'>
                      청구 금액
                    </span>
                  </div>
                  <div className='flex w-full flex-col items-start justify-start gap-[20px]'>
                    <div className='flex w-full items-center justify-between gap-[10px]'>
                      <span className='block shrink-0 text-[15px] font-[500] leading-[15px] text-[#7D848A]'>
                        소계
                      </span>
                      <strong className='text-[15px] font-[500] leading-[15px] text-[#7D848A]'>
                        <span className='inline-block pr-1 text-[#222]'>
                          1,059,206
                        </span>
                        원
                      </strong>
                    </div>
                    <div className='flex w-full items-center justify-between gap-[10px]'>
                      <span className='block shrink-0 text-[15px] font-[500] leading-[15px] text-[#7D848A]'>
                        부가계
                      </span>
                      <strong className='text-[15px] font-[500] leading-[15px] text-[#7D848A]'>
                        <span className='inline-block pr-1 text-[#222]'>
                          105,921
                        </span>
                        원
                      </strong>
                    </div>
                    <div className='flex w-full items-center justify-between gap-[10px] border-t border-[#E5EAEE] pt-[20px]'>
                      <span className='block shrink-0 text-[15px] font-[500] leading-[15px] text-[#7D848A]'>
                        총계
                      </span>
                      <strong className='text-[15px] font-[500] leading-[15px] text-[#7D848A]'>
                        <span className='inline-block pr-1 text-[20px] font-[600] leading-[20px] text-[#222]'>
                          1,165,127
                        </span>
                        원
                      </strong>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
