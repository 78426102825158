interface ResetButtonInterface {
  clickEvent: () => void;
}

const ResetButton = ({ clickEvent }: ResetButtonInterface) => {
  return (
    <button
      onClick={clickEvent}
      className='self-end h-[40px] flex justify-center items-center gap-[2px] py-[9px] px-4 rounded-[100px] bg-[#F3F6F9] text-[#7D848A]'
    >
      <img
        src='/assets/images/v2/undo-gray.png'
        alt='리셋아이콘'
        className='w-4 object-contain'
      />
      <span className='text-[13px] leading-[13px] font-[500]'>처음으로</span>
    </button>
  );
};

export default ResetButton;
