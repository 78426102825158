/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useState } from 'react';

import { AccidentReport } from '@carsayo/types';

import { cn } from 'utils';
import MultiImageViewer from 'components_v3/dialog/MultiImageViewer';

/** 사고접수시 고객이 업로드한 사진 리스트 */
export default function TabPictures({
  targetAccidentReport,
}: {
  targetAccidentReport: AccidentReport;
}) {
  if (targetAccidentReport.pictures_url.length === 0) return null;

  const [isImageViewerOpen, setIsImageViewerOpen] = useState(false);
  const [imageViewerIndex, setImageViewerIndex] = useState(0);

  return (
    <div key={targetAccidentReport.id + 'repairr'} className=''>
      <div className='my-[20px] w-full rounded-[16px] bg-white px-4 pt-3 pb-[20px] shadow-[0px_6px_20px_#0000000D]'>
        <form
          action=''
          className='flex flex-col items-start justify-start gap-6 '
        >
          <div className='w-full'>
            <div
              className={cn(
                'flex w-full items-center justify-between',
                targetAccidentReport.pictures_url.length > 0
                  ? 'mb-2.5'
                  : 'mb-5',
              )}
            >
              <span className='inline-block text-[#7D848A] text-[15px] font-medium leading-[100%] tracking-[-0.3px]'>
                차량 상태
              </span>

              {targetAccidentReport.pictures_url.length > 0 && (
                <button
                  type='button'
                  onClick={() => {
                    setIsImageViewerOpen(true);
                  }}
                  className='rounded-[8px] bg-[#F3F6F9] p-2 text-[13px] font-[500] leading-[13px]'
                >
                  전체보기
                </button>
              )}
            </div>
            <div className='w-full overflow-x-auto'>
              <div className='flex items-center justify-start gap-[6px]'>
                <ul className='flex items-center justify-start gap-[6px] pr-4'>
                  {targetAccidentReport.pictures_url.map((item, index) => (
                    <li
                      className='relative h-[97px] w-[97px] overflow-hidden rounded-[6px]'
                      onClick={() => {
                        setImageViewerIndex(index);
                        setIsImageViewerOpen(true);
                      }}
                      key={item}
                    >
                      <img
                        src={item}
                        alt={'수리 이미지'}
                        className='h-full w-full object-cover'
                      />
                    </li>
                  ))}
                </ul>

                <MultiImageViewer
                  isOpen={isImageViewerOpen}
                  setIsOpen={setIsImageViewerOpen}
                  initialIndex={imageViewerIndex}
                  fileUrl={targetAccidentReport.pictures_url}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
