import { FormHelperText } from '@mui/material';
import {
  LocalizationProvider,
  DatePicker as MUIDatePicker,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import 'dayjs/locale/ko';

import { koKR } from '@mui/x-date-pickers/locales/koKR';
import { cn } from 'utils';

dayjs.locale('ko');

type DatePickerProps = {
  value: Dayjs | null;
  onChange: (newValue: Dayjs | null) => void;

  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  datePickerType?: string;
  minDate?: Dayjs | null;
  maxDate?: Dayjs | null;
};

export default function CustomDatePicker({
  value,
  onChange,
  disabled,
  error,
  helperText,
  datePickerType,
  minDate,
  maxDate,
}: DatePickerProps) {
  const showValue = value
    ? `${dayjs(value).format('YYYY-MM-DD')} ~ ${dayjs(value).add(1, 'year').subtract(1, 'day').format('YYYY-MM-DD')}`
    : '';
  return (
    <>
      <div className='relative h-[44px] w-full max-w-[131px]'>
        <span
          className={cn(
            'absolute bottom-0 left-3 top-0 z-[1] my-auto h-[20px]',
          )}
        >
          <img
            src='/assets/images/png/calendar_2.png'
            alt='달력 아이콘'
            className='h-[20px] object-contain'
          />
        </span>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale='ko'
          dateFormats={{
            normalDate: 'YYYY년MM월DD일',
            keyboardDate: 'YYYY/MM/DD',
            year: 'YYYY년',
            month: 'MM월',
          }}
          localeText={{
            ...koKR.components.MuiLocalizationProvider.defaultProps.localeText,
            datePickerToolbarTitle: '날짜 선택',
            previousMonth: '이전 달',
            nextMonth: '다음 달',
            fieldClearLabel: '초기화',
            cancelButtonLabel: '닫기',
            okButtonLabel:
              value !== null
                ? `${dayjs(value).format('YYYY년 MM월 DD일')} 적용`
                : `적용`,
          }}
        >
          <MUIDatePicker
            disabled={disabled}
            disableOpenPicker
            format='YYYY-MM-DD'
            value={value}
            className='select-none'
            // onClose={() => onChange(null)}
            onChange={(newValue) => {
              if (newValue && newValue.isValid()) {
                if (
                  (minDate && newValue.isBefore(minDate, 'day')) ||
                  (maxDate && newValue.isAfter(maxDate, 'day'))
                ) {
                  return;
                }
                onChange(dayjs(newValue));
              } else {
                onChange(null);
              }
            }}
            minDate={minDate ?? undefined}
            maxDate={maxDate ?? undefined}
            slotProps={{
              textField: {
                inputProps: {
                  value: value
                    ? datePickerType === 'range'
                      ? showValue
                      : dayjs(value).format('YYYY-MM-DD')
                    : '날짜 선택',
                },
              },
              calendarHeader: {
                format: 'YYYY년 MM월',
                sx: {
                  '& .MuiPickersCalendarHeader-label': {
                    fontFamily: 'Pretendard',
                    letterSpacing: '-0.02em',
                  },
                },
              },
              toolbar: {
                sx: {
                  '&': {
                    paddingBottom: '0px',
                    alignItems: 'center',
                  },
                  '& .MuiTypography-root': {
                    fontSize: '15px',
                    fontFamily: 'Pretendard',
                    letterSpacing: '-0.02em',
                    color: '#222',
                    fontWeight: '500',
                  },
                  '& .MuiPickersToolbar-content': {
                    justifyContent: 'center',
                    '& h4': {
                      display: 'none',
                    },
                  },
                },
              },
              actionBar: {
                sx: {
                  '& .MuiButtonBase-root': {
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    whiteSpace: 'nowrap',
                    fontWeight: '500',
                    color: '#222',
                    padding: '18px',
                    height: '44px',
                    borderRadius: '8px',
                    '&:first-of-type': {
                      width: '30%',
                      border: '1px solid #ddd',
                    },
                    '&:last-of-type': {
                      width: '70%',
                      backgroundColor: '#7273F9',
                      color: '#fff',
                    },
                  },
                },
              },
              layout: {
                sx: {
                  '.MuiPaper-root': {
                    margin: '0px',
                  },
                  '.MuiDayCalendar-weekDayLabel': {
                    fontFamily: 'Pretendard',
                    fontSize: '13px',
                  },
                  '.MuiDayCalendar-root': {
                    fontFamily: 'Pretendard',
                  },
                  '.MuiButtonBase-root.Mui-selected': {
                    backgroundColor: '#7273F9 !important',
                  },
                },
              },
            }}
            sx={{
              width: '100%',
              '& .MuiInputBase-root': {
                border: error ? '1px solid #FF4D4D' : '1px solid #E5E5EC',
                backgroundColor: disabled ? '#F3F6F9' : '#fff',
                borderRadius: '10px',
                height: '44px',
                padding: '12px',
                maxWidth: '131px',
              },
              '& .MuiFormLabel-root': {
                padding: '12px',
                paddingLeft: '14px',
                fontSize: '15px',
                lineHeight: '15px',
                fontWeight: '500',
                fontFamily: 'Pretendard',
                color: disabled ? '#222' : '#222',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                display: 'none',
              },
              '& .MuiInputBase-input': {
                fontSize: '15px',
                lineHeight: '15px',
                fontWeight: '400',
                fontFamily: 'Pretendard',
                padding: '0px 0px 0px 26px',
                color: disabled ? '#222' : '#222',
                opacity: disabled ? 1 : 1,
                letterSpacing: '-0.02em',
              },
              '& .MuiInputBase-readOnly': {
                color: '#222 !important',
                opacity: 1,
                WebkitTextFillColor: '#222 !important',
              },
            }}
          />
        </LocalizationProvider>
      </div>

      {error && helperText && (
        <FormHelperText
          sx={{
            color: '#FF4D4D',
            fontSize: '12px',
            lineHeight: '20px',
            letterSpacing: '0.4px',
            fontWeight: '400',
            marginX: '14px',
            marginTop: '3px !important',
          }}
        >
          {helperText}
        </FormHelperText>
      )}
    </>
  );
}
