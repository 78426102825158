import classNames from 'classnames';
import { InputHTMLAttributes, useId } from 'react';
import { cn } from 'utils';

interface CarsayoRadioInterface
  extends Omit<
    InputHTMLAttributes<HTMLInputElement>,
    'type' | 'className' | 'id' | 'checked'
  > {
  label: string;
  checked: boolean;
  className?: string;
}

const CarsayoCheckRadio = ({
  label,
  checked,
  className,
  ...rest
}: CarsayoRadioInterface) => {
  const id = useId();
  return (
    <label
      className={cn(
        'flex items-center flex-row gap-1 text-[15px] leading-[18px] font-[500] checked:text-[#222] text-[#7D848A] font-Pretendard',
        checked ? 'text-[#222]' : 'text-[#7D848A]',
      )}
      htmlFor={id}
    >
      <input
        id={id}
        type='radio'
        name='progressRadio'
        checked={checked}
        className={classNames(
          className && className,
          `appearance-none hover:cursor-pointer w-[22px] h-[22px] shrink-0 bg-no-repeat bg-cover bg-center !border-[#E5E5EC] rounded-full !text-[10px] text-[#fff] focus:ring-transparent disabled:opacity-50 disabled:pointer-events-none`,
          'checked:!bg-radio-check-active checked:!bg-[length:20px_20px]',
          '!bg-radio-check-default !bg-[length:20px_20px] !ml-0',
        )}
        {...rest}
      />
      {label}
    </label>
  );
};

export default CarsayoCheckRadio;
