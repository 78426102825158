import { cn } from 'utils';
import React from 'react';
import { MainCardMenu } from '../ask';

interface AskMainCardLayoutInterface {
  bgColor: string;
  characterImg: string;
  redirectAction: () => void;
  title: string;
  description: string[];
  menuList: MainCardMenu[];
  listBg: string;
  imgSize: {
    width: string;
    height: string;
  };
  rightPadding?: string;
  bottomPadding?: string;
}

const AskMainCardLayout = ({
  bgColor,
  characterImg,
  redirectAction,
  title,
  description,
  menuList,
  listBg,
  rightPadding,
  bottomPadding,
  imgSize,
}: AskMainCardLayoutInterface) => {
  return (
    <div
      className={cn(
        `w-full min-h-[180px] rounded-[16px] py-6 px-5 min-w-[265px] relative shadow-[0px_10px_20px_#1111110D]`,
        bgColor ? `${bgColor}` : 'bg-white',
      )}
      onClick={redirectAction}
    >
      <div className='w-full flex gap-[6px] justify-start items-center mb-6'>
        <h3 className='text-[18px] leading-[18px] font-[600] text-white'>
          {title || '물어보기'}
        </h3>
      </div>
      <div className='w-full flex justify-between items-start'>
        <ul className='w-full flex flex-col justify-start items-start gap-[6px]'>
          {menuList &&
            menuList.length > 0 &&
            menuList.map((item, index) => {
              return (
                <li
                  key={`askMainCard_${item.text}_${index}`}
                  className={cn(
                    `${listBg}`,
                    'rounded-[4px] p-[6px] flex justify-start items-center gap-[6px]',
                  )}
                >
                  <img
                    src={item.imgUrl}
                    alt={`askMainCardImg_${item.text}`}
                    className='w-4 object-contain'
                  />
                  <span className='text-[14px] leading-[14px] text-white font-[500]'>
                    {item.text}
                  </span>
                </li>
              );
            })}
        </ul>
      </div>
      <img
        src={characterImg}
        alt={`askMainCard_${title}_img`}
        className={cn(
          `absolute bottom-[8px] right-4`,
          rightPadding,
          bottomPadding,
          imgSize.width,
          imgSize.height,
        )}
      />
    </div>
  );
};

export default AskMainCardLayout;
