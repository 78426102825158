import { FormHelperText } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { cn } from 'utils';

type DatePickerProps = {
  /** 이 값 넣어도 뭐 안됨 이상함 */
  value: Dayjs | null;
  onChange: (newValue: Dayjs | null) => void;

  disabled?: boolean;
  error?: boolean;
  helperText?: string;
};

export default function DatePicker({
  value,
  onChange,
  disabled,
  error,
  helperText,
}: DatePickerProps) {
  return (
    <>
      <div className='relative h-[44px] w-full'>
        <span
          className={cn(
            'absolute bottom-0 left-4 top-0 z-[1] my-auto h-[20px]',
            disabled && 'opacity-50',
          )}
        >
          <img
            src='/assets/images/png/calendar_2.png'
            alt='달력 아이콘'
            className='h-[20px] object-contain'
          />
        </span>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ko'>
          <MUIDatePicker
            disabled={disabled}
            disableOpenPicker
            format='YYYY-MM-DD'
            value={value}
            className='select-none'
            onChange={(newValue) => {
              onChange(dayjs(newValue));
            }}
            localeText={{
              previousMonth: '이전 달',
              nextMonth: '다음 달',
            }}
            slotProps={{
              textField: {
                placeholder: '날짜를 선택하세요',
              },
            }}
            sx={{
              width: '100%',
              '& .MuiInputBase-root': {
                paddingLeft: '28px',
                border: error ? '1px solid #FF4D4D' : 'none',
                backgroundColor: '#F1F4F7',
                borderRadius: '10px',
                height: '44px',
                padding: '12px 16px',
              },
              '& .MuiFormLabel-root': {
                paddingLeft: '28px',
                fontSize: '15px',
                lineHeight: '13px',
                fontWeight: '500',
                fontFamily: 'Pretendard',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                display: 'none',
              },
              '& .MuiInputBase-input': {
                fontSize: '15px',
                lineHeight: '15px',
                fontWeight: '500',
                fontFamily: 'Pretendard',
                padding: '0px 16px 0px 28px',
              },
            }}
          />
        </LocalizationProvider>
      </div>

      {error && helperText && (
        <FormHelperText
          sx={{
            color: '#FF4D4D',
            fontSize: '12px',
            lineHeight: '20px',
            letterSpacing: '0.4px',
            fontWeight: '400',
            marginX: '14px',
            marginTop: '3px !important',
          }}
        >
          {helperText}
        </FormHelperText>
      )}
    </>
  );
}
