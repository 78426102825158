export default function TagIcon() {
  return (
    <svg
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_221_2947)'>
        <path
          d='M12.0284 4.92915C11.4159 2.23415 9.06506 1.02081 7.00006 1.02081C7.00006 1.02081 7.00006 1.02081 6.99422 1.02081C4.93506 1.02081 2.57839 2.22831 1.96589 4.92331C1.28339 7.93331 3.12672 10.4825 4.79506 12.0866C5.41339 12.6816 6.20672 12.9791 7.00006 12.9791C7.79339 12.9791 8.58672 12.6816 9.19922 12.0866C10.8676 10.4825 12.7109 7.93915 12.0284 4.92915ZM7.00006 7.85165C5.98506 7.85165 5.16256 7.02915 5.16256 6.01415C5.16256 4.99915 5.98506 4.17665 7.00006 4.17665C8.01506 4.17665 8.83756 4.99915 8.83756 6.01415C8.83756 7.02915 8.01506 7.85165 7.00006 7.85165Z'
          fill='#B2B7BB'
        />
      </g>
      <defs>
        <clipPath id='clip0_221_2947'>
          <rect width='14' height='14' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
