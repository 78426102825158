/* eslint-disable @typescript-eslint/no-unused-vars */
'use client';

import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  cancelAccidentReport,
  getMemberAccidentReport,
} from 'apis/accidentReport';
import TextArea from 'components_v3/ui/TextArea';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';
import {
  AccidentReportDataCancelForm,
  AccidentReportDataCancelSchema,
} from './_forms/repair';
import V3_Confirm from 'components_v3/dialog/Confirm';
import V3_Button from 'components_v3/ui/Button';
import TabCancelInfo from './_components/tab.cancelInfo';
import TabInInfo from './_components/tab.inInfo';
import TabRepairInfo from './_components/tab.repairInfo';
import TabRepairFinishInfo from './_components/tab.repairFinishInfo';
import TabCustomerInfo from './_components/tab.customerInfo';
import { cn } from 'utils';
import dayjs from 'dayjs';
import useSystemStore from 'store/useSystemStore';
import useOnResumeStore from 'store/useOnResumeStore';
import TabPictures from './_components/tab.pictures';

export default function AccidentReportDetailPage() {
  const queryClient = useQueryClient();
  const { id } = useParams();

  if (!id) return null;

  const navigate = useNavigate();

  const { setTopTabbarState, setIsBottomTabbar } = useSystemStore();
  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      leftElement: 'back',
      title: '사고 접수',
    });
    setIsBottomTabbar(false);
  }, []);

  const {
    data: targetAccidentReport,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['accidentReport', id],
    queryFn: async () => {
      return await getMemberAccidentReport({
        id,
      });
    },
  });

  /** 취소하기 팝업 */
  const [isOpen, setOpen] = useState(false);

  const { setOnResume } = useOnResumeStore();

  /** 취소하기 Form */
  const form = useForm<AccidentReportDataCancelForm>({
    mode: 'onSubmit',
    resolver: zodResolver(AccidentReportDataCancelSchema),
    defaultValues: {
      type: '공업사 취소',
      reason: '',
    },
    shouldFocusError: true,
  });

  const { mutate } = useMutation({
    mutationFn: async () => {
      return await cancelAccidentReport({
        id: id,
        reason: form.getValues().reason,
      });
    },
    onSuccess: (data) => {
      toast.success('취소 처리되었습니다.');
      refetch();
    },
    onSettled: () => {
      form.reset();
      setOpen(false);
    },
  });

  const submit = async () => {
    const isValid = await form.trigger();
    if (!isValid) {
      return;
    }

    await mutate();
    return;
  };

  useEffect(() => {
    if (!id) return;
    setOnResume(() => {
      queryClient.invalidateQueries({ queryKey: ['accidentReport', id] });
    });
  }, [id]);

  return (
    <div className='min-h-full w-full bg-[#F3F6F9] px-[20px] pb-[50px] pt-[40px]'>
      {targetAccidentReport && (
        <>
          <div className='w-full bg-white p-[20px] pt-[21px] rounded-[16px] shadow-[0px_6px_20px_#0000000D]'>
            <div className='flex justify-start items-center pb-[15px] gap-[6px] border-b border-[#E5EAEE] mb-[20px]'>
              <img
                src={
                  targetAccidentReport.state === 'canceled'
                    ? '/assets/images/v2/red_check.png'
                    : '/assets/images/v2/blue_check.png'
                }
                alt='체크이미지'
                className='w-[24px] object-contain'
              />
              <span
                className={cn(
                  'text-[18px] leading-[18px] font-[500] text-[#005CE6]',
                  targetAccidentReport.state === 'canceled' && 'text-red-500',
                )}
              >
                {targetAccidentReport.state === 'submitted' && '배정 완료'}
                {targetAccidentReport.state === 'accepted' && '진행중'}
                {targetAccidentReport.state === 'finished' && '수리 완료'}
                {targetAccidentReport.state === 'canceled' && '취소'}
              </span>
            </div>
            <div>
              <ul className='flex flex-col items-start justify-start gap-[14px] text-[15px] font-[400] leading-[15px]'>
                <li className='flex items-start justify-start'>
                  <span className='min-w-[75px]'>요청일</span>
                  <strong className='font-[500]'>
                    {dayjs(targetAccidentReport.created_at).format(
                      'YYYY.MM.DD HH:mm',
                    )}
                  </strong>
                </li>
                <li className='flex items-start justify-start'>
                  <span className='min-w-[75px]'>공업사</span>
                  <strong className='font-[500]'>
                    {targetAccidentReport.repairShop?.name}
                  </strong>
                </li>
                <li className='flex items-start justify-start'>
                  <span className='min-w-[75px]'>연락처</span>
                  <strong className='font-[500]'>
                    {' '}
                    {targetAccidentReport.repairShop?.contact_number}
                  </strong>
                </li>
              </ul>
              {targetAccidentReport.state === 'finished' && (
                <button
                  disabled={!!targetAccidentReport.review}
                  onClick={() => navigate(`/accidentReport/review/${id}`)}
                  className={cn(
                    'mt-[22px] w-full p-[19px]  bg-[#F0F0FD] text-[#494AF1] h-[54px] flex justify-center items-center text-[16px] leading-[16px] font-[500] rounded-[10px]',
                    targetAccidentReport.review &&
                      'bg-[#E5E5EC] text-[#999999]',
                  )}
                >
                  {targetAccidentReport.review ? '리뷰 작성 완료' : '리뷰 작성'}
                </button>
              )}
            </div>
          </div>

          {/* 고객 업로드 사진 */}
          <TabPictures targetAccidentReport={targetAccidentReport} />

          {/* 기능 버튼들 */}
          <div className='mt-5 flex w-full items-center justify-center gap-[7px]'>
            {targetAccidentReport.state === 'accepted' &&
              !targetAccidentReport.info_in && (
                <V3_Button onClick={() => setOpen(!isOpen)}>
                  접수 취소
                </V3_Button>
              )}
            <V3_Button
              theme='primary'
              onClick={() => {
                window.location.href = `tel:${targetAccidentReport.repairShop?.contact_number}`;
              }}
            >
              공업사 통화
            </V3_Button>
          </div>

          {/* 고객 정보 */}
          <TabCustomerInfo targetAccidentReport={targetAccidentReport} />

          {/* 취소 정보 */}
          <TabCancelInfo targetAccidentReport={targetAccidentReport} />

          {/* 입고 정보 */}
          <TabInInfo
            targetAccidentReport={targetAccidentReport}
            refetch={refetch}
          />

          {/* 수리 정보 */}
          <TabRepairInfo
            targetAccidentReport={targetAccidentReport}
            refetch={refetch}
          />

          {/* 수리 완료 정보 */}
          <TabRepairFinishInfo
            targetAccidentReport={targetAccidentReport}
            refetch={refetch}
          />
        </>
      )}

      <V3_Confirm
        isOpen={isOpen}
        setIsOpen={setOpen}
        onConfirm={submit}
        title={'접수 취소'}
      >
        <div className='relative mt-[14px] w-full'>
          <TextArea
            {...form.register('reason')}
            placeholder='공업사에 전달할 취소 사유를 입력하세요.'
            minRows={5}
            maxLength={200}
            className='w-full'
            error={!!form.formState.errors.reason}
            helperText={form.formState.errors.reason?.message}
          ></TextArea>
        </div>
      </V3_Confirm>
    </div>
  );
}
