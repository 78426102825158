/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
} from 'components/ui/drawer';
import { useLayoutEffect, useState } from 'react';
import { Button } from 'components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'components/ui/form';
import { Input } from 'components/ui/input';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Link, useNavigate } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';
import useMemberStore from 'store/useMemberStore';
import { updateNickname } from 'apis/setting';
import { parseStringPhoneNumber } from 'utils/textConvert';
import Confirm from 'components/ui/confirm';
import { logoutApi } from 'apis/authentication';
import { getPostList } from 'apis/board';
import dayjs from 'dayjs';
import EditIcon from 'assets/images/v2/edit.svg';
import { CarsayoToast } from 'components/common/v2/CarsayoToast';
import { useQueryClient } from '@tanstack/react-query';
import { PostList } from '@carsayo/types';
import RightArrow from 'components/common/global/svg/RightArrow';
import { returnPhoneNumberWithDashes } from 'utils/telNumber';
import { cn } from 'utils';
import { ChevronRight } from 'lucide-react';

const passwordPattern = /^[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|\s|]+$/;
const formSchema = z.object({
  nickname: z
    .string()
    .min(1, {
      message: '닉네임을 입력해주세요',
    })
    .max(13, {
      message: '닉네임 길이는 최대 10자리 입니다',
    })
    .regex(passwordPattern, {
      message: '특수문자는 사용하실 수 없습니다',
    }),
});
function EditDrawer({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}) {
  const { setLoginMember } = useMemberStore();
  const { setLoadingDim } = useSystemStore();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      nickname: '',
    },
  });

  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    setLoadingDim(true);

    await updateNickname({
      nickName: data.nickname,
    })
      .then((member) => {
        setLoginMember(member);
        setLoadingDim(false);
        setIsOpen(false);
      })
      .catch((e) => {
        form.setError('nickname', { message: e });
        setLoadingDim(false);
      });
  };
  return (
    <Drawer open={isOpen} onOpenChange={(value) => setIsOpen(value)}>
      <DrawerContent>
        <div className='mx-auto w-full max-w-sm'>
          <DrawerHeader className='relative'>
            <DrawerTitle>닉네임 변경</DrawerTitle>
            <DrawerClose asChild>
              <Button className='absolute right-5 top-[14px] h-7 w-7 border-0 bg-transparent p-0'>
                <img src='/assets/images/v2/close.svg' alt='' />
              </Button>
            </DrawerClose>
          </DrawerHeader>
          <div className='p-4 pb-10'>
            <Form {...form}>
              <form
                id='nicknameForm'
                onSubmit={form.handleSubmit(onSubmit)}
                className='space-y-6'
              >
                <FormField
                  control={form.control}
                  name='nickname'
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>닉네임</FormLabel>
                      <FormControl>
                        <Input placeholder='닉네임 입력' {...field}></Input>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <Button type='submit' form='nicknameForm'>
                  변경하기
                </Button>
              </form>
            </Form>
          </div>
        </div>
      </DrawerContent>
    </Drawer>
  );
}

export default function MypageMain() {
  const [isOpen, setIsOpen] = useState(false);
  const [isLogoutOpen, setIsLogoutOpen] = useState(false);
  const { setLoadingDim, setTopTabbarState, setIsBottomTabbar } =
    useSystemStore();
  const { loginMember, resetLoginMember } = useMemberStore();

  const [isNewNotice, setIsNewNotice] = useState<boolean>();
  const [isNewFaq, setIsNewFaq] = useState<boolean>();

  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const handleClickEvents = {
    call: (callNumber: string) => {
      const telUrl = 'tel:' + callNumber;
      window.location.href = telUrl;
    },
    email: (address: string) => {
      const telUrl = 'mailto:' + address;
      window.location.href = telUrl;
    },
    logout: async () => {
      setLoadingDim(true);
      try {
        await logoutApi();
        queryClient.resetQueries();
        resetLoginMember();
        setLoadingDim(false);
        navigate('/main');
      } catch (e) {
        CarsayoToast.error(`${e}`);
        setLoadingDim(false);
      }
    },
  };

  const handleVoidEvents = {
    getNewestNotice: async () => {
      const searchedPostList: PostList = await getPostList({
        boardKey: 'notice',
        skip: 0,
        take: 1,
        option: null,
        search: null,
      });
      if (searchedPostList.searchedPost[0]) {
        const checkedNotice: string | null =
          localStorage.getItem('checkedNotice');
        if (checkedNotice === String(searchedPostList.searchedPost[0].id))
          return;

        if (
          dayjs(searchedPostList.searchedPost[0].created_at).isAfter(
            dayjs().add(-7, 'day'),
          )
        ) {
          setIsNewNotice(true);
        }
      }

      return;
    },
    getNewestFaq: async () => {
      const searchedFAQList: PostList = await getPostList({
        boardKey: 'faq',
        skip: 0,
        take: 1,
        option: null,
        search: null,
      });
      if (searchedFAQList.searchedPost[0]) {
        if (
          dayjs(searchedFAQList.searchedPost[0].created_at).isAfter(
            dayjs().add(-7, 'day'),
          )
        ) {
          setIsNewFaq(true);
        }
      }

      return;
    },
  };

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'my',
      title: '마이',
    });
    setIsBottomTabbar(true);
    // setTopTabbarState({
    //   state: 'hidden',
    //   mode: 'subpage',
    //   rightElement: 'home',
    //   leftElement: 'back',
    // });
    handleVoidEvents.getNewestNotice();
    handleVoidEvents.getNewestFaq();
  }, []);

  return (
    <div className='bg-[#F3F6F9]'>
      <div className='inset-0 bottom-auto z-10 bg-white py-4 px-[20px] pb-[30px]'>
        {/* 회원정보 */}
        <div className='mt-1 flex gap-3 border border-[#E5EAEE] p-[14px] rounded-[16px]'>
          {loginMember?.dealerInfo ? (
            <div
              className='w-full flex justify-between items-center'
              onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                e.stopPropagation();
                navigate('/mypage/info');
              }}
            >
              <div className='w-full flex flex-col justify-start items-start gap-4'>
                <div className='flex justify-start items-center'>
                  <div className='flex h-[62px] w-[62px] bg-[#F1F1F5] items-center justify-center overflow-hidden rounded-full mr-4 shrink-0'>
                    <img
                      src={loginMember?.profileImage_url}
                      alt='프로필이미지'
                      className='w-full h-full object-cover'
                    />
                  </div>
                  <div>
                    {/* 닉네임 */}
                    <div className='flex items-center gap-2 text-lg font-semibold leading-[18px] mb-2'>
                      {loginMember?.name_nick ? (
                        loginMember.name_nick
                      ) : (
                        <span className='text-[#999]'>닉네임 없음</span>
                      )}
                    </div>
                    {/* 전화번호 */}
                    <div className='text-[14px] leading-[14px] font-[400] text-[#222222]'>
                      {loginMember?.phoneNumber ? (
                        returnPhoneNumberWithDashes(loginMember.phoneNumber)
                          .phoneNumberWithDashes
                      ) : (
                        <span className='text-[#999]'>번호 없음</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <ChevronRight
                width={24}
                height={24}
                strokeWidth={1.5}
                color='#B2B7BB'
              />
            </div>
          ) : (
            <div
              className='w-full flex justify-between items-center font-semibold'
              onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                e.stopPropagation();
                navigate('/mypage/info');
              }}
            >
              <div className='flex justify-start items-center'>
                <div className='flex h-[62px] w-[62px] bg-[#F1F1F5] items-center justify-center overflow-hidden rounded-full mr-4 shrink-0'>
                  <img
                    src={loginMember?.profileImage_url}
                    alt='프로필이미지'
                    className='w-full h-full object-cover'
                  />
                </div>
                <div>
                  {/* 닉네임 */}
                  <div
                    className='flex items-center gap-2 text-lg font-semibold leading-[18px] mb-2'
                    // onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                    //   e.stopPropagation();
                    //   setIsOpen(true);
                    // }}
                  >
                    {loginMember?.name_nick}
                  </div>
                  {/* 이름 */}
                  <div className='text-[14px] leading-[14px] font-[400] text-[#222222]'>
                    {loginMember?.name_real
                      ? loginMember.name_real
                      : loginMember?.name_nick}
                  </div>
                </div>
              </div>
              {loginMember?.dealerInfo && (
                <div className='ml-auto'>
                  {loginMember.dealerInfo.signupState === 'accepted' && (
                    <div className='flex h-5 items-center border border-[#04B014] px-1 text-[11px] text-[#04B014]'>
                      승인 완료
                    </div>
                  )}
                  {loginMember.dealerInfo.signupState === 'rejected' && (
                    <div className='flex h-5 items-center border border-[#ff0000] px-1 text-[11px] text-[#ff0000]'>
                      승인 거부
                    </div>
                  )}
                  {loginMember.dealerInfo.signupState === 'submitted' && (
                    <div className='flex h-5 items-center border border-[#000000] px-1 text-[11px] text-[#000000]'>
                      승인 대기
                    </div>
                  )}
                </div>
              )}
              <div className='flex h-[24px] w-[24px] items-center justify-center'>
                <ChevronRight
                  width={24}
                  height={24}
                  color='#B2B7BB'
                  strokeWidth={1.5}
                  className='min-w-[24px]'
                />
              </div>
            </div>
          )}
        </div>
        {loginMember?.dealerInfo && (
          <div className='w-full flex justify-between items-center py-[15px] px-4 bg-[#F3F6F9] rounded-[10px] mt-2.5'>
            <strong className='text-[14px] leading-[19.6px] font-[500]'>
              카 매니저 인증
            </strong>
            {loginMember.dealerInfo.signupState === 'accepted' && (
              <div className='flex justify-center items-center gap-0.5'>
                <img
                  src='/assets/images/png/check.png'
                  alt='체크아이콘'
                  className='w-4 h-4 object-contain'
                />
                <span className='text-[13px] leading-[13px] font-[500]'>
                  2025.01.03 승인
                </span>
              </div>
            )}
            {loginMember.dealerInfo.signupState === 'rejected' && (
              <div className='flex justify-center items-center gap-0.5'>
                <img
                  src='/assets/images/png/wait.png'
                  alt='대기아이콘'
                  className='w-4 h-4 object-contain'
                />
                <span className='text-[13px] leading-[13px] font-[500]'>
                  승인 대기
                </span>
              </div>
            )}
            {loginMember.dealerInfo.signupState === 'submitted' && (
              <div className='flex justify-center items-center gap-0.5'>
                <img
                  src='/assets/images/png/cancel.png'
                  alt='거부아이콘'
                  className='w-4 h-4 object-contain'
                />
                <span className='text-[13px] leading-[13px] font-[500]'>
                  승인 거부
                </span>
              </div>
            )}
          </div>
        )}
        {/* 물어보기 및 결제 내역 */}
        {/* {process.env.REACT_APP_NODE_ENV !== 'production' &&
          loginMember?.customerInfo && (
            <div className='rounded-[16px] mt-4  bg-[#F3F6F9]'>
              <ul className='flex justify-center items-center'>
                <li
                  className={cn(
                    ' w-full',
                    'relative after:content-[""] after:w-[1px] after:h-[20px] after:bg-[#DFDFE6]',
                    'after:absolute after:right-0 after:top-0 after:bottom-0 after:my-auto',
                  )}
                >
                  <div
                    className={cn(
                      'p-[20px] w-full flex flex-col justify-center items-center gap-2 min-h-[80px]',
                    )}
                    onClick={() => navigate('/mypage/inquiry')}
                  >
                    <span className='text-[13px] leading-[13px] text-[#555]'>
                      1:1문의
                    </span>
                    <strong
                      className={cn(
                        'text-[16px] leading-[16px] font-[600]',
                        loginMember.count.inquiry.totalCount > 0
                          ? 'text-[#222222]'
                          : 'text-[#CCCCCC]',
                      )}
                    >
                      {loginMember.count.inquiry.totalCount > 0
                        ? loginMember.count.inquiry.totalCount
                        : 0}
                      건
                    </strong>
                  </div>
                </li>
                <li
                  className={cn(
                    ' w-full',
                    'relative after:content-[""] after:w-[1px] after:h-[20px] after:bg-[#DFDFE6]',
                    'after:absolute after:right-0 after:top-0 after:bottom-0 after:my-auto',
                  )}
                >
                  <div
                    className={cn(
                      'p-[20px] w-full flex flex-col justify-center items-center gap-2 min-h-[80px]',
                    )}
                  >
                    <span className='text-[13px] leading-[13px] text-[#555]'>
                      사고접수
                    </span>
                    <strong
                      className={cn(
                        'text-[16px] leading-[16px] font-[600]',
                        loginMember.customerInfo.count.accidentRegistration
                          .totalCount > 0
                          ? 'text-[#222222]'
                          : 'text-[#CCCCCC]',
                      )}
                    >
                      {loginMember.customerInfo.count.accidentRegistration
                        .totalCount > 0
                        ? loginMember.customerInfo.count.accidentRegistration
                            .totalCount
                        : 0}
                      건
                    </strong>
                  </div>
                </li>
                <li className='w-full'>
                  <div
                    className={cn(
                      'p-[20px] w-full flex flex-col justify-center items-center gap-2 min-h-[80px]',
                    )}
                    onClick={() => navigate('/incident/pay')}
                  >
                    <span className='text-[13px] leading-[13px] text-[#555]'>
                      결제내역
                    </span>
                    <strong
                      className={cn(
                        'text-[16px] leading-[16px] font-[600]',
                        loginMember.count.payment.totalCount > 0
                          ? 'text-[#222222]'
                          : 'text-[#CCCCCC]',
                      )}
                    >
                      {loginMember.count.payment.totalCount > 0
                        ? loginMember.count.payment.totalCount
                        : 0}
                      건
                    </strong>
                  </div>
                </li>
              </ul>
            </div>
          )} */}
        {/* 차량 관리 */}
        {loginMember?.customerInfo?.registerCar &&
          loginMember?.customerInfo?.registerCar.list.length > 0 && (
            <div
              className='p-4 mt-[10px] rounded-[16px] bg-[#F0F0FD] flex justify-between items-center'
              onClick={() => navigate('/ask/carManagement/main')}
            >
              <div className='flex justify-start items-center gap-[10px]'>
                <div className='w-[32px] h-[32px] rounded-[6px] flex justify-center items-center bg-white'>
                  <img
                    src='/assets/images/v2/blueCar.png'
                    alt='파란 자동차 아이콘'
                    className='w-[22px] object-contain'
                  />
                </div>
                <div className='flex justify-start items-center gap-[8px]'>
                  <strong className='text-[20px] leading-[20px] font-[600]'>
                    {
                      loginMember?.customerInfo?.registerCar.list.find(
                        (item) => item.isRepresentative === true,
                      )?.carName
                    }
                  </strong>
                  <span className='text-[#5455EA] text-[14px] leading-[14px] font-[500] py-[6px] px-[8px] rounded-[4px] bg-[#DFDFFB]'>
                    대표
                  </span>
                </div>
              </div>
              <img
                src='/assets/images/png/setting-2.png'
                alt='설정아이콘'
                className='w-[20px] h-[20px] object-contain '
              />
            </div>
          )}
      </div>
      {/* 자주 찾는 메뉴 */}
      {loginMember?.customerInfo && (
        <div className='bg-white py-[30px] mt-2'>
          <div className='text-[15px] leading-[15px] text-[#7D848A] mb-[20px] font-[600] px-[20px]'>
            자주 찾는 메뉴
          </div>
          <div className='w-full overflow-x-auto scroll-hidden'>
            <ul className='inline-flex justify-start items-center gap-[10px] px-[20px]'>
              <li>
                <button
                  className='min-w-[210px] p-[10px] pb-[15px] pl-[14px] rounded-[16px] bg-[#F3F6F9] min-h-[70px] flex justify-between items-center'
                  onClick={() => navigate('/ask/repair')}
                >
                  <div className='flex flex-col justify-center items-start'>
                    <h5 className='font-[500] text-[14px] leading-[14px] mb-[6px] pt-[4px]'>
                      공업사
                    </h5>
                    <p className='text-[13px] leading-[13px] text-[#555]'>
                      전문 수리 업체
                    </p>
                  </div>
                  <div className='w-[45px] h-[45px] flex justify-center items-center rounded-full bg-[#fff]'>
                    <img
                      src='/assets/images/png/my_menuIcon01.png'
                      alt='공업용 니퍼 아이콘'
                      className='h-[23px] object-contain'
                    />
                  </div>
                </button>
              </li>
              <li>
                <button
                  onClick={() => navigate('/static/insurance')}
                  className='min-w-[210px] p-[10px] pb-[15px] pl-[14px] rounded-[16px] bg-[#F3F6F9] min-h-[70px] flex justify-between items-center'
                >
                  <div className='flex flex-col justify-center items-start'>
                    <h5 className='font-[500] text-[14px] leading-[14px] mb-[6px] pt-[4px]'>
                      다이렉트 자동차 보험
                    </h5>
                    <p className='text-[13px] leading-[13px] text-[#555]'>
                      혜택을 더해서 가입하기
                    </p>
                  </div>
                  <div className='w-[45px] h-[45px] flex justify-center items-center rounded-full bg-[#FFFFFF]'>
                    <img
                      src='/assets/images/png/my_menuIcon02.png'
                      alt='마크 있는 차 아이콘'
                      className='h-[31px] object-contain'
                    />
                  </div>
                </button>
              </li>
              <li>
                <button
                  className='min-w-[210px] p-[10px] pb-[15px] pl-[14px] rounded-[16px] bg-[#F3F6F9] min-h-[70px] flex justify-between items-center'
                  onClick={() =>
                    navigate('/static/insurance', {
                      state: {
                        key: '하나손해보험',
                      },
                    })
                  }
                >
                  <div className='flex flex-col justify-center items-start'>
                    <h5 className='font-[500] text-[14px] leading-[14px] mb-[6px] pt-[4px]'>
                      하나 원데이 자동차 보험
                    </h5>
                    <p className='text-[13px] leading-[13px] text-[#555]'>
                      다른 사람 차량 운전 시
                    </p>
                  </div>
                  <img
                    src='/assets/images/static/company/hana.png'
                    alt='하나 자동차 보험 아이콘 '
                    className='h-[45px] w-[45px] object-contain shrink-0'
                  />
                </button>
              </li>
            </ul>
          </div>
        </div>
      )}
      {/* 정보 설정 */}
      {/* {loginMember?.dealerInfo && (
        <div className='bg-white py-[30px] px-[20px] mt-2'>
          <div className='text-[15px] leading-[15px] text-[#7D848A] mb-[20px] font-[600]'>
            정보 설정
          </div>
          <Link to='/mypage/info' className='flex h-[52px] items-center'>
            <div className='flex items-center gap-1.5'>
              <div className='text-[15px] leading-[15px] font-semibold'>
                내 정보
              </div>
            </div>
            <div className='ml-auto flex h-5 w-5 items-center justify-center'>
              <img src='/assets/images/v2/arrowRightMy.svg' alt='' />
            </div>
          </Link>
          <Link to='/mypage/alert' className='flex h-[52px] items-center'>
            <div className='flex items-center gap-1.5'>
              <div className='text-[15px] leading-[15px] font-semibold'>
                알림 설정
              </div>
            </div>
            <div className='ml-auto flex h-5 w-5 items-center justify-center'>
              <img src='/assets/images/v2/arrowRightMy.svg' alt='' />
            </div>
          </Link>
          <Link to='/mypage/commission' className='flex h-[52px] items-center'>
            <div className='flex items-center gap-1.5'>
              <div className='text-[15px] leading-[15px] font-semibold'>
                수수료 정산
              </div>
            </div>
            <div className='ml-auto flex h-5 w-5 items-center justify-center'>
              <img src='/assets/images/v2/arrowRightMy.svg' alt='' />
            </div>
          </Link>
          <Link
            to='/mypage/dealerRegionBrand'
            className='flex h-[52px] items-center'
          >
            <div className='flex items-center gap-1.5'>
              <div className='text-[15px] leading-[15px] font-semibold'>
                지역 및 자동차 브랜드 설정
              </div>
            </div>
            <div className='ml-auto flex h-5 w-5 items-center justify-center'>
              <img src='/assets/images/v2/arrowRightMy.svg' alt='' />
            </div>
          </Link>
        </div>
      )} */}
      {/* 내 활동 */}
      <div className='mt-2 bg-white py-[30px] px-[20px]'>
        <div className='text-[15px] leading-[15px] text-[#7D848A] mb-[20px] font-[600]'>
          내 활동
        </div>
        {loginMember?.dealerInfo ? (
          <>
            <Link
              to='/mypage/commission'
              className='flex h-[70px] items-center border-b border-[#E5EAEE]'
            >
              <div className='flex items-center gap-1.5'>
                <div className='text-[16px] leading-[16px] font-[500] relative flex justify-start items-center gap-[12px]'>
                  <div className='w-[30px] h-[30px] flex justify-center items-center rounded-[6px] bg-[#EAECF0]'>
                    <img
                      className='w-[22px] object-contain'
                      src='/assets/images/v2/noData.png'
                      alt='수수료 정산 아이콘'
                    />
                  </div>
                  수수료 정산
                </div>
              </div>
              <div className='ml-auto flex h-6 w-6 items-center justify-center mr-4'>
                <ChevronRight
                  width={24}
                  height={24}
                  color='#666'
                  strokeWidth={1.5}
                />
              </div>
            </Link>
            <Link
              to='/mypage/dealerRegionBrand'
              className='flex h-[70px] items-center border-b border-[#E5EAEE]'
            >
              <div className='flex items-center gap-1.5'>
                <div className='text-[16px] leading-[16px] font-[500] relative flex justify-start items-center gap-[12px]'>
                  <div className='w-[30px] h-[30px] flex justify-center items-center rounded-[6px] bg-[#FFEBDF]'>
                    <img
                      className='w-[22px] object-contain'
                      src='/assets/images/png/my_supportIcon06.png'
                      alt='지역 및 자동차 브랜드 설정 아이콘'
                    />
                  </div>
                  지역 및 자동차 브랜드 설정
                </div>
              </div>
              <div className='ml-auto flex h-6 w-6 items-center justify-center mr-4'>
                <ChevronRight
                  width={24}
                  height={24}
                  color='#666'
                  strokeWidth={1.5}
                />
              </div>
            </Link>
          </>
        ) : (
          <>
            <Link
              to='/incident/pay'
              className='flex h-[70px] items-center border-b border-[#E5EAEE]'
            >
              <div className='flex items-center gap-1.5'>
                <div className='text-[16px] leading-[16px] font-[500] relative flex justify-start items-center gap-[12px]'>
                  <div className='w-[30px] h-[30px] flex justify-center items-center rounded-[6px] bg-[#EAECF0]'>
                    <img
                      className='w-[22px] object-contain'
                      src='/assets/images/v2/noData.png'
                      alt='결제내역 아이콘'
                    />
                  </div>
                  결제내역
                </div>
              </div>
              <div className='ml-auto flex h-6 w-6 items-center justify-center mr-4'>
                <ChevronRight
                  width={24}
                  height={24}
                  color='#666'
                  strokeWidth={1.5}
                />
              </div>
            </Link>
            <Link
              to='/accidentReport/list'
              className='flex h-[70px] items-center border-b border-[#E5EAEE]'
            >
              <div className='flex items-center gap-1.5'>
                <div className='text-[16px] leading-[16px] font-[500] relative flex justify-start items-center gap-[12px]'>
                  <div className='w-[30px] h-[30px] flex justify-center items-center rounded-[6px] bg-[#FFEBDF]'>
                    <img
                      className='w-[22px] object-contain'
                      src='/assets/images/v2/incidentIcon.png'
                      alt='사고 접수 아이콘'
                    />
                  </div>
                  사고 접수
                </div>
              </div>
              <div className='ml-auto flex h-6 w-6 items-center justify-center mr-4'>
                <ChevronRight
                  width={24}
                  height={24}
                  color='#666'
                  strokeWidth={1.5}
                />
              </div>
            </Link>
            <Link
              to='/mypage/review'
              className='flex h-[70px] items-center border-b border-[#E5EAEE]'
            >
              <div className='flex items-center gap-1.5'>
                <div className='text-[16px] leading-[16px] font-[500] relative flex justify-start items-center gap-[12px]'>
                  <div className='w-[30px] h-[30px] flex justify-center items-center rounded-[6px] bg-[#F9E7FF]'>
                    <img
                      className='w-[22px] object-contain'
                      src='/assets/images/v2/reviewIcon.png'
                      alt='리뷰 관리 아이콘'
                    />
                  </div>
                  리뷰 관리
                </div>
              </div>
              <div className='ml-auto flex h-6 w-6 items-center justify-center mr-4'>
                <ChevronRight
                  width={24}
                  height={24}
                  color='#666'
                  strokeWidth={1.5}
                />
              </div>
            </Link>
          </>
        )}
      </div>
      {/* 고객 지원 */}
      <div className=' bg-white pb-[30px] px-[20px]'>
        <div className='text-[15px] leading-[15px] text-[#7D848A] mb-[20px] font-[600]'>
          고객지원
        </div>
        <Link
          to='/mypage/notice'
          className='flex h-[70px] items-center border-b border-[#E5EAEE]'
        >
          <div className='flex items-center gap-1.5'>
            <div className='text-[16px] leading-[16px] font-[500] relative flex justify-start items-center gap-[12px]'>
              <div className='w-[30px] h-[30px] flex justify-center items-center rounded-[6px] bg-[#FFF0F1]'>
                <img
                  className='w-[22px] object-contain'
                  src='/assets/images/png/my_supportIcon01.png'
                  alt='공지사항 아이콘'
                />
              </div>
              공지사항
              {isNewNotice && (
                <span className='absolute w-[5px] h-[5px] rounded-full bg-[#DC0000] right-[-10px] top-[-2px]'></span>
              )}
            </div>
            {/* {isNewNotice && (
              <div className='h-2 w-2 rounded-full bg-destructive'></div>
            )} */}
          </div>
          <div className='ml-auto flex h-6 w-6 items-center justify-center mr-4'>
            <ChevronRight
              width={24}
              height={24}
              color='#666'
              strokeWidth={1.5}
            />
          </div>
        </Link>
        {loginMember?.customerInfo && (
          <Link
            to='/mypage/inquiry'
            className='flex h-[70px] items-center border-b border-[#E5EAEE]'
          >
            <div className='flex items-center gap-1.5'>
              <div className='text-[16px] leading-[16px] font-[500] relative flex justify-start items-center gap-[12px]'>
                <div className='w-[30px] h-[30px] flex justify-center items-center rounded-[6px] bg-[#E5FCE3]'>
                  <img
                    className='w-[22px] object-contain'
                    src='/assets/images/png/my_supportIcon02.png'
                    alt='1:1 문의 아이콘'
                  />
                </div>
                1:1 문의
              </div>
            </div>
            <div className='ml-auto flex h-6 w-6 items-center justify-center mr-4'>
              <ChevronRight
                width={24}
                height={24}
                color='#666'
                strokeWidth={1.5}
              />
            </div>
          </Link>
        )}

        <Link
          to='/mypage/faq'
          className='flex h-[70px] items-center border-b border-[#E5EAEE]'
        >
          <div className='flex items-center gap-1.5'>
            <div className='text-[16px] leading-[16px] font-[500] relative flex justify-start items-center gap-[12px]'>
              <div className='w-[30px] h-[30px] flex justify-center items-center rounded-[6px] bg-[#E6E7FF]'>
                <img
                  className='w-[22px] object-contain'
                  src='/assets/images/png/my_supportIcon03.png'
                  alt='자주묻는질문 아이콘'
                />
              </div>
              자주 묻는 질문
              {isNewFaq && (
                <span className='absolute w-[5px] h-[5px] rounded-full bg-[#DC0000] right-[-10px] top-[-2px]'></span>
              )}
            </div>
          </div>
          <div className='ml-auto flex h-6 w-6 items-center justify-center mr-4'>
            <ChevronRight
              width={24}
              height={24}
              color='#666'
              strokeWidth={1.5}
            />
          </div>
        </Link>
        <Link
          to='/mypage/termList'
          className='flex h-[70px] items-center border-b border-[#E5EAEE]'
        >
          <div className='flex items-center gap-1.5'>
            <div className='text-[16px] leading-[16px] font-[500] relative flex justify-start items-center gap-[12px]'>
              <div className='w-[30px] h-[30px] flex justify-center items-center rounded-[6px] bg-[#EDF6FF]'>
                <img
                  className='w-[22px] object-contain'
                  src='/assets/images/png/my_supportIcon04.png'
                  alt='약관 / 정책 아이콘'
                />
              </div>
              약관 / 정책
            </div>
          </div>
          <div className='ml-auto flex h-6 w-6 items-center justify-center mr-4'>
            <ChevronRight
              width={24}
              height={24}
              color='#666'
              strokeWidth={1.5}
            />
          </div>
        </Link>
        <Link
          to='/mypage/alert'
          className='flex h-[70px] items-center border-b border-[#E5EAEE]'
        >
          <div className='flex items-center gap-1.5'>
            <div className='text-[16px] leading-[16px] font-[500] relative flex justify-start items-center gap-[12px]'>
              <div className='w-[30px] h-[30px] flex justify-center items-center rounded-[6px] bg-[#FFF8EB]'>
                <img
                  className='w-[22px] object-contain'
                  src='/assets/images/png/my_supportIcon05.png'
                  alt='알림 설정 아이콘'
                />
              </div>
              알림 설정
            </div>
          </div>
          <div className='ml-auto flex h-6 w-6 items-center justify-center mr-4'>
            <ChevronRight
              width={24}
              height={24}
              color='#666'
              strokeWidth={1.5}
            />
          </div>
        </Link>
      </div>
      {/* <div className='mt-2 bg-white py-[24px] px-[20px]'>
        <div className='text-[13px] leading-[13px] text-[#555555] mb-4 font-[500]'>
          불편 신고 및 고객 지원 센터
        </div>
        <div className='mb-[6px] text-[14px] leading-[19.6px] text-[#767676] flex justify-start items-center'>
          <span className='block min-w-[48px] mr-4'>평일</span>
          <span className='text-[14px] leading-[20px] text-[#111111]'>
            09:00 ~ 18:00
          </span>
        </div>
        <div className='text-[14px] leading-[19.6px] text-[#767676] flex justify-start items-center'>
          <span className='block min-w-[48px] mr-4'>점심시간</span>
          <span className='text-[14px] leading-[20px] text-[#111111]'>
            13:00 ~ 14:00
          </span>
        </div>
        <div className='mt-4 grid grid-cols-2 gap-[7px]'>
          <Button
            onClick={() => handleClickEvents.call('16681571')}
            variant='outline'
            className='flex justify-center items-center text-[14px] leading-[14px] font-[500] border-[#E5E5EC]'
          >
            <img
              src='/assets/images/v2/call-gray.svg'
              alt='전화아이콘'
              className='w-[16px] object-contain mr-[6px]'
            />
            1668-1571
          </Button>
          <Button
            // onClick={() => handleClickEvents.email('info@carsayo.net')}
            onClick={() => navigate('/mypage/inquiry')}
            variant='outline'
            className='flex justify-center items-center text-[14px] leading-[14px] font-[500] border-[#E5E5EC]'
          >
            <img
              src='/assets/images/v2/message.svg'
              alt='문자아이콘'
              className='w-[16px] object-contain mr-[6px]'
            />
            1:1 문의
          </Button>
        </div>
      </div> */}
      <div className='mt-2 bg-white px-[20px] pt-[30px] pb-[50px]'>
        {/* <div className='mt-4 flex'>
          <div className='text-[#555555] text-[13px] leading-[13px] font-[500]'>
            앱 버전
            <span className='ml-[3px]'>2.0.00</span>
          </div>
          <div className='ml-auto text-[#111111] text-[13px] leading-[18px] font-[400]'>
            최신 버전입니다.
          </div>
        </div> */}
        <Button
          onClick={() => {
            setIsLogoutOpen(true);
          }}
          variant='gray'
          className='font-[500] text-[15px] leading-[22.5px] min-h-[50px] bg-[#F3F6F9] rounded-[10px]'
        >
          로그아웃
        </Button>
      </div>
      <EditDrawer isOpen={isOpen} setIsOpen={setIsOpen}></EditDrawer>
      <Confirm
        isOpen={isLogoutOpen}
        setIsOpen={setIsLogoutOpen}
        isCloseButton={true}
        onConfirm={handleClickEvents.logout}
        title='카사요 로그아웃'
        desc={`정말 로그아웃 하시겠습니까?`}
      ></Confirm>
    </div>
  );
}
