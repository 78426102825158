import { getInsuranceDataApi } from 'pages/v2/ask/api';
import useInsuranceOriginDataStore from 'pages/v2/ask/store/dataStore';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

export default function AccidentReportIndex() {
  const { setOriginInsuranceData } = useInsuranceOriginDataStore();

  const getInsuranceData = async () => {
    const data = await getInsuranceDataApi();
    if (data) {
      setOriginInsuranceData(data);
    }
  };

  useEffect(() => {
    getInsuranceData();
  }, []);

  return <Outlet />;
}
