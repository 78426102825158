'use client';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useState } from 'react';

import DialogWrapper from '.';
import V3_Button from 'components_v3/ui/Button';
import { cn } from 'utils';

export default function V3_Confirm({
  isOpen,
  setIsOpen,

  onClose,
  onConfirm,

  title,
  children,
  disagreeText,
  agreeText,
  disableBackdropClose,
  isAlert = false,
  inIcon,
  className,
}: {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  /** router.back() 사용 불가능 */
  onClose?: () => void;

  /** 정의할 경우 onClose를 직접 호출해 주어야 합니다. */
  onConfirm?: () => void | Promise<void>;

  title: string;
  children?: React.ReactNode;
  disagreeText?: string;
  agreeText?: string;
  /** 백드롭 클릭으로 종료되지 않도록 */
  disableBackdropClose?: boolean;
  isAlert?: boolean;
  inIcon?: string;
  className?: string;
}) {
  const [isPending, setIsPending] = useState(false);

  const handleConfirm = async () => {
    if (onConfirm) {
      setIsPending(true);
      await onConfirm();
      setIsPending(false);
    } else {
      setIsOpen(false);
    }
  };

  return (
    <DialogWrapper isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
      <Dialog
        open={isOpen}
        onClose={(event, reason) => {
          if (disableBackdropClose && reason === 'backdropClick') {
            return;
          }
          setIsOpen(false);
        }}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        PaperProps={{
          sx: {
            borderRadius: '16px',
            width: '90vw',
            maxWidth: '400px',
          },
        }}
      >
        <DialogTitle
          id='alert-dialog-title'
          className='pb-[20px] pt-[24px] text-center !text-[16px] !font-[600] !leading-[25.6px]'
          sx={{
            fontFamily: 'Pretendard',
          }}
        >
          {inIcon && (
            <img
              src={inIcon}
              alt={'안내팝업 아이콘'}
              className='mx-auto mb-[20px] h-[40px] w-[40px] object-contain'
            />
          )}
          {title}
        </DialogTitle>
        <DialogContent
          className={cn(
            '!px-[15px]',
            children && '!pb-[30px]',
            inIcon && '!pt-[30px]',
            className,
          )}
        >
          {children && (
            <div
              id='alert-dialog-description'
              className='!mt-[6px] !whitespace-pre-line !text-center !text-[15px] !font-[500] !leading-[24px] !text-[#222222]'
            >
              {children}
            </div>
          )}
        </DialogContent>
        <DialogActions className='border-t border-[#EEEEEE] !p-0'>
          {isAlert === true ? (
            <V3_Button
              isLoading={isPending}
              className='!h-[54px] !rounded-none !border-none !p-0 !text-[#555555]'
              onClick={handleConfirm}
            >
              {agreeText ? agreeText : '확인'}
            </V3_Button>
          ) : (
            <V3_Button
              disabled={isPending}
              className='!h-[54px] !rounded-none !border-none !p-0 !text-[#555555]'
              onClick={() => setIsOpen(false)}
            >
              {disagreeText ? disagreeText : '취소'}
            </V3_Button>
          )}

          {isAlert === false && (
            <V3_Button
              isLoading={isPending}
              className='!ml-0 !h-[54px] !rounded-none !border-b-0 !border-l !border-r-0 !border-t-0 !border-[#EEEEEE] !p-0 !text-[#484AF5]'
              onClick={handleConfirm}
            >
              {agreeText ? agreeText : '확인 '}
            </V3_Button>
          )}
        </DialogActions>
      </Dialog>
    </DialogWrapper>
  );
}
