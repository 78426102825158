/* eslint-disable @typescript-eslint/no-unused-vars */
import { useLayoutEffect } from 'react';
import useSystemStore from 'store/useSystemStore';
import ReactHtmlParser from 'react-html-parser';

export default function TermFinanceConsumerCertificate() {
  const { setTopTabbarState } = useSystemStore();

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      title: '금융소비자보호법 등록증',
      rightElement: undefined,
      leftElement: 'back',
    });
  }, []);

  return (
    <div className='relative w-full flex-1 bg-white px-[24px] overflow-y-auto pb-[30px] pt-[35px]'>
      {ReactHtmlParser(`
          <ul style="display: flex; flex-direction: column; gap: 30px; text-align: left; font-size: 16px; font-weight: 300; padding-left: 0px; line-height: 25px;">
            <li style="display: flex; flex-direction: column;">
            <div style="margin-bottom: 10px;"><span style="font-weight: 500;"> 가. </span> 당사는 금융소비자보호법 상 '방문판매 등에 관한 법률에 따른 전화권유판매'로 상품에 관한 계약체결을 대리·중개하는 금융상품판매 대리·중개업자(법인)으로 하기의 다수 금융상품직접판매업자를 대리·중개하고 있습니다.</div>
            <div style="margin-top: 10px; margin-bottom: 10px;"><span style="font-weight: 500;"> 나. </span> 당사는 금융상품직접판매업자로부터 금융상품계약체결권을 부여받지 않은 경우 금융상품 계약을 체결할 권한이 없으며, 금융소비자보호법을 위반하여 금융소비자에게 손해를 발생시킨 경우 금융소비자보호법 제44조, 제45조에 따라 각 당사자는 그 손해를 배상할 책임이 있습니다.</div>
            <div style="margin-top: 10px; margin-bottom: 10px;"><span style="font-weight: 500;"> 다. </span> 당사는 금융소비자보호법에 따른 금융상품직접판매업자로부터 금전 수령에 관한 권한을 부여 받은 경우를 제외하고는 금융소비자가 계약에 따라 지급해야 하는 금전을 수령할 권한이 없으며, 대리중개 시 직접판매업자로부터 정해진 수수료 외에 금품 그 밖에 재산상 이익을 요구하거나 받을 수 없습니다.</div>
            <div style="margin-top: 10px; margin-bottom: 20px;"><span style="font-weight: 500;"> 라. </span> 금융소비자가 제공하는 개인(신용)정보 등은 금융상품직접판매업자가 보유, 관리(보험업법에 따른 보험중개사의 경우 제외)함을 알려드리며, 당사는 기타 금융소비자보호법에서 요구하는 금융소비자 보호 또는 건전한 질서유지를 위한 내용을 준수하고 있습니다.</div>
          </ul>
        `)}
      <img
        className='mb-[30px]'
        src='https://cdn.carsayo.net/resource/document/financialConsumerProtectionCertificate/231228.png'
      ></img>
    </div>
  );
}
