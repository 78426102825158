/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { zodResolver } from '@hookform/resolvers/zod';
import { FormHelperText } from '@mui/material';
import dayjs from 'dayjs';
import { X } from 'lucide-react';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { AccidentReport } from '@carsayo/types';

import {
  AccidentReportDataInForm,
  AccidentReportDataInSchema,
} from '../_forms/repair';
import DatePicker from 'components_v3/ui/DatePicker';
import { cn } from 'utils';
import V3_Input from 'components_v3/ui/Input';
import MultiImageViewer from 'components_v3/dialog/MultiImageViewer';

export default function TabInInfo({
  targetAccidentReport,
  refetch,
}: {
  targetAccidentReport: AccidentReport;
  refetch: () => void;
}) {
  if (targetAccidentReport.state === 'canceled') return null;
  if (!targetAccidentReport.info_in) return null;

  const form = useForm<AccidentReportDataInForm>({
    mode: 'onSubmit',
    resolver: zodResolver(AccidentReportDataInSchema),
    defaultValues: {
      in_date: targetAccidentReport.info_in?.in_date ?? '',
      pictures: targetAccidentReport?.info_in?.pictures ?? [],
      repair_expected_date:
        targetAccidentReport.info_in?.repair_expected_date ?? '',
      repair_expected_cost:
        targetAccidentReport.info_in?.repair_expected_cost ?? 0,
      out_expected_date: targetAccidentReport.info_in?.out_expected_date ?? '',
    },
    shouldFocusError: true,
  });

  const handleNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value.replace(/,/g, '');
    const numericValue = Number(rawValue);
    if (!isNaN(numericValue)) {
      form.setValue('repair_expected_cost', numericValue, {
        shouldValidate: true,
      });
    }
  };

  const isDisabled = true;

  const [isImageViewerOpen, setIsImageViewerOpen] = useState(false);
  const [imageViewerIndex, setImageViewerIndex] = useState(0);

  return (
    <div key={targetAccidentReport.id + 'in'} className=''>
      <strong className='mb-[20px] mt-[30px] flex items-center justify-start text-[18px] font-[600] leading-[18px] text-[#000]'>
        입고 정보
      </strong>
      <div className='mb-[20px] w-full rounded-[16px] bg-white px-4 py-[20px] shadow-[0px_6px_20px_#0000000D]'>
        <FormProvider {...form}>
          <form
            action=''
            className='flex flex-col items-start justify-start gap-6'
          >
            <label htmlFor='' className='w-full'>
              <span className='inline-block pb-[12px] text-[15px] leading-[15px] text-[#7D848A]'>
                입고일
              </span>
              <DatePicker
                value={
                  form.getValues('in_date')
                    ? dayjs(form.getValues('in_date'))
                    : null
                }
                disabled={isDisabled}
                onChange={(newValue) => {
                  const data =
                    newValue?.format('YYYYMMDD') ?? dayjs().format('YYYYMMDD');
                  form.setValue('in_date', data, {
                    shouldValidate: true,
                  });
                }}
                error={!!form.formState.errors.in_date}
                helperText={form.formState.errors.in_date?.message}
              />
            </label>
            <div className='w-full'>
              <div
                className={cn(
                  'flex w-full items-center justify-between',
                  form.getValues('pictures').length > 0
                    ? 'mb-[13px]'
                    : 'mb-[20px]',
                )}
              >
                <span className='inline-block text-[15px] leading-[15px] text-[#7D848A]'>
                  입고 사진
                </span>
                {form.getValues('pictures').length > 0 && (
                  <button
                    type='button'
                    onClick={() => {
                      setIsImageViewerOpen(true);
                    }}
                    className='rounded-[8px] bg-[#F3F6F9] p-2 text-[13px] font-[500] leading-[13px]'
                  >
                    전체보기
                  </button>
                )}
              </div>
              <div className='w-full overflow-x-auto'>
                <div className='flex items-center justify-start gap-[6px]'>
                  <ul className='flex items-center justify-start gap-[6px] pr-4'>
                    {form.getValues('pictures').map((item, index) => (
                      <li
                        className='relative h-[97px] w-[97px] overflow-hidden rounded-[6px]'
                        onClick={() => {
                          setImageViewerIndex(index);
                          setIsImageViewerOpen(true);
                        }}
                        key={item}
                      >
                        <img
                          src={item}
                          alt={'입고 이미지'}
                          className='h-full w-full object-cover'
                        />
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            const currentData = (form.getValues('pictures') ||
                              []) as string[];

                            const filteredData = currentData.filter(
                              (el: string) => el !== item,
                            );

                            if (filteredData.length === 0) {
                              form.setValue('pictures', [] as any, {
                                shouldValidate: true,
                              });
                            } else {
                              form.setValue('pictures', filteredData as any, {
                                shouldValidate: true,
                              });
                            }
                          }}
                          className={cn(
                            'absolute right-1 top-1 z-10 flex h-[20px] w-[20px] items-center justify-center rounded-full bg-[#000000CC]',
                            isDisabled && 'hidden',
                          )}
                        >
                          <X width={12} height={12} color='#fff' />
                        </button>
                      </li>
                    ))}
                  </ul>

                  <MultiImageViewer
                    isOpen={isImageViewerOpen}
                    setIsOpen={setIsImageViewerOpen}
                    initialIndex={imageViewerIndex}
                    fileUrl={form.getValues('pictures')}
                  />
                </div>

                {form.formState.errors.pictures && (
                  <FormHelperText
                    sx={{
                      color: '#FF4D4D',
                      fontSize: '12px',
                      lineHeight: '20px',
                      letterSpacing: '0.4px',
                      fontWeight: '400',
                      marginX: '14px',
                      marginTop: '3px !important',
                    }}
                  >
                    {form.formState.errors.pictures.message}
                  </FormHelperText>
                )}
              </div>
            </div>
            <label htmlFor='' className='w-full'>
              <span className='inline-block pb-[12px] text-[15px] leading-[15px] text-[#7D848A]'>
                수리 시작 예정일
              </span>
              <DatePicker
                value={
                  form.getValues('repair_expected_date')
                    ? dayjs(form.getValues('repair_expected_date'))
                    : null
                }
                disabled={isDisabled}
                onChange={(newValue) => {
                  const data =
                    newValue?.format('YYYYMMDD') ?? dayjs().format('YYYYMMDD');
                  form.setValue('repair_expected_date', data, {
                    shouldValidate: true,
                  });
                }}
                error={!!form.formState.errors.repair_expected_date}
                helperText={form.formState.errors.repair_expected_date?.message}
              />
            </label>
            <label htmlFor='' className='w-full'>
              <span className='inline-block pb-[12px] text-[15px] leading-[15px] text-[#7D848A]'>
                예상 수리비
              </span>
              <V3_Input
                type='text'
                theme='secondary'
                variant='outlined'
                disabled={isDisabled}
                inUnit='원'
                value={
                  form.getValues('repair_expected_cost')?.toLocaleString() ?? 0
                }
                onChange={handleNumber}
                error={!!form.formState.errors.repair_expected_cost}
                helperText={form.formState.errors.repair_expected_cost?.message}
              />
            </label>
            <label htmlFor='' className='w-full'>
              <span className='inline-block pb-[12px] text-[15px] leading-[15px] text-[#7D848A]'>
                출고 예정일
              </span>
              <DatePicker
                value={
                  form.getValues('out_expected_date')
                    ? dayjs(form.getValues('out_expected_date'))
                    : null
                }
                disabled={isDisabled}
                onChange={(newValue) => {
                  const data =
                    newValue?.format('YYYYMMDD') ?? dayjs().format('YYYYMMDD');
                  form.setValue('out_expected_date', data, {
                    shouldValidate: true,
                  });
                }}
                error={!!form.formState.errors.out_expected_date}
                helperText={form.formState.errors.out_expected_date?.message}
              />
            </label>
          </form>
        </FormProvider>
      </div>
    </div>
  );
}
