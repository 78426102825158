/* eslint-disable @typescript-eslint/no-unused-vars */
import { Drawer } from '@mui/material';
import { styled } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import { koKR } from '@mui/x-date-pickers/locales/koKR';
import { ChevronDown, X } from 'lucide-react';
import React, { ReactNode, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import V3_Button from 'components_v3/ui/Button';

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '.MuiDrawer-paper': {
    width: '100dvw',
    padding: '10px',
    boxShadow: theme.shadows[5],
  },
}));

interface CustomDialogProps {
  children: ReactNode;
  open: boolean;
  setIsOpen: (state: boolean) => void;
}

const CustomDialog: React.FC<CustomDialogProps> = ({
  children,
  open,
  setIsOpen,
}) => {
  const toggleDrawer = () => {
    setIsOpen(!open);
  };

  return (
    <StyledDrawer
      variant='temporary'
      anchor='bottom'
      open={open}
      onClose={toggleDrawer}
      sx={{
        width: '100%',
        zIndex: 400,
        '& .MuiPaper-root': {
          borderTopRightRadius: '24px',
          borderTopLeftRadius: '24px',
        },
        '& .MuiPickersLayout-root': {
          minWidth: '280px',
          display: 'block',
        },
        '& .MuiDateCalendar-root': {
          height: 'auto',
          maxHeight: '70dvh',
          width: '100%',
          margin: '0px',
          alignItems: 'center',
        },
        '& .MuiYearCalendar-root': {
          margin: '0 auto',
        },
        '& .MuiMonthCalendar-root': {
          width: 'calc(100%-68px)',
          gap: '9px',
          margin: '0 auto',
        },
        '& .MuiPickersToolbar-root': {
          alignItems: 'center',
          paddingTop: '12px',
          '& .MuiTypography-root': {
            fontFamily: 'Pretendard Variable',
            fontSize: '20px',
            lineHeight: '20px',
            fontWeight: '600',
            letterSpacing: '-0.02em',
            color: '#222',
          },
          '& .MuiPickersToolbar-content': {
            display: 'none',
          },
        },
        '& .MuiPickersMonth-root': {
          flexBasis: 'calc((100% - 27px) / 4)',
          aspectRatio: '60/60',
        },
        '& .MuiPickersArrowSwitcher-root': {
          display: 'none',
        },
        '& .MuiPickersMonth-monthButton': {
          aspectRatio: '60/60',
          height: 'auto',
          width: '100%',
          fontFamily: 'Pretendard Variable',
          fontWeight: '500',
          fontSize: '18px',
          lineHeight: '18px',
        },
        '& .MuiPickersYear-yearButton': {
          borderRadius: '16px !important',
        },
        '& .Mui-selected': {
          backgroundColor: '#7273F9 !important',
          borderRadius: '100%',
        },
        '& .Mui-selected:hover': {
          backgroundColor: '#7273F9 !important',
          borderRadius: '100%',
        },
        '& .Mui-selected:focus': {
          backgroundColor: '#7273F9 !important',
          borderRadius: '100%',
        },
        '& .MuiDialogActions-root': {
          display: 'none',
        },
      }}
    >
      {children}
    </StyledDrawer>
  );
};

export default CustomDialog;
