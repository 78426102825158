/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import SelectV3, { SelectV3Item } from 'components/common/v3/selectv3';
import Button from 'components_v3/ui/Button';
import { CustomCheckbox } from 'components_v3/ui/Checkbox';
import { ChevronRight, X } from 'lucide-react';
import { useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';
import { cn } from 'utils';

export default function QuoteRequestPurchasePage() {
  const navigate = useNavigate();
  const { setTopTabbarState, setIsBottomTabbar } = useSystemStore();
  const [paymentMethod, setPaymentMethod] = useState('카드');
  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      title: '견적 요청권 구매',
      rightElement: (
        <div className='min-w-[28px] cursor-pointer'>
          <X
            onClick={() => navigate(-1)}
            width={28}
            height={28}
            strokeWidth={1.5}
          />
        </div>
      ),
    });
    setIsBottomTabbar(false);
  }, []);
  return (
    <div className='w-full flex flex-col justify-between gap-[50px] items-start min-h-[calc(100dvh-60px)]'>
      <div className='h-full flex-1'>
        <div className='w-full py-[30px] px-5 border-b-[8px] border-[#F3F6F9]'>
          <div className='flex justify-start items-center gap-4'>
            <div className='flex justify-center items-center p-2.5 bg-[#F3F6F9] rounded-[10px]'>
              <img
                src='/assets/images/png/carsayo_card.png'
                alt='카사요 기프트카드 이미지'
                className='w-[70px] object-contain'
              />
            </div>
            <div className='flex flex-col justify-start items-start gap-[14px]'>
              <div className='flex flex-col justify-start items-start gap-0.5'>
                <strong className='text-[15px] leading-[24px] font-[600]'>
                  견적 요청권
                </strong>
                <p className='text-[14px] leading-[22.4px] font-[500] text-[#555555]'>
                  신차 구매, 내 차 팔기
                </p>
              </div>
              <strong className='text-[16px] leading-[16px] font-[600]'>
                10,000원
              </strong>
            </div>
          </div>
          <div className='w-full rounded-[10px] bg-[#F3F6F9] p-4 mt-[40px]'>
            <div className='border-b border-[#E5E5EC] pb-4 text-[15px] font-[500] leading-[22.5px]'>
              무료 견적 요청 안내 사항
            </div>
            <ul className='mt-5 flex w-full list-none flex-col items-start justify-start gap-3'>
              <li
                className={cn(
                  'relative break-keep pl-5 text-[14px] leading-[21px] text-[#555555]',
                  'after:absolute after:left-[9px] after:top-[10px] after:h-[3px] after:w-[3px] after:rounded-full after:bg-[#555555] after:content-[""]',
                )}
              >
                무료 견적 요청은 신차 구매, 내 차 팔기 각 2건으로 제한됩니다.
              </li>
              <li
                className={cn(
                  'relative break-keep pl-5 text-[14px] leading-[21px] text-[#555555]',
                  'after:absolute after:left-[9px] after:top-[10px] after:h-[3px] after:w-[3px] after:rounded-full after:bg-[#555555] after:content-[""]',
                )}
              >
                2대 초과 견적 요청 시, 건당 1만원의 정보 이용료가 발생합니다.
              </li>
              <li
                className={cn(
                  'relative max-w-[290px] break-keep pl-5 text-[14px] leading-[21px] text-[#555555]',
                  'after:absolute after:left-[9px] after:top-[10px] after:h-[3px] after:w-[3px] after:rounded-full after:bg-[#555555] after:content-[""]',
                )}
              >
                <span className='text-[#484AF5]'>
                  카사요를 통해 주문을 진행한 경우
                </span>{' '}
                결제한 견적 요청권 구매금액은{' '}
                <span className='text-[#484AF5]'>100% 환불</span>해 드립니다.
              </li>
              <li
                className={cn(
                  'relative max-w-[290px] break-keep pl-5 text-[14px] leading-[21px] text-[#555555]',
                  'after:absolute after:left-[9px] after:top-[10px] after:h-[3px] after:w-[3px] after:rounded-full after:bg-[#555555] after:content-[""]',
                )}
              >
                결제가 완료되면 무료 견적 신청이 가능하며 마이 페이지 {'>'}{' '}
                결제내역 페이지에서 확인할 수 있습니다.
              </li>
            </ul>
          </div>
        </div>
        {/* 구매자 정보 */}
        <div className='w-full pt-[22px] pb-[30px] px-5 border-b-[8px] border-[#F3F6F9]'>
          <div className='mb-6 text-[18px] leading-[18px] font-[600]'>
            구매자 정보
          </div>
          <ul className='py-5 px-4 bg-[#F3F6F9] rounded-[10px] text-[15px] leading-[15px] font-[500] flex flex-col justify-start items-start gap-5'>
            <li className='w-full flex justify-between items-center gap-5'>
              <span className='text-[#7D848A]'>주문자</span>
              <strong className='font-[500]'>홍길동</strong>
            </li>
            <li className='w-full flex justify-between items-center gap-5'>
              <span className='text-[#7D848A]'>연락처</span>
              <strong className='font-[500]'>010-1234-1234</strong>
            </li>
          </ul>
        </div>
        {/* 결제 수단 */}
        <div className='w-full pt-[30px] pb-[30px] px-5 border-b-[8px] border-[#F3F6F9]'>
          <div className='mb-6 text-[18px] leading-[18px] font-[600]'>
            결제 수단
          </div>
          <div className='w-full flex flex-col justify-start items-start gap-2.5'>
            <ul className='w-full grid grid-cols-3 text-[14px] leading-[14px] gap-[7px]'>
              <li
                onClick={() => setPaymentMethod('카드')}
                className={cn(
                  'flex justify-center items-center p-[18px] rounded-[10px] border border-[#E5E5EC] cursor-pointer transition-all',
                  paymentMethod === '카드' &&
                    'bg-[#F6F6FC] border-[#7273F9] text-[#484AF5] font-[600]',
                )}
              >
                카드
              </li>
              <li
                onClick={() => setPaymentMethod('토스페이')}
                className={cn(
                  'flex justify-center items-center p-[18px] rounded-[10px] border border-[#E5E5EC] cursor-pointer transition-all',
                  paymentMethod === '토스페이' &&
                    'bg-[#F6F6FC] border-[#7273F9] text-[#484AF5] font-[600]',
                )}
              >
                토스페이
              </li>
              <li
                onClick={() => setPaymentMethod('카카오페이')}
                className={cn(
                  'flex justify-center items-center p-[18px] rounded-[10px] border border-[#E5E5EC] cursor-pointer transition-all',
                  paymentMethod === '카카오페이' &&
                    'bg-[#F6F6FC] border-[#7273F9] text-[#484AF5] font-[600]',
                )}
              >
                카카오페이
              </li>
              <li
                onClick={() => setPaymentMethod('페이코')}
                className={cn(
                  'flex justify-center items-center p-[18px] rounded-[10px] border border-[#E5E5EC] cursor-pointer transition-all',
                  paymentMethod === '페이코' &&
                    'bg-[#F6F6FC] border-[#7273F9] text-[#484AF5] font-[600]',
                )}
              >
                페이코
              </li>
              <li
                onClick={() => setPaymentMethod('KB Pay')}
                className={cn(
                  'flex justify-center items-center p-[18px] rounded-[10px] border border-[#E5E5EC] cursor-pointer transition-all',
                  paymentMethod === 'KB Pay' &&
                    'bg-[#F6F6FC] border-[#7273F9] text-[#484AF5] font-[600]',
                )}
              >
                KB Pay
              </li>
              <li
                onClick={() => setPaymentMethod('삼성페이')}
                className={cn(
                  'flex justify-center items-center p-[18px] rounded-[10px] border border-[#E5E5EC] cursor-pointer transition-all',
                  paymentMethod === '삼성페이' &&
                    'bg-[#F6F6FC] border-[#7273F9] text-[#484AF5] font-[600]',
                )}
              >
                삼성페이
              </li>
              <li
                onClick={() => setPaymentMethod('네이버페이')}
                className={cn(
                  'flex justify-center items-center p-[18px] rounded-[10px] border border-[#E5E5EC]',
                  paymentMethod === '네이버페이' &&
                    'bg-[#F6F6FC] border-[#7273F9] text-[#484AF5] font-[600]',
                )}
              >
                네이버페이
              </li>
              <li
                onClick={() => setPaymentMethod('무통장입금')}
                className={cn(
                  'flex justify-center items-center p-[18px] rounded-[10px] border border-[#E5E5EC]',
                  paymentMethod === '무통장입금' &&
                    'bg-[#F6F6FC] border-[#7273F9] text-[#484AF5] font-[600]',
                )}
              >
                무통장입금
              </li>
              <li
                onClick={() => setPaymentMethod('휴대폰결제')}
                className={cn(
                  'flex justify-center items-center p-[18px] rounded-[10px] border border-[#E5E5EC]',
                  paymentMethod === '휴대폰결제' &&
                    'bg-[#F6F6FC] border-[#7273F9] text-[#484AF5] font-[600]',
                )}
              >
                휴대폰결제
              </li>
            </ul>
            {paymentMethod === '카드' && (
              <SelectV3 onValueChange={() => {}} value={'KB국민카드'}>
                <SelectV3Item value={'KB국민카드'}>
                  <div className='flex items-center'>KB국민카드</div>
                </SelectV3Item>
                <SelectV3Item value={'신한카드'}>
                  <div className='flex items-center'>신한카드</div>
                </SelectV3Item>
                <SelectV3Item value={'BC카드'}>
                  <div className='flex items-center'>BC카드</div>
                </SelectV3Item>
                <SelectV3Item value={'우리카드'}>
                  <div className='flex items-center'>우리카드</div>
                </SelectV3Item>
                <SelectV3Item value={'하나카드'}>
                  <div className='flex items-center'>하나카드</div>
                </SelectV3Item>
              </SelectV3>
            )}
            <SelectV3 onValueChange={() => {}} value={'일시불'}>
              <SelectV3Item value={'일시불'}>
                <div className='flex items-center'>일시불</div>
              </SelectV3Item>
              <SelectV3Item value={'할부'}>
                <div className='flex items-center'>할부</div>
              </SelectV3Item>
            </SelectV3>
          </div>
        </div>
        {/* 주문 내역 */}
        <div className='w-full pt-[30px] px-5'>
          <div className='text-[18px] leading-[18px] font-[600] pb-6 border-b border-[#E5EAEE]'>
            주문 내역
          </div>
          <div className='py-6'>
            <div className='w-full flex justify-between items-center gap-4 pb-6 border-b border-[#E5EAEE] text-[14px] leading-[19.6px]'>
              <span className='text-[#7D848A]'>견적 요청권</span>
              <strong className='font-[500]'>10,000원</strong>
            </div>
            <div className='w-full flex justify-between items-center gap-4 pt-6'>
              <span className='text-[16px] leading-[16px] font-[500]'>
                총 결제 금액
              </span>
              <strong className='text-[18px] leading-[18px] font-[600]'>
                10,000원
              </strong>
            </div>
          </div>
          <div className='w-full'>
            <div className='w-full flex justify-between items-center gap-4 py-[14px] px-4 rounded-[10px] bg-[#F3F6F9] mb-4'>
              <span className='text-[15px] leading-[22.5px] font-[500]'>
                개인정보처리방침 안내
              </span>
              <ChevronRight
                width={24}
                height={24}
                color='#666'
                strokeWidth={1.5}
              />
            </div>
            <CustomCheckbox
              value={''}
              label=' 위의 내용을 모두 확인하였으며, 결제에 동의합니다.'
              className='!text-[14px]'
            />
          </div>
        </div>
      </div>
      <div className='w-full flex justify-center items-center px-5 pt-[12px] pb-[10px]'>
        <Button
          theme='primary'
          onClick={() => navigate('/newcar/quote-request/done')}
        >
          10,000원 결제하기
        </Button>
      </div>
    </div>
  );
}
