/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { CarMakerData, SidoData, SidoId } from '@carsayo/types';
import V3_Button from 'components_v3/ui/Button';
import { CustomCheckbox } from 'components_v3/ui/Checkbox';
import { useLayoutEffect, useState } from 'react';
import useSystemStore from 'store/useSystemStore';
import { cn } from 'utils';

const RegionSection = () => {
  const [selectedSido, setSelectedSido] = useState<SidoId[]>([]);
  const [isAllSelected, setIsAllSelected] = useState(false);

  const sidoSelect = (id: SidoId) => () => {
    if (selectedSido.includes(id)) {
      const updatedSido = selectedSido.filter((sidoId) => sidoId !== id);
      setSelectedSido(updatedSido);
      setIsAllSelected(false);
    } else {
      const updatedSido = [...selectedSido, id];
      setSelectedSido(updatedSido);

      if (updatedSido.length === SidoData.length) {
        setIsAllSelected(true);
      }
    }
  };
  const toggleAllSelection = () => {
    if (isAllSelected) {
      setSelectedSido([]);
      setIsAllSelected(false);
    } else {
      const allIds = SidoData.map((item) => item.id);
      setSelectedSido(allIds);
      setIsAllSelected(true);
    }
  };
  return (
    <section className='w-full px-[20px] border-b-[8px] border-[#F3F6F9] pb-[30px]'>
      <div className='flex justify-between items-center gap-4'>
        <strong className='text-[18px] leading-[18px] font-[600]'>
          지역 선택
        </strong>
        <CustomCheckbox
          type='brandSetting'
          value='전체 선택'
          label='전체 선택'
          checked={isAllSelected}
          onChange={toggleAllSelection}
          className='!text-[14px]'
        />
      </div>
      <div className='pt-[25px]'>
        <ul className='grid grid-cols-3 gap-[7px] text-[14px] leading-[14px] font-[500]'>
          {SidoData.map((item) => (
            <li
              onClick={sidoSelect(item.id)}
              className={cn(
                'w-full flex justify-center items-center py-3 px-4 min-h-[50px] border border-[#E5E5EC] rounded-[8px] transition-all',
                selectedSido.find((id) => id === item.id) && 'border-[#7273F9]',
              )}
            >
              {item.name_short}
            </li>
          ))}
        </ul>
      </div>
      <div className='py-5 px-4 bg-[#F3F6F9] rounded-[10px] mt-[30px]'>
        <ul className='text-[#555555] flex flex-col justify-start items-start gap-[12px]'>
          <li className='flex justify-start items-center relative text-[13px] break-keep leading-[19.5px] pl-[18px] after:content-["•"] after:absolute after:left-1 after:top-0 after:bottom-0 after:my-auto'>
            선택된 지역의 주문만 확인할 수 있으며 자유롭게 변경할 수 있습니다.
          </li>
        </ul>
      </div>
    </section>
  );
};

const DomesticSection = () => {
  const [selectedMaker, setSelectedMaker] = useState<number[]>([]);
  const [isAllSelected, setIsAllSelected] = useState(false);

  const domesticSelect = (id: number) => () => {
    if (selectedMaker.includes(id)) {
      const updatedDomestic = selectedMaker.filter((maker) => maker !== id);
      setSelectedMaker(updatedDomestic);
      setIsAllSelected(false);
    } else {
      const updatedDomestic = [...selectedMaker, id];
      setSelectedMaker(updatedDomestic);

      if (
        updatedDomestic.length ===
        CarMakerData.filter((maker) => maker.type === 'domestic').length
      ) {
        setIsAllSelected(true);
      }
    }
  };
  const toggleAllSelection = () => {
    if (isAllSelected) {
      setSelectedMaker([]);
      setIsAllSelected(false);
    } else {
      const allIds = CarMakerData.filter(
        (maker) => maker.type === 'domestic',
      ).map((item) => item.id);
      setSelectedMaker(allIds);
      setIsAllSelected(true);
    }
  };

  return (
    <section className='w-full px-[20px] border-b-[8px] border-[#F3F6F9] py-[30px]'>
      <div className='flex justify-between items-center gap-4'>
        <strong className='text-[18px] leading-[18px] font-[600]'>
          국산 브랜드 선택
        </strong>
        <CustomCheckbox
          type='brandSetting'
          value='전체 선택'
          label='전체 선택'
          checked={isAllSelected}
          onChange={toggleAllSelection}
          className='!text-[14px]'
        />
      </div>
      <div className='pt-[25px]'>
        <ul className='grid grid-cols-3 gap-[7px] text-[12px] leading-[18px] font-[600]'>
          {CarMakerData.filter((maker) => maker.type === 'domestic').map(
            (item) => (
              <li
                onClick={domesticSelect(item.id)}
                className={cn(
                  'w-full flex flex-col gap-1.5 justify-center items-center py-3 px-4 min-h-[50px] border border-[#E5E5EC] rounded-[8px] transition-all whitespace-nowrap',
                  selectedMaker.find((id) => id === item.id) &&
                    'border-[#7273F9]',
                )}
              >
                <img
                  src={item.image_url}
                  alt={`${item.name}_이미지`}
                  className='h-[40px] object-contain'
                />
                {item.name}
              </li>
            ),
          )}
        </ul>
      </div>
    </section>
  );
};

const ImportedSection = () => {
  const [selectedMaker, setSelectedMaker] = useState<number[]>([]);
  const [isAllSelected, setIsAllSelected] = useState(false);

  const importedSelect = (id: number) => () => {
    if (selectedMaker.includes(id)) {
      const updatedImported = selectedMaker.filter((maker) => maker !== id);
      setSelectedMaker(updatedImported);
      setIsAllSelected(false);
    } else {
      const updatedImported = [...selectedMaker, id];
      setSelectedMaker(updatedImported);

      if (
        updatedImported.length ===
        CarMakerData.filter((maker) => maker.type === 'imported').length
      ) {
        setIsAllSelected(true);
      }
    }
  };
  const toggleAllSelection = () => {
    if (isAllSelected) {
      setSelectedMaker([]);
      setIsAllSelected(false);
    } else {
      const allIds = CarMakerData.filter(
        (maker) => maker.type === 'imported',
      ).map((item) => item.id);
      setSelectedMaker(allIds);
      setIsAllSelected(true);
    }
  };

  return (
    <section className='w-full px-[20px] pt-[30px]'>
      <div className='flex justify-between items-center gap-4'>
        <strong className='text-[18px] leading-[18px] font-[600]'>
          수입 브랜드 선택
        </strong>
        <CustomCheckbox
          type='brandSetting'
          value='전체 선택'
          label='전체 선택'
          checked={isAllSelected}
          onChange={toggleAllSelection}
          className='!text-[14px]'
        />
      </div>
      <div className='pt-[25px]'>
        <ul className='grid grid-cols-3 gap-[7px] text-[12px] leading-[18px] font-[600]'>
          {CarMakerData.filter((maker) => maker.type === 'imported').map(
            (item) => (
              <li
                onClick={importedSelect(item.id)}
                className={cn(
                  'w-full flex flex-col gap-1.5 justify-center items-center py-3 px-4 min-h-[90px] border border-[#E5E5EC] rounded-[8px] transition-all',
                  selectedMaker.find((id) => id === item.id) &&
                    'border-[#7273F9]',
                )}
              >
                <img
                  src={item.image_url}
                  alt={`${item.name}_이미지`}
                  className='h-[40px] object-contain'
                />
                {item.name}
              </li>
            ),
          )}
        </ul>
      </div>
    </section>
  );
};

export default function DealerBrandSettingPage() {
  const { setTopTabbarState, setIsBottomTabbar } = useSystemStore();
  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      title: '지역 및 자동차 브랜드 설정',
      rightElement: undefined,
      leftElement: 'back',
    });
    setIsBottomTabbar(false);
  }, []);
  return (
    <div className='pt-[30px] pb-[10px] flex flex-col justify-start items-start gap-[50px]'>
      <div className='w-full'>
        <RegionSection />
        <DomesticSection />
        <ImportedSection />
      </div>
      <div className='w-full px-5'>
        <V3_Button theme='primary'>적용</V3_Button>
      </div>
    </div>
  );
}
