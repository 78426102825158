/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useState } from 'react';
import BrandListCard from './BrandListCard';
import { CarMakerData } from '@carsayo/types';
import { ChevronRight } from 'lucide-react';

export default function ImportedList({
  setStep,
}: {
  setStep: (state: number) => void;
}) {
  const [viewType, setViewType] = useState<'model' | 'brand'>('brand');
  const brandList = CarMakerData.filter(
    (item) => item.type === 'imported' && item.isSuperCar === false,
  );
  return (
    <div>
      <div className='px-5 flex justify-end items-center mb-5'>
        <button
          onClick={() => setViewType(viewType === 'brand' ? 'model' : 'brand')}
          className='flex justify-center items-center gap-1 p-2.5 border border-[#E5E5EC] rounded-[10px] h-[34px]'
        >
          {viewType === 'brand' ? (
            <img
              src='/assets/images/v2/listIcon.png'
              alt='리스트아이콘'
              className='w-4 object-contain'
            />
          ) : (
            <img
              src='/assets/images/v2/gridIcon.png'
              alt='그리드아이콘'
              className='w-4 object-contain'
            />
          )}
          <span className='text-[14px] leading-[14px] font-[500]'>
            {viewType === 'brand' ? '세부모델 보기' : '브랜드 보기'}
          </span>
        </button>
      </div>
      <div className='w-full'>
        {viewType === 'brand' && (
          <ul className='grid grid-cols-2 gap-[7px] px-5'>
            {brandList.map((item) => (
              <li key={`importedBrand_${item.id}`} onClick={() => setStep(2)}>
                <BrandListCard
                  logo={item.image_url}
                  title={item.name}
                  imgUrl={'/assets/images/v2/carExImg.png'}
                />
              </li>
            ))}
          </ul>
        )}
        {viewType === 'model' && (
          <ul className='grid grid-cols-1 gap-6'>
            {brandList.map((item) => (
              <li key={`importedModel_${item.id}`} onClick={() => setStep(2)}>
                <div className='w-[calc(100%-40px)] mx-auto flex justify-between items-center gap-5 mb-4'>
                  <div className='flex justify-start items-center gap-[12px]'>
                    <div className='w-[44px] h-[44px] flex justify-center items-center rounded-full border border-[#E5E5EC]'>
                      <img
                        src={item.image_url}
                        alt={`${item.name}_logo`}
                        className='w-[30px] object-contain'
                      />
                    </div>
                    <span className='text-[18px] leading-[18px] font-[500] text-[#000]'>
                      {item.name}
                    </span>
                  </div>
                  <span className='text-[#333] text-[15px] leading-[15px] font-[500] inline-flex justify-center items-center '>
                    14종
                    <ChevronRight
                      color='#666'
                      width={24}
                      height={24}
                      strokeWidth={1.5}
                      className='ml-1'
                    />
                  </span>
                </div>
                <div className='w-full overflow-x-auto overflow-y-hidden scroll-hidden'>
                  <ul className='mx-[20px] w-auto flex justify-start items-center gap-[9px]'>
                    <li className='w-[calc(100dvw-40px)] shrink-0'>
                      <div className='p-[20px] flex justify-center items-center bg-gradient-to-b from-[#111111] to-[#333333] rounded-[10px]'>
                        <img
                          src='/assets/images/v2/bmw7.png'
                          alt=''
                          className='w-full h-[108px] object-contain'
                        />
                      </div>
                      <p className='mt-4 text-[15px] leading-[15px] font-[500]'>
                        디 올뉴그랜저 하이브리드
                      </p>
                    </li>
                    {/* 마지막에만 pr-[20px]가 들어갑니다! */}
                    <li className='w-[calc(100dvw-40px)] shrink-0 pr-[20px]'>
                      <div className='p-[20px] flex justify-center items-center bg-gradient-to-b from-[#111111] to-[#333333] rounded-[10px]'>
                        <img
                          src='/assets/images/v2/bmw7.png'
                          alt=''
                          className='w-full h-[108px] object-contain'
                        />
                      </div>
                      <p className='mt-4 text-[15px] leading-[15px] font-[500]'>
                        디 올뉴그랜저 하이브리드
                      </p>
                    </li>
                  </ul>
                </div>
                <div className='w-[calc(100%-40px)] mx-auto h-[1px] bg-[#E5EAEE] mt-6'></div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}
