export default function ClockIcon() {
  return (
    <svg
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.00008 1.16669C3.78591 1.16669 1.16675 3.78585 1.16675 7.00002C1.16675 10.2142 3.78591 12.8334 7.00008 12.8334C10.2142 12.8334 12.8334 10.2142 12.8334 7.00002C12.8334 3.78585 10.2142 1.16669 7.00008 1.16669ZM9.53758 9.08252C9.45592 9.22252 9.31008 9.29835 9.15842 9.29835C9.08258 9.29835 9.00675 9.28085 8.93675 9.23419L7.12842 8.15502C6.67925 7.88669 6.34675 7.29752 6.34675 6.77835V4.38669C6.34675 4.14752 6.54508 3.94919 6.78425 3.94919C7.02342 3.94919 7.22175 4.14752 7.22175 4.38669V6.77835C7.22175 6.98835 7.39675 7.29752 7.57758 7.40252L9.38592 8.48169C9.59592 8.60419 9.66592 8.87252 9.53758 9.08252Z'
        fill='#B2B7BB'
      />
    </svg>
  );
}
