export default function PurchaseIcon({
  fill,
  width,
  height,
}: {
  fill?: string;
  width?: string;
  height?: string;
}) {
  return (
    <svg
      width={width || '30'}
      height={height || '30'}
      viewBox='0 0 30 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_85_4657)'>
        <path
          d='M5.06119 21.9668H2.22168V13.4483L5.06119 6.34949H17.839L23.518 13.4483H24.9377C25.6908 13.4483 26.4131 13.7474 26.9456 14.2799C27.4781 14.8124 27.7772 15.5347 27.7772 16.2878V21.9668H24.9377M19.2587 21.9668H10.7402H19.2587ZM2.22168 13.4483H23.518H2.22168ZM14.9995 13.4483V6.34949V13.4483Z'
          fill={fill || '#B2B7BB'}
        />
        <path
          d='M5.06119 21.9668H2.22168V13.4483M2.22168 13.4483L5.06119 6.34949H17.839L23.518 13.4483M2.22168 13.4483H23.518M23.518 13.4483H24.9377C25.6908 13.4483 26.4131 13.7474 26.9456 14.2799C27.4781 14.8124 27.7772 15.5347 27.7772 16.2878V21.9668H24.9377M19.2587 21.9668H10.7402M14.9995 13.4483V6.34949'
          stroke={fill || '#B2B7BB'}
          strokeWidth='1.893'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M16.8936 12.344V7.927H17.5246L21.3106 12.344H16.8936Z'
          fill='white'
        />
        <path
          d='M17.4311 22.4399C17.4311 23.4682 17.8396 24.4544 18.5667 25.1815C19.2938 25.9087 20.28 26.3171 21.3083 26.3171C22.3366 26.3171 23.3228 25.9087 24.0499 25.1815C24.7771 24.4544 25.1856 23.4682 25.1856 22.4399C25.1856 21.4116 24.7771 20.4254 24.0499 19.6983C23.3228 18.9712 22.3366 18.5627 21.3083 18.5627C20.28 18.5627 19.2938 18.9712 18.5667 19.6983C17.8396 20.4254 17.4311 21.4116 17.4311 22.4399Z'
          fill={fill || '#B2B7BB'}
          stroke='white'
          strokeWidth='1.44444'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M3.55024 22.4399C3.55024 23.4682 3.95873 24.4544 4.68585 25.1815C5.41297 25.9087 6.39916 26.3171 7.42747 26.3171C8.45577 26.3171 9.44196 25.9087 10.1691 25.1815C10.8962 24.4544 11.3047 23.4682 11.3047 22.4399C11.3047 21.4116 10.8962 20.4254 10.1691 19.6983C9.44196 18.9712 8.45577 18.5627 7.42747 18.5627C6.39916 18.5627 5.41297 18.9712 4.68585 19.6983C3.95873 20.4254 3.55024 21.4116 3.55024 22.4399Z'
          fill={fill || '#B2B7BB'}
          stroke='white'
          strokeWidth='1.44444'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M20.0479 22.4399C20.0479 22.7746 20.1808 23.0956 20.4175 23.3322C20.6542 23.5689 20.9752 23.7019 21.3099 23.7019C21.6446 23.7019 21.9656 23.5689 22.2022 23.3322C22.4389 23.0956 22.5719 22.7746 22.5719 22.4399C22.5719 22.1052 22.4389 21.7842 22.2022 21.5475C21.9656 21.3108 21.6446 21.1779 21.3099 21.1779C20.9752 21.1779 20.6542 21.3108 20.4175 21.5475C20.1808 21.7842 20.0479 22.1052 20.0479 22.4399Z'
          fill='white'
        />
        <path
          d='M6.16602 22.4399C6.16602 22.7746 6.29898 23.0956 6.53565 23.3322C6.77232 23.5689 7.09331 23.7019 7.42802 23.7019C7.76272 23.7019 8.08372 23.5689 8.32039 23.3322C8.55706 23.0956 8.69002 22.7746 8.69002 22.4399C8.69002 22.1052 8.55706 21.7842 8.32039 21.5475C8.08372 21.3108 7.76272 21.1779 7.42802 21.1779C7.09331 21.1779 6.77232 21.3108 6.53565 21.5475C6.29898 21.7842 6.16602 22.1052 6.16602 22.4399Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_85_4657'>
          <rect width='30' height='30' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
