import dayjs from 'dayjs';
import React from 'react';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

interface AskCardLayoutInterface {
  chatIcon: string;
  chatName: string;
  time: Date;
  title: string;
  children: React.ReactNode;
  addBubble?: React.ReactNode;
}

const AskCardLayout = ({
  chatIcon,
  chatName,
  time,
  title,
  children,
  addBubble,
}: AskCardLayoutInterface) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const nowTime = dayjs(time).tz('Asia/Seoul');
  const AMPM = nowTime.format('A');
  return (
    <div className='w-[79.2vw] min-w-[265px] flex flex-col justify-start items-start gap-2'>
      <div className='flex justify-start items-center gap-[6px]'>
        <div className='flex justify-center items-center mr-1'>
          <img
            src={chatIcon}
            alt={`chat_${chatName}_icon`}
            className='w-[36px] h-[36px] object-contain'
          />
        </div>
        <h3 className='text-[16px] font-[500] leading-[16px]'>{chatName}</h3>
        <div className='text-[#707070] text-[14px] leading-[14px]'>
          <span className='mr-[3px]'>{AMPM}</span>
          <span>{dayjs(time).format('hh:mm')}</span>
        </div>
      </div>
      {addBubble && addBubble}
      <div className='w-full py-4 px-3 bg-white rounded-[12px] flex flex-col justify-start items-start'>
        <div className='w-full text-start text-[15px] font-[500] leading-[22.5px] mb-0.5'>
          {title}
        </div>
        <div className='w-full'>{children}</div>
      </div>
    </div>
  );
};

export default AskCardLayout;
