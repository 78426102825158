/* eslint-disable @typescript-eslint/no-unused-vars */
import NoData from 'components/common/v3/NoData';
import { useLayoutEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';
import { cn } from 'utils';

export interface SettlementInterface {
  state: 'assigned' | 'received' | 'canceled';
  carNumber: string;
  company?: string;
  name: string;
  updated_date: string;
  address: string;
  incidentCompany: string;
  phone: string;
}

const SettlementCard = ({ item }: { item: SettlementInterface }) => {
  const { setTopTabbarState, setIsBottomTabbar } = useSystemStore();
  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      rightElement: (
        <Link
          to='/accidentReport/init'
          className='text-[15px] leading-[15px] font-[600] text-[#494AF1]'
        >
          등록
        </Link>
      ),
      leftElement: 'back',
      title: '사고 접수',
    });
    setIsBottomTabbar(false);
  }, []);

  return (
    <div className='w-full rounded-[16px] bg-white p-[20px] pt-6  shadow-[0px_6px_20px_#0000000D]'>
      <div className='flex w-full items-center justify-start gap-[15px] border-b border-[#E5EAEE] pb-[20px]'>
        <div className='flex h-[60px] w-[60px] items-center justify-center rounded-[8px] bg-[#F3F6F9]'>
          <img
            src='/assets/images/v2/pink_car.png'
            alt='자동차 아이콘'
            className='w-[36px] object-contain'
          />
        </div>
        <div className='flex flex-col items-start justify-start gap-[8px]'>
          <strong className='flex flex-wrap-reverse items-center justify-start gap-2 text-[22px] font-[600] leading-[22px]'>
            {item.carNumber}
          </strong>
          <p className='flex items-center justify-start gap-[6px] text-[15px] font-[400] leading-[15px]'>
            <strong
              className={cn(
                'font-[600]',
                item.company ? 'text-[#222222]' : 'text-[#FF4747]',
              )}
            >
              {item.company ? item.company : '보험사 확인 필요'}
            </strong>
            <span>{item.name}</span>
          </p>
        </div>
      </div>
      <div className='w-full pt-[20px]'>
        <ul className='flex flex-col items-start justify-start gap-[14px] text-[15px] font-[400] leading-[15px]'>
          <li className='flex items-start justify-start'>
            <span className='min-w-[75px] text-left'>요청일</span>
            <strong className='font-[500]'>{item.updated_date}</strong>
          </li>
          <li className='flex items-start justify-start'>
            <span className='min-w-[75px] text-left'>공업사</span>
            <strong className='font-[500]'>{item.incidentCompany}</strong>
          </li>
          <li className='flex items-start justify-start'>
            <span className='min-w-[75px] text-left'>연락처</span>
            <strong className='font-[500]'>{item.phone}</strong>
          </li>
        </ul>
        <button className='mt-6 flex w-full justify-center items-center h-[48px] bg-[#7273F9] rounded-[10px] p-4 text-[16px] leading-[16px] font-[600] text-white'>
          {item.state === 'assigned'
            ? '배정 완료'
            : item.state === 'received'
              ? '입고 완료'
              : '취소'}
        </button>
      </div>
    </div>
  );
};

export default function IncidentMain() {
  const navigate = useNavigate();
  const completedList: Array<SettlementInterface> = [
    {
      state: 'assigned',
      carNumber: '137머 2323',
      company: '삼성화재',
      name: '홍길동',
      address: '서울시 성동구 아차산로 143',
      incidentCompany: '제일 1급 정비 공업사',
      updated_date: '2024.12.21 13:10',
      phone: '02-2323-2222',
    },
    {
      state: 'received',
      carNumber: '137머 2323',
      company: '삼성화재',
      name: '홍길동',
      address: '서울시 성동구 아차산로 143',
      incidentCompany: '제일 1급 정비 공업사',
      updated_date: '2024.12.21 13:10',
      phone: '02-2323-2222',
    },
    {
      state: 'canceled',
      carNumber: '137머 2323',
      company: '삼성화재',
      name: '홍길동',
      address: '서울시 성동구 아차산로 143',
      incidentCompany: '제일 1급 정비 공업사',
      updated_date: '2024.12.21 13:10',
      phone: '02-2323-2222',
    },
  ];
  return (
    <div className='py-[30px] px-[20px] bg-[#F3F6F9]'>
      <div className='w-full flex-1'>
        {completedList && completedList.length > 0 ? (
          <ul className='w-ful flex flex-col items-start justify-start gap-[20px]'>
            {completedList.map((item, index) => (
              <li key={`${item.carNumber}_${index}`} className='w-full'>
                <button
                  className='inlin-flex w-full h-full'
                  onClick={() => navigate(`/incident/detail/${item.state}`)}
                >
                  <SettlementCard item={item} />
                </button>
              </li>
            ))}
          </ul>
        ) : (
          <div className='flex min-h-[calc(100dvh-120px)] w-full items-center justify-center pb-[30%]'>
            <NoData text='요청중인 정산 내역이 없어요' />
          </div>
        )}
      </div>
    </div>
  );
}
