import { useMemo } from 'react';

import { RepairShop } from '@carsayo/types';

import { getTodayKey } from 'pages/v3/accidentReport/pages/init/tabs/success';
import is영업중 from 'pages/v3/accidentReport/utils/checkWorkingMode';
import { useDataStore } from 'store/useDataStore';

const RepairShopCard = ({
  repairShop,
  onClick,
}: {
  repairShop: RepairShop & { distance?: number; isWorking: boolean };
  onClick: () => void;
}) => {
  const { holiday } = useDataStore();

  const distanceFormat = useMemo(() => {
    if (!repairShop.distance) return null;
    const distance = repairShop.distance;
    if (distance >= 1000) {
      return `${(distance / 1000).toFixed(1)}km`; // 1km 이상이면 km 단위로 소수점 1자리까지
    }
    return `${distance.toLocaleString()}m`; // 1km 미만이면 m 단위로 표시
  }, [repairShop.distance]);

  const workingTimeFormat = useMemo(() => {
    if (!repairShop.workingTime) return null;
    const workingTimeInfo: {
      start: string;
      end: string;
    } | null = repairShop.workingTime[getTodayKey()];

    if (workingTimeInfo === null) return '금일 휴무';
    else
      return `${workingTimeInfo.start.slice(0, 2)}:${workingTimeInfo.start.slice(2, 4)}~${workingTimeInfo.end.slice(0, 2)}:${workingTimeInfo.end.slice(2, 4)}`;
  }, [repairShop.workingTime]);

  const isWorking = useMemo(() => {
    if (repairShop.isWorking === true) return true;
    else if (repairShop.isWorking === false) return false;

    return is영업중(repairShop, holiday);
  }, [repairShop, holiday]);

  return (
    <div
      className='w-full rounded-[16px] overflow-hidden shadow-[0px_6px_20px_#0000000D]'
      onClick={onClick}
    >
      <div className='w-full relative overflow-hidden rounded-t-[10px]'>
        <img
          src={repairShop.picture.main}
          alt='공업사 예시 이미지'
          className='w-full h-full min-h-[160px] min-h-[250px] max-h-[250px] object-cover'
        />
        <ul className='absolute bottom-[10px] left-[10px] flex justify-start items-center gap-[4px]'>
          {isWorking === true && (
            <li className='inline-flex min-w-[60px] h-[26px] justify-center items-center gap-[4px] bg-[#00000080] py-[4px] px-[6px] border-[0.5px] border-[#E5E5EC33] rounded-[6px]'>
              <strong className='block w-[8px] h-[8px] bg-[#5DDF72] rounded-full'></strong>
              <span className='text-[13px] leading-[13px] font-[500] text-[#fff]'>
                영업중
              </span>
            </li>
          )}
          {isWorking === false && (
            <li className='inline-flex min-w-[60px] h-[26px] justify-center items-center gap-[4px] bg-[#00000080] py-[4px] px-[6px] border-[0.5px] border-[#E5E5EC33] rounded-[6px]'>
              <strong className='block w-[8px] h-[8px] bg-[#CCCCCC] rounded-full'></strong>
              <span className='text-[13px] leading-[13px] font-[500] text-[#CCCCCC]'>
                영업마감
              </span>
            </li>
          )}

          {repairShop.towing_available ? (
            <li className='inline-flex min-w-[60px] h-[26px] justify-center items-center gap-[4px] bg-[#00000080] py-[4px] pr-[6px] pl-[4px] border-[0.5px] border-[#E5E5EC33] rounded-[6px]'>
              <strong className='block w-[10px] h-[10px]'>
                <img
                  src='/assets/images/v2/tick-circle.svg'
                  alt='체크아이콘'
                  className='w-full object-contain'
                />
              </strong>
              <span className='text-[13px] leading-[13px] font-[500] text-[#fff]'>
                견인가능
              </span>
            </li>
          ) : (
            <li className='inline-flex min-w-[60px] h-[26px] justify-center items-center gap-[2px] bg-[#00000080] py-[4px] pr-[6px] pl-[4px] border-[0.5px] border-[#E5E5EC33] rounded-[6px]'>
              <strong className='block w-[14px] h-[14px]'>
                <img
                  src='/assets/images/v2/close-circle.svg'
                  alt='닫기아이콘'
                  className='w-full object-contain'
                />
              </strong>
              <span className='text-[13px] leading-[13px] font-[500] text-[#CCCCCC]'>
                견인불가
              </span>
            </li>
          )}
        </ul>
      </div>
      <div className='px-4 pt-[15px] pb-[20px] bg-white'>
        <h4 className='text-[16px] leading-[25.6px] font-[500] mb-[4px] whitespace-nowrap text-ellipsis overflow-hidden'>
          {repairShop.name}
        </h4>
        <p className='text-[#555555] text-[13px] leading-[19.5px] mb-[10px]'>
          {repairShop.address.main.replace(/\[[^\]]*\]/g, '').trim()}
        </p>
        <ul className='flex flex-wrap justify-start items-center gap-[6px]'>
          {distanceFormat && (
            <li className='inline-flex h-[26px] justify-center items-center gap-[4px] p-1.5 bg-[#F3F6F9] rounded-[6px]'>
              <img src='/assets/images/v2/location.svg' alt='위치표시아이콘' />
              <span className='text-[13px] leading-[13px] font-[500] text-[#555555]'>
                {distanceFormat}
              </span>
            </li>
          )}
          <li className='inline-flex h-[26px] justify-center items-center gap-[4px] p-1.5 bg-[#F3F6F9] rounded-[6px]'>
            <img src='/assets/images/v2/clock-gray.svg' alt='회색시계아이콘' />
            <span className='text-[13px] leading-[13px] font-[500] text-[#555555]'>
              {workingTimeFormat}
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default RepairShopCard;
