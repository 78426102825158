/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button } from 'components/ui/button';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { convertDealerType } from 'utils/convert';
import useOrderStore from '../../store';

export default function UnAnsweredReviewItem({
  type,
  item,
}: {
  type: string;
  item: any;
}) {
  const navigate = useNavigate();
  const setOrder = useOrderStore((state) => state.setOrder);
  return (
    <div className='bg-white rounded-[16px] p-4 pt-[19px] mx-[20px] shadow-[0px_6px_20px_#0000000D]'>
      <div className='flex justify-between items-center pb-[20px] border-b border-[#E5EAEE] mb-4'>
        <strong className='text-[#005CE6] text-[16px] leading-[16px] font-[600]'>
          {type === 'repair'
            ? (item.state === 'submitted' && '배정 완료') ||
              (item.state === 'accepted' && '진행중') ||
              (item.state === 'finished' && '수리 완료') ||
              (item.state === 'canceled' && '취소')
            : '주문 완료'}
        </strong>
        <span className='text-[13px] leading-[19.5px] font-[500] text-[#999]'>
          {dayjs(item.finished_at).format('YYYY.MM.DD hh:mm')}
        </span>
      </div>
      <div className='flex flex-col justify-between items-start gap-6'>
        <div className='w-full flex justify-start items-center gap-4'>
          {type === 'repair' ? (
            <div className='bg-[#F3F6F9] flex justify-center items-center w-[74px] h-[74px] rounded-[6px] shrink-0 overflow-hidden'>
              <img
                src={item.info_repair_finished.pictures}
                alt={`${item.title}_repairImg`}
                className='w-full h-full object-cover'
              />
            </div>
          ) : (
            <div className='bg-[#F3F6F9] flex justify-center items-center w-[120px] h-[90px] rounded-[10px] shrink-0 overflow-hidden'>
              <img
                src={item.imageUrl}
                alt={`${item.title}_carImg`}
                className='w-[90%] object-contain'
              />
            </div>
          )}

          <div className='flex flex-col justify-start items-start gap-1 flex-1 w-full'>
            {type !== 'repair' ? (
              <>
                <span className='inline-block mb-[2px] text-[14px] leading-[14px] font-[500] text-[#555]'>
                  {item.type === 'newCar'
                    ? item.order_newCar.installmentTerm > 0
                      ? '할부'
                      : '일시불'
                    : item.type === 'leaseRent'
                      ? convertDealerType[
                          item.order_leaseRent.type as 'lease' | 'rent'
                        ]
                      : ''}
                </span>
                <strong className='inline-block text-[14px] leading-[22.4px] font-[600] text-[#222] break-keep whitespace-pre-line'>
                  {item.title}
                </strong>
                <p className='break-keep whitespace-pre-line text-[13px] leading-[20.8px] font-[500] text-[#555]'>
                  {item.type === 'newCar'
                    ? item.order_newCar.car.car_trim.name
                    : item.type === 'leaseRent'
                      ? item.order_leaseRent.car.car_trim.name
                      : item.order_sellCar.coocon_carInfo.carName}
                </p>
              </>
            ) : (
              <>
                <strong className='inline-block text-[15px] leading-[21px] font-[500] text-[#222] break-keep whitespace-pre-line'>
                  {item.partner_repairshop.business_name}
                </strong>
                <p className='inline-block text-[13px] leading-[13px] font-[400] text-[#555] break-keep whitespace-pre-line mb-[8px]'>
                  {item.partner_repairshop.business_main_address
                    .split(']')[1]
                    .trim()}{' '}
                  {item.partner_repairshop.business_detail_address}
                </p>
                <strong className='inline-block text-[15px] leading-[15px] font-[600] text-[#222] break-keep whitespace-pre-line'>
                  {item.info_repair_finished.price.repairCost.labor.toLocaleString()}
                  원
                </strong>
              </>
            )}
          </div>
        </div>
        <Button
          onClick={() => {
            setOrder(item);
            type === 'repair'
              ? navigate(`/accidentReport/review/${item.id}`)
              : navigate(`/mypage/review/add?type=${type}`);
          }}
          variant={'lightBlue'}
          className='h-[50px]'
        >
          리뷰 작성
        </Button>
      </div>
    </div>
  );
}
