/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import SwiperClass from 'swiper/types/swiper-class';
import { CarsayoToast } from 'components/common/v2/CarsayoToast';

import { ChevronLeft } from 'lucide-react';
import { RepairShop, RepairShopWorkingTime } from '@carsayo/types';
import SubPage from 'components/view/SubPage';
import useKakaoMap_V3 from 'hooks_v3/useKakaoMap';

const RepairDetailSubPage = ({
  repairShop,
  isOpen,
  setIsOpen,
}: {
  repairShop: RepairShop & { distance?: number; isWorking: boolean };
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}) => {
  if (!repairShop.location.latitude || !repairShop.location.longitude)
    return null;

  const navigate = useNavigate();
  const [swiper, setSwiper] = useState<SwiperCore | null>(null);
  const [page, setPage] = useState(1);

  const distanceFormat = useMemo(() => {
    if (!repairShop.distance) return null;
    const distance = repairShop.distance;
    if (distance >= 1000) {
      return `${(distance / 1000).toFixed(1)}km`; // 1km 이상이면 km 단위로 소수점 1자리까지
    }
    return `${distance.toLocaleString()}m`; // 1km 미만이면 m 단위로 표시
  }, [repairShop.distance]);

  const location =
    repairShop.address.main.replace(/\[[^\]]*\]/g, '').trim() +
    ' ' +
    repairShop.address.detail;

  const workingTimeData: Record<
    keyof RepairShopWorkingTime,
    string | null
  > | null = useMemo(() => {
    if (!repairShop.workingTime) return null;
    return {
      mon: repairShop.workingTime.mon
        ? `${repairShop.workingTime.mon.start.slice(0, 2)}:${repairShop.workingTime.mon.start.slice(2, 4)}~${repairShop.workingTime.mon.end.slice(0, 2)}:${repairShop.workingTime.mon.end.slice(2, 4)}`
        : null,
      tue: repairShop.workingTime.tue
        ? `${repairShop.workingTime.tue.start.slice(0, 2)}:${repairShop.workingTime.tue.start.slice(2, 4)}~${repairShop.workingTime.tue.end.slice(0, 2)}:${repairShop.workingTime.tue.end.slice(2, 4)}`
        : null,
      wed: repairShop.workingTime.wed
        ? `${repairShop.workingTime.wed.start.slice(0, 2)}:${repairShop.workingTime.wed.start.slice(2, 4)}~${repairShop.workingTime.wed.end.slice(0, 2)}:${repairShop.workingTime.wed.end.slice(2, 4)}`
        : null,
      thu: repairShop.workingTime.thu
        ? `${repairShop.workingTime.thu.start.slice(0, 2)}:${repairShop.workingTime.thu.start.slice(2, 4)}~${repairShop.workingTime.thu.end.slice(0, 2)}:${repairShop.workingTime.thu.end.slice(2, 4)}`
        : null,
      fri: repairShop.workingTime.fri
        ? `${repairShop.workingTime.fri.start.slice(0, 2)}:${repairShop.workingTime.fri.start.slice(2, 4)}~${repairShop.workingTime.fri.end.slice(0, 2)}:${repairShop.workingTime.fri.end.slice(2, 4)}`
        : null,
      sat: repairShop.workingTime.sat
        ? `${repairShop.workingTime.sat.start.slice(0, 2)}:${repairShop.workingTime.sat.start.slice(2, 4)}~${repairShop.workingTime.sat.end.slice(0, 2)}:${repairShop.workingTime.sat.end.slice(2, 4)}`
        : null,
      sun: repairShop.workingTime.sun
        ? `${repairShop.workingTime.sun.start.slice(0, 2)}:${repairShop.workingTime.sun.start.slice(2, 4)}~${repairShop.workingTime.sun.end.slice(0, 2)}:${repairShop.workingTime.sun.end.slice(2, 4)}`
        : null,
      holiday: repairShop.workingTime.holiday
        ? `${repairShop.workingTime.holiday.start.slice(0, 2)}:${repairShop.workingTime.holiday.start.slice(2, 4)}~${repairShop.workingTime.holiday.end.slice(0, 2)}:${repairShop.workingTime.holiday.end.slice(2, 4)}`
        : null,
      break: repairShop.workingTime.break
        ? `${repairShop.workingTime.break.start.slice(0, 2)}:${repairShop.workingTime.break.start.slice(2, 4)}~${repairShop.workingTime.break.end.slice(0, 2)}:${repairShop.workingTime.break.end.slice(2, 4)}`
        : null,
    };
  }, [repairShop.workingTime]);

  const containerRef = useRef<null | HTMLDivElement>(null);

  const kakaoMap = useKakaoMap_V3({
    containerRef: containerRef,
    initPosition: {
      lat: repairShop.location.latitude,
      lng: repairShop.location.longitude,
    },
  });

  useEffect(() => {
    if (
      isOpen &&
      containerRef.current &&
      repairShop.location.latitude &&
      repairShop.location.longitude
    ) {
      kakaoMap.createMap({
        lat: repairShop.location.latitude,
        lng: repairShop.location.longitude,
      });
    }
  }, [repairShop, isOpen]);

  return (
    <SubPage isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className='flex flex-none h-[60px] items-center justify-between px-4'>
        <a onClick={() => navigate(-1)}>
          <ChevronLeft
            width={32}
            height={32}
            strokeWidth={1.5}
            className='w-[32px] object-contain'
          />
        </a>
        <div className='text-[20px] leading-[20px] font-[600]'>공업사 정보</div>
        <div className='min-w-5'></div>
      </div>
      <div className='w-full h-full overflow-y-auto'>
        <div className='relative w-full mb-6 h-[200px]'>
          <Swiper
            slidesPerView={1}
            onRealIndexChange={(swiper: SwiperClass) =>
              setPage(swiper.realIndex + 1)
            }
            onSwiper={(swiper: SwiperClass) => setSwiper(swiper)}
            loop={true}
          >
            <SwiperSlide className='w-full max-h-[200px] min-h-[200px]'>
              <img
                src={repairShop.picture.main}
                alt='공업사 예시 이미지'
                className='w-full h-[200px] object-cover'
              />
            </SwiperSlide>
            {repairShop.picture.sub?.map((el, index) => {
              return (
                <SwiperSlide
                  className='w-full max-h-[200px] min-h-[200px]'
                  key={index}
                >
                  <img
                    src={el}
                    alt='공업사 예시 이미지'
                    className='w-full h-[200px] object-cover'
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
          <div className='absolute z-10 bottom-[10px] right-[10px] bg-[#00000080] py-[5px] px-[8px] rounded-[100px] text-[13px] leading-[13px] font-[500] text-white min-w-[44px] inline-flex justify-center items-center'>
            <span>{page}</span>/{(repairShop.picture.sub?.length ?? 0) + 1}
          </div>
          <ul className='absolute bottom-[10px] z-10 left-[10px] flex justify-start items-center gap-[4px]'>
            {repairShop.isWorking === true ? (
              <li className='inline-flex min-w-[60px] h-[26px] justify-center items-center gap-[4px] bg-[#00000080] py-[4px] px-[6px] border-[0.5px] border-[#E5E5EC33] rounded-[6px]'>
                <strong className='block w-[8px] h-[8px] bg-[#5DDF72] rounded-full'></strong>
                <span className='text-[13px] leading-[13px] font-[500] text-[#fff]'>
                  영업중
                </span>
              </li>
            ) : (
              <li className='inline-flex min-w-[60px] h-[26px] justify-center items-center gap-[4px] bg-[#00000080] py-[4px] px-[6px] border-[0.5px] border-[#E5E5EC33] rounded-[6px]'>
                <strong className='block w-[8px] h-[8px] bg-[#CCCCCC] rounded-full'></strong>
                <span className='text-[13px] leading-[13px] font-[500] text-[#CCCCCC]'>
                  영업마감
                </span>
              </li>
            )}

            {repairShop.towing_available ? (
              <li className='inline-flex min-w-[60px] h-[26px] justify-center items-center gap-[4px] bg-[#00000080] py-[4px] pr-[6px] pl-[4px] border-[0.5px] border-[#E5E5EC33] rounded-[6px]'>
                <strong className='block w-[10px] h-[10px]'>
                  <img
                    src='/assets/images/v2/tick-circle.svg'
                    alt='체크아이콘'
                    className='w-full object-contain'
                  />
                </strong>
                <span className='text-[13px] leading-[13px] font-[500] text-[#fff]'>
                  견인가능
                </span>
              </li>
            ) : (
              <li className='inline-flex min-w-[60px] h-[26px] justify-center items-center gap-[2px] bg-[#00000080] py-[4px] pr-[6px] pl-[4px] border-[0.5px] border-[#E5E5EC33] rounded-[6px]'>
                <strong className='block w-[14px] h-[14px]'>
                  <img
                    src='/assets/images/v2/close-circle.svg'
                    alt='닫기아이콘'
                    className='w-full object-contain'
                  />
                </strong>
                <span className='text-[13px] leading-[13px] font-[500] text-[#CCCCCC]'>
                  견인불가
                </span>
              </li>
            )}
          </ul>
        </div>
        <div className='pb-[50px]'>
          {/* 공업사 정보 */}
          <div className='border-b-[8px] border-[#F3F6F9] px-[20px] pb-[30px]'>
            <h3 className='text-[20px] leading-[32px] font-[600] mb-1'>
              {repairShop.name}
            </h3>
            <p className='text-[#222] text-[16px] leading-[24px] mb-4'>
              <span>
                {distanceFormat && (
                  <strong className='font-[500]'>
                    {distanceFormat} {' · '}
                  </strong>
                )}
                {location}
              </span>
            </p>
            <p className='text-[#333333] text-[15px] leading-[24px] font-[400] whitespace-pre-line'>
              {repairShop.description}
            </p>
          </div>
          {/* 영업시간 */}
          {workingTimeData && (
            <div className='pt-[30px] px-[20px]'>
              <h4 className='w-full text-[#111] text-[18px] leading-[18px] font-[600] mb-[20px]'>
                영업시간
              </h4>
              <ul className='flex flex-wrap gap-x-[7px] gap-y-[7px]'>
                <li className='w-[calc(50%-3.5px)] flex flex-col justify-center items-start gap-2 bg-[#F3F6F9] py-[10px] px-4 min-h-[70px] rounded-[10px]'>
                  <span className='text-[#555] text-[15px] leading-[15px] font-[500]'>
                    월요일
                  </span>
                  <strong className='text-[15px] leading-[15px] font-[500] text-[#222]'>
                    {workingTimeData.mon ? workingTimeData.mon : '-'}
                  </strong>
                </li>
                <li className='w-[calc(50%-3.5px)] flex flex-col justify-center items-start gap-2 bg-[#F3F6F9] py-[10px] px-4 min-h-[70px] rounded-[10px]'>
                  <span className='text-[#555] text-[15px] leading-[15px] font-[500]'>
                    화요일
                  </span>
                  <strong className='text-[15px] leading-[15px] font-[500] text-[#222]'>
                    {workingTimeData.tue ? workingTimeData.tue : '-'}
                  </strong>
                </li>
                <li className='w-[calc(50%-3.5px)] flex flex-col justify-center items-start gap-2 bg-[#F3F6F9] py-[10px] px-4 min-h-[70px] rounded-[10px]'>
                  <span className='text-[#555] text-[15px] leading-[15px] font-[500]'>
                    수요일
                  </span>
                  <strong className='text-[15px] leading-[15px] font-[500] text-[#222]'>
                    {workingTimeData.wed ? workingTimeData.wed : '-'}
                  </strong>
                </li>
                <li className='w-[calc(50%-3.5px)] flex flex-col justify-center items-start gap-2 bg-[#F3F6F9] py-[10px] px-4 min-h-[70px] rounded-[10px]'>
                  <span className='text-[#555] text-[15px] leading-[15px] font-[500]'>
                    목요일
                  </span>
                  <strong className='text-[15px] leading-[15px] font-[500] text-[#222]'>
                    {workingTimeData.thu ? workingTimeData.thu : '-'}
                  </strong>
                </li>
                <li className='w-[calc(50%-3.5px)] flex flex-col justify-center items-start gap-2 bg-[#F3F6F9] py-[10px] px-4 min-h-[70px] rounded-[10px]'>
                  <span className='text-[#555] text-[15px] leading-[15px] font-[500]'>
                    금요일
                  </span>
                  <strong className='text-[15px] leading-[15px] font-[500] text-[#222]'>
                    {workingTimeData.fri ? workingTimeData.fri : '-'}
                  </strong>
                </li>
                <li className='w-[calc(50%-3.5px)] flex flex-col justify-center items-start gap-2 bg-[#F3F6F9] py-[10px] px-4 min-h-[70px] rounded-[10px]'>
                  <span className='text-[#555] text-[15px] leading-[15px] font-[500]'>
                    토요일
                  </span>
                  <strong className='text-[15px] leading-[15px] font-[500] text-[#222]'>
                    {workingTimeData.sat ? workingTimeData.sat : '-'}
                  </strong>
                </li>
                <li className='w-[calc(50%-3.5px)] flex flex-col justify-center items-start gap-2 bg-[#F3F6F9] py-[10px] px-4 min-h-[70px] rounded-[10px]'>
                  <span className='text-[#555] text-[15px] leading-[15px] font-[500]'>
                    일요일
                  </span>
                  <strong className='text-[15px] leading-[15px] font-[500] text-[#222]'>
                    {workingTimeData.sun ? workingTimeData.sun : '-'}
                  </strong>
                </li>
                <li className='w-[calc(50%-3.5px)] flex flex-col justify-center items-start gap-2 bg-[#F3F6F9] py-[10px] px-4 min-h-[70px] rounded-[10px]'>
                  <span className='text-[#555] text-[15px] leading-[15px] font-[500]'>
                    공휴일
                  </span>
                  <strong className='text-[15px] leading-[15px] font-[500] text-[#222]'>
                    {workingTimeData.holiday ? workingTimeData.holiday : '-'}
                  </strong>
                </li>
                <li className='w-[calc(50%-3.5px)] flex flex-col justify-center items-start gap-2 bg-[#F3F6F9] py-[10px] px-4 min-h-[70px] rounded-[10px]'>
                  <span className='text-[#555] text-[15px] leading-[15px] font-[500]'>
                    휴게시간
                  </span>
                  <strong className='text-[15px] leading-[15px] font-[500] text-[#222]'>
                    {workingTimeData.break ? workingTimeData.break : '-'}
                  </strong>
                </li>
              </ul>
            </div>
          )}
          {/* 위치 */}
          <div className='pt-[40px] px-[20px]'>
            <h4 className='w-full text-[#111] text-[18px] leading-[18px] font-[600] mb-[20px]'>
              위치
            </h4>
            <div className='flex w-full justify-between items-start gap-[10px] mb-[20px]'>
              <div className='flex-auto text-[#222222] text-[16px] leading-[24px] break-words whitespace-normal'>
                {location}
              </div>
              <button
                onClick={() => {
                  navigator.clipboard
                    .writeText(location)
                    .then(() => {
                      CarsayoToast.success('복사했습니다.');
                    })
                    .catch((err) => {
                      console.error('Failed to copy: ', err);
                    });
                }}
                className='flex-none py-[10px] px-[14px] bg-[#F3F6F9] rounded-[8px] text-[14px] leading-[14px] font-[500] inline-flex justify-center items-center min-w-[52px] h-[34px]'
              >
                복사
              </button>
            </div>
            {/* 카카오 지도 컨테이너 */}
            <div className='relative w-full rounded-[10px] overflow-hidden'>
              <div
                className='w-full h-[200px] rounded-[10px] pointer-events-none touch-none'
                ref={containerRef}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </SubPage>
  );
};

export default RepairDetailSubPage;
