import { api } from 'apis';
import { toast } from 'sonner';

export type ReviewStatusType = 'not_written' | 'written';
export type ReviewFilterType = 'new' | 'sell' | 'repair';

export interface ReviewProps {
  status: ReviewStatusType;
  filter: ReviewFilterType;
  skip: number;
  take: number;
}

export interface ReviewListProps {
  totalCnt: number;
  orders: any;
}

export interface UnAnsweredReviewDataInterface {
  category: string;
  id: string;
  state: string;
  order_date: string;
  img: string;
  orderType: string | null;
  orderName: string;
  orderSubName: string | null;
  orderDescription?: string;
  orderPrice?: string;
}
export interface AnsweredReviewDataInterface {
  category: string;
  id: string;
  state: string;
  order_date: string;
  img: string;
  orderType: string | null;
  orderName: string;
  orderSubName: string | null;
  orderDescription?: string;
  orderPrice?: string;
  manager?: {
    img: string;
    nickName: string;
    type: string;
  };
  review: {
    rating: number;
    created_at: string;
    title?: string;
    contents: string;
    imgs?: Array<string>;
  };
}

export const getReviewList = async (
  reqData: ReviewProps,
): Promise<ReviewListProps> => {
  try {
    const apiUrl =
      reqData.filter === 'repair' ? 'my/review/accidentReport' : 'my/review';
    const response = await api.get(
      `${apiUrl}?status=${reqData.status}&filter=${reqData.filter}&skip=${reqData.skip}&take=${reqData.take}`,
    );

    const data: ReviewListProps = (response as any).data;
    return data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const removeReview = async ({
  orderId,
  type,
}: {
  orderId: string;
  type: string;
}) => {
  try {
    const apiUrl = type === 'repair' ? 'my/review/accidentReport' : 'my/review';
    const response = await api.delete(`${apiUrl}/${orderId}`);
    return response.data;
  } catch (error: any) {
    if (error.isAxiosError && error.response) {
      toast.error(error.response.data.message);
    } else {
      toast.error(error.message);
    }
  }
};
