import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from 'utils';
import { ButtonHTMLAttributes, forwardRef } from 'react';

const buttonVariants = cva(
  'w-full inline-flex items-center justify-center text-[16px] leading-[16px] font-[500] rounded-[8px] whitespace-nowrap ring-offset-background transition-colors outline-none disabled:pointer-events-none disabled:bg-[#B2B7BB] disabled:text-white disabled:opacity-100',
  {
    variants: {
      variant: {
        default: 'bg-primary text-primary-foreground',
        zinc: 'bg-[#343434] text-primary-foreground',
        black: 'bg-black text-primary-foreground',
        gray: 'bg-[#E5E5EC]',
        destructive: 'bg-destructive text-destructive-foreground',
        outline: 'border border-[#E5E5EC] font-normal',
        secondary: 'bg-secondary text-secondary-foreground',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        link: 'text-primary underline-offset-4',
        lightBlue: 'bg-[#F0F0FD] text-[#494AF1]',
      },
      size: {
        default: 'h-[54px] px-4',
        xs: 'h-8 px-3 text-desc',
        sm: 'h-9 px-3 text-sm',
        lg: 'h-11 text-sm',
        icon: 'h-10 w-10',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

export interface ButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        type='button'
        {...props}
      />
    );
  },
);
Button.displayName = 'Button';

export { Button, buttonVariants };
