/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import AskCardLayout from '../component/AskCardLayout';
import { CustomerActionInterface } from '../ask';
import { useNavigate } from 'react-router-dom';
interface CargipsaMenuCardInterface {
  customerAction: CustomerActionInterface | null;
  setCustomerAction: (data: CustomerActionInterface) => void;
  nowTime: Date;
}

const CargongMenuCard = ({
  customerAction,
  setCustomerAction,
  nowTime,
}: CargipsaMenuCardInterface) => {
  const navigate = useNavigate();
  return (
    <AskCardLayout
      chatIcon='/assets/images/v2/chat-cargong-icon.png'
      chatName='카공이'
      time={nowTime}
      title='안녕하세요, 카공이에요!'
    >
      <div className='w-full'>
        <p className='mb-[14px] text-[15px] leading-[22.5px] text-[#555555]'>
          궁금하신 사항의 메뉴를 선택해주세요.
        </p>
        <div className='w-full flex flex-col justify-start items-start gap-[10px]'>
          {/* 공업사 박스 */}
          <div
            className='w-full bg-[#ECE9FF] rounded-[10px] p-[12px]'
            onClick={() => navigate('/ask/repair')}
          >
            <h4 className='text-[16px] leading-[16px] font-[600] mb-4 pl-[4px] pt-[4px] text-[#111111]'>
              공업사 안내
            </h4>
            <div className='relative w-full flex flex-col justify-start items-start gap-[12px] rounded-[6px]'>
              <div className='absolute left-[14px] top-4'>
                <h3 className='text-white text-[15px] leading-[15px] font-[600] mb-[8px]'>
                  차 수리를 위한 공업사
                </h3>
                <p className='text-white text-[13px] leading-[13px] font-[300]'>
                  원하는 위치의 공업사를 확인해보세요
                </p>
              </div>
              <img
                src='/assets/images/v2/ask-carGong-menuImg01.png'
                className='w-full h-full rounded-[6px] object-contain'
              />
            </div>
          </div>
          {/* 공식 서비스센터 안내 박스 */}
          {/* <div 
            className='w-full bg-[#DAEDFF] rounded-[10px] p-[12px]'
            onClick={() => navigate('/ask/service')}
          >
            <h4 className='text-[16px] font-[600] mb-[12px] pl-[4px] pt-[4px] text-[#505050]'>
              공식 서비스센터 안내
            </h4>
            <div className='relative w-full flex flex-col justify-start items-start gap-[12px] rounded-[6px]'>
              <div className='absolute left-[14px] top-4'>
                <h3 className='text-white text-[15px] leading-[15px] font-[600] mb-[8px]'>
                  차량 제조사별 센터
                </h3>
                <p className='text-white text-[13px] leading-[13px] font-[300]'>
                  가까운 센터를 이용해보세요
                </p>
              </div>
              <img
                src='/assets/images/v2/ask-carGong-menuImg02.svg'
                className='w-full h-full rounded-[6px]'
              />
            </div>
          </div> */}
        </div>
      </div>
    </AskCardLayout>
  );
};
export default CargongMenuCard;
