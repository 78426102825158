import { CircularProgress } from '@mui/material';
import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import React from 'react';
import { cn } from 'utils';

export interface ButtonProps extends MuiButtonProps {
  isLoading?: boolean;
  asChild?: boolean;
  theme?:
    | 'primary'
    | 'secondary'
    | 'lightBlue'
    | 'blueBorder'
    | 'gray'
    | 'insurance'
    | 'insuranceReverse';
}

const V3_Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, isLoading, children, theme, disabled, ...props }, ref) => {
    return (
      <MuiButton
        ref={ref as React.Ref<HTMLButtonElement>}
        disabled={disabled || isLoading}
        {...props}
        className={cn(className)}
        sx={{
          ...(theme === 'primary'
            ? {
                backgroundColor: '#7273F9',
                color: '#fff',
                fontWeight: '500',
                border: '1px solid #7273F9',
                '&:hover': {
                  backgroundColor: 'rgba(114, 115, 249, .8)',
                  color: '#fff',
                },
                '&.Mui-disabled': {
                  backgroundColor: '#B2B7BB',
                  color: '#fff',
                  borderColor: '#B2B7BB',
                  cursor: 'not-allowed',
                },
              }
            : theme === 'secondary'
              ? {
                  backgroundColor: '#000',
                  color: '#fff',
                  fontWeight: '500',
                  border: '1px solid #ddd',
                  '&:hover': {
                    backgroundColor: '#000',
                    color: '#fff',
                  },
                  '&.Mui-disabled': {
                    backgroundColor: '#B2B7BB',
                    color: '#fff',
                    borderColor: '#B2B7BB',
                    cursor: 'not-allowed',
                  },
                }
              : theme === 'gray'
                ? {
                    backgroundColor: '#F3F6F9',
                    color: '#222222',
                    fontWeight: '500',
                    border: 'none',
                    '&:hover': {
                      backgroundColor: 'rgba(243, 246, 249, .8)',
                      color: '#222222',
                    },
                    '&.Mui-disabled': {
                      backgroundColor: '#B2B7BB',
                      color: '#fff',
                      borderColor: '#B2B7BB',
                      cursor: 'not-allowed',
                    },
                  }
                : theme === 'lightBlue'
                  ? {
                      backgroundColor: '#F0F0FD',
                      color: '#494AF1',
                      fontWeight: '500',
                      border: '1px solid transparent',
                      '&:hover': {
                        backgroundColor: 'rgba(240, 240, 253, .8)',
                        color: '#494AF1',
                      },
                      '&.Mui-disabled': {
                        backgroundColor: '#B2B7BB',
                        color: '#fff',
                        borderColor: '#B2B7BB',
                        cursor: 'not-allowed',
                      },
                    }
                  : theme === 'blueBorder'
                    ? {
                        backgroundColor: '#fff',
                        color: '#484AF5',
                        fontWeight: '500',
                        border: '1px solid #484AF5',
                        '&:hover': {
                          backgroundColor: 'rgba(0, 0, 0, 0.1)',
                          color: '#484AF5',
                        },
                        '&.Mui-disabled': {
                          backgroundColor: '#B2B7BB',
                          color: '#fff',
                          borderColor: '#B2B7BB',
                          cursor: 'not-allowed',
                        },
                      }
                    : theme === 'insurance'
                      ? {
                          backgroundColor: '#008383',
                          color: '#fff',
                          fontWeight: '500',
                          border: 'none',
                          '&:hover': {
                            backgroundColor: 'rgba(0, 131, 131, .8)',
                            color: '#fff',
                          },
                          '&.Mui-disabled': {
                            backgroundColor: '#B2B7BB',
                            color: '#fff',
                            borderColor: '#B2B7BB',
                            cursor: 'not-allowed',
                          },
                        }
                      : theme === 'insuranceReverse'
                        ? {
                            backgroundColor: '#E3F5F5',
                            color: '#008383',
                            fontWeight: '500',
                            border: 'none',
                            '&:hover': {
                              backgroundColor: 'rgba(227, 245, 245, .8)',
                              color: '#008383',
                            },
                            '&.Mui-disabled': {
                              backgroundColor: '#B2B7BB',
                              color: '#fff',
                              borderColor: '#B2B7BB',
                              cursor: 'not-allowed',
                            },
                          }
                        : {
                            backgroundColor: '#fff',
                            color: '#222',
                            fontWeight: '500',
                            border: '1px solid #E5E5EC',
                            '&:hover': {
                              backgroundColor: 'rgba(0,0,0,0.2)',
                              color: '#222',
                            },
                            '&:focus': {
                              backgroundColor: 'rgba(0,0,0,0.1)',
                              color: '#222',
                            },
                            '&.Mui-disabled': {
                              backgroundColor: '#B2B7BB',
                              color: '#fff',
                              borderColor: '#B2B7BB',
                              cursor: 'not-allowed',
                            },
                          }),
          width: '100%',
          height: '54px',
          padding: '19px',
          borderRadius: '8px',
          fontSize: '16px',
          fontWeight: '500',
          fontFamily: 'Pretendard',
          letterSpacing: '-0.02em',
        }}
      >
        {isLoading ? <CircularProgress color='inherit' size={20} /> : children}
      </MuiButton>
    );
  },
);
V3_Button.displayName = 'Button';

export default V3_Button;
