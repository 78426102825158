/* eslint-disable @typescript-eslint/no-unused-vars */
import { X } from 'lucide-react';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';
import {
  AccidentReportForm,
  AccidentReportSchema,
} from './forms/accidentReportForm';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import AccidentReportLandingTab from './tabs/landing';
import AccidentReportFormTab from './tabs/form';
import AccidentReportSearchTab from './tabs/search';
import useMemberStore from 'store/useMemberStore';
import { useQuery } from '@tanstack/react-query';
import { getTotalRepairshopList } from 'apis/accidentReport';
import { RepairShop } from '@carsayo/types';
import AccidentReportSuccessTab from './tabs/success';

export default function AccidentReportInitPage() {
  const navigate = useNavigate();

  const { loginMember } = useMemberStore();
  const { setTopTabbarState, setIsBottomTabbar } = useSystemStore();

  const [selectedRepairShop, setSelectedRepairShop] = useState<
    (RepairShop & { accidentReportId: string; distance: number }) | null
  >(null);

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      title: currentTab === 3 ? ' ' : '사고 접수',
      rightElement: (
        <div className='min-w-5 cursor-pointer'>
          <X onClick={() => navigate(-1)} />
        </div>
      ),
    });

    setIsBottomTabbar(false);
  }, []);

  const form = useForm<AccidentReportForm>({
    mode: 'onSubmit',
    resolver: zodResolver(AccidentReportSchema),
    disabled: !loginMember,
    shouldFocusError: true,
  });

  const [flag, setFlag] = useState(false);
  useEffect(() => {
    if (!loginMember) return;
    if (flag) return;

    form.setValue('info_name', loginMember.name_real ?? '');
    form.setValue('info_phoneNumber', loginMember.phoneNumber ?? '');
    form.setValue('info_birth', loginMember.birth ?? '');
    form.setValue('info_gender', loginMember.gender ?? 'male');
    form.setValue('pictures', []);

    const targetRegisterCar =
      loginMember?.customerInfo?.registerCar?.representative ??
      loginMember?.customerInfo?.registerCar?.list?.[0] ??
      null;

    if (targetRegisterCar) {
      form.setValue('carName', targetRegisterCar.carName);
      if (targetRegisterCar.insuranceCompany) {
        form.setValue(
          'insuranceCompany',
          targetRegisterCar.insuranceCompany.id,
        );
      }
    }

    form.setValue('option_traction', true);
    setFlag(true);
  }, [loginMember]);

  const { data: totalRepairshopList } = useQuery({
    queryKey: ['totalRepairshopList'],
    queryFn: async () => {
      return await getTotalRepairshopList();
    },
    staleTime: 1000 * 60 * 1,
  });

  const [currentTab, setCurrentTab] = useState(0);

  if (currentTab === 0) {
    return (
      <AccidentReportLandingTab
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
      />
    );
  }

  if (currentTab === 1) {
    return (
      <AccidentReportFormTab
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        form={form}
      />
    );
  }
  if (currentTab === 2 && totalRepairshopList) {
    return (
      <AccidentReportSearchTab
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        form={form}
        totalRepairshopList={totalRepairshopList}
        setSelectedRepairShop={setSelectedRepairShop}
      />
    );
  }
  if (currentTab === 3 && selectedRepairShop) {
    return <AccidentReportSuccessTab selectedRepairShop={selectedRepairShop} />;
  }

  return <></>;
}
