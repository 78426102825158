import { Link } from 'react-router-dom';
import HomeIcon from './icons/Home';
import MyIcon from './icons/My';
import PurchaseIcon from './icons/Purchase';
import QuoteIcon from './icons/Quote';
import AskIcon from './icons/Ask';

export default function BottomTabbarV2() {
  return (
    <div className='fixed bottom-0 left-0 w-full z-[40] bg-white h-[50px] border-t border-[#EEEEEE] shadow-[0px_-2px_15px_#00000014]'>
      <ul className='flex justify-center items-center w-full'>
        <li className='w-[20%] h-full p-[5px] pb-[4px] box-border'>
          <Link
            to={'/main'}
            className='w-full h-full flex flex-col items-center gap-[1px]'
          >
            <HomeIcon
              width='30'
              height='30'
              fill={location.pathname === '/main' ? '#7273F9' : '#B2B7BB'}
            />
            <span
              className={`w-full text-center text-[11px] leading-[11px] font-[600] ${location.pathname === '/main' ? 'text-[#7273F9]' : 'text-[#A1A7AC]'}`}
            >
              홈
            </span>
          </Link>
        </li>
        <li className='w-[20%] p-[5px] pb-[4px]'>
          {/* /newcar/init */}
          <Link
            to='/purchase/brand'
            className='w-full h-full flex flex-col items-center gap-[1px]'
          >
            <PurchaseIcon
              width='30'
              height='30'
              fill={
                location.pathname.includes('/purchase') ? '#7273F9' : '#B2B7BB'
              }
            />
            <span
              className={`w-full text-center text-[11px] leading-[11px] font-[600] ${location.pathname.includes('/purchase') ? 'text-[#7273F9]' : 'text-[#A1A7AC]'}`}
            >
              신차 구매
            </span>
          </Link>
        </li>
        <li className='w-[20%] p-[5px] pb-[4px]'>
          <Link
            to={'/quote'}
            className='w-full h-full flex flex-col items-center gap-[1px]'
          >
            <QuoteIcon
              width='30'
              height='30'
              fill={
                location.pathname.includes('/quote') ? '#7273F9' : '#B2B7BB'
              }
            />
            <span
              className={`w-full text-center text-[11px] leading-[11px] font-[600] ${location.pathname.includes('/quote') ? 'text-[#7273F9]' : 'text-[#A1A7AC]'}`}
            >
              견적함
            </span>
          </Link>
        </li>
        <li className='w-[20%] p-[5px] pb-[4px]'>
          <Link
            to={'/ask/main'}
            className='w-full h-full flex flex-col items-center gap-[1px]'
          >
            <AskIcon
              width='30'
              height='30'
              fill={location.pathname.includes('/ask') ? '#7273F9' : '#B2B7BB'}
            />
            <span
              className={`w-full text-center text-[11px] leading-[11px] font-[600] ${location.pathname.includes('/ask') ? 'text-[#7273F9]' : 'text-[#A1A7AC]'}`}
            >
              물어보기
            </span>
          </Link>
        </li>
        <li className='w-[20%] p-[5px] pb-[4px]'>
          <Link
            to={'/mypage'}
            className='w-full h-full flex flex-col items-center gap-[1px]'
          >
            <MyIcon
              width='30'
              height='30'
              fill={
                location.pathname.includes('/mypage') ? '#7273F9' : '#B2B7BB'
              }
            />
            <span
              className={`w-full text-center text-[11px] leading-[11px] font-[600] ${location.pathname.includes('/mypage') ? 'text-[#7273F9]' : 'text-[#A1A7AC]'}`}
            >
              마이
            </span>
          </Link>
        </li>
      </ul>
    </div>
  );
}
