import { updateMessageReceiveSetting } from 'apis/setting';
import { Switch } from 'components/ui/switch';
import useUpdateMarketingAlert from 'hooks/useUpdateMarketingAlert';
import { useLayoutEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import useMemberStore from 'store/useMemberStore';
import useSystemStore from 'store/useSystemStore';

export default function MypageAlert() {
  const navigate = useNavigate();

  const { setTopTabbarState, setIsBottomTabbar } = useSystemStore();

  const { updateMarketingAlert } = useUpdateMarketingAlert();

  const { loginMember, setLoginMember } = useMemberStore();

  const memberSettingUpdateDTO = useMemo(() => {
    return {
      marketing_email: loginMember?.setting.marketing_email,
      marketing_push: loginMember?.setting.marketing_push,
      marketing_sms: loginMember?.setting.marketing_sms,
      push_cs: loginMember?.setting.push_cs,
      push_event: loginMember?.setting.push_event,
      push_lease: loginMember?.setting.push_lease,
      push_newCar: loginMember?.setting.push_newCar,
      push_notice: loginMember?.setting.push_notice,
      push_sellCar: loginMember?.setting.push_sellCar,
    };
  }, [loginMember]);

  const handleClickEvents = {
    whole: async () => {
      const state = !(
        memberSettingUpdateDTO.push_lease &&
        memberSettingUpdateDTO.push_newCar &&
        memberSettingUpdateDTO.push_sellCar &&
        memberSettingUpdateDTO.push_cs &&
        memberSettingUpdateDTO.push_notice
      );
      const updatedMember = await updateMessageReceiveSetting({
        push_lease: state,
        push_newCar: state,
        push_sellCar: state,
        push_notice: state,
        push_cs: state,
      });

      setLoginMember(updatedMember);

      return;
    },
    whole2: async () => {
      const state = !(
        memberSettingUpdateDTO.marketing_push &&
        memberSettingUpdateDTO.marketing_email &&
        memberSettingUpdateDTO.marketing_sms
      );
      const updatedMember = await updateMessageReceiveSetting({
        marketing_push: state,
        marketing_email: state,
        marketing_sms: state,
      });

      setLoginMember(updatedMember);

      return;
    },
    newCar: async () => {
      const state = !memberSettingUpdateDTO.push_newCar;
      const updatedMember = await updateMessageReceiveSetting({
        push_newCar: state,
      });

      setLoginMember(updatedMember);

      return;
    },
    leaseRent: async () => {
      const state = !memberSettingUpdateDTO.push_lease;
      const updatedMember = await updateMessageReceiveSetting({
        push_lease: state,
      });

      setLoginMember(updatedMember);

      return;
    },
    sellCar: async () => {
      const state = !memberSettingUpdateDTO.push_sellCar;
      const updatedMember = await updateMessageReceiveSetting({
        push_sellCar: state,
      });

      setLoginMember(updatedMember);

      return;
    },
    notice: async () => {
      const state = !memberSettingUpdateDTO.push_notice;
      const updatedMember = await updateMessageReceiveSetting({
        push_notice: state,
      });

      setLoginMember(updatedMember);

      return;
    },
    cs: async () => {
      const state = !memberSettingUpdateDTO.push_cs;
      const updatedMember = await updateMessageReceiveSetting({
        push_cs: state,
      });

      setLoginMember(updatedMember);

      return;
    },

    /** 마케팅 푸시 수신 약관 동의를 같이 합니다.
     * @notice 알람, 내 정보 페이지 두 곳에서 유사한 코드 사용
     */
    marketing_push: async () => {
      await updateMarketingAlert({
        target: 'push',
      });

      return;
    },

    /** 마케팅 푸시 수신 약관 동의를 같이 합니다. */
    marketing_sms: async () => {
      await updateMarketingAlert({
        target: 'sms',
      });

      return;
    },

    /** 마케팅 푸시 수신 약관 동의를 같이 합니다. */
    marketing_email: async () => {
      await updateMarketingAlert({
        target: 'email',
      });

      return;
    },
  };

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      title: '알림 설정',
      rightElement: undefined,
      leftElement: 'back',
    });
    setIsBottomTabbar(false);
  }, []);

  return (
    <div className='px-[20px] pb-[50px] pt-[30px]'>
      <div className='flex flex-col justify-start items-start gap-[10px]'>
        <div className='w-full flex items-center justify-between'>
          <div className='text-[18px] leading-[18px] font-semibold'>
            서비스 알림
          </div>
          <Switch
            checked={
              memberSettingUpdateDTO.push_lease &&
              memberSettingUpdateDTO.push_newCar &&
              memberSettingUpdateDTO.push_sellCar &&
              memberSettingUpdateDTO.push_cs &&
              memberSettingUpdateDTO.push_notice
            }
            onClick={handleClickEvents.whole}
          />
        </div>
        <p className='text-[14px] leading-[22.4px] font-[400] text-[#555]'>
          카사요의 서비스 이용 관련에 대해 <br />
          실시간으로 알려드립니다.
        </p>
      </div>
      <div className='pb-[30px] mb-[30px] border-b border-[#E5EAEE]'>
        {/* <div className='flex h-[52px] items-center font-semibold'>견적</div>
        <div className='text-xs text-[#505050]'>
          카사요의 전체 주문 및 진행에 대한 전반적인 상황(견적발송, 낙찰 등)에
          대해 실시간 알림을 제공합니다.
        </div> */}
        <div className='mt-6'>
          <div className='flex h-[60px] items-center justify-between bg-[#F1F4F7] rounded-[10px] px-[10px] py-[15px] mb-2.5'>
            <div className='pl-[6px] text-[15px] leading-[15px] font-[500]'>
              리스 / 장기렌트
            </div>
            <Switch
              checked={memberSettingUpdateDTO.push_lease}
              onClick={handleClickEvents.leaseRent}
            />
          </div>
          <div className='flex h-[60px] items-center justify-between bg-[#F1F4F7] rounded-[10px] px-[10px] py-[15px] mb-2.5'>
            <div className='pl-[6px] text-[15px] leading-[15px] font-[500]'>
              신차
            </div>
            <Switch
              checked={memberSettingUpdateDTO.push_newCar}
              onClick={handleClickEvents.newCar}
            />
          </div>
          <div className='flex h-[60px] items-center justify-between bg-[#F1F4F7] rounded-[10px] px-[10px] py-[15px] mb-2.5'>
            <div className='pl-[6px] text-[15px] leading-[15px] font-[500]'>
              내 차 팔기
            </div>
            <Switch
              checked={memberSettingUpdateDTO.push_sellCar}
              onClick={handleClickEvents.sellCar}
            />
          </div>

          <div className='flex h-[60px] items-center justify-between bg-[#F1F4F7] rounded-[10px] px-[10px] py-[15px] mb-2.5'>
            <div className='pl-[6px] text-[15px] leading-[15px] font-[500]'>
              공지사항
            </div>
            <Switch
              checked={memberSettingUpdateDTO.push_notice}
              onClick={handleClickEvents.notice}
            />
          </div>
          <div className='flex h-[60px] items-center justify-between bg-[#F1F4F7] rounded-[10px] px-[10px] py-[15px]'>
            <div className='pl-[6px] text-[15px] leading-[15px] font-[500]'>
              고객센터
            </div>
            <Switch
              checked={memberSettingUpdateDTO.push_cs}
              onClick={handleClickEvents.cs}
            />
          </div>
        </div>
      </div>
      {/* <div className='px-5 pb-8'>
        <div className='flex h-[52px] items-center font-semibold'>부가</div>
        <div className='text-xs text-[#505050]'>
          카사요의 공지사항, 문의 내역, 서비스 평가 등 전반적인 이용관련 상황에
          대해 실시간 알림을 제공합니다.
        </div>
        
      </div> */}
      <div className='flex flex-col justify-start items-start gap-[10px]'>
        <div className='w-full flex items-center justify-between'>
          <div className='text-[18px] leading-[18px] font-semibold'>
            이벤트 / 혜택 알림
          </div>
          <Switch
            checked={
              memberSettingUpdateDTO.marketing_push &&
              memberSettingUpdateDTO.marketing_sms &&
              memberSettingUpdateDTO.marketing_email
            }
            onClick={handleClickEvents.whole2}
          />
        </div>
        <p className='text-[14px] leading-[22.4px] font-[400] text-[#555] max-w-[269px] break-keep'>
          마케팅 정보 수신에 동의하시면 이벤트와 프로모션 및 다양한 혜택 등을
          알려드립니다.
        </p>
      </div>
      <div className=''>
        <div className='mt-6'>
          <div className='flex h-[60px] items-center justify-between bg-[#F1F4F7] rounded-[10px] px-[10px] py-[15px] mb-2.5'>
            <div
              className='underline pl-[6px] text-[15px] leading-[15px] font-[500]'
              onClick={() => {
                navigate('/mypage/termDetail/95100');
              }}
            >
              앱 푸시
            </div>
            <Switch
              checked={memberSettingUpdateDTO.marketing_push}
              onClick={handleClickEvents.marketing_push}
            />
          </div>
          <div className='flex h-[60px] items-center justify-between bg-[#F1F4F7] rounded-[10px] px-[10px] py-[15px] mb-2.5'>
            <div
              className='underline active:text-gray-900 pl-[6px] text-[15px] leading-[15px] font-[500]'
              onClick={() => {
                navigate('/mypage/termDetail/95200');
              }}
            >
              문자 메시지
            </div>
            <Switch
              checked={memberSettingUpdateDTO.marketing_sms}
              onClick={handleClickEvents.marketing_sms}
            />
          </div>
          <div className='flex h-[60px] items-center justify-between bg-[#F1F4F7] rounded-[10px] px-[10px] py-[15px]'>
            <div
              className='underline pl-[6px] text-[15px] leading-[15px] font-[500]'
              onClick={() => {
                navigate('/mypage/termDetail/95300');
              }}
            >
              이메일
            </div>
            <Switch
              checked={memberSettingUpdateDTO.marketing_email}
              onClick={handleClickEvents.marketing_email}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
