/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { SidoData, SidoId, SigunguData, SigunguId } from '@carsayo/types';
import { Item } from '@radix-ui/react-select';
import RightArrow from 'components/common/global/svg/RightArrow';
import SelectV3, { SelectV3Item } from 'components/common/v3/selectv3';
import Button from 'components_v3/ui/Button';
import { ChevronRight } from 'lucide-react';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';

export default function PositionMapSetting() {
  const { setTopTabbarState, setIsBottomTabbar } = useSystemStore();

  const positionList = [
    {
      id: 'direct',
      label: '직접설정',
      text: '서울특별시 성동구',
    },
    {
      id: 'current',
      label: '현재위치',
      text: '위치 설정 ',
    },
  ];
  const [currentState, setCurrentState] = useState('direct');
  const [selected, setSelected] = useState<SidoId | null>(null);
  const [selected02, setSelected02] = useState<SigunguId | null>(null);
  const navigate = useNavigate();

  const handleResidenceRegionChange = (value: SigunguId) => {
    console.info(value);
    setSelected02(value);
  };

  const handleSelected = (item: { sigunguId: SigunguId } | SidoId) => {
    if (typeof item === 'number') {
      setSelected(item);
      if (item === 8) {
        // 세종시
        handleResidenceRegionChange(76);
      }
    } else {
      handleResidenceRegionChange(item.sigunguId);
    }
  };

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      leftElement: 'back',
      title: '위치 설정',
    });
  }, []);

  useEffect(() => {
    if (currentState === 'current') {
      navigate('/static/location');
    }
  }, [currentState]);

  return (
    <div className='px-[20px] pt-[20px] pb-[10px] min-h-[calc(100dvh-60px)] flex justify-between items-start flex-col'>
      <div className='w-full'>
        {/* 위치정보 타입 설정 */}
        <div className='mb-[24px]'>
          <SelectV3
            onValueChange={(value) => setCurrentState(value)}
            value={
              positionList.find((item) => item.id === currentState)?.id ??
              'direct'
            }
            renderValue={() => (
              <div className='flex justify-start items-center gap-[10px]'>
                {currentState === 'direct' ? (
                  <span className='text-[13px] leading-[13px] font-[600] text-[#222] inline-flex justify-center items-center py-1.5 px-2.5 min-w-[65px] h-[25px] bg-[#E6ECF2] rounded-full'>
                    직접설정
                  </span>
                ) : (
                  <span className='text-[13px] leading-[13px] font-[600] text-[#484AF5] inline-flex justify-center items-center py-1.5 px-2.5 min-w-[65px] h-[25px] bg-[#F6F6FC] rounded-full'>
                    현재위치
                  </span>
                )}

                <span className='text-[16px] leading-[16px] font-[400]'>
                  {positionList.find((item) => item.id === currentState)
                    ?.text ?? 'direct'}
                </span>
              </div>
            )}
          >
            {positionList.map((item) => (
              <SelectV3Item value={item.id}>
                <div className='flex justify-start items-center gap-[10px]'>
                  {item.id === 'direct' ? (
                    <span className='text-[13px] leading-[13px] font-[600] text-[#222] inline-flex justify-center items-center py-1.5 px-2.5 min-w-[65px] h-[25px] bg-[#E6ECF2] rounded-full'>
                      직접설정
                    </span>
                  ) : (
                    <span className='text-[13px] leading-[13px] font-[600] text-[#484AF5] inline-flex justify-center items-center py-1.5 px-2.5 min-w-[65px] h-[25px] bg-[#F6F6FC] rounded-full'>
                      현재위치
                    </span>
                  )}
                  <span className='text-[16px] leading-[16px] font-[400]'>
                    {item?.text ?? ''}
                  </span>
                </div>
              </SelectV3Item>
            ))}
          </SelectV3>
        </div>
        {/* 직접선택 박스 */}
        <div className='w-full'>
          {selected && (
            <div className='flex h-[54px] items-center bg-[#F3F6F9] py-[13px] px-4 mb-6 rounded-[10px]'>
              <div className='flex justify-start items-center gap-[6px] text-[16px] leading-[24px] font-[600] text-[#222]'>
                {
                  SidoData.find((el) => {
                    return el.id === selected;
                  })?.name
                }
                {/* {selected02 &&
                  SigunguData.find((el) => {
                    return el.id === selected02;
                  })?.name} */}
              </div>
            </div>
          )}
          <div className='grow overflow-y-auto'>
            <div className='grid grid-cols-3 gap-[7px] overflow-y-auto'>
              {selected
                ? SigunguData.filter((el) => {
                    return el.sidoId === selected && selected !== 8;
                  }).map((item, index) => (
                    <label
                      key={index}
                      className='relative flex items-center justify-center rounded-[9px] border py-3 px-4 text-[14px] leading-[14px] min-h-[50px] font-[500] has-[:checked]:border-primary has-[:checked]:text-[#484AF5] has-[:checked]:bg-[#F6F6FC]'
                      onClick={() => handleSelected({ sigunguId: item.id })}
                    >
                      <input
                        type='radio'
                        name='region'
                        value={index}
                        className='w-0 appearance-none'
                      />
                      {item.name}
                    </label>
                  ))
                : SidoData.map((item, index) => (
                    <label
                      key={item.name}
                      className='relative flex items-center justify-center rounded-[9px] border py-3 px-4 text-[14px] leading-[14px] min-h-[50px] font-[500] has-[:checked]:border-primary has-[:checked]:text-[#484AF5] has-[:checked]:bg-[#F6F6FC]'
                      onClick={() => handleSelected(item.id)}
                    >
                      <input
                        type='radio'
                        name='region'
                        value={item.name}
                        className='w-0 appearance-none'
                      />
                      {item.name}
                    </label>
                  ))}
            </div>
          </div>
        </div>
      </div>
      <div className='w-full pt-8 flex justify-center items-center gap-2.5'>
        <Button
          disabled={selected === null}
          className='!w-[85px] !min-w-[85px] whitespace-nowrap disabled:!bg-white disabled:!text-[#B2B7BB] disabled:!border-[#E5E5EC]'
          onClick={() => {
            setSelected(null);
            setSelected02(null);
          }}
        >
          초기화
        </Button>
        <Button
          // 현재 기능이 없어서 뒤로가도록 처리했습니다.
          onClick={() => navigate(-1)}
          theme='primary'
          disabled={selected === null || selected02 === null}
        >
          선택
        </Button>
      </div>
    </div>
  );
}
