import { Box } from '@mui/material';
import { ImagesInterface } from 'pages/v2/incident/detail';
import React from 'react';
import { cn } from 'utils';

interface ImageUploadProps {
  id: string;
  handleImagesAdd: (item: ImagesInterface) => void;
  maxCount: number;
  currentCount?: number;
  width?: string;
  height?: string;
  className?: string;
  type?: string;
}

const ImageUploadBox: React.FC<ImageUploadProps> = ({
  id,
  handleImagesAdd,
  maxCount,
  currentCount,
  width,
  height,
  className,
  type,
}) => {
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const imageData = reader.result as string;
        handleImagesAdd({ image: imageData, imageName: file.name });
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Box
      sx={{
        width: width ? width : '97px',
        height: height ? height : '97px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '8px',
        boxSizing: 'border-box',
        maxWidth: '100%',
        cursor: 'pointer',
        flexShrink: '0',
      }}
      className={cn(className)}
    >
      <label
        htmlFor={id}
        className={cn(
          'flex h-full w-full items-center justify-center bg-[#F1F4F7] overflow-hidden rounded-[8px]',
          type === 'dashed' &&
            'bg-white border-[2px] border-dashed border-[#E5EAEE]',
        )}
      >
        <input
          type='file'
          accept='image/*'
          onChange={handleImageChange}
          style={{
            display: 'none',
          }}
          id={id}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '6px',
            minHeight: '53px',
          }}
        >
          {type === 'dashed' ? (
            <img
              src='/assets/images/png/imageUploaderCamera.png'
              alt='카메라 아이콘'
              className='w-[32px] object-contain'
            />
          ) : (
            <img
              src='/assets/images/v2/img_input.png'
              alt='카메라 아이콘'
              className='w-[32px] object-contain'
            />
          )}

          {type === 'dashed' && (
            <span className='text-[15px] leading-[15px] font-[500] text-[#7D848A]'>
              {currentCount}/{maxCount}
            </span>
          )}
        </div>
      </label>
    </Box>
  );
};

export default ImageUploadBox;
