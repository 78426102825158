/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useState } from 'react';
import { SelectedBrandInterface } from '../main';
import ModelTopBox from './ModelTopBox';
import { cn } from 'utils';
import ModelCard from './ModelCard';
import Button from 'components_v3/ui/Button';
import NoData from 'components/common/v3/NoData';

export interface ModelInterface {
  id: string;
  type: string;
  title: string;
  count: number;
  price: string;
  carImg: string;
}

export interface CarTypeInterface {
  label: string;
  color: string;
}

export const carTypeList: CarTypeInterface[] = [
  {
    label: '세단',
    color: '#B2B7BB',
  },
  {
    label: 'SUV',
    color: '#333333',
  },
  {
    label: 'RV',
    color: '#C2255C',
  },
  {
    label: '경차',
    color: '#0EBB86',
  },
  {
    label: '준중형차',
    color: '#17A279',
  },
  {
    label: '중형차',
    color: '#077F5B',
  },
  {
    label: '대형차',
    color: '#6841DA',
  },
  {
    label: '승합차',
    color: '#09869C',
  },
  {
    label: '스포츠카',
    color: '#4363EC',
  },
];

export default function ModelStep({
  setStep,
  selectedBrand,
}: {
  setStep: (state: any) => void;
  selectedBrand: SelectedBrandInterface;
}) {
  const carList: ModelInterface[] = [
    {
      id: '01',
      type: 'SUV',
      title: 'GLE-클래스 24년식',
      count: 20,
      price: '4,347만원 ~ 5,200만원',
      carImg: '/assets/images/png/ex-car.png',
    },
    {
      id: '02',
      type: 'RV',
      title: 'GLE-클래스 24년식',
      count: 20,
      price: '4,347만원 ~ 5,200만원',
      carImg: '/assets/images/png/ex-car.png',
    },
    {
      id: '03',
      type: '경차',
      title: 'GLE-클래스 24년식',
      count: 20,
      price: '4,347만원 ~ 5,200만원',
      carImg: '/assets/images/png/ex-car.png',
    },
    {
      id: '04',
      type: '준중형차',
      title: 'GLE-클래스 24년식',
      count: 20,
      price: '4,347만원 ~ 5,200만원',
      carImg: '/assets/images/png/ex-car.png',
    },
    {
      id: '05',
      type: '중형차',
      title: 'GLE-클래스 24년식',
      count: 20,
      price: '4,347만원 ~ 5,200만원',
      carImg: '/assets/images/png/ex-car.png',
    },
    {
      id: '06',
      type: '대형차',
      title: 'GLE-클래스 24년식',
      count: 20,
      price: '4,347만원 ~ 5,200만원',
      carImg: '/assets/images/png/ex-car.png',
    },
    {
      id: '07',
      type: '승합차',
      title: 'GLE-클래스 24년식',
      count: 20,
      price: '4,347만원 ~ 5,200만원',
      carImg: '/assets/images/png/ex-car.png',
    },
    {
      id: '08',
      type: '스포츠카',
      title: 'GLE-클래스 24년식',
      count: 20,
      price: '4,347만원 ~ 5,200만원',
      carImg: '/assets/images/png/ex-car.png',
    },
  ];

  const [currentModel, setCurrentModel] = useState('전체');
  const [selectedModel, setSelectedModel] = useState('01');

  return (
    <div className='w-full pb-[10px] min-h-full flex flex-col justify-start items-start'>
      <ModelTopBox type={selectedBrand.type} />
      <div className='w-full flex flex-col justify-start items-start gap-[30px] pt-6 flex-1 h-full'>
        <div className='w-full overflow-x-auto scroll-hidden pl-5'>
          <ul className='inline-flex justify-start items-center gap-1.5 after:content-[""] after:block after:w-[20px] after:h-[40px] after:shrink-0'>
            <li
              onClick={() => setCurrentModel('전체')}
              className={cn(
                'shrink-0 inline-flex justify-center items-center min-w-[54px] h-[40px] py-2.5 px-[14px] rounded-full border-[#E5E5EC] border transition-all',
                currentModel === '전체' && 'border-none bg-[#222] text-white',
              )}
            >
              전체
            </li>
            {carTypeList.map((item, index) => (
              <li
                onClick={() => setCurrentModel(item.label)}
                className={cn(
                  'shrink-0 inline-flex justify-center items-center min-w-[54px] h-[40px] py-2.5 px-[14px] rounded-full border-[#E5E5EC] border transition-all',
                  currentModel === item.label &&
                    'border-none bg-[#222] text-white',
                  index === carTypeList.length - 1 && '',
                )}
              >
                {item.label}
              </li>
            ))}
          </ul>
        </div>
        {(currentModel === '전체' && carList.length > 0) ||
        carList.filter((model) => model.type === currentModel).length > 0 ? (
          <div className='w-full grid grid-cols-1 px-5 gap-4'>
            {currentModel === '전체'
              ? carList.map((item) => (
                  <ModelCard
                    item={item}
                    selectedModel={selectedModel}
                    setSelectedModel={setSelectedModel}
                  />
                ))
              : carList
                  .filter((model) => model.type === currentModel)
                  .map((item) => (
                    <ModelCard
                      item={item}
                      selectedModel={selectedModel}
                      setSelectedModel={setSelectedModel}
                    />
                  ))}
          </div>
        ) : (
          <div className='w-full pt-[25%]'>
            <NoData text='모델이 없습니다.' />
          </div>
        )}
      </div>
      {/* {((currentModel === '전체' && carList.length > 0) ||
        carList.filter((model) => model.type === currentModel).length > 0) && ( */}
      <div className='w-full flex justify-center items-center gap-[7px] px-[20px] pt-[50px]'>
        <Button onClick={() => setStep(1)}>이전</Button>
        <Button onClick={() => setStep(2)} theme='primary'>
          다음
        </Button>
      </div>
      {/* )} */}
    </div>
  );
}
