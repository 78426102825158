/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ChangeEvent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useMemberStore from 'store/useMemberStore';
import useSystemStore from 'store/useSystemStore';
import SearchListDataCardLayout from '../../component/SearchListDataCard';
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
} from 'components/ui/drawer';
import { Button } from 'components/ui/button';
import { ArrowRight, ChevronLeft, ChevronRight, X } from 'lucide-react';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select';
import RightArrow from 'components/common/global/svg/RightArrow';
import { SigunguId, SidoId, SidoData, SigunguData } from '@carsayo/types';
import CarsayoButton from 'components/common/CarsayoButton';
import { CarMakerData, CarMakerId } from '@carsayo/types';
import { cn } from 'utils';
import ServiceCenterDataCard from '../../component/ServiceCenterDataCard';
import useCurrentLocationStore from 'store/useCurrentLocation';

const ServiceMainList = () => {
  const { setTopTabbarState, setIsBottomTabbar } = useSystemStore();
  const { loginMember, refreshLoginMember } = useMemberStore();
  const { setCurrentLocation } = useCurrentLocationStore();
  const location = useLocation();
  const navigate = useNavigate();
  const divRef = useRef<HTMLDivElement | null>(null);
  const [searchText, setSearchText] = useState<string>('');
  const headerRef = useRef<HTMLDivElement | null>(null);
  const [brandData, setBrandData] = useState<any | null>(null);

  /** 현재 위치 정보 타입 : 기본 값이 내 현재위치인 nearest지만 위치정보 약관동의가 안돼있으면 직접선택인 direct가 기본값 */
  const [locationType, setLocationType] = useState<'direct' | 'nearest' | null>(
    'direct',
  );
  const [locationDrawerOpen, setLocationDrawerOpen] = useState(false);

  const [hasScrolled, setHasScrolled] = useState(false); // 스크롤 여부 상태
  const [headerHeight, setHeaderHeight] = useState<number>(0);

  const handleScroll = useCallback(() => {
    if (divRef.current) {
      const scrollTop = divRef.current.scrollTop;
      if (scrollTop > 10) {
        setHasScrolled(true);
      } else {
        setHasScrolled(false);
      }
    }
  }, []);

  const updateHeaderHeight = () => {
    if (headerRef.current) {
      setHeaderHeight(headerRef.current.offsetHeight);
    }
  };

  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const LocationSettingBox = () => {
    const [selectedLocationType, setSelectedLocationType] =
      useState<string>('');
    const [selected, setSelected] = useState<SidoId | null>(null);
    const [selected02, setSelected02] = useState<SigunguId | null>(null);

    const handleResidenceRegionChange = (value: SigunguId) => {
      console.info(value);
      setSelected02(value);
    };

    const handleSelected = (item: { sigunguId: SigunguId } | SidoId) => {
      if (typeof item === 'number') {
        setSelected(item);
        if (item === 8) {
          // 세종시
          handleResidenceRegionChange(76);
        }
      } else {
        handleResidenceRegionChange(item.sigunguId);
      }
    };

    useEffect(() => {
      if (selectedLocationType === 'nearest') {
        navigate('/static/location');
      }
    }, [selectedLocationType]);
    return (
      <div>
        <Drawer open={locationDrawerOpen}>
          <DrawerContent className='mx-auto flex max-h-[90vh] w-full max-w-sm flex-col'>
            <DrawerHeader className='relative'>
              <DrawerTitle className='text-center text-lg font-semibold'>
                위치 정보 설정
              </DrawerTitle>
              <Button
                onClick={() => setLocationDrawerOpen(!locationDrawerOpen)}
                className='absolute right-5 top-4 h-7 w-7 border-0 bg-transparent p-0 text-[#111111]'
              >
                <X className='h-7 w-7' />
              </Button>
            </DrawerHeader>
            <div className='p-4 pb-8'>
              {/* 위치정보 타입 설정 */}
              <div className='mb-[20px]'>
                <Select
                  defaultValue='direct'
                  onValueChange={(value) => setSelectedLocationType(value)}
                >
                  <SelectTrigger
                    style={{
                      fontSize: '16px',
                      fontWeight: '400',
                      color: '#111',
                    }}
                  >
                    <SelectValue placeholder='위치를 선택하세요' />
                  </SelectTrigger>
                  <SelectContent className='z-[300]'>
                    <SelectItem
                      key={`locationType_direct`}
                      value={'direct'}
                      defaultChecked={true}
                    >
                      <span className='mr-[10px] bg-[#F6F6FC] py-[4px] px-[6px] text-[#666666] text-[14px] leading-[14px] font-[500] !rounded-[4px]'>
                        직접설정
                      </span>
                      <span>서울특별시 성동구</span>
                    </SelectItem>
                    <SelectItem key={`locationType_nearest`} value={'nearest'}>
                      <span className='mr-[10px] bg-[#E6E6F6] py-[4px] px-[6px] text-[#5455EA] text-[14px] leading-[14px] font-[500] !rounded-[4px]'>
                        현재위치
                      </span>
                      <span>위치 설정</span>
                    </SelectItem>
                  </SelectContent>
                </Select>
              </div>
              {/* 직접선택 박스 */}
              <div className='w-full'>
                <div className='border-b'>
                  {selected && (
                    <div className='flex h-[54px] items-center bg-[#F7F7FB] p-4 mb-4'>
                      <div className='w-2.5'>·</div>
                      <div className='flex justify-start items-center gap-[6px]'>
                        {
                          SidoData.find((el) => {
                            return el.id === selected;
                          })?.name
                        }
                        {selected02 && <RightArrow stroke='#999999' />}
                        {selected02 &&
                          SigunguData.find((el) => {
                            return el.id === selected02;
                          })?.name}
                      </div>
                      <div
                        className='ml-auto flex items-center'
                        onClick={() => {
                          setSelected(null);
                          setSelected02(null);
                        }}
                      >
                        초기화
                        <ChevronRight className='h-4 w-4' strokeWidth='1.5' />
                      </div>
                    </div>
                  )}
                </div>
                <div className='grow p-4 max-h-[50vh]  overflow-y-auto'>
                  <div className='grid grid-cols-3 gap-[21.5px] overflow-y-auto'>
                    {selected
                      ? SigunguData.filter((el) => {
                          return el.sidoId === selected;
                        }).map((item, index) => (
                          <label
                            key={index}
                            className='relative flex items-center justify-center rounded-lg border py-3 text-sm font-semibold has-[:checked]:border-primary'
                            onClick={() =>
                              handleSelected({ sigunguId: item.id })
                            }
                          >
                            <input
                              type='radio'
                              name='region'
                              value={index}
                              className='w-0 appearance-none'
                            />
                            {item.name}
                          </label>
                        ))
                      : SidoData.map((item, index) => (
                          <label
                            key={item.name}
                            className='relative flex items-center justify-center rounded-lg border py-3 text-sm font-semibold has-[:checked]:border-primary'
                            onClick={() => handleSelected(item.id)}
                          >
                            <input
                              type='radio'
                              name='region'
                              value={item.name}
                              className='w-0 appearance-none'
                            />
                            {item.name}
                          </label>
                        ))}
                  </div>
                </div>
              </div>
              <div className='mt-6'>
                <CarsayoButton
                  color='purpleBlue'
                  disabled={selected02 === null}
                >
                  확인
                </CarsayoButton>
              </div>
            </div>
          </DrawerContent>
        </Drawer>
      </div>
    );
  };

  useLayoutEffect(() => {
    if (location.state.brand) {
      setBrandData(
        CarMakerData.find((brand) => brand.id === location.state.brand) ?? null,
      );
      setTopTabbarState({
        state: 'fixed',
        mode: 'subpage',
        leftElement: (
          <div
            onClick={() => {
              // navigate('/ask/service', {
              //   state: {
              //     brandData:
              //       CarMakerData.find(
              //         (brand) => brand.id === location.state.brand,
              //       ) ?? null,
              //   },
              // });
              navigate(-1);
            }}
          >
            <ChevronLeft width={32} height={32} strokeWidth={1.5} />
          </div>
        ),
        title: '공식 서비스',
      });
    } else {
      setTopTabbarState({
        state: 'fixed',
        mode: 'subpage',
        leftElement: (
          <div
            onClick={() => {
              // navigate('/ask/service', { state: { brandData: brandData } });
              navigate(-1);
            }}
          >
            <ChevronLeft width={32} height={32} strokeWidth={1.5} />
          </div>
        ),
        title: '공식 서비스',
      });
    }

    setIsBottomTabbar(false);
    return () => {
      // 물어보기 메뉴 변경시 현재 위치 저장 location  초기화
      setCurrentLocation({
        lat: 37.5461181757326,
        lng: 127.050040830902,
      });
    };
  }, []);

  useEffect(() => {
    const divElement = divRef.current;

    if (divElement) {
      divElement.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (divElement) {
        divElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);

  useEffect(() => {
    updateHeaderHeight();

    window.addEventListener('resize', updateHeaderHeight);

    return () => {
      window.removeEventListener('resize', updateHeaderHeight);
    };
  }, []);

  return (
    <>
      <div className='w-full h-full'>
        {brandData && (
          <>
            <div
              ref={headerRef}
              className={`min-h-[115px] w-full py-[10px] bg-white transition-all ${hasScrolled ? 'shadow-[0px_4px_10px_#1111111A] relative z-10' : 'shadow-none'}`}
            >
              <div className='w-full relative mb-4 px-[20px]'>
                <input
                  value={searchText}
                  onChange={handleChangeSearch}
                  type='text'
                  placeholder='공업사를 검색해보세요'
                  className={`w-full flex justify-start items-center transition-all bg-[#F6F6FC] p-4 ${searchText && searchText.length > 0 && '!pr-[80px]'} border border-[#F6F6FC] rounded-[10px] text-[16px] leading-[16px] font-[500] focus:outline-none focus:ring-0 focus:border-[#333]`}
                />
                {searchText && searchText.length > 0 && (
                  <button
                    className='absolute right-[64px] bottom-0 top-0 my-auto'
                    onClick={() => setSearchText('')}
                  >
                    <img
                      src='/assets/images/v2/close-circle.svg'
                      alt='삭제 아이콘'
                      className='w-[22px] h-[22px] object-contain'
                    />
                  </button>
                )}

                <button className='absolute right-[36px] bottom-0 top-0 my-auto'>
                  <img src='/assets/images/v2/search.svg' alt='검색 아이콘' />
                </button>
              </div>
              {/* 위치 기반 검색 영역 */}
              <div className='flex justify-between items-center mb-[6px] px-[20px] '>
                {locationType === 'nearest' ? (
                  <div
                    className='flex justify-start items-center gap-[4px]'
                    onClick={() => navigate('/static/location')}
                  >
                    <img src='/assets/images/v2/gps.svg' alt='gps아이콘' />
                    <span>서울특별시 성동구</span>
                  </div>
                ) : (
                  <div
                    className='flex justify-start items-center gap-[4px]'
                    onClick={() => setLocationDrawerOpen(!locationDrawerOpen)}
                  >
                    <img src='/assets/images/v2/gps.svg' alt='gps아이콘' />
                    <span>위치 설정</span>
                  </div>
                )}
                <div className='flex justify-start items-center gap-[2px]'>
                  <img
                    src='/assets/images/v2/nearest-order.svg'
                    alt='정렬아이콘'
                  />
                  <span>가까운순</span>
                </div>
              </div>
            </div>
            {/* 컨텐츠영역 */}
            <div
              className={`w-full pt-[0px] pb-[50px] overflow-y-auto`}
              style={{
                height: headerHeight
                  ? `calc(100% - ${headerHeight}px)`
                  : 'calc(100% - 115px)',
              }}
              ref={divRef}
            >
              {/* 브랜드 배너 */}
              <div
                className={cn(
                  'w-full py-[10px] px-[20px] flex justify-start items-center gap-[10px] mb-[20px]',
                  brandData.type === 'domestic' ? 'bg-[#222222]' : 'bg-white',
                )}
              >
                <img
                  src={
                    brandData.type === 'domestic'
                      ? `/assets/images/static/brand/mark-${brandData.name}.svg`
                      : brandData.image_url
                  }
                  alt={`${brandData.name}심볼`}
                  className='w-[24px] h-[24px] object-contain'
                />
                <span
                  className={cn(
                    'text-[14px] leading-[14px] font-[500]',
                    brandData.type === 'domestic'
                      ? 'text-white'
                      : 'text-[#111111]',
                  )}
                >
                  {brandData.name} 공식 서비스센터입니다.
                </span>
              </div>
              <div className='w-full  px-[20px] h-[100%-112px] flex flex-col justify-start items-center gap-[20px]'>
                {/* <RepairEmpty /> */}
                <ServiceCenterDataCard />
                <ServiceCenterDataCard />
                <ServiceCenterDataCard />
                <ServiceCenterDataCard />
              </div>
            </div>
          </>
        )}
      </div>
      {/* 위치 정보 설정 박스 */}
      <LocationSettingBox />
    </>
  );
};
export default ServiceMainList;
