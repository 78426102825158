/* eslint-disable @typescript-eslint/no-unused-vars */
import V3_Button from 'components_v3/ui/Button';

import { AccidentReport, RepairShop } from '@carsayo/types';
import TagIcon from '../icon/tag';
import ClockIcon from '../icon/clock';
import { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { Button } from 'components/ui/button';
import { useNavigate } from 'react-router-dom';
import V3_Confirm from 'components_v3/dialog/Confirm';
import { X } from 'lucide-react';
import { useMutation } from '@tanstack/react-query';
import { cancelAccidentReport } from 'apis/accidentReport';
import useSystemStore from 'store/useSystemStore';
import { toast } from 'sonner';

type RepairShopWorkingTimeKey =
  | 'mon'
  | 'tue'
  | 'wed'
  | 'thu'
  | 'fri'
  | 'sat'
  | 'sun';
// 현재 요일을 RepairShopWorkingTime 키 형식으로 변환하는 함수
export const getTodayKey = (): RepairShopWorkingTimeKey => {
  const dayOfWeekMap: Record<number, RepairShopWorkingTimeKey> = {
    0: 'sun',
    1: 'mon',
    2: 'tue',
    3: 'wed',
    4: 'thu',
    5: 'fri',
    6: 'sat',
  };

  const today = dayjs().day();
  return dayOfWeekMap[today];
};

export default function AccidentReportSuccessTab({
  selectedRepairShop,
}: {
  selectedRepairShop: RepairShop & {
    distance: number;
    accidentReportId: string;
  };
}) {
  const navigate = useNavigate();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { setLoadingDim } = useSystemStore();
  const distanceFormat = useMemo(() => {
    const distance = selectedRepairShop.distance;
    if (distance >= 1000) {
      return `${(distance / 1000).toFixed(1)}km`; // 1km 이상이면 km 단위로 소수점 1자리까지
    }
    return `${distance.toLocaleString()}m`; // 1km 미만이면 m 단위로 표시
  }, [selectedRepairShop.distance]);

  const workingTimeFormat = useMemo(() => {
    if (!selectedRepairShop.workingTime) return null;
    const workingTimeInfo: {
      start: string;
      end: string;
    } | null = selectedRepairShop.workingTime[getTodayKey()];

    if (!workingTimeInfo) return '금일 휴무';
    else
      return `${workingTimeInfo.start.slice(0, 2)}:${workingTimeInfo.start.slice(2, 4)}~${workingTimeInfo.end.slice(0, 2)}:${workingTimeInfo.end.slice(2, 4)}`;
  }, [selectedRepairShop.workingTime]);

  const formatPhoneNumber = (phone: string) => {
    if (/^02\d{8}$/.test(phone)) {
      return phone.replace(/^(02)(\d{4})(\d{4})$/, '$1-$2-$3');
    }

    if (/^\d{10,11}$/.test(phone)) {
      return phone.replace(/^(\d{3})(\d{3,4})(\d{4})$/, '$1-$2-$3');
    }

    return phone;
  };

  const { mutate: cancelAccidentMutation, isPending } = useMutation({
    mutationFn: async () => {
      setLoadingDim(true);

      await new Promise((rs) => setTimeout(rs, 2000));

      const accidentReport = await cancelAccidentReport({
        id: selectedRepairShop.accidentReportId,
      });
      return accidentReport;
    },
    onSuccess: (accidentReport: AccidentReport) => {
      /** 주문 취소 성공 시 리렌더링 */
      navigate(0);
    },
    onError: () => {
      toast.error('사고접수 취소에 실패했습니다. 공업사에 문의해 주세요.');
    },
    onSettled: () => {
      setLoadingDim(false);
    },
  });

  return (
    <div className='w-full h-full flex flex-col justify-between px-5 pt-[30px] pb-[10px]'>
      <div className=''>
        <div className='pb-[30px] space-y-[10px]'>
          <div className='text-[#222] font-semibold text-[20px] leading-[30px] tracking-[-0.4px]'>
            공업사 선정이 완료되었습니다.
          </div>
          <div className='text-[#222] font-medium text-[16px] leading-[25.6px] tracking-[-0.32px]'>
            공업사와 연락 후 입고 여부 결정 또는 취소를
            <br />
            진행할 수 있습니다.
          </div>
        </div>
        <div className='rounded-[16px] border border-[#E5E5EC] overflow-hidden'>
          <div
            style={{
              backgroundImage: `url(${selectedRepairShop.picture.main})`,
            }}
            className='relative h-[180px] rounded-t-[16px] bg-gradient-to-b from-[rgba(0,0,0,0.20)] to-[rgba(0,0,0,0.20)] bg-cover bg-center'
          >
            <div className='absolute bottom-2.5 left-2.5 flex gap-1'>
              {/* 무조건 영업중 */}
              <div className='bg-[#00000080] border-[#E5E5EC33] border backdrop:blur-[10px] p-1.5 pl-1 rounded-[6px] text-white font-medium text-[13px] leading-[13px] tracking-[-0.26px] flex justify-start items-center gap-[2px]'>
                <img
                  src='/assets/images/png/onGreenIcon.png'
                  alt='영업중 아이콘'
                  className='w-[14px] object-contain'
                />
                영업중
              </div>
              {selectedRepairShop.towing_available === true ? (
                <div className='bg-[#00000080] border-[#E5E5EC33] border backdrop:blur-[10px] p-1.5 pl-1 rounded-[6px] text-white font-medium text-[13px] leading-[13px] tracking-[-0.26px] flex justify-start items-center gap-[2px]'>
                  <img
                    src='/assets/images/png/check.png'
                    alt='견인가능 아이콘'
                    className='w-[14px] object-contain'
                  />
                  견인가능
                </div>
              ) : (
                <div className='bg-[#00000080] border-[#E5E5EC33] border backdrop:blur-[10px] p-1.5 pl-1 rounded-[6px] text-white font-medium text-[13px] leading-[13px] tracking-[-0.26px] flex justify-start items-center gap-[2px]'>
                  <img
                    src='/assets/images/png/close-circle.png'
                    alt='견인불가 아이콘'
                    className='w-[14px] object-contain'
                  />
                  견인불가
                </div>
              )}
            </div>
          </div>
          <div className='flex flex-col justify-between items-start gap-6 p-4 pt-[15px] rounded-b-[16px]'>
            <div>
              <div className='text-[#222222] font-medium text-[16px] leading-[25.6px] tracking-[-0.32px] pb-1'>
                {selectedRepairShop.name}
              </div>
              <div className='text-[#555555] text-[13px] leading-[19.5px] tracking-[-0.26px] pb-2.5'>
                {selectedRepairShop.address.main}{' '}
                {selectedRepairShop.address.detail}
              </div>

              <div className='flex gap-1.5'>
                <div className='rounded-[6px] bg-[#F3F6F9] p-1.5 flex items-center justify-center gap-1 text-[13px] leading-[13px] font-[500] text-[#555555]'>
                  <TagIcon />
                  {distanceFormat}
                </div>
                {workingTimeFormat && (
                  <div className='rounded-[6px] bg-[#F3F6F9] p-1.5 flex items-center justify-center gap-1 text-[13px] leading-[13px] font-[500] text-[#555555]'>
                    <ClockIcon />
                    {workingTimeFormat}
                  </div>
                )}
              </div>
            </div>
            <button
              onClick={() => {
                window.location.href = `tel:${selectedRepairShop.contact_number}`;
              }}
              className='bg-[#F3F6F9] border border-none w-full flex justify-center items-center gap-[6px] rounded-[10px] py-4 px-[10px] h-[50px]'
            >
              <img
                src='/assets/images/png/call-black.png'
                alt='전화아이콘'
                className='w-4 object-contain'
              />
              <span className='text-[16px] font-[500] leading-[16px]'>
                {selectedRepairShop.contact_number
                  ? formatPhoneNumber(selectedRepairShop.contact_number)
                  : '전화번호 없음'}
              </span>
            </button>
          </div>
        </div>
        <div className='flex justify-end items-center mt-4'>
          <Button
            onClick={() => setIsPopupOpen(!isPopupOpen)}
            className='!w-[80px] text-[14px] leading-[14px] font-[500] bg-[#F3F6F9] text-[#222] h-[42px]'
          >
            취소하기
          </Button>
        </div>
      </div>
      <div className='pt-[59px]'>
        <V3_Button
          theme='primary'
          onClick={() => navigate('/accidentReport/list', { replace: true })}
        >
          수리 진행 확인하기
        </V3_Button>
      </div>

      <V3_Confirm
        onClose={() => {
          setIsPopupOpen(!isPopupOpen);
        }}
        onConfirm={() => {
          cancelAccidentMutation();
          setIsPopupOpen(!isPopupOpen);
        }}
        isOpen={isPopupOpen}
        setIsOpen={setIsPopupOpen}
        disableBackdropClose={true}
        title={''}
        agreeText={'확인'}
        className='!pb-[50px] !pt-[24px]'
      >
        <div className='flex flex-col items-center justify-center gap-5'>
          <div className='w-[52.2px] h-[52.2px] flex justify-center items-center rounded-full bg-[#F9D7D7]'>
            <X width={23.2} height={23.2} color='#DC0000' strokeWidth={3} />
          </div>
          <div className='flex flex-col items-center justify-center gap-1.5'>
            <div className='text-[#222] text-center font-[700] text-[16px] leading-[25.6px] tracking-[-0.32px] [font-feature-settings: "liga" off, "clig" off]'>
              취소 하시겠습니까?
            </div>
            <div className='text-[#222] text-center font-[500] text-[15px] leading-[24px] tracking-[-0.3px] [font-feature-settings: "liga" off, "clig" off]'>
              취소하시면 지금까지 입력하신 정보가 사라집니다.
              <br />
              취소 하시겠습니까?
            </div>
          </div>
        </div>
      </V3_Confirm>
    </div>
  );
}
