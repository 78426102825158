import { ChangeEvent, useMemo, useState } from 'react';
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
} from 'components/ui/drawer';
import { Button } from 'components/ui/button';
import { X } from 'lucide-react';
import CarsayoRadio from 'components/common/v2/CarsayoRadio';

import { AccidentReportForm } from '../forms/accidentReportForm';
import { UseFormReturn } from 'react-hook-form';
import useInsuranceOriginDataStore from 'pages/v2/ask/store/dataStore';
import V3_Button from 'components_v3/ui/Button';
import V3_Input from 'components_v3/ui/Input';
import SelectV3, { SelectV3Item } from 'components/common/v3/selectv3';
import { FormHelperText } from '@mui/material';
import MultiImageViewer from 'components_v3/dialog/MultiImageViewer';
import { cn } from 'utils';
import ImageFileUploadBox from 'components_v3/ui/ImageFileUploadBox';

const MAX_IMAGE_COUNT = 5;

export default function AccidentReportFormTab({
  currentTab,
  setCurrentTab,
  form,
}: {
  currentTab: number;
  setCurrentTab: (tab: number) => void;
  form: UseFormReturn<AccidentReportForm>;
}) {
  const { originInsuranceData } = useInsuranceOriginDataStore();

  const [isImageViewerOpen, setIsImageViewerOpen] = useState(false);
  const [imageViewerIndex, setImageViewerIndex] = useState(0);

  const selectedInsuranceCompany = useMemo(() => {
    return (
      originInsuranceData.find(
        (item) => item.id === form.watch('insuranceCompany'),
      ) ?? null
    );
  }, [form.watch('insuranceCompany')]);

  const [accidentDrawerOpen, setAccidentDrawerOpen] = useState(false);

  /** 위도, 경도, 공업사 제외 유효성 검사
   * @note 위도, 경도, 공업사 제외 유효성 검사는 다음 탭에서 처리
   * @note 통과시 탭 전환
   */
  const checkFormValid = async () => {
    const isValid = await form.trigger([
      'info_name',
      'info_phoneNumber',
      'info_birth',
      'carName',
      'insuranceCompany',
    ]);

    if (isValid) {
      setCurrentTab(currentTab + 1);
    }
  };

  return (
    <div className='pt-[36px] pb-[10px] px-5 flex flex-col justify-between min-h-[calc(100dvh-60px)]'>
      <div>
        <div className=''>
          <form className=''>
            <fieldset className='flex flex-col justify-start items-start gap-6'>
              <label htmlFor='info_name' className='w-full'>
                <span className='mb-[12px] inline-block text-[15px] font-[400] leading-[15px]'>
                  이름
                </span>
                <V3_Input
                  {...form.register('info_name')}
                  type='text'
                  id='info_name'
                  placeholder='이름을 입력해 주세요.'
                  variant='outlined'
                  error={!!form.formState.errors.info_name}
                  helperText={form.formState.errors.info_name?.message}
                />
              </label>

              <label htmlFor='info_phoneNumber' className='w-full'>
                <span className='mb-[12px] inline-block text-[15px] font-[400] leading-[15px]'>
                  휴대폰번호
                </span>
                <V3_Input
                  {...form.register('info_phoneNumber')}
                  type='number'
                  id='info_phoneNumber'
                  placeholder='휴대폰번호 (-없이 입력)'
                  variant='outlined'
                  error={!!form.formState.errors.info_phoneNumber}
                  helperText={form.formState.errors.info_phoneNumber?.message}
                />
              </label>

              <label htmlFor='info_birth' className='w-full'>
                <span className='mb-[12px] inline-block text-[15px] font-[400] leading-[15px]'>
                  생년월일
                </span>
                <V3_Input
                  {...form.register('info_birth')}
                  type='number'
                  id='info_birth'
                  placeholder='생년월일 입력해 주세요. (ex.990101)'
                  maxLength={6}
                  variant='outlined'
                  error={!!form.formState.errors.info_birth}
                  helperText={form.formState.errors.info_birth?.message}
                />
              </label>

              <div className='w-full'>
                <label
                  htmlFor='info_gender'
                  className='block mb-[20px] text-[15px] leading-[22px] text-[#111]'
                >
                  성별
                </label>
                <div className='w-full flex justify-start items-center gap-[20px]'>
                  <CarsayoRadio
                    name={'info_gender'}
                    label='남성'
                    onChange={() => form.setValue('info_gender', 'male')}
                    value='male'
                    checked={form.watch('info_gender') === 'male'}
                  />
                  <CarsayoRadio
                    name={'info_gender'}
                    label='여성'
                    onChange={() => form.setValue('info_gender', 'female')}
                    value='female'
                    checked={form.watch('info_gender') === 'female'}
                  />
                </div>
              </div>
              <label htmlFor='carName' className='w-full'>
                <span className='mb-[12px] inline-block text-[15px] font-[400] leading-[15px]'>
                  차량번호
                </span>
                <V3_Input
                  {...form.register('carName')}
                  type='text'
                  id='carName'
                  placeholder='차량번호를 입력해주세요.'
                  variant='outlined'
                  error={!!form.formState.errors.carName}
                  helperText={form.formState.errors.carName?.message}
                />
              </label>

              <div className='w-full'>
                <label
                  htmlFor='insuranceCompany'
                  className='block text-[15px] leading-[22px] mb-[12px]'
                >
                  보험사
                </label>
                <SelectV3
                  onValueChange={(value) => {
                    form.setValue('insuranceCompany', Number(value), {
                      shouldValidate: true,
                    });
                  }}
                  placeholder={'보험사를 선택해 주세요'}
                  value={selectedInsuranceCompany?.name ?? ''}
                  error={!!form.formState.errors.insuranceCompany}
                  renderValue={() => (
                    <div className='flex justify-start items-center'>
                      <img
                        src={selectedInsuranceCompany?.logo_url ?? ''}
                        alt={selectedInsuranceCompany?.name ?? ''}
                        className='w-[28px] h-[28px] mr-2.5'
                      />
                      <span className='text-[16px] leading-[16px] font-[400]'>
                        {selectedInsuranceCompany?.name ?? ''}
                      </span>
                    </div>
                  )}
                >
                  {originInsuranceData.map((item) => (
                    <SelectV3Item value={item.id}>
                      <div className='flex items-center'>
                        <img
                          src={item.logo_url ?? ''}
                          alt={item.name ?? ''}
                          className='w-[28px] h-[28px] mr-2.5'
                        />
                        <span className='text-[16px] leading-[16px]'>
                          {item?.name ?? ''}
                        </span>
                      </div>
                    </SelectV3Item>
                  ))}
                </SelectV3>

                {form.formState.errors.insuranceCompany && (
                  <FormHelperText
                    sx={{
                      color: '#FF4D4D',
                      fontSize: '12px',
                      lineHeight: '20px',
                      letterSpacing: '0.4px',
                      fontWeight: '400',
                      marginX: '14px !important',
                      marginBottom: '10px !important',
                    }}
                  >
                    {form.formState.errors.insuranceCompany?.message}
                  </FormHelperText>
                )}
              </div>

              <div className='w-full'>
                <label
                  htmlFor='option_traction'
                  className='block mb-[20px] text-[15px] leading-[22px] text-[#111]'
                >
                  견인 필요여부
                </label>
                <div className='w-full flex justify-start items-center gap-[20px]'>
                  <CarsayoRadio
                    name={'option_traction'}
                    label='견인 필요'
                    onChange={() => form.setValue('option_traction', true)}
                    value='true'
                    checked={form.watch('option_traction') === true}
                  />
                  <CarsayoRadio
                    name={'option_traction'}
                    label='견인 불필요'
                    onChange={() => form.setValue('option_traction', false)}
                    value='false'
                    checked={form.watch('option_traction') === false}
                  />
                </div>
              </div>

              <div className='w-full'>
                <label
                  htmlFor='option_traction'
                  className='block mb-[20px] text-[15px] leading-[22px] text-[#111]'
                >
                  차량 상태(선택)
                </label>
                <div className='w-[calc(100dvw-40px)] overflow-x-auto'>
                  <div className='grid grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-[6px]'>
                    {form.getValues('pictures').length < MAX_IMAGE_COUNT && (
                      <ImageFileUploadBox
                        id='공업사 추가사진 업로더'
                        accept='image/*'
                        onUpload={(fileInfo) => {
                          form.setValue(
                            'pictures',
                            [...form.getValues('pictures'), fileInfo.url],
                            {
                              shouldValidate: true,
                            },
                          );
                        }}
                        currentCount={form.getValues('pictures').length}
                        maxCount={MAX_IMAGE_COUNT}
                        error={!!form.formState.errors.pictures}
                      />
                    )}

                    {form.getValues('pictures').map((item, index) => (
                      <div
                        className='relative w-full aspect-square overflow-hidden rounded-[6px]'
                        onClick={() => {
                          setImageViewerIndex(index);
                          setIsImageViewerOpen(true);
                        }}
                        key={item}
                      >
                        <img
                          src={item}
                          alt={'공업사 추가사진 이미지'}
                          className='h-full w-full object-cover'
                        />
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            const currentData = (form.getValues('pictures') ||
                              []) as string[];

                            const filteredData = currentData.filter(
                              (el: string) => el !== item,
                            );

                            if (filteredData.length === 0) {
                              form.setValue('pictures', [] as any, {
                                shouldValidate: true,
                              });
                            } else {
                              form.setValue('pictures', filteredData as any, {
                                shouldValidate: true,
                              });
                            }
                          }}
                          className={cn(
                            'absolute right-1 top-1 z-10 flex h-[20px] w-[20px] items-center justify-center rounded-full bg-[#000000CC]',
                          )}
                        >
                          <X width={12} height={12} color='#fff' />
                        </button>
                      </div>
                    ))}

                    <MultiImageViewer
                      isOpen={isImageViewerOpen}
                      setIsOpen={setIsImageViewerOpen}
                      initialIndex={imageViewerIndex}
                      fileUrl={form.getValues('pictures')}
                    />
                  </div>

                  {form.formState.errors.pictures && (
                    <FormHelperText
                      sx={{
                        color: '#FF4D4D',
                        fontSize: '12px',
                        lineHeight: '20px',
                        letterSpacing: '0.4px',
                        fontWeight: '400',
                        marginX: '14px',
                        marginTop: '3px !important',
                      }}
                    >
                      {form.formState.errors.pictures.message}
                    </FormHelperText>
                  )}
                </div>
              </div>
            </fieldset>
          </form>
        </div>
      </div>

      <div className='flex justify-between items-center gap-[7px] pt-[50px]'>
        <V3_Button
          onClick={() => {
            setCurrentTab(currentTab - 1);
          }}
        >
          이전
        </V3_Button>
        <V3_Button theme='primary' onClick={checkFormValid}>
          다음
        </V3_Button>
      </div>

      <Drawer open={accidentDrawerOpen}>
        <DrawerContent className='mx-auto flex max-h-[90vh] w-full max-w-sm flex-col'>
          <DrawerHeader className='relative'>
            <DrawerTitle className='text-center text -lg font-semibold'>
              보험사 선택
            </DrawerTitle>
            <Button
              onClick={() => setAccidentDrawerOpen(!accidentDrawerOpen)}
              className='absolute right-5 top-4 h-7 w-7 border-0 bg-transparent p-0 text-[#111111]'
            >
              <X className='h-7 w-7' />
            </Button>
          </DrawerHeader>

          <div className='p-4 pt-0'>
            <div className='flex justify-between items-end pt-[10px] pb-[20px] border-b-[1px] border-[#F1F1F5]'>
              <div>
                <h4 className='text-[16px] leading-[24px] font-[600] mb-[8px]'>
                  보험사를 선택해주세요
                </h4>
                <p className='text-[14px] leading-[20px]'>
                  고객님을 위한 보험 만기 알림 및 긴급출동 등 특별한 카 라이프에
                  카집사가 함께 하겠습니다.
                </p>
              </div>
              <img
                src='/assets/images/v2/ask-carGipsa02.svg'
                alt='카집사 이미지02'
              />
            </div>
            <div>
              <ul className='brandAllCheck mt-4 grid grid-cols-3 gap-x-[21.5px] gap-y-4 mb-[20px]'>
                {originInsuranceData.map((el, index) => {
                  const targetCompanyName = el.name;
                  const targetCompanyData = el;
                  return (
                    <li
                      key={`${targetCompanyName}_${index}`}
                      className={`group relative h-[88px] rounded-xl border bg-[#fff] py-4 ${targetCompanyName === selectedInsuranceCompany?.name ? 'border-primary' : ''}`}
                    >
                      <label htmlFor={`insuranceRadio_${targetCompanyName}`}>
                        <input
                          type='radio'
                          id={`insuranceRadio_${targetCompanyName}`}
                          value={targetCompanyName}
                          className='absolute h-0 w-0 hidden'
                          checked={
                            targetCompanyName === selectedInsuranceCompany?.name
                          }
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            form.setValue(
                              'insuranceCompany',
                              targetCompanyData.id,
                              { shouldValidate: true },
                            );
                          }}
                        />
                        <div className='mx-auto h-9 w-9'>
                          <img
                            src={targetCompanyData.logo_url}
                            alt={`보험사 로고_${targetCompanyName}`}
                          />
                        </div>
                        <div
                          className={`mt-2 text-[#111]  px-1 overflow-hidden text-ellipsis whitespace-nowrap text-center text-[13px] ${targetCompanyName === selectedInsuranceCompany?.name ? 'font-[500]' : 'font-[400]'} `}
                        >
                          {targetCompanyName}
                        </div>
                      </label>
                    </li>
                  );
                })}
              </ul>
            </div>

            <V3_Button
              theme='primary'
              onClick={() => {
                setAccidentDrawerOpen(!accidentDrawerOpen);
              }}
            >
              보험사 선택
            </V3_Button>
          </div>
        </DrawerContent>
      </Drawer>
    </div>
  );
}
