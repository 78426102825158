import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormGroup,
  FormGroupProps,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { cn } from 'utils';

interface StyledCheckProps extends CheckboxProps {
  type?: string;
}

const StyledCheck = styled(Checkbox, {
  shouldForwardProp: (prop) => prop !== 'type',
})<StyledCheckProps>(({ theme, type }) => ({
  width: type === 'brandSetting' ? 28 : 24,
  height: type === 'brandSetting' ? 28 : 24,
  borderRadius: '50%',
  marginRight: type === 'brandSetting' ? '6px' : '4px',
  border: '1px solid #E5E5EC',
  backgroundColor: '#fff',
  '&.Mui-checked': {
    borderColor: 'transparent',
    color: 'transparent',
    backgroundImage: `url(${type === 'sub' ? '/assets/images/png/check_sub.png' : '/assets/images/png/check.png'})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  '&.MuiCheckbox-root:not(.Mui-checked)': {
    borderColor: 'transparent',
    color: 'transparent',
    backgroundImage: `url(${type === 'sub' ? '/assets/images/png/unCheck_sub.png' : '/assets/images/png/unCheck.png'})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  '&.Mui-disabled': {
    opacity: 0.5,
    cursor: 'not-allowed',
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: '15px',
  fontWeight: 500,
  lineHeight: '15px',
  fontFamily: 'Pretendard',
  color: '#222',
}));

interface CustomCheckProps extends CheckboxProps {
  label?: string;
  value: string;
  type?: string;
  className?: string;
}

const CustomCheckbox: React.FC<CustomCheckProps> = ({
  label,
  value,
  type,
  className,
  ...props
}) => {
  return (
    <FormControlLabel
      sx={{
        marginRight: '0px',
        marginLeft: '0px',
      }}
      control={<StyledCheck value={value} type={type} {...props} />}
      label={
        <StyledTypography
          className={cn(className)}
          sx={{
            letterSpacing: '-0.02em',
          }}
        >
          {label}
        </StyledTypography>
      }
    />
  );
};

interface CustomCheckGroupProps extends FormGroupProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
}

const CustomCheckGroup: React.FC<CustomCheckGroupProps> = ({
  className,
  value,
  onChange,
  label,
  children,
  ...props
}) => {
  return (
    <div className={cn('w-full font-Pretendard tracking-[-0.02em]', className)}>
      {label && <p>{label}</p>}
      <FormGroup
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'start',
          alignItems: 'center',
          flexWrap: 'wrap',
          rowGap: '18.5px',
          columnGap: '18px',
          '& .MuiTypography-root': {
            fontFamily: 'Pretendard',
            letterSpacing: '-0.02em',
          },
        }}
        {...props}
      >
        {children}
      </FormGroup>
    </div>
  );
};

export { CustomCheckbox, CustomCheckGroup };
