import { FileInfo } from '@carsayo/types';
import { Box } from '@mui/material';
import { useFileUpload } from 'hooks_v3/useFileUpload';
import { cn } from 'utils';

const ImageUploadBox = ({
  id,
  onUpload,
  maxCount,
  currentCount,
  width,
  height,
  className,
  type,
}: {
  id: string;
  onUpload: (item: FileInfo, id?: string) => void;
  maxCount: number;
  currentCount?: number;
  width?: string;
  height?: string;
  className?: string;
  type?: string;
}) => {
  const { handleFileUpload, isUploading } = useFileUpload();

  return (
    <Box
      sx={{
        width: width ? width : '97px',
        height: height ? height : '97px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '8px',
        boxSizing: 'border-box',
        maxWidth: '100%',
        cursor: 'pointer',
        flexShrink: '0',
      }}
      className={cn(className)}
    >
      <label
        htmlFor={id}
        className={cn(
          'flex h-full w-full items-center justify-center bg-[#F1F4F7] overflow-hidden rounded-[8px]',
          type === 'dashed' &&
            'bg-white border-[2px] border-dashed border-[#E5EAEE]',
        )}
      >
        <input
          type='file'
          accept='image/*'
          onChange={(e) => {
            handleFileUpload(e, (fileInfo: FileInfo) => {
              onUpload(fileInfo, id);
            });
          }}
          style={{
            display: 'none',
          }}
          disabled={isUploading}
          id={id}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '6px',
            minHeight: '53px',
          }}
        >
          {type === 'dashed' ? (
            <img
              src='/assets/images/png/imageUploaderCamera.png'
              alt='카메라 아이콘'
              className='w-[32px] object-contain'
            />
          ) : (
            <img
              src='/assets/images/v2/img_input.png'
              alt='카메라 아이콘'
              className='w-[32px] object-contain'
            />
          )}

          {type === 'dashed' && (
            <span className='text-[15px] leading-[15px] font-[500] text-[#7D848A]'>
              {currentCount}/{maxCount}
            </span>
          )}
        </div>
      </label>
    </Box>
  );
};

export default ImageUploadBox;
