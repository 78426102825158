import { FileInfo } from '@carsayo/types';
import { Box, Typography } from '@mui/material';
import { useFileUpload } from 'hooks_v3/useFileUpload';
import { useState } from 'react';

const ImageUpload = ({
  id,
  placeholder,
  onUpload,
}: {
  id: string;
  placeholder?: string;
  onUpload: (item: FileInfo, id?: string) => void;
}) => {
  const { handleFileUpload, isUploading } = useFileUpload();
  const [currentFileInfo, setCurrentFileInfo] = useState<FileInfo | null>(null);

  const handleBoxClick = () => {
    if (!currentFileInfo) {
      document.getElementById(id)?.click();
    }
  };

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px',
        paddingLeft: '16px',
        border: '1px solid #E5E5EC',
        borderRadius: '8px',
        cursor: currentFileInfo ? 'default' : 'pointer',
        boxSizing: 'border-box',
        maxWidth: '100%',
        minHeight: '50px',
      }}
      onClick={handleBoxClick}
    >
      <Typography
        variant='body1'
        sx={{
          color: '#999999',
          fontSize: '16px',
          lineHeight: '16px',
          fontWeight: '400',
          fontFamily: 'Pretendard',
          position: 'absolute',
          left: '16px',
          top: '50%',
          transform: 'translateY(-50%)',
        }}
      >
        {placeholder ? placeholder : '파일 업로드'}
      </Typography>

      <input
        type='file'
        accept='image/*'
        onChange={(e) => {
          handleFileUpload(e, (fileInfo: FileInfo) => {
            setCurrentFileInfo(fileInfo);
            onUpload(fileInfo, id);
          });
        }}
        disabled={isUploading}
        style={{
          display: 'none',
          pointerEvents: currentFileInfo ? 'auto' : 'none',
        }}
        id={id}
      />
      <label
        htmlFor={id}
        style={{
          pointerEvents: currentFileInfo ? 'auto' : 'none',
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'center',
        }}
      >
        <span
          style={{
            backgroundColor: '#F3F6F9',
            height: '30px',
            display: 'flex',
            width: '61px',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '13px',
            lineHeight: '13px',
            fontWeight: '500',
            color: '#555555',
            whiteSpace: 'nowrap',
            letterSpacing: '-0.02em',
            borderRadius: '4px',
          }}
        >
          파일선택
        </span>
      </label>
    </Box>
  );
};

export default ImageUpload;
