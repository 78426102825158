import { useState } from 'react';

export const useGeoLocation = () => {
  const [location, setLocation] = useState<{
    lat: number;
    lng: number;
  }>();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const requestLocation = () => {
    setLoading(true);

    // iOS: 네이티브 브릿지를 사용
    if (
      window.webkit &&
      window.webkit.messageHandlers &&
      window.webkit.messageHandlers.getLocation
    ) {
      window.native.onLocationReceived = (latitude, longitude) => {
        setLocation({
          lat: latitude,
          lng: longitude,
        });
        setLoading(false);
        setError(null);
      };
      window.native.onLocationError = (error) => {
        setError(error);
        setLoading(false);
      };
      window.webkit.messageHandlers.getLocation.postMessage('');
      return;
    } else {
      /** iOS 앱 내부가 아닐 경우 */
      if (!navigator.geolocation) {
        setError('Geolocation is not supported by your browser.');
        return;
      }

      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          setLoading(false);
          setError(null);
        },
        (err) => {
          setError(getErrorMessage(err));
          setLoading(false);
        },
        {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 0,
        },
      );
    }
  };

  const getErrorMessage = (error: any) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        return 'User denied the request for Geolocation.';
      case error.POSITION_UNAVAILABLE:
        return 'Location information is unavailable.';
      case error.TIMEOUT:
        return 'The request to get user location timed out.';
      default:
        return 'An unknown error occurred.';
    }
  };

  return {
    /** requestLocation 실행 결과 */
    location,
    /** requestLocation 실행 오류 */
    error,
    /** requestLocation 실행 중 여부 */
    loading,
    /** 현재 위치를 요청합니다. */
    requestLocation,
  };
};
