import dayjs from 'dayjs';

import { AccidentReport } from '@carsayo/types';

export default function TabCustomerInfo({
  targetAccidentReport,
}: {
  targetAccidentReport: AccidentReport;
}) {
  return (
    <div className=''>
      <strong className='mt-[30px] mb-4 flex items-center justify-start text-[18px] font-[600] leading-[18px] text-[#000]'>
        고객 정보
      </strong>
      <div className='w-full rounded-[16px] bg-white px-4 py-[20px] shadow-[0px_6px_20px_#0000000D]'>
        <ul className='flex flex-col items-start justify-start gap-[20px]'>
          <li className='flex w-full items-center justify-between gap-[10px] text-[15px] font-[500] leading-[15px]'>
            <span className='whitespace-nowrap text-[#7D848A]'>이름</span>
            <strong className='font-[500] text-[#222222]'>
              {targetAccidentReport.info_name}
            </strong>
          </li>
          <li className='flex w-full items-center justify-between gap-[10px] text-[15px] font-[500] leading-[15px]'>
            <span className='whitespace-nowrap text-[#7D848A]'>차량번호</span>
            <strong className='font-[500] text-[#222222]'>
              {targetAccidentReport.carNumber}
            </strong>
          </li>
          <li className='flex w-full items-center justify-between gap-[10px] text-[15px] font-[500] leading-[15px]'>
            <span className='whitespace-nowrap text-[#7D848A]'>보험사</span>
            <strong className='font-[500] text-[#222222]'>
              {targetAccidentReport.insuranceCompany.name}
            </strong>
          </li>
          <li className='flex w-full items-center justify-between gap-[10px] text-[15px] font-[500] leading-[15px]'>
            <span className='whitespace-nowrap text-[#7D848A]'>견인 여부</span>
            <strong className='font-[500] text-[#222222]'>
              {targetAccidentReport.option_traction ? '필요' : '불필요'}
            </strong>
          </li>
          <li className='flex w-full items-center justify-between gap-[10px] text-[15px] font-[500] leading-[15px]'>
            <span className='whitespace-nowrap text-[#7D848A]'>배정 여부</span>
            <span className='flex flex-wrap items-center justify-end gap-[10px]'>
              <span className='text-[#005CE6]'>
                {targetAccidentReport.state !== 'canceled'
                  ? '배정 완료'
                  : '취소'}
              </span>
              <strong className='font-[500] text-[#222222]'>
                {dayjs(targetAccidentReport.created_at).format(
                  'YYYY.MM.DD HH:mm',
                )}
              </strong>
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
}
