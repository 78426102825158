/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'components/ui/accordion';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import {
  FileInfo,
  InquiryDetailInfo,
  InquiryIdDTO,
  InquirySearchList,
  InquiryShortInfo,
} from '@carsayo/types';
import { deleteInquiry, getInquiryDetail, getInquiryList } from 'apis/inquiry';
import { useEffect, useLayoutEffect, useState } from 'react';
import useSystemStore from 'store/useSystemStore';
import dayjs from 'dayjs';
import ReactHtmlParser from 'react-html-parser';
import { Button } from 'components/ui/button';
import NoData from 'components/common/v3/NoData';
import { Link, useNavigate } from 'react-router-dom';
import { cn } from 'utils';
import { X } from 'lucide-react';
import Confirm from 'components/ui/confirm';
import { CarsayoToast } from 'components/common/v2/CarsayoToast';

const MyInquiryList = () => {
  const { setTopTabbarState, setIsBottomTabbar } = useSystemStore();
  /** 전체 문의 리스트 */
  const [inquiryList, setInquiryList] = useState<InquiryShortInfo[]>([]);
  /** 해당 페이지에 속하는 size만큼의 리스트 */
  const [pageData, setPageData] = useState<InquiryShortInfo[]>([]);
  /** 전체 리스트를 size만큼씩 잘라서 묶은 리스트 */
  const [chunkData, setChunkData] = useState<InquiryShortInfo[][]>([]);
  const [currentPage, setCurrentPage] = useState(1); // 현재 페이지
  const [inquiryDetailInfo, setInquiryDetailInfo] = useState<{
    [key: string]: InquiryDetailInfo;
  }>({});

  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [previewInfo, setPreviewInfo] = useState<FileInfo | null>();
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [deleteId, setDeleteId] = useState('');

  const [isFetching, setIsFetching] = useState(false);

  const navigate = useNavigate();

  const chunkArray = (array: any[], size: number) => {
    const chunkedArray = [];
    for (let i = 0; i < array.length; i += size) {
      chunkedArray.push(array.slice(i, i + size));
    }
    return chunkedArray;
  };
  const handleVoidEvents = {
    getPostList: async () => {
      setIsFetching(true);
      const searchedInquiryList: InquiryShortInfo[] = await getInquiryList();
      if (searchedInquiryList) {
        setInquiryList(searchedInquiryList);
        setChunkData(chunkArray(searchedInquiryList, 10));
        if (currentPage === 1) {
          setPageData(chunkArray(searchedInquiryList, 10)[0]);
        }
      } else {
        setInquiryList([]);
      }

      setIsFetching(false);
      return;
    },
    getDetail: (id: string) => async () => {
      const reqData: InquiryIdDTO = {
        inquiryId: id,
      };
      const searchedDetail: InquiryDetailInfo = await getInquiryDetail(reqData);
      if (searchedDetail) {
        setInquiryDetailInfo({ ...inquiryDetailInfo, [id]: searchedDetail });
      } else {
        setInquiryDetailInfo({});
      }

      return;
    },
  };

  const handleClosePreview = () => {
    setIsPreviewOpen(false);
    setPreviewInfo(null);
  };

  const handleDelete = async (id: string) => {
    try {
      const reqData: InquiryIdDTO = {
        inquiryId: id,
      };
      await deleteInquiry(reqData);
      CarsayoToast.success('1:1 문의가 삭제되었습니다.');
      handleVoidEvents.getPostList();
    } catch (error) {
      console.error('문의 삭제 오류:', error);
    }
  };

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      title: '1:1 문의',
      rightElement: (
        <Link to='/inquiry/write'>
          <img
            src='/assets/images/v2/inquiryIcon.png'
            alt='문의 등록 아이콘'
            className='w-[28px] h-[28px] object-contain'
          />
        </Link>
      ),
      leftElement: 'back',
    });
    setIsBottomTabbar(false);
    handleVoidEvents.getPostList();
  }, []);

  useEffect(() => {
    if (inquiryList) {
      setPageData(chunkArray(inquiryList, 10)[currentPage - 1]);
    }
  }, [inquiryList]);
  return (
    <div className='pt-[20px] pb-[50px]'>
      <strong className='px-[20px] text-[15px] leading-[15px] font-[600] inline-block mb-[20px]'>
        총 {inquiryList?.length ?? 0}건
      </strong>
      {!isFetching && pageData && pageData.length > 0 ? (
        <Accordion
          key={`inquiry-data`}
          type='single'
          collapsible
          className='w-full'
        >
          {pageData.map((el, index) => {
            return (
              <AccordionItem
                value={`inquiryList-${index}`}
                key={`inquiryList-${index}`}
              >
                <AccordionTrigger
                  className='px-[20px] flex items-center gap-2.5 border-b border-[#E5EAEE] py-6 text-left min-h-[122px]'
                  onClick={handleVoidEvents.getDetail(el.id)}
                >
                  <div className='flex-1'>
                    <div className='w-full text-[14px] leading-[14px] font-[600]'>
                      {el.state === 'answered' ? (
                        <span className='text-[#4860FF]'>답변완료</span>
                      ) : (
                        <span className='text-[#7D848A]'>답변대기</span>
                      )}
                      <span className='inline-block ml-[6px] text-[#222222] font-[500]'>
                        {el.type}
                      </span>
                    </div>
                    <div className='mt-2 font-[600] text-[16px] leading-[25.6px]'>
                      {el.title}
                    </div>
                    <div className='mt-[12px] text-[14px] leading-[14px] text-[#999999] font-[500]'>
                      {`${dayjs(el.submitted_at).format('YYYY.MM.DD')} ${dayjs(el.submitted_at).format('HH:mm')}`}
                    </div>
                  </div>
                </AccordionTrigger>
                <AccordionContent className='w-full'>
                  <div className='w-full'>
                    {/* 문의내용 */}
                    <div
                      className={cn(
                        'pt-6 pb-[30px] px-[20px]',
                        el.state === 'submitted' && 'border-b border-[#E5EAEE]',
                      )}
                    >
                      <div className='w-full ql-editor prose whitespace-pre-wrap text-[15px] leading-[24px] font-[400] text-[#222]'>
                        {ReactHtmlParser(
                          inquiryDetailInfo[el.id]?.content ?? '',
                        )}
                      </div>
                      {inquiryDetailInfo[el.id]?.file_1 && (
                        <div className='mt-[20px] bg-[#F3F6F9] w-full rounded-[8px] p-[10px] flex justify-start items-center gap-2'>
                          <img
                            src='/assets/images/v2/clip.png'
                            alt='첨부아이콘'
                            className='w-[24px] object-contain'
                          />
                          <span
                            className='text-[14px] leading-[14px] font-[500] text-[#555]'
                            onClick={() => {
                              setIsPreviewOpen(true);
                              setPreviewInfo(inquiryDetailInfo[el.id]?.file_1);
                            }}
                          >
                            {inquiryDetailInfo[el.id]?.file_1?.name}.
                            {inquiryDetailInfo[el.id]?.file_1?.extension}
                          </span>
                        </div>
                      )}
                      {inquiryDetailInfo[el.id]?.file_2 && (
                        <div className='mt-[10px] bg-[#F3F6F9] w-full rounded-[8px] p-[10px] flex justify-start items-center gap-2'>
                          <img
                            src='/assets/images/v2/clip.png'
                            alt='첨부아이콘'
                            className='w-[24px] object-contain'
                          />
                          <span
                            className='text-[14px] leading-[14px] font-[500] text-[#555]'
                            onClick={() => {
                              setIsPreviewOpen(true);
                              setPreviewInfo(inquiryDetailInfo[el.id]?.file_2);
                            }}
                          >
                            {inquiryDetailInfo[el.id]?.file_2?.name}.
                            {inquiryDetailInfo[el.id]?.file_2?.extension}
                          </span>
                        </div>
                      )}

                      {el.state === 'submitted' && (
                        <div className='flex justify-end items-center gap-[7px] mt-[30px]'>
                          <button
                            className='text-[16px] leading-[16px] font-[500] p-[14px] min-w-[80px] h-[44px] border border-[#E5E5EC] rounded-[10px]'
                            type='button'
                            onClick={() => {
                              navigate(`/inquiry/update/${el.id}`);
                            }}
                          >
                            수정
                          </button>
                          <button
                            className='text-[16px] leading-[16px] font-[500] p-[14px] min-w-[80px] h-[44px] border border-[#E5E5EC] rounded-[10px]'
                            onClick={() => {
                              setDeleteId(el.id);
                              setIsConfirmOpen(true);
                            }}
                          >
                            삭제
                          </button>
                        </div>
                      )}
                    </div>
                    {/* 답변내용 */}
                    {inquiryDetailInfo[el.id]?.state === 'answered' && (
                      <div className='py-6 px-[20px] bg-[#F3F6F9] border-b border-[#E5EAEE]'>
                        <div className='w-full whitespace-pre-wrap ql-editor prose text-[15px] leading-[24px] font-[400] text-[#222222]'>
                          {ReactHtmlParser(
                            inquiryDetailInfo[el.id]?.answerContent ?? '',
                          )}
                        </div>
                        <div className='mt-[20px] text-[14px] leading-[14px] text-[#999999] font-[500]'>
                          {`${dayjs(inquiryDetailInfo[el.id]?.answered_at).format('YYYY.MM.DD')} ${dayjs(inquiryDetailInfo[el.id]?.answered_at).format('HH:mm')}`}
                        </div>
                      </div>
                    )}
                  </div>
                </AccordionContent>
              </AccordionItem>
            );
          })}
        </Accordion>
      ) : (
        <div className='w-full min-h-[calc(100dvh-219px)] flex justify-center items-center pb-[20%]'>
          <NoData text='등록된 문의가 없어요 ' />
        </div>
      )}

      {chunkData.length > currentPage && (
        <Button
          onClick={() => {
            setCurrentPage((prev) => prev + 1);
            const newArray = pageData.concat(chunkData[currentPage]);
            setPageData(newArray);
          }}
          variant='outline'
          className='mt-8'
        >
          더보기
        </Button>
      )}

      {isPreviewOpen && previewInfo && (
        <Dialog
          sx={{
            '& .MuiDialog-paper': {
              height: '100dvh',
              width: '100dvw',
              maxWidth: 'none',
              margin: 0,
              padding: 0,
            },
            '& .MuiPaper-root': {
              height: '100dvh',
              width: '100dvw',
              maxHeight: '100dvh',
            },
          }}
          open={isPreviewOpen}
          onClose={handleClosePreview}
          fullWidth
          maxWidth={'lg'}
          className='bg-[#111111]'
        >
          <DialogTitle className='!relative !flex !min-h-[56px] !items-center !justify-center !bg-[#111] !px-[48px] !py-[12px] !text-[16px] !font-[500] !leading-[16px] text-white'>
            <span className='w-full break-words'>{previewInfo.name}</span>
            <button
              onClick={handleClosePreview}
              className='!absolute !bottom-0 !right-4 !top-0 !my-auto !flex !h-8 !w-8 !items-center !justify-center'
            >
              <X width={16} height={16} color='#fff' />
            </button>
          </DialogTitle>
          <DialogContent className='!flex !w-full scale-100 !items-center !justify-center bg-[#111111] !p-0'>
            <img
              src={previewInfo.url}
              alt='Uploaded'
              style={{ width: '100%', height: '100%', objectFit: 'contain' }}
            />
          </DialogContent>
        </Dialog>
      )}
      <Confirm
        isOpen={isConfirmOpen}
        setIsOpen={setIsConfirmOpen}
        isCloseButton={true}
        onConfirm={() => handleDelete(deleteId)}
        title='1:1 문의 삭제'
        desc={`선택하신 문의 내용을 삭제 하시겠습니까?`}
      ></Confirm>
    </div>
  );
};

export default MyInquiryList;
