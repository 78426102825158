'use client';

import { CircularProgress } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { ChevronLeft, ChevronRight, X } from 'lucide-react';
import * as React from 'react';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

import { FileInfo } from '@carsayo/types';

import DialogWrapper from '.';
import downloadFile from 'utils/downloadFile';
import { cn } from 'utils';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<unknown>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction='left' ref={ref} {...props} />;
});

function DownloadIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
    >
      <path
        d='M5.33325 22.6665V25.3332C5.33325 26.0404 5.6142 26.7187 6.1143 27.2188C6.6144 27.7189 7.29267 27.9998 7.99992 27.9998H23.9999C24.7072 27.9998 25.3854 27.7189 25.8855 27.2188C26.3856 26.7187 26.6666 26.0404 26.6666 25.3332V22.6665'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.33325 14.6665L15.9999 21.3332L22.6666 14.6665'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16 5.3335V21.3335'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
function CloseIcon() {
  return <X strokeWidth={1.2} className='h-8 w-8 text-white' />;
}

type MultiImageViewerProps = {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  initialIndex?: number;
} & (
  | { fileInfo: FileInfo | FileInfo[]; fileUrl?: never } // fileInfo가 있는 경우
  | { fileInfo?: never; fileUrl: string | string[] } // fileUrl이 있는 경우
);

export default function MultiImageViewer({
  isOpen,
  setIsOpen,
  initialIndex,
  fileInfo,
  fileUrl,
}: MultiImageViewerProps) {
  const handleClose = () => {
    setIsOpen(false);
  };

  const [index, setIndex] = React.useState(0);
  React.useEffect(() => {
    setIndex(initialIndex ?? 0);
  }, [initialIndex]);

  const [targetFileInfo, setTargetFileInfo] = React.useState<FileInfo | null>(
    Array.isArray(fileInfo) && fileInfo[index] // fileInfo가 배열이고 index가 유효한 경우
      ? fileInfo[index]
      : !Array.isArray(fileInfo) && fileInfo // fileInfo가 단일 값인 경우
        ? fileInfo
        : null, // fileInfo가 없거나 index가 유효하지 않은 경우
  );
  const [targetFileUrl, setTargetFileUrl] = React.useState<string>(
    Array.isArray(fileUrl) && fileUrl[index] // fileUrl이 배열이고 index가 유효한 경우
      ? fileUrl[index]
      : !Array.isArray(fileUrl) && fileUrl // fileUrl이 단일 값인 경우
        ? fileUrl
        : targetFileInfo?.url ?? '', // fileUrl이 없거나 index가 유효하지 않은 경우
  );

  const totalCount = React.useMemo(() => {
    if (Array.isArray(fileInfo)) {
      return fileInfo.length;
    }
    if (Array.isArray(fileUrl)) {
      return fileUrl.length;
    }
    return 1;
  }, [fileInfo, fileUrl]);

  React.useEffect(() => {
    if (fileInfo && Array.isArray(fileInfo)) {
      setTargetFileInfo(fileInfo[index]);
      setTargetFileUrl(fileInfo[index].url);
    }
    if (fileUrl && Array.isArray(fileUrl)) {
      setTargetFileUrl(fileUrl[index]);
    }
  }, [index, fileUrl, fileInfo]);

  const [downloading, setIsDownloading] = React.useState(false);
  const download = async () => {
    if (downloading) return;
    setIsDownloading(true);
    await downloadFile({
      url: targetFileUrl,
      fileName: targetFileInfo?.name ?? undefined,
    }).finally(() => {
      setIsDownloading(false);
    });
  };

  return (
    <DialogWrapper isOpen={isOpen} setIsOpen={setIsOpen}>
      <Dialog
        fullScreen
        open={isOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0)',
          },
        }}
      >
        <div className='flex h-14 w-full flex-none items-center justify-between gap-4 pl-5 pr-4'>
          <div className='overflow-hidden truncate text-ellipsis text-[16px] font-medium leading-[16px] tracking-[-0.32px] text-white'>
            {totalCount > 1 && (
              <div className='flex items-center justify-center gap-2 text-white'>
                <ChevronLeft
                  className='h-8 w-8 cursor-pointer stroke-1'
                  onClick={() => {
                    if (index > 0) {
                      setIndex(index - 1);
                    }
                  }}
                />
                <div className='flex items-center justify-center text-sm'>
                  <span>{index + 1}</span>
                  <span>/</span>
                  <span>{totalCount}</span>
                </div>
                <ChevronRight
                  className='h-8 w-8 cursor-pointer stroke-1'
                  onClick={() => {
                    if (index < totalCount - 1) {
                      setIndex(index + 1);
                    }
                  }}
                />
              </div>
            )}

            {targetFileInfo?.name ?? ''}
          </div>
          <div className='flex h-14 flex-none items-center justify-between gap-1.5'>
            <button
              className='flex h-8 w-8 items-center justify-center text-white'
              disabled={downloading}
              onClick={download}
            >
              {downloading === false ? (
                <DownloadIcon />
              ) : (
                <CircularProgress size={24} color='inherit' />
              )}
            </button>
            <button onClick={handleClose}>
              <CloseIcon />
            </button>
          </div>
        </div>

        <div className='flex flex-auto items-center justify-center overflow-hidden'>
          <TransformWrapper
            wheel={{ disabled: true }} // disables wheel zoom
            pinch={{ disabled: false }} // enables pinch-zoom
            doubleClick={{ disabled: true }} // disables double-click zoom
            limitToBounds={true}
          >
            <TransformComponent>
              <div
                className={cn(
                  'flex h-[calc(100dvh-56px)] w-[100dvw] items-center justify-center text-white',
                )}
              >
                <img src={targetFileUrl} className='max-h-full max-w-full' />
              </div>
            </TransformComponent>
          </TransformWrapper>
        </div>
      </Dialog>
    </DialogWrapper>
  );
}
