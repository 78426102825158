/* eslint-disable @typescript-eslint/no-unused-vars */
import { useLayoutEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import useSystemStore from 'store/useSystemStore';
import { getManagerCommissionList } from 'apis/commission';
import { cn } from 'utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { OrderCommissionListData, BankCodeData } from '@carsayo/types';
import { CircularProgress } from '@mui/material';
import NoData from 'components/common/v3/NoData';
import SelectV3, { SelectV3Item } from 'components/common/v3/selectv3';
import { ArrowDown } from 'lucide-react';
import { Button } from 'components/ui/button';
import V3_Button from 'components_v3/ui/Button';
import { CarsayoToast } from 'components/common/v2/CarsayoToast';

export default function MypageCommission() {
  const navigate = useNavigate();
  const { setTopTabbarState, setIsBottomTabbar } = useSystemStore();
  const [arrowUp, setArrowUp] = useState(false);

  // 정산내역 데이터 유무 확인용 스테이트
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isData = searchParams.get('isData') ?? 'true';

  const useCommissionList = () => {
    return useQuery({
      queryKey: ['dealerCommissionList'],
      queryFn: async () => {
        const data: OrderCommissionListData[] =
          await getManagerCommissionList();
        return data;
      },
      gcTime: 5 * 60 * 1000, // 5 min
      staleTime: 5 * 1000, // 5 sec
    });
  };
  const { data: commissionList, isFetched } = useCommissionList();

  const filteredCommissionList = useMemo(() => {
    if (!commissionList) return [];
    return commissionList.filter((el) => {
      return el.price;
    });
  }, [commissionList]);

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      title: '수수료 정산',
      rightElement: undefined,
      leftElement: 'back',
    });
    setIsBottomTabbar(false);
  }, []);

  return (
    <>
      {isFetched ? (
        <div className=''>
          {/* 기존에 기능이 들어있는게 있었어서 혹시 몰라 주석처리 합니다. */}
          {filteredCommissionList.length > 0 ? (
            filteredCommissionList.map((item) => (
              <div className='border-b pb-8' key={item.id}>
                <div className='flex gap-2 pt-6 pb-3 items-stretch'>
                  <div
                    className={cn(
                      'flex-none mt-0.5 h-5 px-1 text-[11px] leading-5 text-white',
                      item.state === 'paid' && 'bg-[#2F66BA]',
                      item.state === 'sended' && 'bg-red-500',
                      item.state === 'canceled' && 'bg-gray-700',
                    )}
                  >
                    {item.state === 'paid'
                      ? '납부완료'
                      : item.state === 'sended'
                        ? '미납부'
                        : item.state === 'canceled'
                          ? '취소'
                          : '미청구'}
                  </div>
                  <div
                    onClick={() => {
                      navigate(`/quote/manager/detail/${item.order.id}`);
                    }}
                    className='flex flex-auto items-center gap-2 text-base font-semibold underline'
                  >
                    {item.order.title}
                  </div>
                </div>
                <div className='border'>
                  <div className='flex items-center border-b'>
                    <div className='w-[90px] shrink-0 bg-[#F7F7FB] p-4'>
                      고지일
                    </div>
                    <div className='p-4 text-[#767676]'>
                      {dayjs(item.asked_at).format('YYYY-MM-DD')}
                    </div>
                  </div>
                  {item.state === 'sended' &&
                    item.bankCode &&
                    item.accountNumber && (
                      <>
                        <div className='flex items-center border-b'>
                          <div className='w-[90px] shrink-0 bg-[#F7F7FB] p-4'>
                            입금계좌
                          </div>
                          <div className='p-4 text-[#767676]'>
                            {
                              BankCodeData.find((el) => {
                                return el.id === item.bankCode;
                              })?.name
                            }{' '}
                            {item.accountNumber}
                          </div>
                        </div>
                        <div className='flex items-center border-b'>
                          <div className='w-[90px] shrink-0 bg-[#F7F7FB] p-4'>
                            예금주명
                          </div>
                          <div className='p-4 text-[#767676]'>
                            {item.ownerName}
                          </div>
                        </div>
                      </>
                    )}
                  {item.paid_at && (
                    <div className='flex items-center border-b'>
                      <div className='w-[90px] shrink-0 bg-[#F7F7FB] p-4'>
                        납부확인
                      </div>
                      <div className='p-4 text-[#767676]'>
                        {dayjs(item.paid_at).format('YYYY-MM-DD')}
                      </div>
                    </div>
                  )}
                  {item.canceled_at && (
                    <div className='flex items-center border-b'>
                      <div className='w-[90px] shrink-0 bg-[#F7F7FB] p-4'>
                        취소일
                      </div>
                      <div className='p-4 text-[#767676]'>
                        {dayjs(item.canceled_at).format('YYYY-MM-DD')}
                      </div>
                    </div>
                  )}
                  <div className='flex items-center'>
                    <div className='w-[90px] shrink-0 bg-[#F7F7FB] p-4'>
                      금액
                    </div>
                    <div className='p-4 text-[#767676]'>
                      {item.price ? item.price.toLocaleString() + '원' : ''}
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className='w-full min-h-[calc(100dvh-160px)] flex justify-center items-center pb-[20%]'>
              <NoData text='수수료 정산 내역이 존재하지 않습니다.' />
            </div>
          )}
        </div>
      ) : (
        <div className='flex h-full justify-center items-center'>
          <CircularProgress></CircularProgress>
        </div>
      )}

      {/* {isData === 'true' ? (
            // 정산내역 있을때
            // <div className='w-full pt-[30px] px-5 pb-[50px] bg-[#F3F6F9] min-h-[calc(100dvh-60px)]'>
            //   <div className='mb-5'>
            //     <div className='text-[20px] leading-[32px] font-[600] mb-6'>
            //       2건의 정산내역이 있어요.
            //     </div>
            //     <div className='w-full flex justify-between items-center'>
            //       <div className='max-w-[70px]'>
            //         <SelectV3
            //           value={'전체'}
            //           type='noBorder'
            //           onValueChange={() => {}}
            //         >
            //           <SelectV3Item value={'전체'}>전체</SelectV3Item>
            //         </SelectV3>
            //       </div>

            //       <button
            //         onClick={() => setArrowUp(!arrowUp)}
            //         className='text-[14px] leading-[14px] font-[600] flex justify-center items-center gap-1 text-[#555555]'
            //       >
            //         최신일자
            //         <ArrowDown
            //           width={16}
            //           height={16}
            //           color='#7273F9'
            //           strokeWidth={2}
            //           className={cn(
            //             'transition-all',
            //             arrowUp ? 'rotate-180' : 'rotate-0',
            //           )}
            //         />
            //       </button>
            //     </div>
            //   </div>
              // 대략적인 타입을 파악하지 못해 따로 분리 안했습니다! li 태그가 카드라고 보시면 됩니다! 
          //     <ul className='w-full grid grid-cols-1 gap-5'>
          //       <li className='pt-6 px-5 pb-4 bg-white w-full rounded-[16px]'>
          //         <div className='mb-6'>
          //           <div className='pb-5 border-b border-[#E5EAEE] mb-5'>
          //             <span className='text-[14px] leading-[22.4px] font-[500] text-[#555555]'>
          //               할부
          //             </span>
          //             <div className='mt-0.5 text-[15px] leading-[24px] font-[600]'>
          //               기아 GLE-클래스 24년식 기아 GLE-클래스 24년식 기아
          //               GLE-클래스 24년식
          //             </div>
          //           </div>
          //           <ul className='text-[15px] leading-[15px] font-[400] flex flex-col justify-start items-start gap-[14px]'>
          //             <li className='flex justify-start items-center'>
          //               <span className='min-w-[75px]'>고지일자</span>
          //               <span className='font-[500]'>2025.01.03</span>
          //             </li>
          //             <li className='flex justify-start items-center'>
          //               <span className='min-w-[75px]'>납부확인</span>
          //               <span className='font-[500]'>2025.01.04</span>
          //             </li>
          //             <li className='flex justify-start items-center'>
          //               <span className='min-w-[75px]'>납부금액</span>
          //               <span className='font-[500]'>200,000원</span>
          //             </li>
          //             <li className='flex justify-start items-center'>
          //               <span className='min-w-[75px]'>납부상태</span>
          //               <span className='font-[500] text-[#4860FF]'>
          //                 납부완료
          //               </span>
          //             </li>
          //           </ul>
          //         </div>
          //         <V3_Button
          //           theme='gray'
          //           className='!h-[50px] !text-[15px] !leading-[15px] !font-[600]'
          //         >
          //           견적 상세보기
          //         </V3_Button>
          //       </li>
          //       <li className='pt-6 px-5 pb-4 bg-white w-full rounded-[16px]'>
          //         <div className='mb-6'>
          //           <div className='pb-5 border-b border-[#E5EAEE] mb-5'>
          //             <span className='text-[14px] leading-[22.4px] font-[500] text-[#555555]'>
          //               할부
          //             </span>
          //             <div className='mt-0.5 text-[15px] leading-[24px] font-[600]'>
          //               기아 GLE-클래스 24년식 기아 GLE-클래스 24년식 기아
          //               GLE-클래스 24년식
          //             </div>
          //           </div>
          //           <ul className='text-[15px] leading-[15px] font-[400] flex flex-col justify-start items-start gap-[14px]'>
          //             <li className='flex justify-start items-center'>
          //               <span className='min-w-[75px]'>고지일자</span>
          //               <span className='font-[500]'>2025.01.01</span>
          //             </li>
          //             <li className='flex justify-start items-start leading-[22.5px] -mt-1'>
          //               <span className='min-w-[75px]'>입금계좌</span>
          //               <span className='font-[500]'>
          //                 국민은행{' '}
          //                 <span
          //                   className='underline'
          //                   onClick={() => {
          //                     navigator.clipboard
          //                       .writeText('450901010451261')
          //                       .then(() => {
          //                         CarsayoToast.success('복사했습니다.');
          //                       })
          //                       .catch((err) => {
          //                         console.error('Failed to copy: ', err);
          //                       });
          //                   }}
          //                 >
          //                   450901010451261
          //                 </span>
          //               </span>
          //             </li>
          //             <li className='flex justify-start items-center'>
          //               <span className='min-w-[75px]'>예금주명</span>
          //               <span className='font-[500]'>카사요(주)</span>
          //             </li>
          //             <li className='flex justify-start items-center'>
          //               <span className='min-w-[75px]'>납부금액</span>
          //               <span className='font-[500]'>10,000원</span>
          //             </li>
          //             <li className='flex justify-start items-center'>
          //               <span className='min-w-[75px]'>납부상태</span>
          //               <span className='font-[500] text-[#FF4747]'>
          //                 미납부
          //               </span>
          //             </li>
          //           </ul>
          //         </div>
          //         <V3_Button
          //           theme='gray'
          //           className='!h-[50px] !text-[15px] !leading-[15px] !font-[600]'
          //         >
          //           견적 상세보기
          //         </V3_Button>
          //       </li>
          //     </ul>
          //   </div>
          // ) : (
            // 정산내역 없을때
          //   <div className='w-full min-h-[calc(100dvh-160px)] flex justify-center items-center pb-[20%]'>
          //     <NoData text='수수료 정산 내역이 존재하지 않습니다.' />
          //   </div>
          // )}

          {/* )} */}
    </>
  );
}
