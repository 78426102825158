/* eslint-disable @typescript-eslint/no-unused-vars */
import dayjs from 'dayjs';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { cn } from 'utils';
import { parsePaymentState } from 'utils/textConvert';
import RefundInfo from './RefundInfo';
import V3_Confirm from 'components_v3/dialog/Confirm';

export default function PayCard({
  item,
  refetch,
}: {
  item: any;
  refetch: () => void;
}) {
  const today = dayjs();
  const expireDateObj =
    item.virtualBank && item.status === 'virtualAccountWaiting'
      ? dayjs(item.virtualBank.expireDate, 'YYYYMMDD')
      : null;
  const diffInDays = expireDateObj ? expireDateObj.diff(today, 'day') : null;
  const isCancelable =
    item.status === 'paid' &&
    item.paid_at &&
    dayjs().diff(dayjs(item.paid_at), 'day') <= 7;

  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [isRefundInfoOpen, setIsRefundInfoOpen] = useState(false);

  const getMethodType = (method: string) => {
    switch (method) {
      case 'CARD':
        return '신용카드';
      case 'BANK':
        return '계좌이체';
      case 'CELLPHONE':
        return '휴대폰 결제';
      case 'VBANK':
        return '가상계좌';
      default:
        return '';
    }
  };

  const containerVariants = {
    hidden: { opacity: 0, x: -100 },
    show: {
      opacity: 1,
      x: 0,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const handleCancelPaid = () => {
    setIsRefundInfoOpen(true);
  };

  return (
    <div className='w-full py-6 px-[20px] bg-white rounded-[16px]'>
      <div className='pb-[20px] mb-[20px] border-b border-[#E5EAEE] flex justify-start items-center gap-4'>
        <div className='w-[60px] h-[60px] shrink-0 rounded-[6.7px] bg-[#F3F6F9] flex justify-center items-center'>
          <img
            src={item.imageUrl}
            alt='카사요 카드 이미지'
            className='w-[46px] object-contain'
          />
        </div>
        <div className='w-full flex flex-col justify-start items-start gap-[2px]'>
          <strong className='w-full flex justify-start items-center text-[15px] leading-[24px] font-[600]'>
            {item.title}
          </strong>
          <p className='w-full flex justify-start items-center text-[14px] leading-[22.4px] font-[500] text-[#555] break-keep'>
            {item.description}
          </p>
        </div>
      </div>
      <ul className='flex flex-col items-start justify-start gap-[14px] text-[15px] font-[400] leading-[15px]'>
        <li className='flex items-start justify-start'>
          <span className='min-w-[75px]'>결제금액</span>
          <strong className='font-[500]'>{`${item.amount.toLocaleString()}원`}</strong>
        </li>
        <li className='flex items-start justify-start'>
          <span className='min-w-[75px]'>
            {item.status === 'canceled' ? '취소일시' : '결제일시'}
          </span>
          <strong className='font-[500]'>
            {dayjs(
              item.status === 'canceled' ? item.canceled_at : item.updated_at,
            ).format('YYYY.MM.DD HH:mm')}
          </strong>
        </li>
        <li className='flex items-start justify-start'>
          <span className='min-w-[75px]'>결제방법</span>
          {item.virtualBank ? (
            <div className='mt-[-3.5px] flex flex-col justify-start items-start gap-1 text-[15px] leading-[22.5px] font-[500]'>
              <span>가상계좌 : {item.virtualBank.bankName}</span>
              <span>
                입금기한 :{' '}
                {`${dayjs(item.virtualBank.expireDate).format('YYYY.MM.DD')} ${
                  diffInDays !== null ? `(${diffInDays}일)` : ''
                }`}
              </span>
              <span>예금주 : 카사요(주)</span>
            </div>
          ) : (
            <strong className='font-[500]'>{getMethodType(item.method)}</strong>
          )}
        </li>
        <li className='flex items-start justify-start'>
          <span className='min-w-[75px]'>결제상태</span>
          <strong
            className={cn(
              'font-[600]',
              item.status === 'used' ? 'text-[#4860FF]' : 'text-[#CE8D00]',
            )}
          >
            {parsePaymentState(item.status)}
          </strong>
        </li>
      </ul>
      {/* 우선 히든 */}
      {/* {isCancelable && (
        <div className='flex justify-end items-center mt-[20px]'>
          <button
            className='flex justify-center items-center text-[16px] leading-[16px] font-[500] h-[44px] py-[14px] px-4 rounded-[10px] border border-[#E5E5EC]'
            type='button'
            onClick={() => setIsConfirmOpen(true)}
          >
            결제 취소
          </button>
        </div>
      )} */}
      <AnimatePresence>
        {isRefundInfoOpen && (
          <motion.div
            key={'refundInfo'}
            variants={containerVariants}
            initial='hidden'
            animate='show'
            exit='hidden'
            className='fixed left-0 top-0 w-full z-[3]'
          >
            <RefundInfo
              setIsOpen={setIsRefundInfoOpen}
              item={item}
              refetch={refetch}
            />
          </motion.div>
        )}
      </AnimatePresence>

      <V3_Confirm
        onClose={() => {
          setIsConfirmOpen(!isConfirmOpen);
        }}
        onConfirm={() => {
          handleCancelPaid();
          setIsConfirmOpen(!isConfirmOpen);
        }}
        isOpen={isConfirmOpen}
        setIsOpen={setIsConfirmOpen}
        disableBackdropClose={true}
        title={''}
        agreeText={'결제취소'}
        className='!pb-[50px] !pt-[44px]'
      >
        <div className='flex flex-col items-center justify-center gap-5'>
          <div className='flex flex-col items-center justify-center gap-1.5'>
            <div className='text-[#222] text-center font-[700] text-[16px] leading-[25.6px] tracking-[-0.32px] [font-feature-settings: "liga" off, "clig" off]'>
              결제 취소
            </div>
            <div className='text-[#222] text-center font-[500] text-[15px] leading-[24px] tracking-[-0.3px] [font-feature-settings: "liga" off, "clig" off]'>
              {`결제를 취소 하시겠습니까?
가상계좌의 경우 입력하신 환불계좌로
영업일 기준 3~5일 이내로 입금됩니다.`}
            </div>
          </div>
        </div>
      </V3_Confirm>
    </div>
  );
}
