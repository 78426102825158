import { Holiday, RepairShop } from '@carsayo/types';
import { getTodayKey } from '../pages/init/tabs/success';
import dayjs from 'dayjs';

/** 공휴일 데이터 삽입 필요 */
export default function is영업중(
  repairShop: RepairShop,
  holiday: Holiday[],
): boolean {
  /** 금일 날짜 영업 시간으로 필터링 */
  const todayKey = getTodayKey();
  const currentHHMM = dayjs().tz('Asia/Seoul').format('HHmm');
  const workingTime = repairShop.workingTime;

  let isWorkingTime: boolean = true;
  const isWorkingMode = repairShop.working_status === 'open';

  const today = dayjs().tz('Asia/Seoul').format('YYYYMMDD');
  const isHoliday = Boolean(holiday.find((el) => el.locdate === today));

  /** 공휴일일 경우 공휴일 체크만 합니다. */
  if (isHoliday) {
    const todayWorkingTime = workingTime?.holiday;

    if (!todayWorkingTime) {
      return false;
    } else {
      if (Number(currentHHMM) < Number(todayWorkingTime.start)) {
        return false;
      }
      if (Number(currentHHMM) > Number(todayWorkingTime.end)) {
        return false;
      }
    }

    return isWorkingMode && isWorkingTime;
  }

  if (workingTime && workingTime[todayKey]) {
    const todayWorkingTime = workingTime[todayKey]!;

    if (Number(currentHHMM) < Number(todayWorkingTime.start)) {
      isWorkingTime = false;
    }
    if (Number(currentHHMM) > Number(todayWorkingTime.end)) {
      isWorkingTime = false;
    }
  } else {
    isWorkingTime = false;
  }

  return isWorkingMode && isWorkingTime;
}
