/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { CarsayoNotification } from '@carsayo/types';
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import {
  getNotificationList,
  readNotification,
  readWholeNotification,
} from 'apis/notification';
import NoData from 'components/common/v3/NoData';
import dayjs from 'dayjs';
import { useLayoutEffect, useMemo, useState } from 'react';
import useSystemStore from 'store/useSystemStore';
import InfiniteScroll from 'react-infinite-scroll-component';
import { cn } from 'utils';
import { useNavigate } from 'react-router-dom';
import useMemberStore from 'store/useMemberStore';

const SETTING = {
  take: 20,
};

export default function NotificationPage() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { setTopTabbarState, setIsBottomTabbar } = useSystemStore();
  const [tab, setTab] = useState<'all' | 'unread' | 'read'>('all');

  const { loginMember, setLoginMember } = useMemberStore();

  const { data, fetchNextPage, hasNextPage, refetch } = useInfiniteQuery({
    queryKey: ['notification', tab],
    queryFn: async ({ pageParam = 1 }) => {
      return await getNotificationList({
        skip: pageParam * SETTING.take,
        take: SETTING.take,
        isReaded: tab === 'all' ? undefined : tab === 'unread' ? false : true,
      });
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.length < SETTING.take) return undefined;
      else return allPages.length;
    },
    placeholderData: (previousData, previousQuery) => previousData,
  });

  const handleReadWholeNotification = async () => {
    await readWholeNotification();
    refetch();
    if (loginMember) {
      setLoginMember({
        ...loginMember,
        count: {
          ...loginMember.count,
          notification: {
            ...loginMember.count.notification,
            unreadCount: 0,
          },
        },
      });
    }
  };

  const handleReadNotification = async (noti: CarsayoNotification) => {
    if (!noti.isReaded) {
      await readNotification({ notificationId: noti.id });
      /** 안읽은 알림 개수 빼기 */
      if (loginMember) {
        setLoginMember({
          ...loginMember,
          count: {
            ...loginMember.count,
            notification: {
              ...loginMember.count.notification,
              unreadCount: loginMember.count.notification.unreadCount - 1,
            },
          },
        });
      }
    }

    if (noti.path.app) {
      navigate(noti.path.app);
    }
  };

  const filteredNotiList: CarsayoNotification[] = useMemo(() => {
    return (data?.pages.flatMap((page) => page) ?? []).filter((el) => {
      if (tab === 'all') return true;
      if (tab === 'unread') return el.isReaded === false;
      if (tab === 'read') return el.isReaded === true;
    });
  }, [data, tab]);

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      title: '알림',
      rightElement: undefined,
      leftElement: 'back',
    });
    setIsBottomTabbar(false);
  }, []);

  return (
    <div className='w-full flex flex-col h-full bg-[#F3F6F9] px-[20px] py-4'>
      <div className='mb-[30px] flex w-full items-center justify-between'>
        <ul className='flex items-center justify-start gap-[6px] text-[15px] font-[500] leading-[15px]'>
          <button
            onClick={() => {
              setTab('all');
            }}
            className={cn(
              'flex h-[40px] w-[64px] items-center justify-center rounded-[100px] border border-[#E5E5EC] bg-white px-[14px] py-[10px] transition-all',
              tab === 'all' && 'border-[#7273F9] bg-[#7273F9] text-white',
            )}
          >
            전체
          </button>
          <button
            onClick={() => setTab('unread')}
            className={cn(
              'flex h-[40px] w-[99px] items-center justify-center whitespace-nowrap rounded-[100px] border border-[#E5E5EC] bg-white px-[14px] py-[10px] transition-all',
              tab === 'unread' && 'border-[#7273F9] bg-[#7273F9] text-white',
            )}
          >
            안 읽은 알림
          </button>
        </ul>
        <button
          onClick={handleReadWholeNotification}
          className='text-[15px] font-[500] leading-[15px]'
        >
          모두 읽음
        </button>
      </div>

      {filteredNotiList && filteredNotiList.length > 0 ? (
        <div
          id='scrollableDiv_k4921'
          className='w-full flex-auto overflow-auto'
        >
          <InfiniteScroll
            next={fetchNextPage}
            hasMore={hasNextPage}
            loader={
              <div className='font-semibold text-center pb-4'>
                정보를 불러오는 중입니다..
              </div>
            }
            dataLength={10000}
            scrollableTarget='scrollableDiv_k4921'
            className='flex-col items-start justify-start gap-4 flex'
          >
            {filteredNotiList.map((noti, index) => (
              <li
                onClick={() => handleReadNotification(noti)}
                className={cn(
                  'relative flex w-full flex-col items-start justify-start gap-2 rounded-[16px] border border-transparent bg-white px-[20px] py-6 shadow-[0px_6px_20px_#0000000D]',
                  noti.isReaded === false && 'border-[#7273F9] bg-[#F0F0FF]',
                )}
                key={`${noti.title}_${index}`}
              >
                <strong className='text-[16px] font-[600] leading-[24px]'>
                  {noti.title}
                </strong>
                <p className='mb-[6px] whitespace-pre-line text-[15px] font-[500] leading-[24px] text-[#555555]'>
                  {noti.content}
                </p>
                <span className='text-[13px] font-[500] leading-[18.2px] text-[#666666]'>
                  {dayjs(noti.created_at).format('YYYY.MM.DD')}
                </span>
                {noti.isReaded === false && (
                  <span className='absolute right-[12px] top-[12px] h-2 w-2 rounded-full bg-[#7273F9]'></span>
                )}
              </li>
            ))}
          </InfiniteScroll>
        </div>
      ) : (
        <div className='mb-[30px] flex h-full w-full items-center justify-center'>
          <NoData
            imgUrl='/assets/images/v2/noFaq.png'
            text='알림 내역이 없어요'
          />
        </div>
      )}
    </div>
  );
}
