import ConfirmV2 from 'components/common/v3/confirm';
import { InputV2 } from 'components/common/v3/inputv2';
import { useLayoutEffect, useState } from 'react';
import useSystemStore from 'store/useSystemStore';

export default function UserPasswordChange() {
  const { setTopTabbarState } = useSystemStore();
  const [pwType, setPwType] = useState('password');
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      title: '비밀번호 변경',
      rightElement: undefined,
      leftElement: 'back',
    });
  }, []);
  return (
    <div className='min-h-[calc(100dvh-60px)] px-[20px] pt-[50px] pb-[10px] w-full flex flex-col justify-between items-start'>
      <div className='w-full flex flex-col justify-start items-start gap-6'>
        <div className='w-full flex flex-col justify-start items-start gap-[12px]'>
          <span className='text-[15px] leading-[15px] font-[400]'>
            현재 비밀번호
          </span>
          <div className='relative w-full'>
            <InputV2
              type={pwType}
              placeholder='현재 비밀번호를 입력해 주세요.'
              className='placeholder:text-[#999999] pr-[50px]'
            />
            <button
              onClick={() => {
                pwType === 'password'
                  ? setPwType('text')
                  : setPwType('password');
              }}
              className='flex justify-center items-center w-6 h-6 absolute right-[13px] top-1/2 transform -translate-y-1/2 focus:outline-none focus:ring-0'
            >
              {pwType === 'password' ? (
                <img
                  src='/assets/images/v2/hiddenPw.png'
                  className='w-full object-contain'
                />
              ) : (
                <img
                  src='/assets/images/v2/visiblePw.png'
                  className='w-full object-contain'
                />
              )}
            </button>
          </div>
        </div>
        <div className='w-full flex flex-col justify-start items-start gap-[12px]'>
          <span className='text-[15px] leading-[15px] font-[400]'>
            새 비밀번호
          </span>
          <div className='relative w-full'>
            <InputV2
              type={pwType}
              placeholder='영문, 숫자로 6자리 이상 입력해 주세요.'
              className='placeholder:text-[#999999] pr-[50px]'
            />
            <button
              onClick={() => {
                pwType === 'password'
                  ? setPwType('text')
                  : setPwType('password');
              }}
              className='flex justify-center items-center w-6 h-6 absolute right-[13px] top-1/2 transform -translate-y-1/2 focus:outline-none focus:ring-0'
            >
              {pwType === 'password' ? (
                <img
                  src='/assets/images/v2/hiddenPw.png'
                  className='w-full object-contain'
                />
              ) : (
                <img
                  src='/assets/images/v2/visiblePw.png'
                  className='w-full object-contain'
                />
              )}
            </button>
          </div>
        </div>
        <div className='w-full flex flex-col justify-start items-start gap-[12px]'>
          <span className='text-[15px] leading-[15px] font-[400]'>
            새 비밀번호 확인
          </span>
          <div className='relative w-full'>
            <InputV2
              type={pwType}
              placeholder='새 비밀번호를 다시 입력해 주세요.'
              className='placeholder:text-[#999999] pr-[50px]'
            />
            <button
              onClick={() => {
                pwType === 'password'
                  ? setPwType('text')
                  : setPwType('password');
              }}
              className='flex justify-center items-center w-6 h-6 absolute right-[13px] top-1/2 transform -translate-y-1/2 focus:outline-none focus:ring-0'
            >
              {pwType === 'password' ? (
                <img
                  src='/assets/images/v2/hiddenPw.png'
                  className='w-full object-contain'
                />
              ) : (
                <img
                  src='/assets/images/v2/visiblePw.png'
                  className='w-full object-contain'
                />
              )}
            </button>
          </div>
        </div>
      </div>
      <button
        onClick={() => setIsConfirmOpen(!isConfirmOpen)}
        className='flex justify-center items-center h-[50px] w-full text-white bg-[#7273F9] rounded-[8px] text-[15px] leading-[15px] font-[500] disabled:bg-[#B2B7BB]'
      >
        변경하기
      </button>
      <ConfirmV2
        title={'비밀번호가 일치하지 않습니다.'}
        isOpen={isConfirmOpen}
        setIsOpen={setIsConfirmOpen}
      />
    </div>
  );
}
