/* eslint-disable @typescript-eslint/no-unused-vars */
import { getTerm, getTermCategoryInfo } from 'apis/setting';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
} from 'components/ui/drawer';
import { ChevronLeft, Loader2, X } from 'lucide-react';
import { Button } from 'components/ui/button';
import { TermCategoryId } from '@carsayo/types';
import dayjs from 'dayjs';
import SelectV3, { SelectV3Item } from 'components/common/v3/selectv3';
import { useQuery } from '@tanstack/react-query';
import useMemberStore from 'store/useMemberStore';
import ReactHtmlParser from 'react-html-parser';

function formatNumber(num: number) {
  // If the number is an integer, format it to one decimal place
  if (Number.isInteger(num)) {
    return num.toFixed(1);
  }
  // Otherwise, return the number as it is
  return num.toString();
}

function dealer_경고및계정정지정책() {
  const { loginMember } = useMemberStore();
  const [dealerWarning, setDealerWarning] = useState<string>();
  const navigate = useNavigate();

  useEffect(() => {
    const htmlURL =
      loginMember?.dealerInfo?.type === 'newcar'
        ? '/assets/dealerWarning/dealer_warning_newcar.htm'
        : loginMember?.dealerInfo?.type === 'usedcar'
          ? '/assets/dealerWarning/dealer_warning_usedcar.htm'
          : loginMember?.dealerInfo?.type === 'leaseRent'
            ? '/assets/dealerWarning/dealer_warning_leaserentcar.htm'
            : undefined;

    if (htmlURL) {
      fetch(htmlURL)
        .then((response) => response.text())
        .then((data) => setDealerWarning(data))
        .catch((error) =>
          console.error('Error fetching the HTML file:', error),
        );
    } else {
      setDealerWarning(undefined);
    }
  }, []);

  return (
    <>
      <div className='relative w-full h-full flex-1 bg-white px-5 overflow-y-auto pb-[50px] pt-[30px]'>
        <>
          <div className='w-full flex flex-col justify-start items-start gap-4 pb-[30px] border-b-[2px] border-[#222]'>
            <strong className='text-[20px] leading-[20px] font-[600]'>
              경고 및 계정 정지 정책
            </strong>
          </div>

          <div className='w-full flex-1 overflow-y-auto pt-[30px]'>
            {dealerWarning && ReactHtmlParser(dealerWarning)}
          </div>
        </>
      </div>
    </>
  );
}

export default function TermDetailPage() {
  const { setTopTabbarState } = useSystemStore();
  const { id: termCategoryId } = useParams();
  const navigate = useNavigate();

  if (termCategoryId === 'warning') {
    return dealer_경고및계정정지정책();
  }

  const [targetTermId, setTargetTermId] = useState<string | undefined>();
  const [isOpen, setIsOpen] = useState(false);
  const { data: TermCategoryData } = useQuery({
    queryKey: ['termCategoryDetail', termCategoryId],
    queryFn: async () => {
      if (!termCategoryId) return null;
      const termCategoryData = await getTermCategoryInfo({
        termCategoryId: Number(termCategoryId) as TermCategoryId,
        termId: targetTermId,
      });

      return termCategoryData;
    },
    enabled: !!termCategoryId,
  });
  const { data: TermData, isLoading: isTermLoading } = useQuery({
    queryKey: ['termDetail', termCategoryId, targetTermId],
    queryFn: async () => {
      if (!termCategoryId) return null;
      const termData = await getTerm({
        termCategoryId: Number(termCategoryId) as TermCategoryId,
        termId: targetTermId,
      });

      return termData;
    },
    enabled: !!termCategoryId && !!TermCategoryData,
  });

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'hidden',
      mode: 'subpage',
      title: ' ',
      rightElement: undefined,
      leftElement: 'back',
    });
  }, []);

  return (
    <>
      <div className='flex h-[60px] items-center justify-between px-5'>
        <div className='min-w-5'>
          <a onClick={() => navigate(-1)}>
            <ChevronLeft
              width={32}
              height={32}
              strokeWidth={1.5}
              className='w-[32px] object-contain'
            />
          </a>
        </div>
        <div className='text-[20px] leading-[20px] font-[600]'>
          {/* {TermCategoryData?.title ?? ''} */}{' '}
        </div>
        <div className='min-w-5'>
          {TermCategoryData ? (
            <SelectV3
              type='term'
              value={TermData ? TermData?.id : ''}
              onValueChange={(data) => {
                setTargetTermId(data);
              }}
              placeholder=''
            >
              {TermCategoryData.termList.map((el) => {
                return (
                  <SelectV3Item key={`termVersion_${el.version}`} value={el.id}>
                    v{formatNumber(el.version)}
                  </SelectV3Item>
                );
              })}
            </SelectV3>
          ) : (
            <div className='w-[80px]'></div>
          )}
        </div>
      </div>
      <div className='relative w-full h-full flex-1 bg-white px-5 overflow-y-auto pb-[50px] pt-[30px]'>
        {isTermLoading || !TermData ? (
          <div className='w-full flex flex-col justify-center items-center h-full'>
            <Loader2 className='h-8 w-8 animate-spin' />
          </div>
        ) : (
          <>
            <div className='w-full flex flex-col justify-start items-start gap-4 pb-[30px] border-b-[2px] border-[#222]'>
              <strong className='text-[20px] leading-[20px] font-[600]'>
                {TermCategoryData?.title ?? '약관'}
              </strong>
              <span className='text-[15px] leading-[15px] font-[500] text-[#999]'>
                {TermData?.created_at
                  ? dayjs(TermData.created_at).format('YYYY.MM.DD')
                  : '등록일 없음'}
              </span>
            </div>

            <div
              className='w-full flex-1 overflow-y-auto pt-[30px]'
              dangerouslySetInnerHTML={{ __html: TermData?.content_html ?? '' }}
            ></div>

            <Drawer open={isOpen} onOpenChange={(open) => setIsOpen(open)}>
              <DrawerContent className='flex flex-col'>
                <DrawerHeader className='relative'>
                  <DrawerTitle>버전 선택</DrawerTitle>
                  <DrawerClose asChild>
                    <Button className='absolute right-5 top-4 h-7 w-7 border-0 bg-transparent p-0 text-[#111111]'>
                      <X className='h-7 w-7' />
                    </Button>
                  </DrawerClose>
                </DrawerHeader>
                <div className='px-4 pb-4'>
                  <div className='border-t'>
                    {TermCategoryData?.termList &&
                      TermCategoryData.termList.map((el) => {
                        return (
                          <div
                            key={el.id}
                            onClick={() => {
                              setTargetTermId(el.id);
                              setIsOpen(false);
                            }}
                            className='mt-4 flex h-[52px] w-full items-center justify-center border text-base font-medium'
                          >
                            V{formatNumber(el.version)}
                          </div>
                        );
                      })}
                  </div>
                </div>
              </DrawerContent>
            </Drawer>
          </>
        )}
      </div>
    </>
  );
}
