/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import AskCardLayout from '../component/AskCardLayout';
import { CustomerActionInterface } from '../ask';
import { useNavigate } from 'react-router-dom';
interface CargipsaMenuCardInterface {
  customerAction: CustomerActionInterface | null;
  setCustomerAction: (data: CustomerActionInterface) => void;
  nowTime: Date;
}

const CargipsaMenuCard = ({
  customerAction,
  setCustomerAction,
  nowTime,
}: CargipsaMenuCardInterface) => {
  const navigate = useNavigate();
  return (
    <AskCardLayout
      chatIcon='/assets/images/v2/chat-cargipsa-icon.png'
      chatName='카집사'
      time={nowTime}
      title='안녕하세요, 카집사입니다!'
    >
      <div className='w-full'>
        <p className='mb-[14px] text-[15px] leading-[22.5px] text-[#555555]'>
          궁금하신 사항의 메뉴를 선택해주세요.
        </p>
        <div className='w-full flex flex-col justify-start items-start gap-[10px]'>
          {/* 고객센터 박스 */}
          <div className='w-full bg-[#FFF3D4] rounded-[10px] p-[12px] pt-4'>
            <h4 className='text-[16px] font-[600] mb-4 pl-[4px]'>고객센터</h4>
            <ul className='w-full flex flex-col gap-[6px]'>
              <li className='w-full'>
                <button
                  className='w-full pr-7 flex justify-start items-center relative bg-white p-[10px] rounded-[6px] gap-[10px] shadow-[0px_6px_10px_#1111110F]'
                  onClick={
                    // () => navigate('/ask/theme/cargipsa/inquiry')
                    () => {
                      setCustomerAction({
                        id: 'inquiryAddOpen',
                        textShow: false,
                        text: '1:1문의 등록 열기',
                        actionReturn: 'card',
                        time: new Date(),
                      });
                    }
                  }
                >
                  <div className='shrink-0 bg-[#FFE7B8] w-[28px] h-[28px] border border-[#F8D795] rounded-full flex justify-center items-center'>
                    <img
                      src='/assets/images/v2/customer-service-icon01.png'
                      alt='문의아이콘'
                      className='w-[18px] h-[18px] object-contain'
                    />
                  </div>
                  <span>1:1 문의</span>
                  <img
                    src='/assets/images/v2/export.png'
                    alt='내보내기아이콘'
                    className='absolute right-[10px] bottom-0 top-0 my-auto w-4 object-contain'
                  />
                </button>
              </li>
              <li className='w-full'>
                <button
                  className='w-full pr-7 flex justify-start items-center relative bg-white p-[10px] rounded-[6px] gap-[10px] shadow-[0px_6px_10px_#1111110F]'
                  onClick={() => {
                    setCustomerAction({
                      id: 'emergencyClick',
                      textShow: false,
                      text: '고장 긴급출동',
                      actionReturn: 'card',
                      time: new Date(),
                    });
                  }}
                >
                  <div className='shrink-0 bg-[#FEE3E3] w-[28px] h-[28px] border border-[#EA8C8C33] rounded-full flex justify-center items-center'>
                    <img
                      src='/assets/images/v2/customer-service-icon02.png'
                      alt='고장출동아이콘'
                      className='w-[18px] h-[18px] object-contain'
                    />
                  </div>
                  <span>고장(긴급) 출동</span>
                </button>
              </li>
              <li className='w-full'>
                <button
                  className='w-full pr-7 flex justify-start items-center relative bg-white p-[10px] rounded-[6px] gap-[10px] shadow-[0px_6px_10px_#1111110F]'
                  onClick={() => {
                    setCustomerAction({
                      id: 'accidentOpen',
                      textShow: false,
                      text: '사고접수',
                      actionReturn: 'card',
                      time: new Date(),
                    });
                  }}
                >
                  <div className='shrink-0 bg-[#FFDDCA] w-[28px] h-[28px] border border-[#EBC7B3] rounded-full flex justify-center items-center'>
                    <img
                      src='/assets/images/v2/customer-service-icon03.png'
                      alt='접수아이콘'
                      className='w-[18px] h-[18px] object-contain'
                    />
                  </div>
                  <span>사고 접수</span>
                  <img
                    src='/assets/images/v2/export.png'
                    alt='내보내기아이콘'
                    className='absolute right-[10px] bottom-0 top-0 my-auto w-4 object-contain'
                  />
                </button>
              </li>
              {/* 병원 주석처리 */}
              {/* <li className='w-full'>
                <button
                  className='w-full pr-7 flex justify-start items-center relative bg-white p-[10px] rounded-[6px] gap-[10px]'
                  onClick={() => navigate('/ask/hospital')}
                >
                  <div className='shrink-0 bg-[#C8EDD7] w-[28px] h-[28px] border border-[#A9D4BB] rounded-full flex justify-center items-center'>
                    <img
                      src='/assets/images/v2/customer-service-icon04.svg'
                      alt='병원아이콘'
                      className='w-[18px] h-[18px] object-contain'
                    />
                  </div>
                  <span>병원</span>
                  <img
                    src='/assets/images/v2/export.svg'
                    alt='내보내기아이콘'
                    className='absolute right-[10px] bottom-0 top-0 my-auto'
                  />
                </button>
              </li> */}
            </ul>
            {/* 전화문의 박스 */}
            <div className='w-full bg-[#F3E3BD] rounded-[10px] p-[12px] pt-4 mt-2.5'>
              <h4 className='text-[14px] font-[500] mb-[12px] text-[#505050]'>
                전화문의
              </h4>
              <div className='w-full flex flex-col justify-start items-start gap-[12px]'>
                <ul className='w-full flex flex-col justify-start items-start pl-[10px] gap-[6px]'>
                  <li className='w-full flex items-center gap-4'>
                    <span className='min-w-[48px] text-[#505050] text-[14px] leading-[19.6px] font-[400]'>
                      평일
                    </span>
                    <strong className='font-[500] text-[14px] leading-[20px] text-[#222222]'>
                      10:00 ~ 18:00
                    </strong>
                  </li>
                  <li className='w-full flex items-center gap-4'>
                    <span className='min-w-[48px] text-[#505050] text-[14px] leading-[19.6px] font-[400]'>
                      점심시간
                    </span>
                    <strong className='font-[500] text-[14px] leading-[20px] text-[#222222]'>
                      13:00 ~ 14:00
                    </strong>
                  </li>
                </ul>
                <button
                  className='w-full bg-white flex justify-center items-center gap-[6px] p-4 rounded-[6px]'
                  onClick={() => {
                    setCustomerAction({
                      id: 'callInquiry',
                      textShow: false,
                      text: '전화문의',
                      actionReturn: 'popup',
                      time: new Date(),
                    });
                  }}
                >
                  <img
                    src='/assets/images/v2/call-blue.png'
                    alt='파란전화기아이콘'
                    className=' w-4 object-contain'
                  />
                  <span className='text-[16px] leading-[16px] font-[500]'>
                    1668-1571
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AskCardLayout>
  );
};
export default CargipsaMenuCard;
