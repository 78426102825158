import { useNavigate } from 'react-router-dom';
import { CustomerActionInterface } from '../../ask';
import AskCardLayout from '../../component/AskCardLayout';
import ResetButton from '../../component/ResetButton';

interface InsuranceCompleteCardInterface {
  customerAction: CustomerActionInterface | null;
  setCustomerAction: (data: CustomerActionInterface) => void;
}

const InquiryCompleteCard = ({
  customerAction,
  setCustomerAction,
}: InsuranceCompleteCardInterface) => {
  const navigate = useNavigate();
  return (
    <div>
      <AskCardLayout
        chatIcon='/assets/images/v2/chat-cargipsa-icon2.png'
        chatName='카집사'
        time={new Date()}
        title='1:1 문의가 정상적으로 접수되었습니다.'
      >
        <div className='w-full flex flex-col'>
          <p className='mb-[14px] text-[15px] leading-[22.5px] text-[#555555] flex flex-col justify-start items-start'>
            <span>답변 완료시 알림으로 안내드리며,</span>
            <span>
              <button
                className='text-[#3156C6] font-[600] underline'
                onClick={() => navigate('/mypage/inquiry')}
              >
                마이페이지 &gt; 고객지원 &gt; 1:1문의
              </button>
              에서
            </span>
            <span>답변 내용을 확인할 수 있습니다.</span>
          </p>
          <div className='relative bg-[#DBE4FE] p-[12px] rounded-[10px] mb-[14px] min-h-[160px]'>
            <h5
              style={{ fontFamily: 'Times New Roman' }}
              className='text-[32px] text-[#3156C6] leading-[36.78px] font-[400] mb-[8px]'
            >
              Complete
            </h5>
            <img
              src='/assets/images/v2/ask-character-complete.png'
              alt='카집사 이미지'
              className='absolute right-0 bottom-[10px] h-[113px] object-contain'
            />
          </div>
          <ResetButton
            clickEvent={() => {
              setCustomerAction({
                id: 'reset',
                textShow: false,
                text: '처음으로',
                actionReturn: 'reset',
                time: new Date(),
              });
            }}
          />
        </div>
      </AskCardLayout>
    </div>
  );
};
export default InquiryCompleteCard;
