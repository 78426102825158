export default function BrandListCard({
  logo,
  title,
  imgUrl,
}: {
  logo: string;
  title: string;
  imgUrl: string;
}) {
  return (
    <div className='w-full min-h-[170px] relative border border-[#E5E5EC] bg-gradient-to-b from-[#FFFFFF] to-[#F3F6F9] rounded-[10px] p-4'>
      <div className='flex flex-col justify-start items-start gap-1.5'>
        <img
          src={logo}
          alt={`${title}_logo`}
          className='w-[40px] h-[40px] object-contain'
        />
        <span className='text-[16px] leading-[16px] font-[600]'>{title}</span>
      </div>
      <div className='absolute right-0 bottom-2.5 h-[90px]'>
        <img
          src={imgUrl}
          alt='자동차 예시 이미지'
          className='h-full object-contain'
        />
      </div>
    </div>
  );
}
