import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

interface CarTrim {
  name: string;
}

interface Car {
  car_trim: CarTrim;
}

interface MemberDealer {
  rating: number;
}

interface MemberOrderManager {
  name_nick: string;
  profileImage_url: string | null;
  member_dealer: MemberDealer;
}

interface OrderLeaseRent {
  type: string;
  car: Car;
}

interface OrderData {
  id: string;
  title: string;
  type: string;
  imageUrl: string;
  brand: string;
  finished_at: string;
  order_leaseRent: OrderLeaseRent | null;
  order_newCar: any | null;
  order_sellCar: any | null;
  order_review: any | null;
  member_order_manager_idTomember: MemberOrderManager | null;

  info_repair_finished?: any | null;
  partner_repairshop?: any | null;
}

interface OrderStore {
  order: OrderData | null;
  setOrder: (order: OrderData) => void;
  clearOrder: () => void;
}

const useOrderStore = create<OrderStore>()(
  devtools(
    persist(
      immer((set) => ({
        order: null,
        setOrder: (order) => set({ order }),
        clearOrder: () => set({ order: null }),
      })),
      {
        name: 'orderReviewStore',
      },
    ),
  ),
);

export default useOrderStore;
