interface AnswerBubbleInterface {
  title: string;
  bgColor: string;
  textColor?: string;
}

const HelloBubble = ({ title, bgColor, textColor }: AnswerBubbleInterface) => {
  return (
    <div className='self-end w-full max-w-[79.2vw] flex flex-row justify-start items-end gap-2'>
      <div
        className={`w-auto p-3 ${bgColor} rounded-[12px] flex flex-col justify-start items-start`}
      >
        <div
          className={`w-auto text-start text-[15px] font-[500] leading-[22.5px] ${textColor ? textColor : 'text-white'}`}
        >
          {title}
        </div>
      </div>
    </div>
  );
};
export default HelloBubble;
