import { CustomerActionInterface } from '../ask';
import AskCardLayout from '../component/AskCardLayout';
import ResetButton from '../component/ResetButton';

interface InsuranceCompleteCardInterface {
  customerAction: CustomerActionInterface | null;
  setCustomerAction: (data: CustomerActionInterface) => void;
}

const InsuranceCompleteCard = ({
  customerAction,
  setCustomerAction,
}: InsuranceCompleteCardInterface) => {
  return (
    <div>
      <AskCardLayout
        chatIcon='/assets/images/v2/chat-carkey-icon.png'
        chatName='카키'
        time={new Date()}
        title='자동차 보험 만기일 등록이 완료되었습니다.'
      >
        <div className='w-full flex flex-col'>
          <p className='mb-[14px] text-[15px] leading-[22.5px] text-[#555555] flex flex-col justify-start items-start'>
            <span>이제부터 세상에 없던 카 라이프를 경험하세요.</span>
            <span>
              <strong className='text-[#3156C6] font-[700]'>카집사</strong>
              로부터 등록된 정보에 대해 간편하게
            </span>
            <span>안내받아보세요.</span>
          </p>
          <div className='relative bg-[#DBE4FE] p-[12px] rounded-[10px] mb-[14px] min-h-[160px]'>
            <h5
              style={{ fontFamily: 'Times New Roman' }}
              className='text-[32px] text-[#3156C6] leading-[36.78px] font-[400] mb-[8px]'
            >
              Complete
            </h5>
            <img
              src='/assets/images/v2/ask-character-complete.png'
              alt='카집사 이미지'
              className='absolute right-0 bottom-[10px] h-[113px] object-contain'
            />
          </div>
          <ResetButton
            clickEvent={() => {
              setCustomerAction({
                id: 'reset',
                textShow: false,
                text: '처음으로',
                actionReturn: 'reset',
                time: new Date(),
              });
            }}
          />
        </div>
      </AskCardLayout>
    </div>
  );
};
export default InsuranceCompleteCard;
