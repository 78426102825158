import dayjs from 'dayjs';

import { AccidentReport } from '@carsayo/types';

export default function TabCancelInfo({
  targetAccidentReport,
}: {
  targetAccidentReport: AccidentReport;
}) {
  if (
    targetAccidentReport.state !== 'canceled' ||
    !targetAccidentReport.info_cancel
  )
    return null;

  if (!targetAccidentReport.info_cancel) return null;

  return (
    <div>
      <strong className='mb-4 mt-[30px] flex items-center justify-start text-[18px] font-[600] leading-[18px] text-[#000]'>
        취소 정보
      </strong>
      <div className='mb-[16px] w-full rounded-[16px] bg-white px-4 py-[20px] shadow-[0px_6px_20px_#0000000D]'>
        <ul className='flex flex-col items-start justify-start gap-[20px]'>
          <li className='flex w-full items-center justify-between gap-[10px] text-[15px] font-[500] leading-[15px]'>
            <span className='whitespace-nowrap text-[#7D848A]'>취소 구분</span>
            <strong className='font-[500] text-[#222222]'>
              {targetAccidentReport.info_cancel.type ?? '없음'}
            </strong>
          </li>
          <li className='flex w-full items-center justify-between gap-[10px] text-[15px] font-[500] leading-[15px]'>
            <span className='whitespace-nowrap text-[#7D848A]'>취소 일자</span>
            <strong className='font-[500] text-[#222222]'>
              {dayjs(targetAccidentReport.info_cancel.canceled_at).format(
                'YYYY.MM.DD HH:mm',
              )}
            </strong>
          </li>
          <li className='flex w-full items-center justify-between gap-[10px] text-[15px] font-[500] leading-[15px]'>
            <span className='whitespace-nowrap text-[#7D848A]'>취소 사유</span>
            <strong className='font-[500] text-[#222222]'>
              {targetAccidentReport.info_cancel.reason ?? '없음'}
            </strong>
          </li>
        </ul>
      </div>
    </div>
  );
}
