/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AccidentRegistrationCreateDTO,
  MemberGender,
  RegisterCar,
} from '@carsayo/types';
import CarsayoInput from 'components/common/CarsayoInput';
import { ChangeEvent, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select';
import CarsayoButton from 'components/common/CarsayoButton';
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
} from 'components/ui/drawer';
import { Button } from 'components/ui/button';
import { X } from 'lucide-react';
import CarsayoRadio from 'components/common/v2/CarsayoRadio';
import useMemberStore from 'store/useMemberStore';
import { accidentCreateApi } from '../../api';
import CarsayoConfirmPopup from 'components/common/CarsayoConfirmPopup';
import useInsuranceOriginDataStore from '../../store/dataStore';
import React from 'react';

const AccidentAddPage = () => {
  const { originInsuranceData } = useInsuranceOriginDataStore();
  const { loginMember, refreshLoginMember } = useMemberStore();
  const carFullData: RegisterCar[] | undefined = useMemo(() => {
    return loginMember?.customerInfo?.registerCar.list;
  }, [loginMember]);
  const navigate = useNavigate();
  const [insuranceCompany, setInsuranceCompany] = useState<string>('');
  const [accidentData, setAccidentData] =
    useState<AccidentRegistrationCreateDTO>({
      name_real: '',
      phoneNumber: '',
      birth: '',
      gender: 'male',
      carName:
        carFullData && carFullData.length > 0
          ? carFullData.find((item) => item.isRepresentative)?.carName ?? ''
          : '',
      insurance_company_id: 0,
      option: {
        needTraction: true,
      },
    });
  const [accidentDrawerOpen, setAccidentDrawerOpen] = useState(false);
  const [accidentDoneConfirm, setAccidentDoneConfirm] = useState(false);
  const [isOpen, setIsOpen] = React.useState(false);

  const handleChangeSex = (sex: MemberGender) => () => {
    setAccidentData((prev) => ({
      ...prev,
      gender: sex,
    }));
  };
  const handleChangeNeedTraction = (check: boolean) => () => {
    setAccidentData((prev) => ({
      ...prev,
      option: {
        needTraction: check,
      },
    }));
  };

  const addInquiry = async () => {
    console.info(accidentData);
    setAccidentDoneConfirm(!accidentDoneConfirm);
    try {
      const result = await accidentCreateApi(accidentData);
      if (result) {
        refreshLoginMember();
        setAccidentDoneConfirm(!accidentDoneConfirm);
      } else {
        console.error('사고처리 등록 중 에러');
      }
    } catch (error: any) {
      console.error(error);
    }
  };

  return (
    <div className='p-4'>
      <h4 className='text-[16px] leading-[24px] font-[600] mb-[4px] flex flex-col justify-start items-start'>
        <span>사고 접수에 필요한</span>
        <span>정보를 입력해주세요. </span>
      </h4>
      {carFullData && (
        <div className='pb-4'>
          <form className='pt-[20px]'>
            <fieldset className='flex flex-col justify-start items-start gap-[20px] pb-6'>
              <div className='w-full'>
                <label
                  htmlFor='accidentOwnerName'
                  className='block mb-[8px] text-[15px] leading-[22px] text-[#111]'
                >
                  이름
                </label>
                <CarsayoInput
                  id='accidentOwnerName'
                  className='!px-3 rounded-none text-[#111] text-[16px] leading-[24px] font-[400]'
                  value={accidentData.name_real}
                  placeholder='이름을 입력해주세요.'
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setAccidentData((prev) => ({
                      ...prev,
                      name_real: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className='w-full'>
                <label
                  htmlFor='accidentOwnerPhone'
                  className='block mb-[8px] text-[15px] leading-[22px] text-[#111]'
                >
                  연락처
                </label>
                <CarsayoInput
                  id='accidentOwnerPhone'
                  className='!px-3 rounded-none text-[#111] text-[16px] leading-[24px] font-[400]'
                  value={accidentData.phoneNumber}
                  placeholder='연락처를 입력해주세요.'
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    const inputValue = e.target.value;
                    if (/^\d*$/.test(inputValue)) {
                      setAccidentData((prev) => ({
                        ...prev,
                        phoneNumber: inputValue.replace(/\s/g, ''),
                      }));
                    }
                  }}
                />
              </div>
              <div className='w-full'>
                <label
                  htmlFor='accidentOwnerBirth'
                  className='block mb-[8px] text-[15px] leading-[22px] text-[#111]'
                >
                  생년월일
                </label>
                <CarsayoInput
                  maxLength={6}
                  id='accidentOwnerBirth'
                  className='!px-3 rounded-none text-[#111] text-[16px] leading-[24px] font-[400]'
                  value={accidentData.birth}
                  placeholder='생년월일을 입력해주세요.(ex.990101)'
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    const inputValue = e.target.value;
                    if (/^\d*$/.test(inputValue)) {
                      setAccidentData((prev) => ({
                        ...prev,
                        birth: inputValue.replace(/\s/g, ''),
                      }));
                    }
                  }}
                />
              </div>
              <div className='w-full'>
                <label
                  htmlFor='accidentOwnerSex'
                  className='block mb-[8px] text-[15px] leading-[22px] text-[#111]'
                >
                  성별
                </label>
                <div className='w-full flex justify-start items-center gap-[24px]'>
                  <CarsayoRadio
                    theme='black'
                    name={'accidentOwnerSex'}
                    label='남성'
                    onChange={handleChangeSex('male' as MemberGender)}
                    value='male'
                    checked={accidentData.gender === 'male'}
                  />
                  <CarsayoRadio
                    theme='black'
                    name={'accidentOwnerSex'}
                    label='여성'
                    onChange={handleChangeSex('female' as MemberGender)}
                    value='female'
                    checked={accidentData.gender === 'female'}
                  />
                </div>
              </div>
              <div className='w-full'>
                <label
                  htmlFor='accidentCarNumber'
                  className='block mb-[8px] text-[15px] leading-[22px] text-[#111]'
                >
                  차량번호
                </label>
                {carFullData && carFullData.length > 0 ? (
                  <Select
                    open={isOpen}
                    onOpenChange={(open) => {
                      setTimeout(() => {
                        setIsOpen(open);
                      }, 50);
                    }}
                    onValueChange={(value) =>
                      setAccidentData((prev) => ({
                        ...prev,
                        carName: value,
                        registerCarId:
                          carFullData && carFullData.length > 0
                            ? String(
                                carFullData.find(
                                  (item) => item.carName === value,
                                )?.id ?? '',
                              )
                            : '',
                      }))
                    }
                  >
                    <SelectTrigger
                      onPointerDown={(e) => e.preventDefault()}
                      onClick={() =>
                        setIsOpen((prev) => {
                          return !prev;
                        })
                      }
                      style={{
                        fontSize: '16px',
                        fontWeight: '400',
                        color: '#111',
                      }}
                    >
                      <SelectValue placeholder={carFullData[0].carName} />
                    </SelectTrigger>
                    <SelectContent>
                      {carFullData &&
                        carFullData.length > 0 &&
                        carFullData.map((item) => {
                          return (
                            <SelectItem
                              key={`carData_${item.id}`}
                              value={item.carName}
                            >
                              {item.carName}
                            </SelectItem>
                          );
                        })}
                    </SelectContent>
                  </Select>
                ) : (
                  <CarsayoInput
                    id='accidentCarNumber'
                    className='!px-3 rounded-none text-[#111] text-[16px] leading-[24px] font-[400]'
                    value={accidentData.carName}
                    placeholder='차량번호를 입력해주세요.'
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setAccidentData((prev) => ({
                        ...prev,
                        carName: e.target.value,
                        createRegisterCar: {
                          carName: e.target.value,
                          insurance_company_id:
                            accidentData.insurance_company_id ?? 0,
                        },
                      }));
                    }}
                  />
                )}
              </div>
              <div className='w-full'>
                <label
                  htmlFor='insuranceCompany'
                  className='block text-[15px] leading-[22px] mb-[6px]'
                >
                  보험사
                </label>
                <div
                  className='w-full block py-[14px] px-[16px] border border-[#E5E5EC] rounded-[6px] text-[16px] leading-[24px]'
                  onClick={() => setAccidentDrawerOpen(!accidentDrawerOpen)}
                >
                  <div className='flex justify-between items-center'>
                    {accidentData.insurance_company_id ? (
                      <div className='flex justify-start items-center gap-[10px]'>
                        <img
                          src={
                            originInsuranceData.find(
                              (item) =>
                                item.id === accidentData.insurance_company_id,
                            )?.logo_url
                          }
                          alt={`${accidentData.insurance_company_id}_로고이미지`}
                          className='w-[20px] object-contain'
                        />
                        <span className='text-[#111] text-[16px] leading-[24px]'>
                          {
                            originInsuranceData.find(
                              (item) =>
                                item.id === accidentData.insurance_company_id,
                            )?.name
                          }
                        </span>
                      </div>
                    ) : (
                      <div className='flex justify-start items-center gap-[10px]'>
                        <img
                          src='/assets/images/png/symbol-default.png'
                          alt='카사요심볼회색'
                          className='w-[20px] object-contain'
                        />
                        <span className='text-[#767676] text-[16px] leading-[24px]'>
                          보험사를 선택해주세요
                        </span>
                      </div>
                    )}

                    <img
                      src='/assets/images/v2/arrow-left.png'
                      alt='우측 화살표'
                      className='rotate-[180deg] w-4 h-4 object-contain'
                    />
                  </div>
                </div>
              </div>
              <div className='w-full'>
                <label
                  htmlFor='accidentNeedTraction'
                  className='block mb-[8px] text-[15px] leading-[22px] text-[#111]'
                >
                  견인 필요여부
                </label>
                <div className='w-full flex justify-start items-center gap-[24px]'>
                  <CarsayoRadio
                    theme='black'
                    name={'accidentNeedTraction'}
                    label='견인 필요'
                    onChange={handleChangeNeedTraction(true)}
                    value='true'
                    checked={accidentData.option.needTraction === true}
                  />
                  <CarsayoRadio
                    theme='black'
                    name={'accidentNeedTraction'}
                    label='견인 불필요'
                    onChange={handleChangeNeedTraction(false)}
                    value='false'
                    checked={accidentData.option.needTraction === false}
                  />
                </div>
              </div>
            </fieldset>
            <CarsayoButton
              color='purpleBlue'
              disabled={
                accidentData.name_real &&
                accidentData.phoneNumber &&
                accidentData.birth &&
                accidentData.gender &&
                accidentData.insurance_company_id &&
                accidentData.carName &&
                accidentData.option.needTraction !== undefined
                  ? false
                  : true
              }
              onClick={addInquiry}
            >
              사고 처리 접수하기
            </CarsayoButton>
          </form>
        </div>
      )}

      {/* 사고접수 완료 confirm */}
      <CarsayoConfirmPopup
        isShown={accidentDoneConfirm}
        onConfirm={() => {
          setAccidentDrawerOpen(!accidentDrawerOpen);
          navigate('/ask/theme/cargipsa', {
            state: {
              key: ['accidentComplete'],
              data: accidentData,
              time: new Date(),
            },
            replace: true,
          });
        }}
        onCancel={() => {}}
        cancelButtonValue='취소'
        confirmButtonValue='확인 '
        isCancel={false}
        isRounded={false}
      >
        <h5 className='mb-[8px] text-[14px] font-[600] leading-[20px]'>
          공업사 배정 진행중입니다.
        </h5>
        <p className='text-center text-[#767676] text-[14px] leading-[20px] flex flex-col justify-start items-start'>
          <span>요청주신 사항에 맞추어 자동으로 배정되며</span>
          <span>진행상태는 카공이에게 확인 가능합니다.</span>
        </p>
      </CarsayoConfirmPopup>

      <Drawer open={accidentDrawerOpen}>
        <DrawerContent className='mx-auto flex max-h-[90vh] w-full max-w-sm flex-col'>
          <DrawerHeader className='relative'>
            <DrawerTitle className='text-center text -lg font-semibold'>
              보험사 선택
            </DrawerTitle>
            <Button
              onClick={() => setAccidentDrawerOpen(!accidentDrawerOpen)}
              className='absolute right-5 top-4 h-7 w-7 border-0 bg-transparent p-0 text-[#111111]'
            >
              <X className='h-7 w-7' />
            </Button>
          </DrawerHeader>

          <div className='p-4 pt-0'>
            <div className='flex justify-between items-end pt-[10px] pb-[20px] border-b-[1px] border-[#F1F1F5]'>
              <div>
                <h4 className='text-[16px] leading-[24px] font-[600] mb-[8px]'>
                  보험사를 선택해주세요
                </h4>
                <p className='text-[14px] leading-[20px]'>
                  고객님을 위한 보험 만기 알림 및 긴급출동 등 특별한 카 라이프에
                  카집사가 함께 하겠습니다.
                </p>
              </div>
              <img
                src='/assets/images/v2/ask-carGipsa02.svg'
                alt='카집사 이미지02'
              />
            </div>
            <div>
              <ul className='brandAllCheck mt-4 grid grid-cols-3 gap-x-[21.5px] gap-y-4 mb-[20px]'>
                {originInsuranceData.map((el, index) => {
                  const targetCompanyName = el.name;
                  const targetCompanyData = el;
                  return (
                    <li
                      key={`${targetCompanyName}_${index}`}
                      className={`group relative h-[88px] rounded-xl border bg-[#fff] py-4 ${targetCompanyName === insuranceCompany ? 'border-primary' : ''}`}
                    >
                      <label htmlFor={`insuranceRadio_${targetCompanyName}`}>
                        <input
                          type='radio'
                          id={`insuranceRadio_${targetCompanyName}`}
                          value={targetCompanyName}
                          className='absolute h-0 w-0 hidden'
                          checked={targetCompanyName === insuranceCompany}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setInsuranceCompany(e.target.value);
                          }}
                        />
                        <div className='mx-auto h-9 w-9'>
                          <img
                            src={targetCompanyData.logo_url}
                            alt={`보험사 로고_${targetCompanyName}`}
                          />
                        </div>
                        <div
                          className={`mt-2 text-[#111]  px-1 overflow-hidden text-ellipsis whitespace-nowrap text-center text-[13px] ${targetCompanyName === insuranceCompany ? 'font-[500]' : 'font-[400]'} `}
                        >
                          {targetCompanyName}
                        </div>
                      </label>
                    </li>
                  );
                })}
              </ul>
            </div>
            <CarsayoButton
              disabled={!insuranceCompany}
              color='purpleBlue'
              onClick={() => {
                setAccidentData((prev) => ({
                  ...prev,
                  insurance_company_id: originInsuranceData
                    ? originInsuranceData.find(
                        (item) => item.name === insuranceCompany,
                      )?.id ?? 0
                    : 0,
                  createRegisterCar:
                    !carFullData || carFullData.length === 0
                      ? {
                          carName: prev?.carName ?? '',
                          insurance_company_id: originInsuranceData
                            ? originInsuranceData.find(
                                (item) => item.name === insuranceCompany,
                              )?.id ?? 0
                            : 0,
                        }
                      : undefined,
                }));
                setAccidentDrawerOpen(!accidentDrawerOpen);
              }}
            >
              보험사 선택
            </CarsayoButton>
          </div>
        </DrawerContent>
      </Drawer>
    </div>
  );
};
export default AccidentAddPage;
