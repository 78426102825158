/* eslint-disable @typescript-eslint/no-unused-vars */
import { useLayoutEffect, useState } from 'react';
import BrandStep from './brand/page';
import { cn } from 'utils';
import { useNavigate } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';
import { X } from 'lucide-react';
import Button from 'components_v3/ui/Button';
import ModelStep from './model/page';

export interface SelectedBrandInterface {
  type: string;
  isSelectedModel: boolean;
}

export default function NewCarStepPage() {
  const navigate = useNavigate();
  const { setTopTabbarState, setIsBottomTabbar } = useSystemStore();
  const [step, setStep] = useState(1);
  const [selectedBrand, setSelectedBrand] = useState<SelectedBrandInterface>({
    type: 'domestic',
    isSelectedModel: true,
  });

  const stepList = [
    {
      step: 1,
      title: '브랜드 선택',
    },
    {
      step: 2,
      title: '모델 선택',
    },
    {
      step: 3,
      title: '세부 모델 선택',
    },
    {
      step: 4,
      title: '색상 선택',
    },
    {
      step: 5,
      title: '옵션 선택',
    },
    {
      step: 6,
      title: '구매 방법 선택',
    },
    {
      step: 7,
      title: '견적 요청',
    },
  ];

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      title:
        stepList.find((item) => item.step === step)?.title ?? '브랜드 선택',
      rightElement: (
        <div className='min-w-[28px] cursor-pointer'>
          <X
            onClick={() => navigate(-1)}
            width={28}
            height={28}
            strokeWidth={1.5}
          />
        </div>
      ),
    });
    setIsBottomTabbar(false);
  }, [step]);
  return (
    <div className='w-full'>
      {/* progressbar */}
      <div className='bg-[#E5E5EC] w-[calc(100%-40px)] mx-auto h-[2px]'>
        <div
          style={{
            width: `calc((100% / 7) * ${step})`,
          }}
          className={cn('bg-[#7273F9] h-[2px] transition-all')}
        ></div>
      </div>
      <div
        className={cn(
          'w-full overflow-y-auto pt-[18px]',
          'h-[calc(100dvh-62px)]',
        )}
      >
        {step === 1 && (
          <BrandStep setStep={setStep} setSelectedBrand={setSelectedBrand} />
        )}
        {step === 2 && (
          <ModelStep setStep={setStep} selectedBrand={selectedBrand} />
        )}
      </div>
    </div>
  );
}
