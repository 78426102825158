import { FileInfo } from '@carsayo/types';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { X } from 'lucide-react';
import { useState } from 'react';

const FilePreviewInput = ({
  currentFileInfo,
  onRemove,
}: {
  currentFileInfo: FileInfo;
  onRemove: (id: string) => void;
}) => {
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);

  const handleImageClick = () => {
    setIsPreviewVisible(true);
  };

  const handleClosePreview = () => {
    setIsPreviewVisible(false);
  };

  const handleRemoveImage = () => {
    onRemove(currentFileInfo.id);
  };

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px',
        paddingLeft: '16px',
        border: '1px solid #E5E5EC',
        borderRadius: '8px',
        cursor: currentFileInfo ? 'default' : 'pointer',
        boxSizing: 'border-box',
        maxWidth: '100%',
        minHeight: '50px',
      }}
    >
      <div className='pr-9'>
        <Typography
          variant='body1'
          sx={{
            marginRight: 2,
            width: 'auto',
            cursor: 'pointer',
            textDecoration: 'underline',
            fontSize: '16px',
            lineHeight: '16px',
            fontWeight: '400',
            fontFamily: 'Pretendard',
            whiteSpace: 'pre-line',
            display: 'inline-block',
            wordBreak: 'break-word',
          }}
          onClick={handleImageClick}
        >
          {currentFileInfo.name}.{currentFileInfo.extension}
        </Typography>
        <button
          onClick={handleRemoveImage}
          type='button'
          style={{
            position: 'absolute',
            top: '50%',
            right: '16px',
            transform: 'translateY(-50%)',
            background: 'none',
            border: 'none',
            cursor: 'pointer',
            backgroundColor: '#909396',
            borderRadius: '50%',
            width: '24px',
            height: '24px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <X width={14} height={14} color='white' strokeWidth={2.5} />
        </button>
      </div>

      {currentFileInfo && (
        <Dialog
          sx={{
            '& .MuiDialog-paper': {
              height: '100dvh',
              width: '100dvw',
              maxWidth: 'none',
              margin: 0,
              padding: 0,
            },
            '& .MuiPaper-root': {
              height: '100dvh',
              width: '100dvw',
              maxHeight: '100dvh',
            },
          }}
          open={isPreviewVisible}
          onClose={handleClosePreview}
          fullWidth
          maxWidth={'lg'}
          className='bg-[#111111]'
        >
          <DialogTitle className='!relative !flex !min-h-[56px] !items-center !justify-center !bg-[#111] !px-[48px] !py-[12px] !text-[16px] !font-[500] !leading-[16px] text-white'>
            <span className='w-full break-words'>{currentFileInfo.name}</span>
            <button
              onClick={handleClosePreview}
              className='!absolute !bottom-0 !right-4 !top-0 !my-auto !flex !h-8 !w-8 !items-center !justify-center'
            >
              <X width={16} height={16} color='#fff' />
            </button>
          </DialogTitle>
          <DialogContent className='!flex !w-full scale-100 !items-center !justify-center bg-[#111111] !p-0'>
            <img
              src={currentFileInfo.url}
              alt='Uploaded'
              style={{ width: '100%', height: '100%', objectFit: 'contain' }}
            />
          </DialogContent>
        </Dialog>
      )}
    </Box>
  );
};

export default FilePreviewInput;
