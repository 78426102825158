import { z } from 'zod';

/** 사고접수 리뷰 작성 정보 */
export const AccidentReportDataReviewSchema = z.object({
  /** 별점 */
  rating: z.number().max(5, { message: '별점은 5점까지 가능합니다.' }).min(1, {
    message: '별점은 1점 이상이어야 합니다.',
  }),
  /** 리뷰 내용 */
  content: z.string().min(1, { message: '리뷰 내용을 입력해 주세요' }),
});

/** 사고접수 리뷰 작성 정보 */
export type AccidentReportDataReviewForm = z.infer<
  typeof AccidentReportDataReviewSchema
>;
