/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useLayoutEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import { AnimatePresence, motion } from 'framer-motion';
import CarsayoBanner from 'components/common/v2/CarsayoBanner';
import useMemberStore from 'store/useMemberStore';
import { Link, useNavigate } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';
import { isLogin, setFcmToken } from 'apis/authentication';
import {
  PopularCar,
  PopularCarCategory,
  UpdateInterestCarDTO,
  InterestCarInfo,
  PopularCarCategoryId,
} from '@carsayo/types';
import SampleCar from 'assets/images/v2/sampleCarImg_BMW5.svg';
import HeartIcon from 'components/common/global/svg/HeartIcon';
import ArrowRight from 'components/common/global/svg/ArrowRight';
import {
  getInterestCarList,
  getPopularCarList,
  selectInterestCar,
} from 'apis/car';
import { classNames } from 'primereact/utils';
import { CarsayoToast } from 'components/common/v2/CarsayoToast';
import dayjs from 'dayjs';
import { cn } from 'utils';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import SwiperCore from 'swiper';
import SwiperClass from 'swiper/types/swiper-class';
import NoQuoteIcon from 'assets/images/v2/noQuoteIcon.svg';
import GoraniImg from 'assets/images/v2/goraniImg.jpg';
import StarBadge from 'assets/images/v2/starBadge.svg';
import managerUniform from 'assets/images/v2/carmanagerLeaseRent.svg';
import { Button } from 'components/ui/button';
import CarsayoCI from 'components/svg/CarsayoCI';
import {
  InsuranceCompany,
  InsuranceCompanyData,
} from 'pages/static/insurance/data/company';
import VideoDrawer from './components/videoDrawer';
import NoticeAccordion from './components/noticeAccordion';
import { addHistoryInsuranceCooconClick } from 'apis/history';
import usePayment from 'store/usePayment';
import UnderlineSketch from 'components/svg/UnderlineSketch';
import HanaInsuranceBanner from './components/hannerInsuranceBanner';
import MainSwiperSection from './components/MainSwiperSection';
import MainNavSection from './components/MainNavSection';
import MainInsuranceSection from './components/MainInsuranceSection';
import ShortNavSection from './components/ShortNavSection';
import MainFooter from './components/MainFooter';
import { InsuranceBannerOrder } from '../ask/data';

interface InterestTemplateProps {
  data: InterestCarInfo;
  setInterestCarList: () => void;
}

function InterestTemplate({ data, setInterestCarList }: InterestTemplateProps) {
  const [isInterestCar, setIsInterestCar] = useState(data.isInterest);
  const carCategoryColors: Record<number, string> = {
    1: 'bg-[#0EBB86]', // 경차
    2: 'bg-[#333333]', // 소형차
    3: 'bg-[#17A279]', // 준중형차
    4: 'bg-[#077F5B]', // 중형차
    5: 'bg-[#6841DA]', // 대형차
    6: 'bg-[#4363EC]', // 스포츠카
    7: 'bg-[#C2255C]', // RV
    8: 'bg-[#333333]', // SUV
    9: 'bg-[#09869C]', // 승합차
    10: 'bg-[#333333]', // 화물차
    11: 'bg-[#333333]', // 버스
    21: 'bg-[#333333]', // 특장차
    20: 'bg-[#333333]', // 기타
    24: 'bg-[#333333]', // 하이브리드
    25: 'bg-[#333333]', // 전기차
  };

  function getColorById(id: number): string {
    return carCategoryColors[id] || 'bg-[#333333]';
  }
  const handleClickEvents = {
    selecteInterestCar: async () => {
      try {
        const result: UpdateInterestCarDTO = await selectInterestCar(
          data.id ?? 0,
          !isInterestCar,
        );
        if (result) {
          setInterestCarList();
          if (result.isInterest) {
            CarsayoToast.text('관심차량을 등록했습니다.');
          } else {
            CarsayoToast.text('관심차량을 해제했습니다.');
          }
          setIsInterestCar(result.isInterest);
          return;
        }
      } catch (error: any) {
        console.error(error);
        return;
      }
    },
  };
  return (
    <>
      <div className='relative mt-6 flex min-h-[220px] py-[1.84vh] items-center justify-center bg-[rgba(0,0,0,.03)]'>
        <img
          src={data.image_url}
          alt={data.name}
          className='w-full object-contain  lg:py-[10px]  md:py-[10px] px-[20vw] py-[1.84vh]'
        />
        <span
          className={cn(
            'absolute right-[10px] top-0 text-[13px] leading-[13px] text-white font-[600] py-[8px] px-[11px] rounded-b-[4px] h-[29px] flex justify-center items-center',
            data.car_category?.id
              ? getColorById(data.car_category.id)
              : 'bg-[#333]',
          )}
        >
          {data.car_category?.name}
        </span>
      </div>
      <div className='mt-[30px] px-[20px]'>
        <div className='font-semibold flex justify-between items-center h-[14px] mb-[10px]'>
          <span className='text-[14px] leading-[14px] font-[500] text-[#555]'>
            {data.carMaker.name ?? '제조사명 없음'}
          </span>
          <button
            className='w-[30px] h-[30px] text-transparent indent-[-9999px]'
            onClick={() => handleClickEvents.selecteInterestCar()}
          >
            <HeartIcon
              fill={isInterestCar ? '#FF6A6A' : undefined}
              stroke={isInterestCar ? '#FF6A6A' : undefined}
              width={'30px'}
              height={'30px'}
            />
            관심차량버튼
          </button>
        </div>
        <div className='text-[16px] font-semibold leading-[25.6px] text-[#222222] mb-1'>
          {data.name ?? '모델명 없음'}
        </div>
        <div className='text-[15px] font-[500] leading-[24px] text-[#555555]'>
          {data.name ?? '세부 모델명명 없음'}
        </div>
        <div className='text-[18px] leading-[22px] font-semibold text-[#222222] text-right'>
          {/* {data.price
            ? `${Math.floor((data.price.min + data.price.max) / 20000).toLocaleString()} 만원`
            : '금액없음'} */}
          {data.price
            ? `${Math.floor(data.price.min / 10000).toLocaleString()} 만원~`
            : '금액없음'}
        </div>
        <Link
          to={`/purchase/brand?carModelId=${data.id}`}
          className='mt-6 flex h-[50px] items-center justify-center rounded-[8px] bg-[#222222] text-white text-[15px] leading-[22.5px]'
        >
          견적 확인하기
        </Link>
      </div>
    </>
  );
}

function Star({
  fill,
  width,
  height,
}: {
  fill: string;
  width: number;
  height: number;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 20 20'
      fill={fill}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.247 3.52291L11.7012 8.21333H16.4042C16.6576 8.21333 16.7644 8.55739 16.5576 8.71187L12.7552 11.6118L14.2095 16.3022C14.2895 16.555 14.0093 16.7726 13.8025 16.6111L10.0002 13.7112L6.19779 16.6111C5.99099 16.7656 5.71082 16.555 5.79087 16.3022L7.24511 11.6118L3.44274 8.71187C3.23594 8.55739 3.34267 8.21333 3.59617 8.21333H8.2991L9.75334 3.52291C9.83339 3.27014 10.1669 3.27014 10.247 3.52291Z'
        fill={fill}
        stroke={fill}
        strokeWidth='1.66667'
        strokeLinejoin='round'
      />
    </svg>
  );
}

// 이용후기
function ReviewContainer() {
  // 이용 후기용 스테이트
  const [reviewSwiper, setReviewSwiper] = useState<SwiperCore | null>(null);
  const [reviewPage, setReviewPage] = useState(1);
  return (
    <div className='mt-3 bg-[#7273F9] px-4 py-6 pb-8 text-white'>
      <div className='text-xl font-medium'>이용후기</div>
      <div className='flex justify-between'>
        <div className='mt-2 text-xs text-[#D9D9D9]'>
          많은 분들이 좋은 조건으로 빠른 거래했어요!
        </div>
        <div className='flex items-center gap-1'>
          <div
            className={cn(
              'flex h-4 w-4 items-center justify-center text-[#CACAD7]',
              reviewPage > 1 && 'text-white cursor-pointer',
            )}
            onClick={() => reviewSwiper?.slidePrev()}
          >
            <ChevronLeft className='h-4 w-4' />
          </div>
          <div>
            <span>{reviewPage}</span>/4
          </div>
          <div
            className={cn(
              'flex h-4 w-4 items-center justify-center text-[#CACAD7]',
              reviewPage < 4 && 'text-white cursor-pointer',
            )}
            onClick={() => reviewSwiper?.slideNext()}
          >
            <ChevronRight className='h-4 w-4' />
          </div>
        </div>
      </div>
      <div className='-mx-4 mt-10'>
        <Swiper
          slidesPerView={1}
          onRealIndexChange={(swiper: SwiperClass) =>
            setReviewPage(swiper.realIndex + 1)
          }
          onSwiper={(swiper: SwiperClass) => setReviewSwiper(swiper)}
        >
          <SwiperSlide className='px-4'>
            <div className='h-[337px] rounded-xl bg-white bg-review-icon bg-right-bottom bg-no-repeat px-4 py-6'>
              <div className='flex justify-between border-b border-[#F1F1F5] pb-6'>
                <div>
                  <div className='inline-flex rounded-2xl bg-[#111111] px-[15px]'>
                    내 차 팔기
                  </div>
                  <div className='mt-2 flex gap-1.5'>
                    <div className='flex'>
                      {Array.from({ length: 5 }).map((_, i) => (
                        <Star key={i} fill='#FFAA00' width={20} height={20} />
                      ))}
                    </div>
                    <div className='text-[#555555]'>정민서</div>
                  </div>
                </div>
                <div>
                  <img src='/assets/images/v2/sell-car.svg' alt='' />
                </div>
              </div>
              <div className='mt-6 leading-8 text-[#111111]'>
                무엇보다도 가격을 최고가로 받을 수 있는 게 좋은 앱이네요.
                훌륭합니다 모든 과정이 빠르고 간단합니다 입찰제 중고차 판매가
                마음에 듭니다.
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className='px-4'>
            <div className='h-[337px] rounded-xl bg-white bg-review-icon bg-right-bottom bg-no-repeat px-4 py-6'>
              <div className='flex justify-between border-b border-[#F1F1F5] pb-6'>
                <div>
                  <div className='inline-flex rounded-2xl bg-[#111111] px-[15px]'>
                    신차구매
                  </div>
                  <div className='mt-2 flex gap-1.5'>
                    <div className='flex'>
                      {Array.from({ length: 5 }).map((_, i) => (
                        <Star key={i} fill='#FFAA00' width={20} height={20} />
                      ))}
                    </div>
                    <div className='text-[#555555]'>림아</div>
                  </div>
                </div>
                <div>
                  <img src='/assets/images/v2/sell-car.svg' alt='' />
                </div>
              </div>
              <div className='mt-6 leading-8 text-[#111111]'>
                앱 뛰어 납니다. 정말 좋네요. 신차 구입을 했어요. 많은 혜택으로
                만족스러운 구매를 했네요
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className='px-4'>
            <div className='h-[337px] rounded-xl bg-white bg-review-icon bg-right-bottom bg-no-repeat px-4 py-6'>
              <div className='flex justify-between border-b border-[#F1F1F5] pb-6'>
                <div>
                  <div className='inline-flex rounded-2xl bg-[#111111] px-[15px]'>
                    내차팔기
                  </div>
                  <div className='mt-2 flex gap-1.5'>
                    <div className='flex'>
                      {Array.from({ length: 5 }).map((_, i) => (
                        <Star key={i} fill='#FFAA00' width={20} height={20} />
                      ))}
                    </div>
                    <div className='text-[#555555]'>하호랑</div>
                  </div>
                </div>
                <div>
                  <img src='/assets/images/v2/sell-car.svg' alt='' />
                </div>
              </div>
              <div className='mt-6 leading-8 text-[#111111]'>
                중고차를 판매할때는 카사요를 쓰는게 이득이었어요. 여러군데
                알아봤는데 편리함이나 가격적으로 여기가 최고였음 만족합니다~
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className='px-4'>
            <div className='h-[337px] rounded-xl bg-white bg-review-icon bg-right-bottom bg-no-repeat px-4 py-6'>
              <div className='flex justify-between border-b border-[#F1F1F5] pb-6'>
                <div>
                  <div className='inline-flex rounded-2xl bg-[#111111] px-[15px]'>
                    신차구매
                  </div>
                  <div className='mt-2 flex gap-1.5'>
                    <div className='flex'>
                      {Array.from({ length: 5 }).map((_, i) => (
                        <Star key={i} fill='#FFAA00' width={20} height={20} />
                      ))}
                    </div>
                    <div className='text-[#555555]'>태익김</div>
                  </div>
                </div>
                <div>
                  <img src='/assets/images/v2/sell-car.svg' alt='' />
                </div>
              </div>
              <div className='mt-6 leading-8 text-[#111111]'>
                새차 구입할 때 많은 혜택도 받았어요 추천합니다. 편리하게 차를
                사고 파는게 가능해서 넘 좋았어요.
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
}

// 메인 스와이퍼
function MainSwiperContainer() {
  const { loginMember } = useMemberStore();
  const navigate = useNavigate();
  // 메인 스와이퍼용 스테이트
  const [page, setPage] = useState(1);
  // 메인 배너용 스와이퍼 상태
  const [bannerSwiper, setBannerSwiper] = useState<SwiperCore | null>(null);
  const [isVideoDrawerOpen, setIsVideoDrawerOpen] = useState<boolean>(false);
  const audioFiles = [
    '/assets/audio/carsayoA.wav',
    '/assets/audio/carsayoB.wav',
    '/assets/audio/carsayoC.wav',
    '/assets/audio/carsayoD.wav',
  ];
  const audioRef = useRef<HTMLAudioElement>(null);
  const [audioPlay, setAudioPlay] = useState<boolean | null>(null);
  const { initPayment } = usePayment();
  const testPayment = () => {
    initPayment({
      amount: '500',
      name: '테스트결제시도',
      redirectPath: 'main',
      failedPath: 'error',
    });
  };

  const playRandomAudio = () => {
    if (audioPlay) {
      if (audioRef.current) {
        audioRef.current.pause();
      }
      setAudioPlay(null);
    } else {
      const randomIndex = Math.floor(Math.random() * audioFiles.length);
      if (audioRef.current) {
        audioRef.current.src = audioFiles[randomIndex];
        audioRef.current.addEventListener(
          'ended',
          () => {
            setAudioPlay(null);
          },
          { once: true },
        );
        audioRef.current.play();
        setAudioPlay(true);
      }
    }
  };
  const handleClickEvents = {
    moveToInsurance: () => {
      navigate('/static/insurance');
    },
    moveToCharacter: () => {
      navigate('/static/character');
    },
    moveToPromotion: () => {
      navigate('/static/promotion');
      // navigate('/ask/main');
    },
    openCooconInsurance: () => {
      addHistoryInsuranceCooconClick({
        from: `app${location.pathname}`,
        target: '쿠콘',
      });
      if (process.env.REACT_APP_NODE_ENV === 'development') {
        window.open(
          'https://insu-dev.coocon.co.kr/bridge.act?org_cd=RUQBBRKU&browser_type=2',
        );
        return;
      }
      window.open(
        'https://insu.coocon.net/bridge.act?org_cd=RUQBBRKU&browser_type=2',
      );
    },
  };
  return (
    <>
      <div className='main-swiper__swiper'>
        <Swiper
          modules={[Autoplay]}
          centeredSlides={true}
          slidesPerView={'auto'}
          slidesPerGroup={1}
          loop={true}
          autoplay={{ delay: 3000 }}
          onRealIndexChange={(swiper: SwiperClass) => {
            setPage(swiper.realIndex + 1);
          }}
          onSwiper={setBannerSwiper}
          navigation={{
            prevEl: '.swiper-button-prev',
            nextEl: '.swiper-button-next',
          }}
        >
          <SwiperSlide>
            <div
              className='swiper__type01'
              onClick={handleClickEvents.moveToInsurance}
              style={{
                background:
                  'linear-gradient(0deg, #3DCEB6, #3DCEB6),linear-gradient(52.16deg, #3DCAB3 60.12%, #2DA590 100%)',
              }}
            >
              <div>
                <span>무이자로 가입하는</span>
                <h3>
                  <span>9개 보험사 다이렉트</span>
                  <span>자동차 보험</span>
                </h3>
                <p>삼성, 캐롯, 하나, 현대, AXA, DB, KB, 메리츠, 한화</p>
              </div>
              <img
                src='/assets/images/svg/m-main-swiper01.svg'
                alt='무이자가입아이콘'
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div
              className='swiper__type02'
              style={{
                background: 'url(/assets/images/svg/premium-bg.svg)',
              }}
            >
              <div onClick={handleClickEvents.moveToCharacter}>
                <div>
                  <h3>
                    <span style={{ fontFamily: 'Times New Roman' }}>Car</span>
                    <span style={{ fontFamily: 'Times New Roman' }}>
                      Heroes Rise
                    </span>
                  </h3>
                  <span>
                    <UnderlineSketch fill='#FFE492' width='80' height='10' />
                  </span>
                  <span className='star-effect'>
                    <img
                      src='/assets/images/svg/star-effect.svg'
                      alt='별모양'
                    />
                  </span>
                </div>
                <h4>
                  <img
                    src='/assets/images/png/m-menu-logo.png'
                    alt='작은심볼로고'
                  />
                </h4>
                <img
                  src='/assets/images/svg/m-menu-character02.svg'
                  alt='카사요히어로즈메뉴이미지02'
                />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='swiper__type03'>
              <div onClick={handleClickEvents.moveToPromotion}>
                <div>
                  <h3>
                    <strong style={{ fontFamily: 'Times New Roman' }}>
                      Premium Service
                    </strong>
                    <span style={{ fontFamily: 'Times New Roman' }}>
                      With Car Heroes
                    </span>
                  </h3>
                </div>
                <img
                  src='/assets/images/svg/m-menu-character.svg'
                  alt='카사요히어로즈메뉴이미지'
                />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div
              className='swiper__type04'
              style={{ backgroundColor: '#FFD600' }}
            >
              <h3>
                <CarsayoCI fill='#111' />
              </h3>
              <p>브랜드 오디오 클립</p>
              <button onClick={playRandomAudio}>
                {audioPlay ? (
                  <div>
                    <span>
                      <img
                        src='/assets/images/svg/stop.svg'
                        alt='정지 아이콘'
                      />
                      <span className='animate-ping bg-yellow-500 absolute inline-flex w-10 h-10 rounded-full opacity-25'></span>
                    </span>
                    <ul
                      className={`audio-visualizer ${audioPlay ? 'playing' : ''}`}
                    >
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                    </ul>
                  </div>
                ) : (
                  <img
                    src='/assets/images/svg/m-main-swiper-playBtn.svg'
                    alt='오디오 재생 아이콘'
                  />
                )}

                <audio ref={audioRef}>
                  <track
                    src='captions_en.vtt'
                    kind='captions'
                    label='english_captions'
                  />
                </audio>
              </button>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='swiper__type05'>
              <h3>
                <CarsayoCI />
              </h3>
              <p>자동차 쇼핑 통합 플랫폼</p>
              <button
                onClick={() => {
                  setIsVideoDrawerOpen(true);
                }}
              >
                브랜드 필름 보기
              </button>
              <img
                src='/assets/images/svg/m-main-swiper-05.svg'
                alt='카공이 캐릭터 이미지'
              />
            </div>
          </SwiperSlide>
        </Swiper>
        <div className='pagination'>
          <div
            className='swiper-button-prev'
            onClick={() => bannerSwiper?.slidePrev()}
          ></div>
          <div className='swiper-pagination'>
            <span>{page}</span>/5
          </div>
          <div
            className='swiper-button-next'
            onClick={() => bannerSwiper?.slideNext()}
          ></div>
        </div>
      </div>
      <div className={`main-swiper__menu bgColor-${page}`}>
        <div>
          <h2>자동차 쇼핑 통합 플랫폼</h2>
          {(process.env.NODE_ENV !== 'production' ||
            (process.env.NODE_ENV === 'production' &&
              loginMember?.id === 'test-carsayo-customer')) && (
            <p className='text-blue-600' onClick={testPayment}>
              테스트결제시도버 튼
            </p>
          )}
          <ul>
            <li
              onClick={() => {
                navigate('/purchase/brand?purchaseType=rent');
              }}
            >
              <div>
                <img src='/assets/images/svg/rentCar.svg' alt='' />
              </div>
              <p>장기렌트</p>
            </li>
            <li
              onClick={() => {
                navigate('/purchase/brand?purchaseType=lease');
              }}
            >
              <div>
                <img src='/assets/images/svg/leaseCar.svg' alt='' />
              </div>
              <p>리스</p>
            </li>
            <li
              onClick={() => {
                navigate('/purchase/brand?purchaseType=newCar');
              }}
            >
              <div>
                <img src='/assets/images/svg/newCar.svg' alt='' />
              </div>
              <p>신차 구매</p>
            </li>
            <li
              onClick={() => {
                navigate('/selling/landing');
              }}
            >
              <div>
                <img src='/assets/images/svg/salesCar.svg' alt='' />
              </div>
              <p>내 차 팔기</p>
            </li>
          </ul>
        </div>
      </div>
      <VideoDrawer
        isOpen={isVideoDrawerOpen}
        setIsOpen={setIsVideoDrawerOpen}
      ></VideoDrawer>
    </>
  );
}

// AD형 보험 이동 banner
function ADInsuranceBanner() {
  const navigate = useNavigate();
  const handleClickEvents = {
    moveToInsurance: () => {
      navigate('/static/insurance');
    },
  };

  return (
    <>
      <div className='ad-banner py-4'>
        <div onClick={handleClickEvents.moveToInsurance}>
          <h4>비교하고 최저가 보험 결정하셨다면</h4>
          <button>혜택을 더해서 자동차 보험 가입하기</button>
          <div>
            <img
              width={238}
              height={238}
              src='/assets/images/png/ad-insurance-img.png'
              alt='혜택 담은 자동차 이미지'
            />
            <p className='tiny:!text-[15px] extra:!text-[15px]'>
              각 카드사별 최대 3만원 혜택을 확인하세요
            </p>
          </div>
        </div>
      </div>
      <HanaInsuranceBanner />
    </>
  );
}

function CustomerMainPage() {
  const [expanded, setExpanded] = useState<boolean>(false);
  const navigate = useNavigate();
  const [isCopyright, setIsCopyright] = useState(false);
  const [interestCarList, setInterestCarList] = useState<InterestCarInfo[]>();
  const [currentBestCar, setCurrentBestCar] =
    useState<PopularCarCategoryId>('domestic');
  const [popularCarDomesticList, setPopularCarDomesticList] =
    useState<PopularCar[]>();
  const [popularCarImportedList, setPopularCarImportedList] =
    useState<PopularCar[]>();
  const [popularCarSuvList, setPopularCarSuvList] = useState<PopularCar[]>();

  const { loginMember } = useMemberStore();
  const { setIsBottomTabbar } = useSystemStore();

  // 견적 통계용 스테이트
  const [quoteSwiper, setQuoteSwiper] = useState<SwiperCore | null>(null);
  const [quotePage, setQuotePage] = useState(1);

  const handleVoidEvents = {
    checkLogin: async () => {
      if (!loginMember) {
        const {
          data: { isLogined },
        } = await isLogin();
        if (!isLogined) {
          navigate('/auth/signup', { replace: true });
          return;
        }
      }
    },

    getPopularCarList: async () => {
      const carListArray: PopularCarCategory[] = await getPopularCarList();
      if (carListArray) {
        setPopularCarDomesticList(
          carListArray
            .filter((item) => item.id === 'domestic')
            .map((item) => item.carList)
            .flat(),
        );
        setPopularCarImportedList(
          carListArray
            .filter((item) => item.id === 'imported')
            .map((item) => item.carList)
            .flat(),
        );
        setPopularCarSuvList(
          carListArray
            .filter((item) => item.id === 'suv')
            .map((item) => item.carList)
            .flat(),
        );
      }
      return;
    },
    getInterestCarList: async () => {
      if (!loginMember) {
        CarsayoToast.error('로그인을 진행해주세요!');
        navigate('/auth/login');
      } else {
        const result: InterestCarInfo[] = await getInterestCarList();
        if (result) {
          setInterestCarList(result);
        }
      }
    },
  };

  const handleClickEvents = {
    call: (callNumber: string) => {
      const telUrl = 'tel:' + callNumber;
      window.location.href = telUrl;
    },
    selecteInterestCar: async (carId: number, isInterest: boolean) => {
      try {
        const result: UpdateInterestCarDTO = await selectInterestCar(
          carId,
          !isInterest,
        );
        if (result) {
          handleVoidEvents.getPopularCarList();
          if (result.isInterest) {
            CarsayoToast.text('관심차량으로 등록했습니다.');
          } else {
            CarsayoToast.text('관심차량을 해제했습니다.');
          }
          handleVoidEvents.getInterestCarList();
          return;
        }
      } catch (error: any) {
        console.error(error);
        CarsayoToast.error(error.response.data.message);
        return;
      }
    },
    moveToInsurance: () => {
      navigate('/static/insurance');
    },
    openCooconInsurance: () => {
      addHistoryInsuranceCooconClick({
        from: `app${location.pathname}`,
        target: '쿠콘',
      });
      if (process.env.REACT_APP_NODE_ENV === 'development') {
        window.open(
          'https://insu-dev.coocon.co.kr/bridge.act?org_cd=RUQBBRKU&browser_type=2',
        );
        return;
      }
      window.open(
        'https://insu.coocon.net/bridge.act?org_cd=RUQBBRKU&browser_type=2',
      );
    },
  };

  useLayoutEffect(() => {
    setIsBottomTabbar(true);
    if (loginMember) setFcmToken();
  }, []);

  useLayoutEffect(() => {
    handleVoidEvents.getPopularCarList();
  }, []);

  useLayoutEffect(() => {
    if (loginMember) handleVoidEvents.getInterestCarList();
  }, [loginMember]);

  // 내 관심차량 리스트
  function MyInterestCar() {
    const [interestSwiper, setInterestSwiper] = useState<SwiperClass | null>(
      null,
    );
    const [interestPage, setInterestPage] = useState<number>(1);
    const [isAnimating, setIsAnimating] = useState(false);
    const handleSetInterestCarList = async () => {
      const carListArray: PopularCarCategory[] = await getPopularCarList();
      if (carListArray) {
        setPopularCarDomesticList(
          carListArray
            .filter((item) => item.id === 'domestic')
            .map((item) => item.carList)
            .flat(),
        );
        setPopularCarImportedList(
          carListArray
            .filter((item) => item.id === 'imported')
            .map((item) => item.carList)
            .flat(),
        );
        setPopularCarSuvList(
          carListArray
            .filter((item) => item.id === 'suv')
            .map((item) => item.carList)
            .flat(),
        );
      }
      const result: InterestCarInfo[] = await getInterestCarList();
      if (result) {
        setInterestCarList(result);
      }
    };
    if (interestCarList && interestCarList.length > 0) {
      return (
        <div className='relative'>
          <Swiper
            modules={[Pagination]}
            spaceBetween={50}
            slidesPerView={1}
            pagination={{ clickable: true }}
            className=' w-full h-full'
            onRealIndexChange={(swiper: SwiperClass) =>
              setInterestPage(swiper.realIndex + 1)
            }
            onTransitionStart={() => {
              setIsAnimating(true);
            }}
            onTransitionEnd={() => {
              setIsAnimating(false);
            }}
            onSwiper={(swiper: SwiperClass) => setInterestSwiper(swiper)}
          >
            {interestCarList.map((item, index) => (
              <SwiperSlide key={`interest-${index}`}>
                <InterestTemplate
                  data={item}
                  setInterestCarList={handleSetInterestCarList}
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <ul className='mt-[30px] flex justify-center items-center gap-[6px]'>
            {interestCarList.map((item, index) => (
              <li
                key={`interestCarListPagination_${item.carMaker.id}`}
                className={cn(
                  'w-2 h-2 rounded-full bg-[#00000033] transition-all',
                  index === interestPage - 1 && 'w-[22px] bg-[#111]',
                )}
              ></li>
            ))}
          </ul>
          <div
            onClick={() => interestSwiper?.slidePrev()}
            className={cn(
              'absolute left-[10px] top-[calc(27%-20px)] flex justify-center items-center w-[40px] h-[40px] bg-[#00000033] rounded-full z-[5]',
              isAnimating && 'hidden',
            )}
          >
            <ChevronLeft
              className='-ml-[2px]'
              width={24}
              height={24}
              strokeWidth={1.5}
              stroke={'#fff'}
            />
          </div>
          <div
            onClick={() => interestSwiper?.slideNext()}
            className={cn(
              'absolute right-[10px] top-[calc(27%-20px)] flex justify-center items-center w-[40px] h-[40px] bg-[#00000033] rounded-full z-[5]',
              isAnimating && 'hidden',
            )}
          >
            <ChevronRight
              className='-mr-[2px]'
              width={24}
              height={24}
              strokeWidth={1.5}
              stroke={'#fff'}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div
          className={classNames(
            'w-full bg-[#F3F6F9] mt-6 flex flex-col justify-center items-center min-h-[220px] gap-6 py-[31px]',
          )}
        >
          <div className='flex flex-col items-center'>
            <div className='flex justify-center items-center bg-[#E5EAEE] rounded-full w-[80px] h-[80px]'>
              <img
                src='/assets/images/png/gray_car.png'
                alt='회색차'
                className='w-[45px] object-contain'
              />
            </div>
          </div>
          <p className='text-[#A1A7AC] text-[18px] leading-[27px] font-[600] text-center'>
            견적 요청하면서
            <br />
            관심 차량을 등록해 보세요.
          </p>
        </div>
      );
    }
  }
  // 차량 조회 순위
  function BestCarSortingList() {
    return (
      <>
        {/* 국산차 리스트 */}
        <div
          className={classNames(
            'transition-all',
            currentBestCar === 'domestic'
              ? 'w-full block h-auto opacity-100 translate-y-0'
              : 'w-0 h-0 hidden opacity-0 translate-y-[-400px]',
          )}
        >
          {popularCarDomesticList && popularCarDomesticList.length > 0 && (
            <ol className='w-full flex flex-col gap-6'>
              {popularCarDomesticList.map((item, index) => {
                return (
                  <li
                    key={`bestCar_${item.id}`}
                    className='flex flex-row gap-[3.2vw] justify-start items-stretch cursor-pointer'
                    onClick={() =>
                      navigate(`/purchase/brand?carModelId=${item.model.id}`)
                    }
                  >
                    <div className='w-[32vw] relative rounded-[12px] px-[12px] pt-[20px] pb-[16px] bg-[#00000008] flex justify-center items-center'>
                      <span className='absolute top-0 left-0 h-[20px] w-auto px-2 bg-[#111111] text-[#fff] text-[11px]'>
                        {index + 1}
                      </span>
                      {item.model.image_url ? (
                        <img
                          src={item.model.image_url}
                          alt='인기조회차량사진'
                          className='object-contain w-full h-full'
                        />
                      ) : (
                        <img
                          src={SampleCar}
                          alt='인기조회차량사진'
                          className='object-contain'
                        />
                      )}
                    </div>
                    <div className='grow'>
                      <div className='flex flex-row justify-between items-center w-full'>
                        <p className='text-[13px] text-[#767676]'>
                          {item.maker.name ? item.maker.name : '제조사명 없음'}
                        </p>
                        <button
                          className='w-[20px] h-[20px] text-transparent indent-[-9999px]'
                          onClick={(event) => {
                            event.stopPropagation();
                            handleClickEvents.selecteInterestCar(
                              item.model.id,
                              item.isInterest,
                            );
                          }}
                        >
                          <HeartIcon
                            fill={item.isInterest ? '#DC0000' : undefined}
                            stroke={item.isInterest ? '#DC0000' : undefined}
                          />
                          관심차량버튼
                        </button>
                      </div>
                      <p className='text-[15px] text-[#555555]'>
                        {item.name ? item.name : '모델명 없음'}
                      </p>
                      <p className='font-[600] text-[#111111] mb-[6px]'>
                        <span className='mr-[3px]'>
                          {item.model.price.min
                            ? Math.floor(
                                item.model.price.min / 10000,
                              ).toLocaleString()
                            : 0}
                        </span>
                        ~
                        <span className='mx-[3px]'>
                          {item.model.price.max
                            ? Math.floor(
                                item.model.price.max / 10000,
                              ).toLocaleString()
                            : 100}
                        </span>
                        만원
                      </p>
                      <div className='flex flex-row justify-between items-center'>
                        <span className='text-[11px] text-[#111111] inline-flex justify-center items-center bg-[#F7F7FB] py-[2px] px-[4px]'>
                          {item.model?.car_category
                            ? item.model.car_category.name
                            : '카테고리 없음'}
                        </span>
                        <Link
                          to={`/purchase/brand?carModelId=${item.model.id}`}
                          className='text-[12px] cursor-pointer text-[#7273F9] inline-flex justify-center items-center gap-[2px]'
                        >
                          견적확인
                          <ArrowRight />
                        </Link>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ol>
          )}
        </div>
        {/* 수입차 리스트 */}
        <div
          className={classNames(
            'transition-all',
            currentBestCar === 'imported'
              ? 'w-full block h-auto opacity-100 translate-y-0'
              : 'w-0 h-0 hidden opacity-0 translate-y-[-400px]',
          )}
        >
          {popularCarImportedList && popularCarImportedList.length > 0 && (
            <ol className='w-full flex flex-col gap-6'>
              {popularCarImportedList.map((item, index) => {
                return (
                  <li
                    key={`bestCar_${item.id}`}
                    className='flex flex-row gap-[3.2vw] justify-start items-stretch cursor-pointer'
                    onClick={() =>
                      navigate(`/purchase/brand?carModelId=${item.model.id}`)
                    }
                  >
                    <div className='w-[32vw] relative rounded-[12px] px-[12px] pt-[20px] pb-[16px] bg-[#00000008] flex justify-center items-center'>
                      <span className='absolute top-0 left-0 h-[20px] w-auto px-2 bg-[#111111] text-[#fff] text-[11px]'>
                        {index + 1}
                      </span>
                      {item.model.image_url ? (
                        <img
                          src={item.model.image_url}
                          alt='인기조회차량사진'
                          className='object-contain w-full h-full'
                        />
                      ) : (
                        <img
                          src={SampleCar}
                          alt='인기조회차량사진'
                          className='object-contain'
                        />
                      )}
                    </div>
                    <div className='grow'>
                      <div className='flex flex-row justify-between items-center w-full'>
                        <p className='text-[13px] text-[#767676]'>
                          {item.maker.name ? item.maker.name : '제조사명 없음'}
                        </p>
                        <button
                          className='w-[20px] h-[20px] text-transparent indent-[-9999px]'
                          onClick={(event) => {
                            event.stopPropagation();
                            handleClickEvents.selecteInterestCar(
                              item.model.id,
                              item.isInterest,
                            );
                          }}
                        >
                          <HeartIcon
                            fill={item.isInterest ? '#DC0000' : undefined}
                            stroke={item.isInterest ? '#DC0000' : undefined}
                          />
                          관심차량버튼
                        </button>
                      </div>
                      <p className='text-[15px] text-[#555555]'>
                        {item.name ? item.name : '모델명 없음'}
                      </p>
                      <p className='font-[600] text-[#111111] mb-[6px]'>
                        <span className='mr-[3px]'>
                          {item.model.price.min
                            ? Math.floor(
                                item.model.price.min / 10000,
                              ).toLocaleString()
                            : 0}
                        </span>
                        ~
                        <span className='mx-[3px]'>
                          {item.model.price.max
                            ? Math.floor(
                                item.model.price.max / 10000,
                              ).toLocaleString()
                            : 100}
                        </span>
                        만원
                      </p>
                      <div className='flex flex-row justify-between items-center'>
                        <span className='text-[11px] text-[#111111] inline-flex justify-center items-center bg-[#F7F7FB] py-[2px] px-[4px]'>
                          {item.model?.car_category
                            ? item.model.car_category.name
                            : '카테고리 없음'}
                        </span>
                        <Link
                          to={`/purchase/brand?carModelId=${item.model.id}`}
                          className='text-[12px] cursor-pointer text-[#7273F9] inline-flex justify-center items-center gap-[2px]'
                        >
                          견적확인
                          <ArrowRight />
                        </Link>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ol>
          )}
        </div>
        {/* SUV 리스트 */}
        <div
          className={classNames(
            'transition-all',
            currentBestCar === 'suv'
              ? 'w-full block h-auto opacity-100 translate-y-0'
              : 'w-0 h-0 hidden opacity-0 translate-y-[-400px]',
          )}
        >
          {popularCarSuvList && popularCarSuvList.length > 0 && (
            <ol className='w-full flex flex-col gap-6'>
              {popularCarSuvList.map((item, index) => {
                return (
                  <li
                    key={`bestCar_${item.id}`}
                    className='flex flex-row gap-[3.2vw] justify-start items-stretch cursor-pointer'
                    onClick={() =>
                      navigate(`/purchase/brand?carModelId=${item.model.id}`)
                    }
                  >
                    <div className='w-[32vw] relative rounded-[12px] px-[12px] pt-[20px] pb-[16px] bg-[#00000008] flex justify-center items-center'>
                      <span className='absolute top-0 left-0 h-[20px] w-auto px-2 bg-[#111111] text-[#fff] text-[11px]'>
                        {index + 1}
                      </span>
                      {item.model.image_url ? (
                        <img
                          src={item.model.image_url}
                          alt='인기조회차량사진'
                          className='object-contain w-full h-full'
                        />
                      ) : (
                        <img
                          src={SampleCar}
                          alt='인기조회차량사진'
                          className='object-contain'
                        />
                      )}
                    </div>
                    <div className='grow'>
                      <div className='flex flex-row justify-between items-center w-full'>
                        <p className='text-[13px] text-[#767676]'>
                          {item.maker.name ? item.maker.name : '제조사명 없음'}
                        </p>
                        <button
                          className='w-[20px] h-[20px] text-transparent indent-[-9999px]'
                          onClick={(event) => {
                            event.stopPropagation();
                            handleClickEvents.selecteInterestCar(
                              item.model.id,
                              item.isInterest,
                            );
                          }}
                        >
                          <HeartIcon
                            fill={item.isInterest ? '#DC0000' : undefined}
                            stroke={item.isInterest ? '#DC0000' : undefined}
                          />
                          관심차량버튼
                        </button>
                      </div>
                      <p className='text-[15px] text-[#555555]'>
                        {item.name ? item.name : '모델명 없음'}
                      </p>
                      <p className='font-[600] text-[#111111] mb-[6px]'>
                        <span className='mr-[3px]'>
                          {item.model.price.min
                            ? Math.floor(
                                item.model.price.min / 10000,
                              ).toLocaleString()
                            : 0}
                        </span>
                        ~
                        <span className='mx-[3px]'>
                          {item.model.price.max
                            ? Math.floor(
                                item.model.price.max / 10000,
                              ).toLocaleString()
                            : 100}
                        </span>
                        만원
                      </p>
                      <div className='flex flex-row justify-between items-center'>
                        <span className='text-[11px] text-[#111111] inline-flex justify-center items-center bg-[#F7F7FB] py-[2px] px-[4px]'>
                          {item.model?.car_category
                            ? item.model.car_category.name
                            : '카테고리 없음'}
                        </span>
                        <Link
                          to={`/purchase/brand?carModelId=${item.model.id}`}
                          className='text-[12px] cursor-pointer text-[#7273F9] inline-flex justify-center items-center gap-[2px]'
                        >
                          견적확인
                          <ArrowRight />
                        </Link>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ol>
          )}
        </div>
      </>
    );
  }

  return (
    <main className='bg-[#F3F6F9] text-sm'>
      <section className='main-swiper bg-white !min-h-[200px]'>
        {/* 메인 스와이퍼 영역 */}
        {/* <MainSwiperContainer /> */}
        <MainSwiperSection />
      </section>
      {/* nav Section */}
      <section className='bg-white pt-[40px] px-[20px] mb-2'>
        <MainNavSection />
      </section>
      <section className='bg-white pt-[40px] pb-[40px] px-[20px] mb-2'>
        <MainInsuranceSection />
      </section>
      {/* <CarsayoBanner bannerCategoryId={20001000} pagination='bullet-tr' /> */}
      {/* 차량 조회 순위 */}
      {/* <div className='bg-white px-4 py-6'>
        <div className='w-full bg-white lg:max-w-[600px] lg:mx-auto md:max-w-[600px] md:mx-auto'>
          <div className='flex items-center justify-between'>
            <div className='text-xl font-semibold'>차량 조회 순위</div>
            <div className='text-xs text-[#767676]'>
              현재 카사요에서 많이 찾고 있어요
            </div>
          </div>
          <div className='my-4'>
            <ul
              className={cn(
                'w-full flex justify-evenly items-center relative',
                'after:content-[""] after:absolute after:w-[calc(100%/3)] after:h-[1px] after:bg-[#7273F9] after:transition-all',
                `after:bottom-0 after:left-0 ${currentBestCar === 'domestic' ? 'after:translate-x-0' : currentBestCar === 'imported' ? 'after:translate-x-[100%]' : 'after:translate-x-[200%]'}`,
              )}
            >
              <li
                className={`w-full cursor-pointer transition-all py-[0.875rem] px-4 text-center border-b-[1px] box-border border-[#E5E5EC]`}
                onClick={() => setCurrentBestCar('domestic')}
              >
                <span>국산차</span>
              </li>
              <li 
                className={`w-full cursor-pointer transition-all py-[0.875rem] px-4 text-center border-b-[1px] box-border border-[#E5E5EC]`}
                onClick={() => setCurrentBestCar('imported')}
              >
                <span>수입차</span>
              </li>
              <li
                className={`w-full cursor-pointer transition-all py-[0.875rem] px-4 text-center border-b-[1px] box-border border-[#E5E5EC]`}
                onClick={() => setCurrentBestCar('suv')}
              >
                <span>SUV</span>
              </li>
            </ul>
          </div>
          <BestCarSortingList></BestCarSortingList>
        </div>
      </div> */}
      {/* 내 관심 차량 */}
      <div className='mt-2 bg-white pt-[40px] pb-[50px]'>
        <div className='w-full bg-white lg:max-w-[600px] lg:mx-auto md:max-w-[600px] md:mx-auto'>
          <div className='flex items-center justify-between  px-5'>
            <div className='text-[22px] leading-[33px] font-[600] max-w-[178px] break-keep'>
              내 관심 차량에 대한 견적을 비교해 드려요
            </div>
          </div>
          <MyInterestCar></MyInterestCar>
        </div>
      </div>
      {/* 간편 메뉴 영역 */}
      <ShortNavSection
        isInterestCarList={
          interestCarList && interestCarList.length > 0 ? true : false
        }
      />
      {/* 이용후기 영역 */}
      {/* <ReviewContainer /> */}
      {/* <CarsayoBanner
        bannerCategoryId={20002000}
        pagination='bullet-tr'
        className='mt-3'
      /> */}
      {/* <div className='bg-white px-4 py-6'>
        <div className='w-full bg-white lg:max-w-[600px] lg:mx-auto md:max-w-[600px] md:mx-auto'>
          <div className='flex items-center justify-between'>
            <div className='flex gap-2'>
              <div className='text-xl font-semibold'>믿으니까</div>
              <img
                src='/assets/images/v2/carsayo.png'
                alt='CARSAYO'
                className='h-7'
              />
            </div>
            <div className='text-xs text-[#767676]'>고객만족 프로세스</div>
          </div>
          <div className='mt-6 space-y-4'>
            <div className='flex flex-wrap'>
              <div className='flex flex-1 items-center gap-4'>
                <div className='h-[22px] w-[22px] bg-[#DF732C] text-center text-[13px] leading-[22px] text-white'>
                  01
                </div>
                <div className='text-[15px] font-semibold leading-[22px]'>
                  견적 요청
                </div>
              </div>
              <div className='w-10 shrink-0'>
                <img
                  src='/assets/images/v2/requestForm.svg'
                  alt='request form icon'
                />
              </div>
              <div className='flex flex-1 items-center justify-end text-[#555555]'>
                원하시는 차량 선택
              </div>
              <div className='mt-2 flex w-full justify-center'>
                <div className='h-5 w-0.5 bg-[#BDBDBD]'></div>
              </div>
            </div>
            <div className='flex flex-wrap'>
              <div className='flex flex-1 items-center gap-4'>
                <div className='h-[22px] w-[22px] bg-[#DF732C] text-center text-[13px] leading-[22px] text-white'>
                  02
                </div>
                <div className='text-[15px] font-semibold leading-[22px]'>
                  견적 비교{' '}
                </div>
              </div>
              <div className='w-10 shrink-0'>
                <img
                  src='/assets/images/v2/quoteComparison.svg'
                  alt='request form icon'
                />
              </div>
              <div className='flex flex-1 items-center justify-end text-[#555555]'>
                카 매니저 조건 확인
              </div>
              <div className='mt-2 flex w-full justify-center'>
                <div className='h-5 w-0.5 bg-[#BDBDBD]'></div>
              </div>
            </div>
            <div className='flex flex-wrap'>
              <div className='flex flex-1 items-center gap-4'>
                <div className='h-[22px] w-[22px] bg-[#DF732C] text-center text-[13px] leading-[22px] text-white'>
                  03
                </div>
                <div className='text-[15px] font-semibold leading-[22px]'>
                  카 매니저 선택
                </div>
              </div>
              <div className='w-10 shrink-0'>
                <img
                  src='/assets/images/v2/selectDealer.svg'
                  alt='request form icon'
                />
              </div>
              <div className='flex flex-1 items-center justify-end text-[#555555]'>
                48시간 여유있게 검토
              </div>
              <div className='mt-2 flex w-full justify-center'>
                <div className='h-5 w-0.5 bg-[#BDBDBD]'></div>
              </div>
            </div>
            <div className='flex flex-wrap'>
              <div className='flex flex-1 items-center gap-4'>
                <div className='h-[22px] w-[22px] bg-[#DF732C] text-center text-[13px] leading-[22px] text-white'>
                  04
                </div>
                <div className='text-[15px] font-semibold leading-[22px]'>
                  카 매니저 상담
                </div>
              </div>
              <div className='w-10 shrink-0'>
                <img
                  src='/assets/images/v2/finalDecision.svg'
                  alt='request form icon'
                />
              </div>
              <div className='flex flex-1 items-center justify-end text-[#555555]'>
                통화 및 궁금증 확인
              </div>
              <div className='mt-2 flex w-full justify-center'>
                <div className='h-5 w-0.5 bg-[#BDBDBD]'></div>
              </div>
            </div>
            <div className='flex flex-wrap'>
              <div className='flex flex-1 items-center gap-4'>
                <div className='h-[22px] w-[22px] bg-[#DF732C] text-center text-[13px] leading-[22px] text-white'>
                  05
                </div>
                <div className='text-[15px] font-semibold leading-[22px]'>
                  최종 결정
                </div>
              </div>
              <div className='w-10 shrink-0'>
                <img
                  src='/assets/images/v2/requestForm.svg'
                  alt='request form icon'
                />
              </div>
              <div className='flex flex-1 items-center justify-end text-[#555555]'>
                협의된 내용으로 출고
              </div>
              <div className='mt-2 flex w-full justify-center'>
                <div className='h-5 w-0.5 bg-[#BDBDBD]'></div>
              </div>
            </div>
          </div>
          <div className='mt-6 flex gap-2 overflow-x-auto lg:justify-between md:justify-between'>
            <div className='flex h-[100px] w-[188px] shrink-0 items-center gap-4 bg-[#F7F7FB] px-4 py-5'>
              <div className='shrink-0'>
                <img
                  src='/assets/images/v2/contractProcess.svg'
                  alt='contract process icon'
                />
              </div>
              <div>
                <div className='text-[13px] font-medium'>차량 계약 과정</div>
                <div className='mt-2 text-xs leading-[18px] text-[#767676]'>
                  계약 시 불편함이 있다면 카사요가 도와드립니다.
                </div>
              </div>
            </div>
            <div className='flex h-[100px] w-[188px] shrink-0 items-center gap-4 bg-[#F7F7FB] px-4 py-5'>
              <div className='shrink-0'>
                <img
                  src='/assets/images/v2/purchaseProcess.svg'
                  alt='purchase process icon'
                />
              </div>
              <div>
                <div className='text-[13px] font-semibold'>차량 구매 과정</div>
                <div className='mt-2 text-xs leading-[18px] text-[#767676]'>
                  딜러의 약속 불이행은 확인 및 조치해드립니다.
                </div>
              </div>
            </div>
            <div className='flex h-[100px] w-[188px] shrink-0 items-center gap-4 bg-[#F7F7FB] px-4 py-5'>
              <div className='shrink-0'>
                <img
                  src='/assets/images/v2/problemSolving.svg'
                  alt='purchase process icon'
                />
              </div>
              <div>
                <div className='text-[13px] font-semibold'>문제 해결 과정</div>
                <div className='mt-2 text-xs leading-[18px] text-[#767676]'>
                  문제 발생 시 카사요가 중재해 드립니다.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* 공지사항 */}
      {/* <div className='mt-3 bg-white px-4 py-2'>
        <div className='w-full bg-white lg:max-w-[600px] lg:mx-auto md:max-w-[600px] md:mx-auto'>
          <NoticeAccordion expanded={expanded} setExpanded={setExpanded} />
        </div>
      </div> */}
      {/* bg-gradient-to-b from-white to-[#F1F1F5] */}
      {/* footer */}
      <MainFooter />
      {/* <div className='bg-[#F7F7FB] px-4 pb-8 pt-6'>
        <Link
          to={'/mypage/termList'}
          className='-mx-4 flex items-center justify-center text-[#505050]'
        >
          <div className='px-[2vw]'>이용약관</div>
          <div className='h-3 w-[1px] bg-[#E5E5EC]'></div>
          <div className='px-[2vw]'>개인정보 처리방침</div>
          <div className='h-3 w-[1px] bg-[#E5E5EC]'></div>
          <div className='px-[2vw]'>금융 소비자 보호법 등록증</div>
        </Link>
        <div
          className='mt-6 flex justify-center gap-1'
          onClick={() => setIsCopyright(!isCopyright)}
        >
          <img
            src='/assets/images/v2/footerCarsayo.png'
            alt='logo'
            className='h-4'
          />
          {!isCopyright ? (
            <img
              src='/assets/images/v2/chevronBottom.svg'
              alt='chevron bottom'
            />
          ) : (
            <img src='/assets/images/v2/chevronTop.svg' alt='chevron top' />
          )}
        </div>

        <AnimatePresence initial={false}>
          {isCopyright && (
            <motion.div
              initial='collapsed'
              animate='open'
              exit='collapsed'
              variants={{
                open: { opacity: 1, height: 'auto' },
                collapsed: { opacity: 0, height: 0 },
              }}
              className='pt-8 text-center text-[13px] text-[#767676]'
            >
              <div className='space-y-1.5'>
                <div>카사요(주) 대표 : 최용민</div>
                <div>
                  <span className='text-[13px] text-[#111111]'>벤처기업</span>{' '}
                  20240514030044 (혁신성장형)
                </div>
                <div>(04790) 서울특별시 성동구 아차산로 49, 1403호</div>
                <div className='flex justify-center gap-2'>
                  <div>사업자등록번호 : 142-87-01847</div>
                </div>
                <div>통신판매업신고 : 제 2021-서울성동-00533</div>
                <div>이메일 : info@carsayo.net</div>
              </div>
              <div
                onClick={() => handleClickEvents.call('16681571')}
                className='mt-3 text-[#111111] underline underline-offset-4'
              >
                고객센터 : 1668-1571
              </div>
              <div className='mt-8'>
                Copyright ⓒ 카사요(주) ALL RIGHTS RESERVED.
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div> */}
    </main>
  );
}

export default CustomerMainPage;
