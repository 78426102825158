import { Holiday } from '@carsayo/types';
import { getHoliday } from 'apis/data';
import create from 'zustand';

interface DataStore {
  holiday: Holiday[];
  fetchHoliday: () => Promise<void>;
}

interface DataStore {
  holiday: Holiday[]; // 공휴일 데이터
  isLoading: boolean; // 로딩 상태
  error: string | null; // 에러 메시지
  fetchHoliday: () => Promise<void>; // 공휴일 데이터 가져오기 메서드
}

export const useDataStore = create<DataStore>((set) => {
  const fetchHoliday = async () => {
    set({ isLoading: true, error: null });
    try {
      const data = await getHoliday(); // 서버에서 공휴일 데이터 가져오기
      set({ holiday: data, isLoading: false });
    } catch (error: any) {
      console.error('Failed to fetch holiday:', error);
      set({ error: error.message, isLoading: false });
    }
  };

  return {
    holiday: [], // 초기값
    isLoading: true,
    error: null,
    fetchHoliday,
  };
});
