import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import ko from 'date-fns/locale/ko';
import { useState } from 'react';
import { cn } from 'utils';
import dayjs from 'dayjs';

export default function CustomDatePicker({
  type,
  value,
  onChange,
  disabled,
  initValue,
}: {
  type?: 'white';
  value?: Date | null;
  onChange?: (newValue: Date | null) => void;
  disabled?: boolean;
  initValue?: Date | null;
}) {
  const [dateValue, setDateValue] = useState<Date | null>(
    value ? value : initValue ?? null,
  );

  return (
    <div
      className={cn(
        'relative w-full',
        type === 'white' ? 'h-[50px]' : 'h-[44px]',
      )}
    >
      <span className='absolute left-4 top-1/2 -translate-y-1/2 z-[1] h-[20px]'>
        {type === 'white' ? (
          <img
            src='/assets/images/v2/calendar-black.png'
            alt='달력 아이콘'
            className='h-[20px] w-[20px] object-contain mt-[0.5px]'
          />
        ) : (
          <img
            src='/assets/images/v2/calendar.png'
            alt='달력 아이콘'
            className='h-[20px] w-[20px] object-contain mt-[0.5px]'
          />
        )}
      </span>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={ko}
        dateFormats={{
          normalDate: 'yyyy.MM.dd',
          year: 'yyyy년',
          month: 'MM월',
        }}
        localeText={{
          previousMonth: '이전 달',
          nextMonth: '다음 달',
          fieldClearLabel: '초기화',
          cancelButtonLabel: '닫기',
          okButtonLabel: value
            ? `${dayjs(value).format('YYYY년 MM월 DD일')} 적용`
            : `적용`,
          datePickerToolbarTitle: '날짜 선택',
        }}
      >
        <DatePicker
          disabled={disabled}
          disableOpenPicker
          label='날짜 선택'
          value={value ? value : dateValue ?? initValue}
          onChange={
            onChange
              ? (newValue) => onChange(newValue)
              : (newValue) => setDateValue(newValue)
          }
          slotProps={{
            calendarHeader: {
              format: 'yyyy년 MM월',
              sx: {
                '& .MuiPickersCalendarHeader-label': {
                  fontFamily: 'Pretendard',
                  letterSpacing: '-0.02em',
                },
              },
            },
            toolbar: {
              sx: {
                '&': {
                  paddingBottom: '0px',
                  alignItems: 'center',
                },
                '& .MuiTypography-root': {
                  fontSize: '16px',
                  fontFamily: 'Pretendard',
                  letterSpacing: '-0.02em',
                  color: '#222',
                  fontWeight: '500',
                },
                '& .MuiPickersToolbar-content': {
                  justifyContent: 'center',
                  '& h4': {
                    display: 'none',
                  },
                },
              },
            },
            actionBar: {
              sx: {
                '& .MuiButtonBase-root': {
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  whiteSpace: 'nowrap',
                  fontWeight: '500',
                  color: '#222',
                  padding: '18px',
                  height: '44px',
                  borderRadius: '8px',
                  '&:first-child': {
                    width: '30%',
                    border: '1px solid #ddd',
                  },
                  '&:last-child': {
                    width: '70%',
                    backgroundColor: '#7273F9',
                    color: '#fff',
                  },
                },
              },
            },
            layout: {
              sx: {
                '.MuiPaper-root': {
                  margin: '0px',
                },
                '.MuiDayCalendar-weekDayLabel': {
                  fontFamily: 'Pretendard',
                  fontSize: '13px',
                },
                '.MuiDayCalendar-root': {
                  fontFamily: 'Pretendard',
                },
                '.MuiButtonBase-root.Mui-selected': {
                  backgroundColor: '#7273F9 !important',
                },
              },
            },
          }}
          sx={{
            width: '100%',
            height: type === 'white' ? '50px' : '44px',
            '& .MuiInputBase-root': {
              paddingLeft: '28px',
              border: type === 'white' ? '1px solid #E5E5EC' : 'none',
              backgroundColor: type === 'white' ? 'white' : '#F1F4F7',
              borderRadius: '10px',
              height: type === 'white' ? '50px' : '44px',
              padding: '12px 16px',
              marginTop: '1px',
              color: type === 'white' ? '#222' : '#7D848A',
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              fontSize: '15px',
              lineHeight: '15px',
            },
            '& .MuiFormLabel-root': {
              display: value ? 'none' : 'block',
              paddingLeft: '28px',
              fontSize: '15px',
              lineHeight: '14px',
              fontWeight: '500',
              fontFamily: 'Pretendard',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              display: 'none',
            },
            '& .MuiInputBase-input': {
              fontSize: '15px',
              lineHeight: '15px',
              fontWeight: '500',
              fontFamily: 'Pretendard',
              padding: '0px 16px 0px 28px',
            },
            '& .MuiPickersToolbar-content': {
              fontFamily: 'Pretendard',
              '& .MuiTypography-root': {
                fontSize: '18px',
              },
            },
          }}
        />
      </LocalizationProvider>
    </div>
  );
}
