import NoData from 'components/common/v3/NoData';

const RepairEmpty = () => {
  return (
    <div className='w-full h-full flex flex-col justify-start items-center gap-[20px] mt-[45%]'>
      {/* <div className='w-[80px] h-[80px] flex justify-center items-center p-[20px] bg-[#F6F6FC] rounded-full'>
        <img
          src='/assets/images/v2/car-repair.svg'
          alt='차량정비아이콘'
          className='w-[40px] object-contain'
        />
      </div>
      <p className='text-[16px] leading-[24px] text-[#555555]'>
        공업사 정보가 없습니다.
      </p> */}
      <NoData
        text='공업사 정보가 없어요'
        imgUrl='/assets/images/v2/car-repair.png'
      />
    </div>
  );
};

export default RepairEmpty;
