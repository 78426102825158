export default function SettlementIcon({
  fill,
  width,
  height,
}: {
  fill?: string;
  width?: string;
  height?: string;
}) {
  return (
    <svg
      width={width || '30'}
      height={height || '30'}
      viewBox='0 0 30 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_433_35271)'>
        <path
          d='M8 25.2857V8.14286C8 7.57454 8.22576 7.02949 8.62763 6.62763C9.02949 6.22576 9.57454 6 10.1429 6H20.8571C21.4255 6 21.9705 6.22576 22.3724 6.62763C22.7742 7.02949 23 7.57454 23 8.14286V25.2857L19.7857 23.1429L17.6429 25.2857L15.5 23.1429L13.3571 25.2857L11.2143 23.1429L8 25.2857ZM12.2857 10.2857H18.7143H12.2857ZM12.2857 14.5714H18.7143H12.2857ZM16.5714 18.8571H18.7143H16.5714Z'
          fill={fill || '#B2B7BB'}
        />
        <path
          d='M12.2857 10.2857H18.7143M12.2857 14.5714H18.7143M16.5714 18.8571H18.7143M8 25.2857V8.14286C8 7.57454 8.22576 7.02949 8.62763 6.62763C9.02949 6.22576 9.57454 6 10.1429 6H20.8571C21.4255 6 21.9705 6.22576 22.3724 6.62763C22.7742 7.02949 23 7.57454 23 8.14286V25.2857L19.7857 23.1429L17.6429 25.2857L15.5 23.1429L13.3571 25.2857L11.2143 23.1429L8 25.2857Z'
          stroke={fill || '#B2B7BB'}
          strokeWidth='1.7037'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M12.2852 10.2856H18.7137M12.2852 14.5714H18.7137M16.5709 18.8571H18.7137'
          stroke='white'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_433_35271'>
          <rect width='30' height='30' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
