/* eslint-disable @typescript-eslint/no-unused-vars */
import { ArrowUp, ChevronDown, Filter, X } from 'lucide-react';
import PayCard from './PayCard';
import NoPay from './NoPay';
import useSystemStore from 'store/useSystemStore';
import { useEffect, useLayoutEffect, useState } from 'react';
import SelectV3, { SelectV3Item } from 'components/common/v3/selectv3';
import { cn } from 'utils';
import { useGetPaymentList } from '../mypage/query';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
} from 'components/ui/drawer';
import { Button } from 'components/ui/button';

const takeCount: number = 10;

const Filtering = {
  all: '전체',
  used: '결제완료',
  virtualAccountWaiting: '입금 대기',
  canceled: '결제취소',
};

type TFiltering = keyof typeof Filtering;

export default function IncidentPay() {
  const { setTopTabbarState, setIsBottomTabbar } = useSystemStore();
  const [sort, setSort] = useState('desc');
  const [filter, setFilter] = useState('all');
  const [isOpen, setIsOpen] = useState(false);
  const [searchPaymentDTO, setSearchPaymentDTO] = useState({
    take: takeCount,
    create_at: 'desc',
    skip: 0,
  });

  const {
    data: payListData,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    isFetching,
    refetch,
  } = useGetPaymentList(searchPaymentDTO);

  useEffect(() => {
    setSearchPaymentDTO((prev) => ({
      ...prev,
      skip: 0,
      create_at: sort === 'asc' ? 'asc' : 'desc',
    }));
    refetch();
  }, [sort]);

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      rightElement: undefined,
      leftElement: 'back',
      title: '결제내역',
    });

    setIsBottomTabbar(false);
  }, []);

  if (isFetching) return <></>;

  return (
    <div className='w-full h-full pb-[50px] bg-[#F3F6F9] px-[20px] py-[30px] overflow-y-hidden'>
      {payListData && payListData.pages[0].totalCnt > 0 && (
        <strong className='text-[20px] leading-[32px] font-[600] inline-block mb-6'>
          {payListData?.pages[0].totalCnt ?? 0}건의 결제내역이 있어요.
        </strong>
      )}

      {payListData && payListData.pages[0].totalCnt > 0 ? (
        <div className='w-full h-full overflow-y-hidden pb-[40px]'>
          <div className='flex justify-between items-center'>
            <div></div>
            {/* <div className='flex gap-[20px] justify-between'>
              <button
                className='flex min-w-[78px] shrink-0 items-center justify-center gap-[4px] rounded-[100px] bg-white py-[10px] pl-[14px] pr-[8px]'
                onClick={() => setIsOpen(true)}
              >
                <span className='text-[14px] font-[600] leading-[14px]'>
                  {Filtering[filter as TFiltering] || '전체'}
                </span>
                <ChevronDown color='#666666' width={18} height={18} />
              </button>
            </div> */}
            {/* <div className='relative'>
              <SelectV3
                type={'noBorder'}
                value={selectedData}
                onValueChange={(e) => setSelectedData(e)}
                placeholder=''
              >
                <SelectV3Item value='all'>전체</SelectV3Item>
                <SelectV3Item value='all2'>전체222</SelectV3Item>
                <SelectV3Item value='all3'>전체333</SelectV3Item>
              </SelectV3>
            </div> */}
            <button
              onClick={() => {
                setSort(sort === 'asc' ? 'desc' : 'asc');
              }}
              className='flex justify-center items-center gap-1'
            >
              <span className='text-[14px] leading-[14px] font-[600] text-[#555]'>
                최신일자
              </span>
              <ArrowUp
                width={16}
                height={16}
                color='#7273F9'
                className={cn(
                  'transition-all',
                  searchPaymentDTO.create_at === 'asc'
                    ? 'rotate-0'
                    : 'rotate-180',
                )}
              />
            </button>
          </div>
          <div
            className='w-full h-full overflow-y-auto mt-2'
            id='paymentScroll'
          >
            <InfiniteScroll
              next={fetchNextPage}
              hasMore={hasNextPage}
              loader={
                <div className='font-semibold text-center py-4'>
                  정보를 불러오는 중입니다..
                </div>
              }
              dataLength={
                payListData?.pages.flatMap((page) => page.datas).length || 0
              }
              scrollableTarget='paymentScroll'
            >
              <ul className='w-full flex flex-col justify-start items-start gap-[20px]'>
                {payListData.pages
                  .flatMap((page) => page.datas)
                  .map((item, index) => (
                    <li key={index} className='w-full'>
                      <PayCard item={item} refetch={refetch} />
                    </li>
                  ))}
              </ul>
            </InfiniteScroll>
          </div>
        </div>
      ) : (
        <div className='w-full'>
          <NoPay />
        </div>
      )}

      <Drawer open={isOpen} onOpenChange={setIsOpen}>
        <DrawerContent className='flex flex-col'>
          <DrawerHeader className='relative'>
            <DrawerTitle>필터</DrawerTitle>
            <DrawerClose asChild>
              <Button className='absolute right-5 top-4 h-7 w-7 border-0 bg-transparent p-0 text-[#111111]'>
                <X className='h-7 w-7' onClick={() => setIsOpen(false)} />
              </Button>
            </DrawerClose>
          </DrawerHeader>
          <div className='p-[20px]'>
            <div className='text-[#222222] text-[15px] font-[600] mb-[16px]'>
              결제 상태
            </div>
            <ul className='flex gap-[6px] mb-[80px]'>
              {Object.keys(Filtering).map((el, index) => (
                <li
                  key={`filter_${index}`}
                  className={cn(
                    'py-[13px] rounded-[18px] border w-[79px] text-center',
                    filter === el ? 'border-[#7273F9]' : 'border-[#E5E5EC]',
                  )}
                >
                  <button type='button' onClick={() => setFilter(el)}>
                    {Filtering[el as TFiltering]}
                  </button>
                </li>
              ))}
            </ul>
            <div className='flex gap-[10px]'>
              <Button
                type='button'
                className='w-[85px] border border-[#E5E5EC] text-[#222222] bg-white'
                onClick={() => {
                  setFilter('all');
                }}
              >
                초기화
              </Button>
              <Button type='button' className='w-full'>
                적용
              </Button>
            </div>
          </div>
        </DrawerContent>
      </Drawer>
    </div>
  );
}
