/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { zodResolver } from '@hookform/resolvers/zod';
import { FormHelperText } from '@mui/material';
import { X } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { AccidentReport } from '@carsayo/types';

import {
  AccidentReportDataRepairFinishForm,
  AccidentReportDataRepairFinishFormDataKeys,
  AccidentReportDataRepairFinishSchema,
} from '../_forms/repair';
import { cn } from 'utils';
import V3_Input from 'components_v3/ui/Input';
import MultiImageViewer from 'components_v3/dialog/MultiImageViewer';

export default function TabRepairFinishInfo({
  targetAccidentReport,
  refetch,
}: {
  targetAccidentReport: AccidentReport;
  refetch: () => void;
}) {
  if (!targetAccidentReport.info_repair_finish) return null;

  const form = useForm<AccidentReportDataRepairFinishForm>({
    mode: 'onSubmit',
    resolver: zodResolver(AccidentReportDataRepairFinishSchema),
    defaultValues: {
      pictures: targetAccidentReport?.info_repair_finish?.pictures ?? [],
      price: targetAccidentReport?.info_repair_finish?.price ?? {
        repairCost: {
          labor: 0,
          parts: 0,
        },
        discountCost: {
          insurance: 0,
        },
        claimAmount: {
          subtotal: 0,
          tax: 0,
          total: 0,
        },
      },
    },
    shouldFocusError: true,
  });

  const handleNumber = (
    e: React.ChangeEvent<HTMLInputElement>,
    key: AccidentReportDataRepairFinishFormDataKeys,
  ) => {
    const rawValue = e.target.value.replace(/,/g, '');
    const numericValue = Number(rawValue);
    if (!isNaN(numericValue)) {
      form.setValue(key, numericValue, {
        shouldValidate: true,
      });
    }
  };

  useEffect(() => {
    const subtotal: number =
      (form.getValues('price.repairCost')?.labor ?? 0) +
      (form.getValues('price.repairCost')?.parts ?? 0) -
      (form.getValues('price.discountCost')?.insurance ?? 0);

    /** VAT 10% */
    const tax: number = Math.floor(subtotal * 0.1);

    const updateTotal = {
      subtotal: subtotal,
      tax: tax,
      total: subtotal + tax,
    };

    form.setValue('price.claimAmount', updateTotal, {
      shouldValidate: true,
    });
  }, [
    form.getValues('price.repairCost.labor'),
    form.getValues('price.repairCost.parts'),
    form.getValues('price.discountCost.insurance'),
  ]);

  /** 여기선 수정 불가능해서 두개가 같음 */
  const isDisabled = true;

  const [isImageViewerOpen, setIsImageViewerOpen] = useState(false);
  const [imageViewerIndex, setImageViewerIndex] = useState(0);

  if (!targetAccidentReport.info_repair_finish) return null;

  return (
    <div key={targetAccidentReport.id + 'finish'} className=''>
      <strong className='mb-[20px] flex items-center justify-start pt-[10px] text-[18px] font-[600] leading-[18px] text-[#000]'>
        수리 완료 정보
      </strong>
      <div className='mb-[20px] w-full rounded-[16px] bg-white px-4 py-[20px] shadow-[0px_6px_20px_#0000000D]'>
        <FormProvider {...form}>
          <form
            action=''
            className='flex flex-col items-start justify-start gap-6'
          >
            <div className='w-full'>
              <div
                className={cn(
                  'flex w-full items-center justify-between',
                  form.getValues('pictures').length > 0
                    ? 'mb-[13px]'
                    : 'mb-[20px]',
                )}
              >
                <span className='inline-block text-[15px] leading-[15px] text-[#7D848A]'>
                  수리 완료 사진
                </span>
                {form.getValues('pictures').length > 0 && (
                  <button
                    type='button'
                    onClick={() => {
                      setIsImageViewerOpen(true);
                    }}
                    className='rounded-[8px] bg-[#F3F6F9] p-2 text-[13px] font-[500] leading-[13px]'
                  >
                    전체보기
                  </button>
                )}
              </div>
              <div className='w-full overflow-x-auto'>
                <div className='flex items-center justify-start gap-[6px]'>
                  <ul className='flex items-center justify-start gap-[6px] pr-4'>
                    {form.getValues('pictures').map((item, index) => (
                      <li
                        className='relative h-[97px] w-[97px] overflow-hidden rounded-[6px]'
                        onClick={() => {
                          setImageViewerIndex(index);
                          setIsImageViewerOpen(true);
                        }}
                        key={item}
                      >
                        <img
                          src={item}
                          alt={'입고 이미지'}
                          className='h-full w-full object-cover'
                        />
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            const currentData = (form.getValues('pictures') ||
                              []) as string[];

                            const filteredData = currentData.filter(
                              (el: string) => el !== item,
                            );

                            if (filteredData.length === 0) {
                              form.setValue('pictures', [] as any, {
                                shouldValidate: true,
                              });
                            } else {
                              form.setValue('pictures', filteredData as any, {
                                shouldValidate: true,
                              });
                            }
                          }}
                          className={cn(
                            'absolute right-1 top-1 z-10 flex h-[20px] w-[20px] items-center justify-center rounded-full bg-[#000000CC]',
                            isDisabled && 'hidden',
                          )}
                        >
                          <X width={12} height={12} color='#fff' />
                        </button>
                      </li>
                    ))}
                  </ul>

                  <MultiImageViewer
                    isOpen={isImageViewerOpen}
                    setIsOpen={setIsImageViewerOpen}
                    initialIndex={imageViewerIndex}
                    fileUrl={form.getValues('pictures')}
                  />
                </div>

                {form.formState.errors.pictures && (
                  <FormHelperText
                    sx={{
                      color: '#FF4D4D',
                      fontSize: '12px',
                      lineHeight: '20px',
                      letterSpacing: '0.4px',
                      fontWeight: '400',
                      marginX: '14px',
                      marginTop: '3px !important',
                    }}
                  >
                    {form.formState.errors.pictures.message}
                  </FormHelperText>
                )}
              </div>

              <div className='w-full'>
                <div className='w-full'>
                  <div className='mb-6 mt-[26px] flex w-full items-center justify-between border-b border-[#222222] pb-[22px] font-[500]'>
                    <span>수리 금액</span>
                    <strong className='text-[15px] font-[500] leading-[15px] text-[#7D848A]'>
                      <span className='pr-1 text-[16px] font-[600] leading-[16px] text-[#222]'>
                        {(
                          (form.getValues('price')?.repairCost.labor ?? 0) +
                          (form.getValues('price')?.repairCost.parts ?? 0)
                        ).toLocaleString() ?? 0}
                      </span>
                      원
                    </strong>
                  </div>
                  <div className='mb-[6px] flex w-full flex-col items-start justify-start gap-[10px]'>
                    <div className='flex w-full items-center justify-start gap-[12px]'>
                      <span className='block shrink-0 text-[15px] font-[500] leading-[15px] text-[#7D848A]'>
                        부품
                      </span>
                      <V3_Input
                        type='text'
                        theme='secondary'
                        variant='outlined'
                        disabled={isDisabled}
                        inUnit='원'
                        value={
                          form
                            .getValues('price')
                            ?.repairCost?.parts?.toLocaleString() ?? 0
                        }
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          handleNumber(e, 'price.repairCost.parts');
                        }}
                        error={!!form.formState.errors.price?.repairCost?.parts}
                        helperText={
                          form.formState.errors.price?.repairCost?.parts
                            ?.message
                        }
                      />
                    </div>
                    <div className='flex w-full items-center justify-start gap-[12px]'>
                      <span className='block shrink-0 text-[15px] font-[500] leading-[15px] text-[#7D848A]'>
                        공임
                      </span>
                      <V3_Input
                        type='text'
                        theme='secondary'
                        variant='outlined'
                        disabled={isDisabled}
                        inUnit='원'
                        value={
                          form
                            .getValues('price')
                            ?.repairCost?.labor?.toLocaleString() ?? 0
                        }
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          handleNumber(e, 'price.repairCost.labor');
                        }}
                        error={!!form.formState.errors.price?.repairCost?.labor}
                        helperText={
                          form.formState.errors.price?.repairCost?.labor
                            ?.message
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='w-full'>
                <div className='w-full'>
                  <div className='mb-6 mt-[26px] flex w-full items-center justify-between border-b border-[#222222] pb-[22px] font-[500]'>
                    <span>차감 금액</span>
                    <strong className='text-[15px] font-[500] leading-[15px] text-[#7D848A]'>
                      <span className='text-[#2 pr-1 text-[16px] font-[600] leading-[16px]'>
                        {form
                          .getValues('price')
                          ?.discountCost?.insurance?.toLocaleString() ?? 0}
                      </span>
                      원
                    </strong>
                  </div>
                  <div className='mb-[6px] flex w-full flex-col items-start justify-start gap-[10px]'>
                    <div className='flex w-full items-center justify-start gap-[12px]'>
                      <span className='block shrink-0 text-[15px] font-[500] leading-[15px] text-[#7D848A]'>
                        보험
                      </span>
                      <V3_Input
                        type='text'
                        theme='secondary'
                        variant='outlined'
                        disabled={isDisabled}
                        inUnit='원'
                        value={
                          form
                            .getValues('price')
                            ?.discountCost?.insurance?.toLocaleString() ?? 0
                        }
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          handleNumber(e, 'price.discountCost.insurance');
                        }}
                        error={
                          !!form.formState.errors.price?.discountCost?.insurance
                        }
                        helperText={
                          form.formState.errors.price?.discountCost?.insurance
                            ?.message
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='w-full'>
                <div className='w-full'>
                  <div className='mb-[20px] mt-[26px] flex w-full items-center justify-between border-b border-[#222222] pb-[22px] font-[500]'>
                    <span>청구 금액</span>
                  </div>
                  <div className='flex w-full flex-col items-start justify-start gap-[20px]'>
                    <div className='flex w-full items-center justify-between gap-[10px]'>
                      <span className='block shrink-0 text-[15px] font-[500] leading-[15px] text-[#7D848A]'>
                        소계
                      </span>
                      <strong className='text-[15px] font-[500] leading-[15px] text-[#7D848A]'>
                        <span className='inline-block pr-1 text-[#222]'>
                          {form
                            .getValues('price')
                            ?.claimAmount?.subtotal?.toLocaleString() ?? 0}
                        </span>
                        원
                      </strong>
                    </div>
                    <div className='flex w-full items-center justify-between gap-[10px]'>
                      <span className='block shrink-0 text-[15px] font-[500] leading-[15px] text-[#7D848A]'>
                        부가계
                      </span>
                      <strong className='text-[15px] font-[500] leading-[15px] text-[#7D848A]'>
                        <span className='inline-block pr-1 text-[#222]'>
                          {form
                            .getValues('price')
                            ?.claimAmount?.tax?.toLocaleString() ?? 0}
                        </span>
                        원
                      </strong>
                    </div>
                    <div className='flex w-full items-center justify-between gap-[10px] border-t border-[#E5EAEE] pt-[20px]'>
                      <span className='block shrink-0 text-[15px] font-[500] leading-[15px] text-[#7D848A]'>
                        총계
                      </span>
                      <strong
                        className={cn(
                          'text-[15px] font-[500] leading-[15px] text-[#7D848A]',
                          form.formState.errors.price?.claimAmount?.total &&
                            'text-[#FF4D4D]',
                        )}
                      >
                        <span
                          className={cn(
                            'inline-block pr-1 text-[20px] font-[600] leading-[20px] text-[#222]',
                            form.formState.errors.price?.claimAmount?.total &&
                              'text-[#FF4D4D]',
                          )}
                        >
                          {form
                            .getValues('price')
                            ?.claimAmount?.total?.toLocaleString() ?? 0}
                        </span>
                        원
                      </strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
}
