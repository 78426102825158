export default function NoData({
  text,
  imgUrl,
}: {
  text?: string;
  imgUrl?: string;
}) {
  return (
    <div className='flex min-h-[122px] w-auto flex-col items-center justify-center gap-[24px]'>
      <div className='w-[80px] h-[80px] flex justify-center items-center rounded-full bg-[#E5EAEE]'>
        <img
          src={imgUrl ? imgUrl : '/assets/images/v2/noData.png'}
          alt={`${text ? text : '데이터 없음'}_이미지`}
          className='h-[50px] w-[50px] object-contain'
        />
      </div>
      <span className='text-[18px] font-[600] leading-[18px] text-[#A1A7AC]'>
        {text ? text : '데이터가 없어요'}
      </span>
    </div>
  );
}
