export default function QuoteIcon({
  fill,
  width,
  height,
}: {
  fill?: string;
  width?: string;
  height?: string;
}) {
  return (
    <svg
      width={width || '30'}
      height={height || '30'}
      viewBox='0 0 30 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_85_4669)'>
        <path
          d='M4.28613 9.04758C4.28613 8.41611 4.53698 7.81051 4.9835 7.36399C5.43001 6.91748 6.03562 6.66663 6.66709 6.66663H23.3338C23.9652 6.66663 24.5708 6.91748 25.0173 7.36399C25.4639 7.81051 25.7147 8.41611 25.7147 9.04758V20.9523C25.7147 21.5838 25.4639 22.1894 25.0173 22.6359C24.5708 23.0824 23.9652 23.3333 23.3338 23.3333H6.66709C6.03562 23.3333 5.43001 23.0824 4.9835 22.6359C4.53698 22.1894 4.28613 21.5838 4.28613 20.9523V9.04758Z'
          fill={fill || '#B2B7BB'}
          stroke={fill || '#B2B7BB'}
          strokeWidth='1.5873'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M1.77344 9.17993L15.001 16.0582L28.2285 9.17993'
          stroke='white'
          strokeWidth='1.8'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_85_4669'>
          <rect width='30' height='30' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
