/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useNavigate } from 'react-router-dom';

export default function ShortNavSection({
  isInterestCarList,
}: {
  isInterestCarList: boolean;
}) {
  const navigate = useNavigate();
  return (
    <div className='w-full px-[20px] bg-white pb-[50px]'>
      <ul className='grid grid-cols-1 gap-[10px]'>
        {!isInterestCarList && (
          <li
            onClick={() => navigate('/selling/landing')}
            className='w-full bg-gradient-to-b from-[#222222] to-[#444444] p-[16px] pt-[20px] relative rounded-[10px] overflow-hidden  min-h-[120px]'
          >
            <div className='flex flex-col justify-between items-start h-full gap-[17px] relative z-[1]'>
              <div className='flex flex-col justify-start items-start gap-[8px] text-white'>
                <div className='flex justify-start items-center gap-[5px]'>
                  <strong className='text-[16px] leading-[24px] font-[600]'>
                    판매하실 차량이 있으신가요?
                  </strong>
                </div>
                <p className='text-[14px] leading-[21px] font-[500]'>
                  내 차 팔기 최적 견적 제공
                </p>
              </div>
            </div>
            <div className='absolute right-[-2px] bottom-0 z-0'>
              <img
                src='/assets/images/png/main_shortNavSection_img.png'
                alt='섹션 아이콘'
                className='h-[120px] w-[151px] object-contain'
              />
            </div>
          </li>
        )}
        <li
          onClick={() =>
            navigate('/static/insurance', {
              state: {
                key: '하나손해보험',
              },
            })
          }
          className='w-full bg-[#D2F3F5] p-[20px] pt-[24px] relative rounded-[10px] overflow-hidden  min-h-[140px]'
        >
          <div className='flex flex-col justify-between items-start h-full gap-[17px] relative z-[1]'>
            <div className='flex flex-col justify-start items-start gap-[8px]'>
              <div className='flex justify-start items-center gap-[5px]'>
                <strong className='text-[18px] leading-[18px] font-[600]'>
                  하나 원데이 자동차보험
                </strong>
                <img
                  src='/assets/images/static/company/hana.png'
                  alt='하나자동차보험 로고'
                  className='w-[22px] object-contain'
                />
              </div>
              <p className='text-[14px] leading-[21px] font-[400]'>
                오늘 하루 다른차 운전할 때도 필수보장!
              </p>
            </div>
            <div className='text-[11px] leading-[16.5px] font-[300] text-[#555] flex flex-col justify-start items-start'>
              <span>손해보험협회 심의필 제130768호</span>
              <span>(2024.08.20 ~ 2025.08.19)</span>
            </div>
          </div>
          <div className='absolute -right-[16px] -bottom-[30px] z-0'>
            <img
              src='/assets/images/png/main_shortNavSection_img01.png'
              alt='섹션 아이콘1'
              className='w-[146px] object-contain bg-[#D2F3F5] bg-blend-hard-light'
            />
          </div>
        </li>
        <li
          onClick={() => navigate('/ask/carManagement/main')}
          className='w-full bg-[#ECF2FF] p-[20px] pt-[26px] relative rounded-[10px] overflow-hidden  min-h-[140px]'
        >
          <div className='flex flex-col justify-between items-start h-full gap-[17px] relative z-[1]'>
            <div className='flex flex-col justify-start items-start gap-[10px]'>
              <div className='flex justify-start items-center gap-[5px]'>
                <strong className='text-[18px] leading-[18px] font-[600]'>
                  차량 정보 등록까지 30초
                </strong>
              </div>
              <p className='text-[14px] leading-[21px] font-[400] break-keep'>
                긴급출동 연결 + 사고수리 접수 + 보험 만기 알림까지
              </p>
            </div>
          </div>
          <div className='absolute -right-[4px] -bottom-[15px] z-0'>
            <img
              src='/assets/images/png/main_shortNavSection_img02.png'
              alt='섹션 아이콘2'
              className='w-[80px] object-contain'
            />
          </div>
        </li>
        <li className='w-full bg-[#FFF2EB] relative rounded-[10px] overflow-hidden  min-h-[140px] cursor-pointer'>
          <button
            onClick={() => navigate('/accidentReport/init')}
            className='text-left block w-full h-full p-[20px] pt-[26px]'
          >
            <div className='flex flex-col justify-between items-start h-full gap-[17px] relative z-[1]'>
              <div className='flex flex-col justify-start items-start gap-[10px]'>
                <div className='flex justify-start items-center gap-[5px]'>
                  <strong className='text-[18px] leading-[18px] font-[600]'>
                    간편한 사고 접수
                  </strong>
                </div>
                <p className='text-[14px] leading-[21px] font-[400] break-keep'>
                  실시간 공업사와 연계되어 모든 과정 확인 가능
                </p>
              </div>
            </div>
            <div className='absolute -right-[16px] -bottom-[21px] z-0'>
              <img
                src='/assets/images/png/main_shortNavSection_img03.png'
                alt='섹션 아이콘3'
                className='w-[113px] object-contain'
              />
            </div>
          </button>
        </li>
      </ul>
    </div>
  );
}
