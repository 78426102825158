import { cn } from 'utils';
import { carTypeList, ModelInterface } from './page';

export default function ModelCard({
  item,
  selectedModel,
  setSelectedModel,
}: {
  item: ModelInterface;
  selectedModel: string;
  setSelectedModel: (value: string) => void;
}) {
  return (
    <div
      onClick={() => setSelectedModel(item.id)}
      className={cn(
        'w-full cursor-pointer flex flex-col justify-start items-start bg-white rounded-[10px] overflow-hidden border border-[#E5E5EC]',
        selectedModel === item.id &&
          'border-[2px] border-[#7273F9] shadow-[0px_6px_20px_#0000000D]',
      )}
    >
      <div className='relative w-full flex justify-center items-center p-[26px] bg-[#F3F6F9] min-h-[160px]'>
        <button className='absolute top-2.5 right-2.5'>
          {selectedModel === item.id ? (
            <img
              src='/assets/images/png/check.png'
              alt='체크이미지'
              className='w-8 object-contain'
            />
          ) : (
            <img
              src='/assets/images/png/unCheck.png'
              alt='비체크이미지'
              className='w-8 object-contain'
            />
          )}
        </button>
        <div className='w-full h-full flex justify-center items-center'>
          <img
            src='/assets/images/png/ex-car.png'
            alt='예시차량사진'
            className='w-[65%] max-w-[180px] object-contain'
          />
        </div>
        <span
          style={{
            backgroundColor: carTypeList.find(
              (type) => type.label === item.type,
            )?.color,
          }}
          className={cn(
            'absolute bottom-2.5 right-2.5 py-2 px-[11px] flex justify-center items-center h-[29px] min-w-[56px] text-white rounded-[4px] text-[13px] leading-[13px] font-[600]',
          )}
        >
          {item.type}
        </span>
      </div>
      <div className='w-full bg-white p-5 pb-[30px]'>
        <div className='flex justify-start items-center gap-1.5'>
          <strong className='text-[18px] leading-[28.8px] font-[600]'>
            {item.title}
          </strong>
          <span className='text-[14px] leading-[14px] font-[500] py-1 px-2 rounded-[4px] bg-[#F3F6F9] flex justify-center items-center min-w-[45px] h-[24px]'>
            {item.count}종
          </span>
        </div>
        <p className='mt-2.5 text-[16px] leading-[16px] font-[400]'>
          {item.price}
        </p>
      </div>
    </div>
  );
}
