import { Button } from 'components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog';

import { ChoiceListInterface } from 'pages/v2/ask/component/ChoiceConfirm';
import { cn } from 'utils';

type Props = {
  title: string;
  desc?: string;
  isOpen: boolean;
  isCloseButton?: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onConfirm?: () => void;
  confirmText?: string;
  cancelText?: string;
  isChoice?: boolean;
  choiceList?: ChoiceListInterface[];
  align?: 'center' | 'left';
  inImg?: string;
  confirmColor?: string;
  cancelColor?: string;
};

export default function ConfirmV2({
  title,
  desc,
  isOpen,
  isCloseButton,
  setIsOpen,
  onConfirm,
  confirmText,
  cancelText,
  isChoice,
  choiceList,
  align,
  inImg,
  confirmColor,
  cancelColor,
}: Props) {
  return (
    <Dialog open={isOpen} onOpenChange={(value) => setIsOpen(value)}>
      <DialogContent className='max-w-[350px] w-[85vw] gap-0 border-none p-0 pt-[30px] rounded-[16px] overflow-hidden shadow-[0px_5px_15px_#0A142C26]'>
        <DialogHeader className='px-2 flex min-h-[130px] flex-col items-center justify-center space-y-2 text-center'>
          {inImg && (
            <div className='w-[60px] h-[60px] flex justify-center items-center mb-[12px]'>
              <img src={inImg} alt='이미지' className='w-full object-contain' />
            </div>
          )}

          <DialogTitle
            className={`w-full text-[16px] pt-[20px] leading-[25.6px] font-[700] ${align === 'left' ? 'text-left pl-[15px]' : 'text-center'}`}
          >
            {title}
          </DialogTitle>
          {desc && desc.length > 0 && (
            <div className='text-center whitespace-pre !mt-[6px] text-[15px] leading-[24px] font-[500] pb-[50px]'>
              {desc}
            </div>
          )}
          {choiceList && choiceList.length > 0 && (
            <ul className='w-full flex flex-col justify-start items-start px-[8px]'>
              {choiceList.map((item, index) => {
                return (
                  <li key={`${item.text}_${index}`} className='p-[12px]'>
                    <button onClick={item.action}> {item.text}</button>
                  </li>
                );
              })}
            </ul>
          )}
        </DialogHeader>
        <DialogFooter className='h-[54px]'>
          {isCloseButton && (
            <Button
              className={cn(
                `h-[54px] rounded-none border-t border-[#EEEEEE] bg-[#fff] text-[#111111]`,
                cancelColor && cancelColor,
              )}
              onClick={() => setIsOpen(false)}
            >
              {cancelText ?? '취소'}
            </Button>
          )}
          {!isChoice && (
            <Button
              className={cn(
                'h-[54px] rounded-none border-t border-l border-[#EEEEEE] bg-white text-[15px] leading-[15px] font-[500] text-[#555555] py-[20px] flex items-center justify-center',
                confirmColor && confirmColor,
              )}
              onClick={() => {
                setIsOpen(false);
                if (onConfirm) onConfirm();
              }}
            >
              {confirmText ?? '확인'}
            </Button>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
