export default function HomeIcon({
  fill,
  width,
  height,
}: {
  fill?: string;
  width?: string;
  height?: string;
}) {
  return (
    <svg
      width={width || '30'}
      height={height || '30'}
      viewBox='0 0 30 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_85_4607)'>
        <path
          d='M23.7496 10.8875L17.0834 5.70246C16.4983 5.24734 15.7783 5.00024 15.0371 5.00024C14.2959 5.00024 13.5759 5.24734 12.9909 5.70246L6.32336 10.8875C5.92266 11.1991 5.59848 11.5981 5.37555 12.0541C5.15263 12.5102 5.03687 13.0111 5.03711 13.5187V22.5187C5.03711 23.1817 5.3005 23.8176 5.76934 24.2865C6.23818 24.7553 6.87407 25.0187 7.53711 25.0187H22.5371C23.2002 25.0187 23.836 24.7553 24.3049 24.2865C24.7737 23.8176 25.0371 23.1817 25.0371 22.5187V13.5187C25.0371 12.49 24.5621 11.5187 23.7496 10.8875Z'
          fill={fill || '#B2B7BB'}
          stroke={fill || '#B2B7BB'}
          strokeWidth='1.8'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M20.001 18.75C17.2385 20.4162 12.761 20.4162 10.001 18.75'
          stroke='white'
          strokeWidth='1.8'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_85_4607'>
          <rect width='30' height='30' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
