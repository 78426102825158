'use client';

import { TextField, TextFieldProps } from '@mui/material';
import dayjs from 'dayjs';
import * as React from 'react';
import { cn } from 'utils';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  theme?: 'secondary';
  /** 단위 입력 */
  inUnit?: string;
  remainingTime?: number;
}

type TProps = TextFieldProps & InputProps;

const V3_Input = React.forwardRef<HTMLInputElement, TProps>(
  ({ className, inUnit, type, theme, error, remainingTime, ...props }, ref) => {
    const [isPasswordVisible, setIsPasswordVisible] = React.useState<
      boolean | null
    >(type === 'password' ? false : null);

    return (
      <div className='relative w-full'>
        <TextField
          className={cn(className)}
          type={
            isPasswordVisible === null
              ? type
              : isPasswordVisible
                ? 'text'
                : 'password'
          }
          ref={ref}
          sx={{
            '& .MuiOutlinedInput-root': {
              padding: 0,
              fontFamily: 'Pretendard',
              '& fieldset': {
                borderColor: error
                  ? '#FF4D4D'
                  : theme === 'secondary' || props.readOnly
                    ? 'transparent'
                    : '#E5E5EC',
                borderRadius: theme === 'secondary' ? '10px' : '8px',
              },
              '&.Mui-focused fieldset': {
                borderColor: error
                  ? '#FF4D4D'
                  : theme === 'secondary'
                    ? 'inherit'
                    : props.readOnly
                      ? 'transparent'
                      : '#222',
                borderWidth: '1px',
              },
              '& input': {
                padding: inUnit
                  ? theme === 'secondary'
                    ? '14.5px 34px 14.5px 16px'
                    : '17px 34px 17px 16px'
                  : type === 'password'
                    ? theme === 'secondary'
                      ? '14.5px 56px 14.5px 16px'
                      : '17px 56px 17px 16px'
                    : theme === 'secondary'
                      ? '14.5px 16px'
                      : '17px 16px',
                textAlign: inUnit ? 'right' : 'left',
                height: theme === 'secondary' ? '15px' : '16px',
                display: 'flex',
                alignItems: 'center',
                backgroundColor:
                  theme === 'secondary' || props.readOnly
                    ? '#F1F4F7'
                    : 'inherit',
                cursor: props.readOnly ? 'not-allowed' : 'text',
                borderRadius: theme === 'secondary' ? '10px' : '8px',
                fontSize: theme === 'secondary' ? '15px' : 'inherit',
                lineHeight: theme === 'secondary' ? '15px' : 'inherit',
                fontWeight: theme === 'secondary' ? '500' : 'inherit',
                letterSpacing: '-0.02em',
              },
              '&.Mui-disabled fieldset': {
                borderColor: error ? '#FF4D4D' : '#E5E5EC',
              },
              '&.Mui-disabled input': {
                borderColor: '#E5E5EC',
                backgroundColor: '#F3F6F9',
                borderRadius: '8px',
                color: '#999999',
              },
              '& input::placeholder': {
                color: '#999999',
                opacity: 1,
                textAlign: 'left',
              },
            },
            '& .MuiFormHelperText-root': {
              color: '#FF4D4D',
            },

            minWidth: '120px',
            minHeight: '44px',
            fontSize: '16px',
            color: '#222',
            width: '100%',
            fontFamily: 'Pretendard',
          }}
          {...props}
        ></TextField>

        {/* 이 아래 있는놈들 위치 짜치게잡힘 나중애 수정 */}
        {/* 비밀번호 보임/숨김 눈깔 */}
        {type === 'password' && (
          <button
            type='button'
            onClick={() => setIsPasswordVisible(!isPasswordVisible)}
            className='absolute right-2 top-[5px] h-10 w-10 p-2'
          >
            {isPasswordVisible ? (
              <img src='/imgs/visiblePw.png' className='h-[24px] w-[24px]' />
            ) : (
              <img src='/imgs/hiddenPw.png' className='h-[24px] w-[24px]' />
            )}
          </button>
        )}

        {inUnit && (
          <span
            className={cn(
              'absolute right-4 top-[14px] text-[15px] font-[500] text-[#7D848A]',
              theme === 'secondary' ? 'leading-[16px]' : 'leading-[22px]',
            )}
          >
            {inUnit}
          </span>
        )}

        {/* 남은 시간(초) */}
        {remainingTime && remainingTime > 0 ? (
          <div className='absolute right-5 top-[5px] h-10 w-10 p-2'>
            <span className='text-[14px] font-normal leading-[14px] tracking-[-0.28px] text-[#FF3C3C]'>
              {dayjs.duration(remainingTime, 'seconds').format('mm:ss')}
            </span>
          </div>
        ) : null}
      </div>
    );
  },
);
V3_Input.displayName = 'Input';

export default V3_Input;
