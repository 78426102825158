'use client';

import { toast } from 'sonner';
import { v4 as uuidv4 } from 'uuid';

export default function downloadFile({
  url,
  fileName,
}: {
  /** 다운로드할 파일 URL */
  url: string;
  /** 다운로드할 파일명
   * @default uuidv4()
   */
  fileName?: string;
}): Promise<void> {
  return new Promise((rs, rj) => {
    const toastId = toast.message('파일 다운로드가 시작되었습니다.');
    fetch(url, {})
      .then((response) => {
        if (!response.ok) {
          toast.error('파일 다운로드에 실패했습니다.');
          throw new Error('File download failed');
        }

        let downloadFileName = fileName ? fileName : uuidv4();

        const ext = null;

        if (ext && !downloadFileName.endsWith(`.${ext}`)) {
          downloadFileName += `.${ext}`;
        }

        return response.blob().then((blob) => ({ blob, downloadFileName }));
      })
      .then(({ blob, downloadFileName }) => {
        // 안드로이드일 경우 브릿지 메소드를 통해 Base64를 직접 전달하여 파일 다운로드합니다
        if (window?.android?.getBase64FromBlobData) {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            window.android?.getBase64FromBlobData(
              reader.result,
              downloadFileName,
            );
          };
          return rs();
        }

        const url = window.URL.createObjectURL(new Blob([blob]));
        const a = document.createElement('a');
        a.href = url;
        a.download = downloadFileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
        toast.success('파일이 성공적으로 다운로드되었습니다.');
        return rs();
      })
      .catch((error) => {
        toast.error('파일 다운로드에 실패했습니다.');
        return rj(error);
      })
      .finally(() => {
        toast.dismiss(toastId);
      });
  });
}
