import Button from 'components_v3/ui/Button';
import { X } from 'lucide-react';
import { useLayoutEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';
import { cn } from 'utils';

export default function QuoteRequestPurchaseDonePage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const paramValue = searchParams.get('state');
  const { setTopTabbarState, setIsBottomTabbar } = useSystemStore();

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      title: '견적 요청권 구매',
      rightElement: (
        <div className='min-w-[28px] cursor-pointer'>
          <X
            onClick={() => navigate(-1)}
            width={28}
            height={28}
            strokeWidth={1.5}
          />
        </div>
      ),
    });
    setIsBottomTabbar(false);
  }, []);
  return (
    <div className='w-full flex flex-col justify-between gap-[50px] items-start min-h-[calc(100dvh-60px)]'>
      <div className={cn('w-full', paramValue === 'wait' && 'mb-[50px]')}>
        <div className='pt-[55px] pb-[106px] flex justify-center items-center'>
          {paramValue === 'cancel' ? (
            <div className='w-full flex flex-col justify-center items-center gap-1.5'>
              <div className='mb-[18px] flex justify-center items-center w-[60px] h-[60px]'>
                <img
                  src='/assets/images/png/purchase_cancel.png'
                  alt='결제 실패 이미지'
                  className='h-full object-contain'
                />
              </div>
              <strong className='text-[22px] leading-[33px] font-[600]'>
                결제가 실패되었습니다.
              </strong>
              <p className='text-[16px] leading-[25.6px] font-[500]'>
                결제 실패 사유가 들어갑니다.
              </p>
            </div>
          ) : paramValue === 'wait' ? (
            <div className='w-full flex flex-col justify-center items-center gap-1.5'>
              <div className='mb-[18px] flex justify-center items-center w-[60px] h-[60px]'>
                <img
                  src='/assets/images/png/purchase_wait.png'
                  alt='결제 대기 이미지'
                  className='h-full object-contain'
                />
              </div>
              <strong className='text-[22px] leading-[33px] font-[600]'>
                가상계좌 입금을 기다리고 있습니다.
              </strong>
              <p className='text-[16px] leading-[25.6px] font-[500]'>
                입금 후 서비스를 이용해 보세요.
              </p>
            </div>
          ) : (
            <div className='w-full flex flex-col justify-center items-center gap-1.5'>
              <div className='mb-[18px] flex justify-center items-center w-[60px] h-[60px]'>
                <img
                  src='/assets/images/png/purchase_done.png'
                  alt='결제 완료 이미지'
                  className='h-full object-contain'
                />
              </div>
              <strong className='text-[22px] leading-[33px] font-[600]'>
                결제가 완료되었습니다.
              </strong>
              <p className='text-[16px] leading-[25.6px] font-[500]'>
                카 매니저들의 견적서를 받아보세요.
              </p>
            </div>
          )}
        </div>
        <div>
          {/* 구매 서비스 */}
          <div className='w-full pb-[30px] px-5 border-b-[8px] border-[#F3F6F9]'>
            <div className='mb-6 text-[18px] leading-[18px] font-[600]'>
              구매 서비스
            </div>
            <div className='flex justify-start items-center gap-4'>
              <div className='flex justify-center items-center p-2.5 bg-[#F3F6F9] rounded-[10px]'>
                <img
                  src='/assets/images/png/carsayo_card.png'
                  alt='카사요 기프트카드 이미지'
                  className='w-[70px] object-contain'
                />
              </div>
              <div className='flex flex-col justify-start items-start gap-[14px]'>
                <div className='flex flex-col justify-start items-start gap-0.5'>
                  <strong className='text-[15px] leading-[24px] font-[600]'>
                    견적 요청권
                  </strong>
                  <p className='text-[14px] leading-[22.4px] font-[500] text-[#555555]'>
                    신차 구매, 내 차 팔기
                  </p>
                </div>
                <strong className='text-[16px] leading-[16px] font-[600]'>
                  10,000원
                </strong>
              </div>
            </div>
          </div>
          {/* 주문 정보 */}
          <div className='w-full pt-[30px] pb-[30px] px-5 border-b-[8px] border-[#F3F6F9]'>
            <div className='mb-6 text-[18px] leading-[18px] font-[600]'>
              주문 정보
            </div>
            <ul className='py-5 px-4 bg-[#F3F6F9] rounded-[10px] text-[15px] leading-[15px] font-[500] flex flex-col justify-start items-start gap-5'>
              <li className='w-full flex justify-between items-center gap-5'>
                <span className='text-[#7D848A]'>주문일시</span>
                <strong className='font-[500]'>2024.12.22 14:20</strong>
              </li>
              <li className='w-full flex justify-between items-center gap-5'>
                <span className='text-[#7D848A]'>주문자</span>
                <strong className='font-[500]'>홍길동</strong>
              </li>
              <li className='w-full flex justify-between items-center gap-5'>
                <span className='text-[#7D848A]'>연락처</span>
                <strong className='font-[500]'>010-1234-1234</strong>
              </li>
            </ul>
          </div>
          {/* 결제 정보 */}
          <div className='w-full pt-[30px] px-5'>
            <div className='mb-6 text-[18px] leading-[18px] font-[600]'>
              결제 정보
            </div>
            <ul className='py-5 px-4 bg-[#F3F6F9] rounded-[10px] text-[15px] leading-[15px] font-[500] flex flex-col justify-start items-start gap-4'>
              {!paramValue && (
                <li className='w-full flex justify-between items-center gap-5'>
                  <span className='text-[#7D848A]'>결제일시</span>
                  <strong className='font-[500] leading-[22.5px]'>
                    2024.12.22 14:20
                  </strong>
                </li>
              )}

              <li className='w-full flex justify-between items-center gap-5 h-[15px]'>
                <span className='text-[#7D848A]'>결제금액</span>
                <strong className='font-[500] leading-[22.5px]'>
                  10,000원
                </strong>
              </li>
              {/* 결제방법 - 결제 완료시 */}
              {!paramValue && (
                <li className='w-full flex justify-between items-center gap-5'>
                  <span className='text-[#7D848A]'>결제방법</span>
                  <strong className='font-[500] leading-[22.5px]'>
                    신용카드
                  </strong>
                </li>
              )}
              {/* 결제방법 - 결제 대기시 */}
              {paramValue === 'wait' && (
                <li className='w-full flex justify-between items-start gap-5'>
                  <span className='text-[#7D848A] mt-[4px] shrink-0'>
                    결제방법
                  </span>
                  <div className='flex flex-col justify-start items-end gap-1 leading-[22.5px]'>
                    <p className='text-right break-keep'>
                      가상계좌 : 신한은행 1123231123
                    </p>
                    <p className='text-right break-keep'>
                      입금기한 : 2024.11.07 (7일)
                    </p>
                    <p className='text-right break-keep'>예금주 : 홍길동 </p>
                  </div>
                </li>
              )}
              {paramValue !== 'cancel' && (
                <li className='w-full flex justify-between items-center gap-5 h-[15px]'>
                  <span className='text-[#7D848A]'>결제상태</span>
                  {paramValue === 'wait' ? (
                    <strong className='font-[600] text-[#CE8D00] leading-[22.5px]'>
                      입금 대기중
                    </strong>
                  ) : (
                    <strong className='font-[600] text-[#4860FF] leading-[22.5px]'>
                      결제완료
                    </strong>
                  )}
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
      {paramValue !== 'wait' && (
        <div className='w-full flex justify-center items-center px-5 pt-[12px] pb-[10px]'>
          {!paramValue && (
            <Button theme='primary' onClick={() => navigate('/newcar/init')}>
              견적 요청 화면으로 이동
            </Button>
          )}
          {paramValue === 'cancel' && (
            <Button
              theme='primary'
              onClick={() => navigate('/newcar/quote-request/purchase')}
            >
              다시 결제하기
            </Button>
          )}
        </div>
      )}
    </div>
  );
}
