/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'components/ui/accordion';
import { useEffect, useLayoutEffect, useState } from 'react';
import useSystemStore from 'store/useSystemStore';
import { getPost } from 'apis/board';
import ReactHtmlParser from 'react-html-parser';
import { PostList, PostDetailInfo } from '@carsayo/types';
import NoData from 'components/common/v3/NoData';
import { useGetPostList } from './query';
import InfiniteScroll from 'react-infinite-scroll-component';

export default function MypageFaq() {
  const { setTopTabbarState, setIsBottomTabbar } = useSystemStore();
  const [isFetching, setIsFetching] = useState(false);
  const [postList, setPostList] = useState<PostList>();
  const [postDetailInfo, setPostDetailInfo] = useState<{
    [key: number]: PostDetailInfo;
  }>({});

  const {
    data: postListData,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    refetch,
  } = useGetPostList('faq');

  const handleVoidEvents = {
    getPost: async (postId: number) => {
      if (postDetailInfo[postId]) return;
      const post: PostDetailInfo = await getPost({
        boardKey: 'faq',
        postId: postId,
      });
      setPostDetailInfo({ ...postDetailInfo, [postId]: post });
    },
  };

  useEffect(() => {
    if (!postListData) return;

    const mergedPostList = postListData.pages.flatMap(
      (page) => page.searchedPost,
    );

    setPostList((prev) => ({
      ...postListData.pages[0],
      searchedPost: mergedPostList,
    }));
  }, [postListData]);

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      title: '자주 묻는 질문',
      rightElement: undefined,
      leftElement: 'back',
    });
    setIsBottomTabbar(false);
  }, []);

  if (isFetching) return <></>;

  return (
    <div className='pt-[20px] pb-[50px] h-full overflow-y-hidden'>
      <strong className='px-[20px] text-[15px] leading-[15px] font-[600] inline-block mb-[20px]'>
        총 {postList?.totalCount ?? 0}건
      </strong>
      {postList && postList?.totalCount > 0 ? (
        <div id='faqScroll' className='w-full h-full overflow-y-auto'>
          <InfiniteScroll
            next={fetchNextPage}
            hasMore={hasNextPage || false}
            loader={
              <div className='font-semibold text-center py-4'>
                정보를 불러오는 중입니다..
              </div>
            }
            dataLength={postList?.searchedPost.length || 0}
            scrollableTarget='faqScroll'
            scrollThreshold={0.9}
          >
            <Accordion
              key={`faq-accordion}`}
              type='single'
              collapsible
              className='w-full'
            >
              {postList &&
                postList.searchedPost.map((el, index) => {
                  return (
                    <AccordionItem
                      value={`normalPost-${index}`}
                      key={`normalPost-${index}`}
                    >
                      <AccordionTrigger
                        onClick={() => handleVoidEvents.getPost(el.id)}
                        className='px-[20px] flex items-center gap-2.5 border-b border-[#E5EAEE] py-6 text-left'
                      >
                        <div className='flex justify-center items-center bg-[#E6E7FF] text-[#7577ED] w-[28px] h-[28px] rounded-full leading-[16px] font-[600]'>
                          Q
                        </div>
                        <div className='text-[16px] leading-[25.6px] font-[600] text-[#222]'>
                          {el.title}
                        </div>
                      </AccordionTrigger>
                      <AccordionContent className='ql-editor prose bg-[#F3F6F9] py-6 px-[20px]'>
                        {ReactHtmlParser(postDetailInfo?.[el.id]?.content_html)}
                      </AccordionContent>
                    </AccordionItem>
                  );
                })}
            </Accordion>
          </InfiniteScroll>
        </div>
      ) : (
        <div className='w-full min-h-[calc(100dvh-170px)] flex justify-center items-center pb-[20%]'>
          <NoData
            imgUrl='/assets/images/v2/noFaq.png'
            text='등록된 내용이 없어요 '
          />
        </div>
      )}
    </div>
  );
}
