/* eslint-disable @typescript-eslint/no-unused-vars */
import { RefObject, useEffect, useState, useCallback, useMemo } from 'react';
import { useGeoLocation } from './useGeoLocation';
import { toastify } from 'utils/Toastify';

const KAKAO_APP_KEY = process.env.REACT_APP_KAKAO_APP_KEY as string;

/** 위치 -> 주소 변환 타입 */
/**
 * Address 인터페이스
 * 지번 주소 정보를 포함하는 구조
 */
interface Address {
  /** 전체 지번 주소 */
  address_name: string;

  /** 지역 1Depth명 (시도 단위) */
  region_1depth_name: string;

  /** 지역 2Depth명 (구 단위) */
  region_2depth_name: string;

  /** 지역 3Depth명 (동 단위) */
  region_3depth_name: string;

  /** 산 여부 ("Y" 또는 "N") */
  mountain_yn: 'Y' | 'N';

  /** 지번 주 번지 */
  main_address_no: string;

  /** 지번 부 번지 (없을 경우 빈 문자열 반환) */
  sub_address_no: string;

  /**
   * Deprecated 필드 - 우편번호 (6자리)
   * 사용이 권장되지 않으며, 공지 참고
   */
  zip_code?: string;
}
/**
 * RoadAddress 인터페이스
 * 도로명 주소 정보를 포함하는 구조
 */
interface RoadAddress {
  /** 전체 도로명 주소 */
  address_name: string;

  /** 지역 1Depth명 (시도 단위) */
  region_1depth_name: string;

  /** 지역 2Depth명 (구 단위) */
  region_2depth_name: string;

  /** 지역 3Depth명 (면 단위) */
  region_3depth_name: string;

  /** 도로명 */
  road_name: string;

  /** 지하 여부 ("Y" 또는 "N") */
  underground_yn: 'Y' | 'N';

  /** 건물 본번 */
  main_building_no: string;

  /** 건물 부번 (없을 경우 빈 문자열 반환) */
  sub_building_no: string;

  /** 건물 이름 */
  building_name: string;

  /** 우편번호 (5자리) */
  zone_no: string;
}
/** GEO 주소 검색 결과 */
interface AddressData {
  address: Address;
  road_address: RoadAddress;
}

/** 카카오 맵 타입 */
type KakaoMap = any;
type KakaoMapPosition = {
  lat: number;
  lng: number;
};

type CurrentPosition = KakaoMapPosition & {
  address: AddressData | null;
};

type MarkerInfo = {
  position: KakaoMapPosition;
  title: string;
};

function useKakaoMap_V3<T>({
  initPosition,
  containerRef,
}: {
  initPosition?: KakaoMapPosition;
  containerRef: RefObject<T extends HTMLElement ? T : HTMLElement>;
}) {
  const geoLocation = useGeoLocation();

  const [isLoading, setIsLoading] = useState(true);
  const [map, setMap] = useState<KakaoMap>();
  const [kakaoSdkLoading, setKakaoSdkLoading] = useState(true);
  const [currentPosition, setCurrentPosition] =
    useState<CurrentPosition | null>(
      initPosition
        ? {
            lat: initPosition.lat,
            lng: initPosition.lng,
            address: null,
          }
        : null,
    );

  /** 현재 위치 마커 */
  const [currentMarker, setCurrentMarker] = useState<any | null>(null);
  const [additionalMarker, setAdditionalMarker] = useState<
    {
      marker: any;
      overlay?: any;
    }[]
  >([]);

  /** GeoLocation and Kakao SDK Loading status
   * @note GeoLocation는 초기 위치가 설정되지 않았을 때 로드합니다.
   */
  const isProviderLoading = useMemo(() => {
    return geoLocation.loading || kakaoSdkLoading;
  }, [geoLocation, kakaoSdkLoading]);

  // kakao map SDK load
  const loadKakaoMapScript = useCallback(() => {
    return new Promise((resolve, reject) => {
      if (window.kakao) {
        setKakaoSdkLoading(false);
        resolve(window.kakao);
        return;
      }

      const script = document.createElement('script');
      script.src = `https://dapi.kakao.com/v2/maps/sdk.js?appkey=${KAKAO_APP_KEY}&libraries=services&autoload=false`;
      script.async = true;

      script.onload = () => {
        window.kakao.maps.load(() => {
          setKakaoSdkLoading(false);
          resolve(window.kakao);
        });
      };
      script.onerror = (e) => {
        reject(new Error('Kakao map script failed to load'));
      };

      document.head.appendChild(script);
    });
  }, []);

  const createMap = (position?: KakaoMapPosition) => {
    if (!currentPosition && !position) return;
    if (!window.kakao) return;

    const targetPosition = {
      lat: position?.lat ?? currentPosition?.lat ?? 37.5461181757326,
      lng: position?.lng ?? currentPosition?.lng ?? 127.050040830902,
    };

    // 지도를 생성 옵션
    const options = {
      center: new window.kakao.maps.LatLng(
        targetPosition.lat,
        targetPosition.lng,
      ),
      level: 3,
    };
    const basicMap = new window.kakao.maps.Map(containerRef.current, options);
    basicMap.setDraggable(true);

    // 지도 드래그 이벤트 등록
    window.kakao.maps.event.addListener(basicMap, 'dragend', function () {
      const newPosition = basicMap.getCenter();
      const lat = newPosition.getLat();
      const lng = newPosition.getLng();
      getAddressFromCoords(
        {
          lat,
          lng,
        },
        (data) => {
          setCurrentPosition({
            lat,
            lng,
            address: data[0] ?? null,
          });
        },
      );
    });

    setMap(basicMap);

    /** 초기 주소값 설정 */
    getAddressFromCoords(
      {
        lat: targetPosition.lat,
        lng: targetPosition.lng,
      },
      (data) => {
        setCurrentPosition({
          lat: targetPosition.lat,
          lng: targetPosition.lng,
          address: data[0] ?? null,
        });
      },
    );

    setIsLoading(false);
  };

  useEffect(() => {
    loadKakaoMapScript();
  }, []);

  useEffect(() => {
    if (currentPosition) return;

    geoLocation.requestLocation();
  }, []);

  useEffect(() => {
    if (!geoLocation.location) return;
    if (isProviderLoading) return;

    const { lat, lng } = geoLocation.location;
    getAddressFromCoords(
      {
        lat,
        lng,
      },
      (data) => {
        setCurrentPosition({
          lat,
          lng,
          address: data[0] ?? null,
        });
      },
    );
  }, [isProviderLoading, geoLocation.location]);

  useEffect(() => {
    if (isProviderLoading) return;
    if (!currentPosition) return;
    if (map) return;

    createMap();
  }, [isProviderLoading, currentPosition]);

  useEffect(() => {
    if (!map) return;
    if (!currentPosition) return;

    createMyPositionMarker(currentPosition);
  }, [map, currentPosition]);

  // 마커 생성 및 표시
  const createMyPositionMarker = (position: KakaoMapPosition) => {
    if (!window.kakao) return;
    if (isProviderLoading) return;

    if (currentMarker) {
      currentMarker.setPosition(
        new window.kakao.maps.LatLng(position.lat, position.lng),
      );
      currentMarker.setMap(map);
      return;
    }

    const markerImage = new window.kakao.maps.MarkerImage(
      '/assets/images/v2/marker.svg',
      new window.kakao.maps.Size(40, 40),
      { offset: new window.kakao.maps.Point(20, 40) },
    );

    // 마커를 생성합니다
    const marker = new window.kakao.maps.Marker({
      map: map,
      position: new window.kakao.maps.LatLng(position.lat, position.lng),
      image: markerImage,
      zIndex: 4,
    });

    setCurrentMarker(marker);

    return marker;
  };

  /** 좌표로 주소 검색
   * @note 콜백으로 주소 데이터를 사용합니다.
   */
  const getAddressFromCoords = (
    position: KakaoMapPosition,
    callback: (data: AddressData[]) => void,
  ) => {
    if (!window.kakao) return;
    const geocoder = new window.kakao.maps.services.Geocoder();
    geocoder.coord2Address(position.lng, position.lat, callback);
  };

  /** 지도상에 노란색 마커를 등록합니다
   * @note 기존에 등록된 노란색 마커는 모두 제거됩니다.
   */
  const setMarker = (marker: MarkerInfo | MarkerInfo[]) => {
    if (!map) return;
    if (!currentPosition) return;
    if (!window.kakao) return;

    additionalMarker.forEach((el) => {
      el.marker.setMap(null);
      el.overlay?.setMap(null);
    });
    setAdditionalMarker([]);

    const markerArray = Array.isArray(marker) ? marker : [marker];
    const markerImage = new window.kakao.maps.MarkerImage(
      'https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/markerStar.png',
      new window.kakao.maps.Size(24, 35),
      { offset: new window.kakao.maps.Point(12, 35) },
    );

    const markerList = markerArray.map((el) => {
      const content = `<div style="position: relative; margin-bottom: 40px; background: rgba(0, 0, 0, 0.6); backdrop-filter: blur(2px); padding: 6px 10px; border-radius: 100px;">
        <span style="color: #fff; font-weight: 600; font-size: 13px; line-height: 100%; letter-spacing: -0.26px;">${el.title}</span> 
      </div>`;
      return {
        marker: new window.kakao.maps.Marker({
          map: map,
          position: new window.kakao.maps.LatLng(
            el.position.lat,
            el.position.lng,
          ),
          title: el.title,
          image: markerImage,
        }),
        overlay: new window.kakao.maps.CustomOverlay({
          map: map,
          position: new window.kakao.maps.LatLng(
            el.position.lat,
            el.position.lng,
          ),
          yAnchor: 1,
          content: content,
        }),
      };
    });

    setAdditionalMarker(markerList);
  };

  /** 현재 위치로 이동
   * @note GPS 사용
   */
  const moveToLocationPosition = () => {
    geoLocation.requestLocation();
  };

  /** 지정한 위치로 이동 */
  const moveToPosition = (position: KakaoMapPosition) => {
    if (!map) return;
    if (!currentMarker) return;
    if (!window.kakao) return;

    map.panTo(new window.kakao.maps.LatLng(position.lat, position.lng));
  };

  useEffect(() => {
    if (geoLocation.error) {
      toastify.error('위치 정보를 불러올 수 없습니다.');
    }
  }, [geoLocation.error]);

  useEffect(() => {
    if (!geoLocation.location) return;
    if (!currentMarker) return;
    if (!window.kakao) return;

    const moveLatLon = new window.kakao.maps.LatLng(
      geoLocation.location.lat,
      geoLocation.location.lng,
    );

    if (map) {
      map.panTo(moveLatLon);
    }
    if (currentMarker) {
      currentMarker.setPosition(moveLatLon);
      return;
    }
  }, [currentMarker, geoLocation.location]);

  const geoState: 'error' | 'loading' | 'success' = useMemo(() => {
    if (geoLocation.error) return 'error';
    if (geoLocation.loading) return 'loading';
    if (geoLocation.location) return 'success';
    return 'loading';
  }, [geoLocation.loading, geoLocation.error, geoLocation.location]);

  return {
    geoState,
    isLoading,
    currentPosition,
    createMap,
    setMarker,
    setCurrentPosition,
    moveToLocationPosition,
  };
}

export default useKakaoMap_V3;
