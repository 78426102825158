import { z } from 'zod';

/** 입고정보 */
export const AccidentReportDataInSchema = z.object({
  /** 입고일 */
  in_date: z
    .string()
    .regex(/^\d{8}$/, { message: '입고일이 입력되어야 합니다' }),
  /** 입고 사진 URL */
  pictures: z
    .array(z.string().url({ message: '유효한 URL을 입력해주세요' }))
    .nonempty({ message: '최소 한 장의 입고 사진을 추가해주세요' }),
  /** 수리 완료 예정일 */
  repair_expected_date: z.string().regex(/^\d{8}$/, {
    message: '수리 완료 예정일이 입력되어야 합니다',
  }),
  /** 예상 수리비 */
  repair_expected_cost: z
    .number()
    .positive({ message: '예상 수리비를 입력해 주세요' }),
  /** 출고 예정일 */
  out_expected_date: z
    .string()
    .regex(/^\d{8}$/, { message: '출고 예정일이 입력되어야 합니다' }),
});

/** 사고접수 입고 정보 */
export type AccidentReportDataInForm = Omit<
  z.infer<typeof AccidentReportDataInSchema>,
  'pictures'
> & {
  pictures: string[];
};

/** 사고접수 취소 정보 */
export const AccidentReportDataCancelSchema = z.object({
  /** 취소 구분 */
  type: z.string().nullable(),
  /** 취소 사유 */
  reason: z.string().min(1, { message: '취소 사유를 입력해 주세요' }),
});

/** 사고접수 취소 정보 */
export type AccidentReportDataCancelForm = z.infer<
  typeof AccidentReportDataCancelSchema
>;

/** 수리 정보 */
export const AccidentReportDataRepairSchema = z.object({
  /** 수리 사진 URL */
  pictures: z
    .array(z.string().url({ message: '유효한 URL을 입력해주세요' }))
    .nonempty({ message: '최소 한 장의 입고 사진을 추가해주세요' }),
});

/** 수리 정보 */
export type AccidentReportDataRepairForm = Omit<
  z.infer<typeof AccidentReportDataRepairSchema>,
  'pictures'
> & {
  pictures: string[];
};

/** 수리 완료 정보 */
export const AccidentReportDataRepairFinishSchema = z.object({
  /** 수리 완료 사진 URL */
  pictures: z
    .array(z.string().url({ message: '유효한 URL을 입력해주세요' }))
    .nonempty({ message: '최소 한 장의 수리 완료 사진을 추가해주세요' }),
  /** 수리 비용 */
  price: z.object({
    repairCost: z.object({
      parts: z.number(),
      labor: z.number(),
    }),
    discountCost: z.object({
      insurance: z.number(),
    }),
    claimAmount: z.object({
      subtotal: z.number(),
      tax: z.number(),
      total: z
        .number()
        .nonnegative({ message: '총 비용은 0 이상의 숫자여야 합니다' }),
    }),
  }),
});

/** 수리 완료 정보 */
export type AccidentReportDataRepairFinishForm = z.infer<
  typeof AccidentReportDataRepairFinishSchema
>;
export type AccidentReportDataRepairFinishFormDataKeys =
  | 'pictures'
  | 'price.repairCost.parts'
  | 'price.repairCost.labor'
  | 'price.discountCost.insurance'
  | 'price.claimAmount.subtotal'
  | 'price.claimAmount.tax'
  | 'price.claimAmount.total';
