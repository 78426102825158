import { ChevronLeft } from 'lucide-react';
import { useLayoutEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';

export default function CharacterIndex() {
  const { setTopTabbarState, setIsBottomTabbar } = useSystemStore();
  const navigate = useNavigate();

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      // className: 'fixed bg-transparent text-white z-[300]',
      mode: 'subpage',
      leftElement: (
        <ChevronLeft
          onClick={() => {
            navigate(-1);
          }}
          className='h-7 w-7 cursor-pointer'
        ></ChevronLeft>
      ),
      title: '캐릭터 스토리',
    });
    setIsBottomTabbar(false);
  }, []);

  return <Outlet />;
}
