import { Link, useNavigate } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';
import IconHome from '../../../assets/images/v2/home.svg';
// import IconLeftArrow from '../../../assets/images/v2/leftArrow.svg';
import IconNotification from '../../../assets/images/v2/noti.svg';
import useMemberStore from 'store/useMemberStore';
import { cn } from 'utils';
import { AlignJustify, ChevronLeft } from 'lucide-react';

const TopTabbar = () => {
  const navigate = useNavigate();

  const { loginMember } = useMemberStore();
  const { topTabberState, isSideMenu, setIsSideMenu } = useSystemStore();

  const handleClickEvents = {
    moveToAlarmPage: () => {
      if (loginMember) {
        // 2025.01.02 알림 페이지 링크 변경했습니다. /my-page/alarm-list -> /mypage/notification
        navigate('/mypage/notification');
      } else {
        navigate('/auth/signup', { replace: true });
      }
    },

    toggleSideMenu: () => {
      navigate({ search: 'subpage' });
      setIsSideMenu(!isSideMenu);
    },
  };

  const Icons = {
    alert: () => {
      return (
        <div
          className='relative cursor-pointer'
          onClick={handleClickEvents.moveToAlarmPage}
        >
          {loginMember && loginMember.count.notification.unreadCount > 0 && (
            <span className='absolute top-[2px] right-[2px] flex w-1 h-1'>
              <span className='animate-ping bg-[#7273F9] absolute inline-flex w-full h-full rounded-full opacity-75'></span>
              <span className='bg-[#7273F9] relative inline-flex w-1 h-1 rounded-full'></span>
            </span>
          )}
          <img src={IconNotification} />
        </div>
      );
    },
  };

  function LeftIcon() {
    return (
      <div className='min-w-5'>
        {topTabberState.leftElement === 'back' ? (
          <a onClick={() => navigate(-1)}>
            {/* <img src={IconLeftArrow} alt='arrow left' /> */}
            <ChevronLeft
              width={32}
              height={32}
              strokeWidth={1.5}
              className='w-[32px] object-contain'
            />
          </a>
        ) : (
          topTabberState.leftElement
        )}
      </div>
    );
  }

  function RightIcon() {
    return (
      <div className='min-w-5'>
        {topTabberState.rightElement === 'home' ? (
          <Link to='/main'>
            <img src={IconHome} alt='carsayoIcon' />
          </Link>
        ) : (
          topTabberState.rightElement
        )}
      </div>
    );
  }

  function Main() {
    return (
      <div
        className={cn(
          'flex h-[60px] items-center justify-between px-4',
          isSideMenu && 'hidden',
        )}
      >
        <Link to='/main'>
          <img src={'/assets/images/v2/carsayo.png'} className='w-[96px]' />
        </Link>
        <Icons.alert />
      </div>
    );
  }
  function My() {
    return (
      <div
        className={cn(
          'flex h-[60px] items-center justify-between px-4',
          isSideMenu && 'hidden',
        )}
      >
        {topTabberState.title && (
          <div className='text-[24px] leading-[24px] font-[600]'>
            {topTabberState.title}
          </div>
        )}
        <Icons.alert />
      </div>
    );
  }

  function MainType_2() {
    return (
      <div className='flex h-[60px] items-center justify-between px-4'>
        <div className='flex items-center gap-3'>
          <AlignJustify
            className='w-6 h-6 cursor-pointer'
            onClick={handleClickEvents.toggleSideMenu}
          />
          <img src={'/assets/images/v2/carsayo.png'} className='w-[96px]' />
        </div>
        <Icons.alert />
      </div>
    );
  }

  function SubPage() {
    return (
      <div className='flex h-[60px] items-center justify-between px-4'>
        <LeftIcon />
        {topTabberState.title && (
          <div className='text-[20px] leading-[20px] font-[600]'>
            {topTabberState.title}
          </div>
        )}
        <RightIcon />
      </div>
    );
  }

  return topTabberState.state !== 'hidden' ? (
    <div
      className={cn(
        'bg-transparent flex-none w-full',
        topTabberState.className,
      )}
      style={{ paddingTop: 'env(safe-area-inset-top, 0)' }}
    >
      {topTabberState.mode === 'main' && <Main />}
      {topTabberState.mode === 'my' && <My />}
      {topTabberState.mode === 'mainType_2' && <MainType_2 />}
      {topTabberState.mode === 'subpage' && <SubPage />}
    </div>
  ) : (
    <></>
  );
};

export default TopTabbar;
