'use client';

import {
  FormHelperText,
  TextareaAutosize,
  TextareaAutosizeProps,
} from '@mui/material';
import * as React from 'react';
import { cn } from 'utils';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  error?: boolean;
  helperText?: string;
}

type TProps = TextareaAutosizeProps & InputProps;

const TextArea = React.forwardRef<HTMLTextAreaElement, TProps>(
  ({ className, error, helperText, ...props }, ref) => {
    const innerRef = React.useRef<HTMLTextAreaElement | null>(null);

    const [length, setLength] = React.useState(0);

    const handleInput = () => {
      if (!props.maxLength) return;
      if (innerRef.current) {
        setLength(innerRef.current.value.length);
      }
    };

    return (
      <>
        <div className='relative w-full'>
          <TextareaAutosize
            className={cn(
              'rounded-[8px] border border-[#E5E5EC] px-4 pt-[12px] focus:outline-none',
              error && 'border-[#FF4D4D]',
              props.maxLength && 'pb-[38px]',
              className,
            )}
            onInput={handleInput}
            ref={(node: HTMLTextAreaElement | null) => {
              innerRef.current = node; // 내부 ref 업데이트
              if (typeof ref === 'function') {
                ref(node); // 외부 ref 업데이트
              } else if (ref) {
                (
                  ref as React.MutableRefObject<HTMLTextAreaElement | null>
                ).current = node;
              }
            }}
            {...props}
          ></TextareaAutosize>

          {props.maxLength && (
            <span className='absolute bottom-[20px] right-4 text-[14px] font-[500] leading-[14px] text-[#999999]'>
              {length}/{props.maxLength}
            </span>
          )}
        </div>
        {error && helperText && (
          <FormHelperText
            sx={{
              color: '#FF4D4D',
              fontSize: '12px',
              lineHeight: '20px',
              letterSpacing: '0.4px',
              fontWeight: '400',
              marginX: '14px',
              marginTop: '3px !important',
            }}
          >
            {helperText}
          </FormHelperText>
        )}
      </>
    );
  },
);
TextArea.displayName = 'TextArea';

export default TextArea;
