import * as React from 'react';

import { cn } from 'utils';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: string;
}

const InputV2 = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(
          'flex w-full border border-input rounded-[8px] border-[#E5E5EC] bg-white text-[#222] py-[17px] px-4 h-[50px] outline-none ring-offset-background file:border-0 file:bg-transparent text-[16px] leading-[16px] font-[400] placeholder:text-[#999999] focus:border-[#111111] disabled:cursor-not-allowed disabled:!text-[#222222] disabled:!border-[#E5E5EC] disabled:!bg-[#F3F6F9] disabled:opacity-100',
          className,
        )}
        ref={ref}
        {...props}
      />
    );
  },
);
InputV2.displayName = 'InputV2';

export { InputV2 };
