/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import { styled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputBase from '@mui/material/InputBase';
import { MenuItem } from '@mui/material';
import { forwardRef, ReactNode, useState } from 'react';
import { cn } from 'utils';
import { ChevronDown } from 'lucide-react';
const CustomArrow = ({ open, type }: { open: boolean; type?: string }) => (
  <span
    className={cn(
      'absolute z-0 right-2 top-1/2 transform -translate-y-1/2 transition-all flex justify-center items-center pointer-events-none',
      open ? 'rotate-180' : 'rotate-0',
      type === 'noBorder'
        ? 'right-2'
        : type === 'term'
          ? 'right-[10px] w-[20px] h-[20px]'
          : 'right-[13px]',
    )}
  >
    {/* <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.5 6.75L9 11.25L4.5 6.75'
        stroke='#666666'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg> */}
    <ChevronDown
      width={type === 'noBorder' ? 18 : type === 'ask' ? 20 : 24}
      height={type === 'noBorder' ? 18 : type === 'ask' ? 20 : 24}
      strokeWidth={1.5}
      color={type === 'ask' ? '#222' : '#666666'}
    />
  </span>
);

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  '& .MuiSelect-icon': {
    display: 'none',
  },
  '#customized-selectV3': {
    minHeight: '30px !important',
    boxSizing: 'border-box',
  },
  '& .MuiFormControl-root': {
    fontFamily: 'Pretendard',
    padding: '10px 8px 10px 14px',
    paddingRight: '32px !important',
    borderRadius: '100px',
    fontSize: '14px',
    lineHeight: '14px',
    fontWeight: '600',
  },
  '& .MuiInputBase-input': {
    position: 'relative',
    border: 'none',
    fontFamily: 'Pretendard',
    backgroundColor: theme.palette.background.paper,
    fontSize: '14px',
    lineHeight: '14px',
    fontWeight: '600',
    color: '#222222',
    padding: '10px 8px 10px 14px',
    paddingRight: '32px !important',
    borderRadius: '100px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    // Use the system font instead of the default Roboto font.
    '&:focus': {
      borderColor: '#E5E5EC',
      fontSize: '14px',
      lineHeight: '14px',
      fontWeight: '600',
      outline: 'none',
      padding: '10px 8px 10px 14px',
      borderRadius: '100px',
    },
  },
  '& .li': {
    color: 'red',
  },
}));

const NormalInput = styled(InputBase)(({ theme, className, error }) => ({
  '& .MuiSelect-icon': {
    display: 'none',
  },
  '#customized-selectV3': {
    height: '50px',
    boxSizing: 'border-box',
  },
  '& .MuiFormControl-root': {
    fontFamily: 'Pretendard',
    padding: '17px 44px 17px 16px',
    borderRadius: '8px',
    fontSize: '16px',
    lineHeight: '16px',
    fontWeight: '400',
  },
  '& .MuiInputBase-input': {
    position: 'relative',
    border: error ? '1px solid #FF4D4D' : '1px solid #E5E5EC',
    fontFamily: 'Pretendard',
    backgroundColor: theme.palette.background.paper,
    fontSize: '16px',
    lineHeight: '16px',
    fontWeight: '400',
    color: '#222222',
    padding: '17px 44px 17px 16px',
    paddingRight: '44px !important',
    borderRadius: '8px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    ...(className && { className }),
    // Use the system font instead of the default Roboto font.
    '&:focus': {
      borderColor: '#E5E5EC',
      fontSize: '16px',
      lineHeight: '16px',
      fontWeight: '400',
      outline: 'none',
      padding: '17px 44px 17px 16px',
      borderRadius: '8px',
      backgroundColor: 'white',
    },
  },
  '& .li': {
    color: 'red',
  },
}));

const TermInput = styled(InputBase)(({ theme }) => ({
  '& .MuiSelect-icon': {
    display: 'none',
  },
  '#customized-selectV3': {
    minWidth: '80px',
    minHeight: '30px !important',
    boxSizing: 'border-box',
  },
  '& .MuiFormControl-root': {
    fontFamily: 'Pretendard',
    padding: '10px',
    paddingRight: '30px !important',
    borderRadius: '8px',
    fontSize: '15px',
    lineHeight: '15px',
    fontWeight: '500',
  },
  '& .MuiInputBase-input': {
    position: 'relative',
    border: '1px solid #E5E5EC',
    fontFamily: 'Pretendard',
    backgroundColor: theme.palette.background.paper,
    fontSize: '15px',
    lineHeight: '15px',
    fontWeight: '500',
    color: '#222222',
    padding: '10px',
    paddingRight: '30px !important',
    borderRadius: '8px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    // Use the system font instead of the default Roboto font.
    '&:focus': {
      borderColor: '#E5E5EC',
      fontSize: '15px',
      lineHeight: '15px',
      fontWeight: '500',
      outline: 'none',
      padding: '10px',
      borderRadius: '8px',
    },
  },
  '& .li': {
    color: 'red',
  },
}));

const AskInput = styled(InputBase)(({ theme }) => ({
  '& .MuiSelect-icon': {
    display: 'none',
  },
  '#customized-selectV3': {
    height: '54px',
    boxSizing: 'border-box',
  },
  '& .MuiFormControl-root': {
    fontFamily: 'Pretendard',
    padding: '17px 44px 17px 16px',
    borderRadius: '10px',
    fontSize: '20px',
    lineHeight: '20px',
    fontWeight: '600',
  },
  '& .MuiInputBase-input': {
    position: 'relative',
    border: '1px solid #E5EAEE',
    fontFamily: 'Pretendard',
    backgroundColor: '#F3F6F9',
    fontSize: '20px',
    lineHeight: '20px',
    fontWeight: '600',
    color: '#222222',
    padding: '17px 44px 17px 16px',
    paddingRight: '44px !important',
    borderRadius: '10px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',

    // Use the system font instead of the default Roboto font.
    '&:focus': {
      borderColor: '#E5EAEE',
      fontSize: '20px',
      lineHeight: '20px',
      fontWeight: '600',
      outline: 'none',
      padding: '17px 44px 17px 16px',
      borderRadius: '10px',
      backgroundColor: '#F3F6F9',
    },
  },
  '& .li': {
    color: 'red',
  },
}));

export const SelectV3Item = styled(MenuItem)(({ theme }) => ({
  color: '#222222',
  padding: '14px',
  fontFamily: 'Pretendard',
  fontSize: '16px',
  lineHeight: '16px',
  fontWeight: '400',
  borderRadius: '6px',
  '&:hover': {
    backgroundColor: '#F3F6F9',
  },
  '&:active': {
    backgroundColor: '#F3F6F9',
    fontWeight: '600',
  },
}));

const SelectV3 = forwardRef(function CustomizedSelects(
  {
    value,
    onValueChange,
    disabled,
    placeholder,
    children,
    classes,
    name,
    type,
    error,
    className,
    renderValue,
  }: {
    value: string;
    onValueChange?: (value: string) => void | Promise<void>;
    placeholder?: string;
    disabled?: boolean;
    children: React.ReactNode;
    classes?: string;
    name?: string;
    type?: string;
    error?: boolean;
    className?: string;
    renderValue?: (value: string) => ReactNode;
  },
  ref,
) {
  const [open, setOpen] = useState(false);
  const handleChange = (event: { target: { value: string } }) => {
    if (onValueChange) onValueChange(event.target.value);
  };

  return (
    <>
      <FormControl variant='standard' className='w-full mt-2'>
        <Select
          labelId='customized-selectV3-label'
          id='customized-selectV3'
          value={value ? value : ''}
          className={classes}
          name={name}
          onChange={handleChange}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          input={
            type === 'noBorder' ? (
              <BootstrapInput />
            ) : type === 'term' ? (
              <TermInput />
            ) : type === 'ask' ? (
              <AskInput />
            ) : (
              <NormalInput error={error} />
            )
          }
          IconComponent={() => <CustomArrow open={open} type={type} />}
          ref={ref}
          disabled={disabled}
          displayEmpty={true}
          renderValue={
            value !== '' && value !== undefined
              ? renderValue
              : () => (
                  <span className='text-[16px] leading-[16px] font-[400] text-gray-500'>
                    {placeholder}
                  </span>
                )
          }
          MenuProps={{
            PaperProps: {
              sx: {
                bgcolor: 'white',
                '&': {
                  borderRadius: '10px',
                  overflow: 'hidden',
                  boxShadow: '0px 6px 20px #0000000D',
                  border: '1px solid #E5EAEE',
                  minWidth: ' 200px !important',
                  width: '100%',
                  maxWidth:
                    type === 'ask' ? 'calc(100% - 80px)' : 'calc(100% - 40px)',
                  marginTop: '5px',
                },
                '& .MuiMenu-list': {
                  padding: '4px',
                  fontSize: '16px',
                  lineHeight: '16px',
                },
                '& .MuiMenuItem-root': {
                  '&:hover': {
                    backgroundColor: '#F3F6F9',
                  },
                  '&:active': {
                    backgroundColor: '#F3F6F9',
                    fontWeight: '600',
                  },
                },
                '& .Mui-selected': {
                  backgroundColor: '#F3F6F9 !important',
                  fontWeight: '600',
                },
              },
            },
            PopoverClasses: {
              root: 'custom-popover-root', // 사용자 정의 클래스를 추가
            },
          }}
        >
          {children}
        </Select>
      </FormControl>
    </>
  );
});

export default SelectV3;
