import V3_Button from 'components_v3/ui/Button';

export default function AccidentReportLandingTab({
  currentTab,
  setCurrentTab,
}: {
  currentTab: number;
  setCurrentTab: (tab: number) => void;
}) {
  return (
    <div className='w-full h-full flex flex-col justify-between px-5 pb-2.5 pt-[8px]'>
      <div>
        <div className='flex justify-between items-end pt-[10px] pb-[20px] border-b-[1px] border-[#F1F1F5]'>
          <div>
            <h4 className='text-[20px] leading-[30px] font-[600] max-w-[196px] break-keep mb-[10px]'>
              사고 접수하시면
              <br />
              신속하게 처리하겠습니다.
            </h4>
          </div>
          <img
            src='/assets/images/v2/ask-carGipsa-accident01.svg'
            alt='카집사보는이미지'
            className='w-[63px] object-contain mr-[19px]'
          />
        </div>
        <div className='w-full pt-6'>
          <ul className='w-full flex flex-col justify-start items-start gap-2.5'>
            <li className='w-full p-4 rounded-[10px] min-h-[119px] bg-[#F3F6F9] relative '>
              <h4 className='flex justify-start items-center gap-2 text-[15px] leading-[15px] font-[600] text-[#3156C6] mb-[13px]'>
                <span className='w-6 h-6 text-[13px] leading-[13px] rounded-full bg-[#DBE4FE] flex justify-center items-center'>
                  1
                </span>
                카집사 접수
              </h4>
              <div className='flex justify-between items-center gap-[10px]'>
                <p className='text-[15px] leading-[21px] font-[400] text-[#000] whitespace-pre-line break-keep max-w-[224px]'>
                  사고접수가 완료되면 카집사가
                  <br />내 위치 기반 근처 공업사를 확인합니다.
                </p>
                <img
                  className='w-[50px] h-[50px] object-contain'
                  src='/assets/images/v2/accident-img01.svg'
                  alt='문자 이미지'
                />
              </div>
            </li>
            <li className='w-full p-4 rounded-[10px] min-h-[119px] bg-[#F3F6F9] relative '>
              <h4 className='flex justify-start items-center gap-2 text-[15px] leading-[15px] font-[600] text-[#3156C6] mb-[13px]'>
                <span className='w-6 h-6 text-[13px] leading-[13px] rounded-full bg-[#DBE4FE] flex justify-center items-center'>
                  2
                </span>
                공업사 배정
              </h4>
              <div className='flex justify-between items-center gap-[10px]'>
                <p className='text-[15px] leading-[21px] font-[400] text-[#000] whitespace-pre-line break-keep max-w-[212px]'>
                  현재 위치 기반 또는 지역 선택을 통해
                  <br />
                  가까운 공업사로 안내해 드립니다.
                </p>
                <img
                  className='w-[50px] h-[50px] object-contain p-[4px]'
                  src='/assets/images/v2/accident-img02.svg'
                  alt='지도 이미지'
                />
              </div>
            </li>
            <li className='w-full p-4 rounded-[10px] min-h-[119px] bg-[#F3F6F9] relative'>
              <h4 className='flex justify-start items-center gap-2 text-[15px] leading-[15px] font-[600] text-[#3156C6] mb-[13px]'>
                <span className='w-6 h-6 text-[13px] leading-[13px] rounded-full bg-[#DBE4FE] flex justify-center items-center'>
                  3
                </span>
                카집사 안내
              </h4>
              <div className='flex justify-between items-center gap-[10px]'>
                <p className='text-[15px] leading-[21px] font-[400] text-[#000] whitespace-pre-line break-keep max-w-[233px]'>
                  카사요 앱에서 카집사가
                  <br />
                  사고수리에 대한 안내를 진행해 드립니다.
                </p>
                <img
                  className='w-[50px] h-[50px] object-contain p-[4px]'
                  src='/assets/images/v2/accident-img03.svg'
                  alt='공구 이미지'
                />
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div className='pt-[70px]'>
        <V3_Button
          theme='primary'
          onClick={() => {
            setCurrentTab(currentTab + 1);
          }}
        >
          접수하기
        </V3_Button>
      </div>
    </div>
  );
}
