import {
  SearchPaymentDTO,
  PaymentSearchList,
  NicePaymentInfo,
} from '@carsayo/types';
import { api } from 'apis';
import { toast } from 'sonner';

export const getPaymentList = async (
  reqData: SearchPaymentDTO,
): Promise<PaymentSearchList> => {
  try {
    const response = await api.post('nicepayment/list', {
      ...reqData,
    });
    const data: PaymentSearchList = response.data;
    return data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export interface PaymentProps {
  take: number;
  create_at: string;
  skip: number;
}

export interface PayListProps {
  datas: NicePaymentInfo[];
  totalCnt: number;
}

export const getMyPagePaymentList = async (
  reqData: PaymentProps,
): Promise<PayListProps> => {
  try {
    const response = await api.get(
      `nicepayment/list?take=${reqData.take}&created_at=${reqData.create_at}&skip=${reqData.skip}`,
    );
    const data: PayListProps = response.data;
    return data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export interface RefundPaymentProps {
  paymentId: string;
  reason: string;
  virtual?: {
    refundAcctNo: string;
    refundBankCd: string;
    refundAcctNm: string;
  };
}

export const cancelPayment = async (reqData: RefundPaymentProps) => {
  try {
    const response = await api.post('nicepayment/cancel', {
      ...reqData,
    });
    return response;
  } catch (error: any) {
    console.info('error', error);
    if (error.isAxiosError && error.response) {
      toast.error(error.response.data.message);
    } else {
      toast.error(error.message);
    }
  }
};
