/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import {
  InsuranceCompany,
  InsuranceCompanyData,
} from 'pages/static/insurance/data/company';
import { InsuranceBannerOrder } from 'pages/v2/ask/data';
import { cn } from 'utils';
import { addHistoryInsuranceCooconClick } from 'apis/history';
import { useNavigate } from 'react-router-dom';

export default function MainInsuranceSection() {
  const navigate = useNavigate();
  const handleClickEvents = {
    openCooconInsurance: () => {
      addHistoryInsuranceCooconClick({
        from: `app${location.pathname}`,
        target: '쿠콘',
      });
      if (process.env.REACT_APP_NODE_ENV === 'development') {
        window.open(
          'https://insu-dev.coocon.co.kr/bridge.act?org_cd=RUQBBRKU&browser_type=2',
        );
        return;
      }
      window.open(
        'https://insu.coocon.net/bridge.act?org_cd=RUQBBRKU&browser_type=2',
      );
    },
    moveToInsurance: () => {
      navigate('/static/insurance');
    },
  };

  return (
    <div>
      <strong className='inline-block text-[22px] leading-[33px] font-[600] max-w-[262px] break-keep mb-6'>
        카사요니깐, 비교에 혜택을 더한 자동차 보험 가입하기
      </strong>
      <ul className='flex flex-col justify-start items-start gap-[20px]'>
        <li
          onClick={handleClickEvents.openCooconInsurance}
          className='w-full p-4 pt-[20px] bg-[#F3F6F9] rounded-[10px] cursor-pointer'
        >
          <div className='flex justify-start items-center gap-2.5 mb-[20px]'>
            <span className='text-[14px] leading-[14px] font-[600] w-[30px] h-[30px] rounded-full bg-[#E3E7EA] flex justify-center items-center'>
              1
            </span>
            <strong className='text-[16px] leading-[24px] font-[600]'>
              보험사별 견적을 비교한 뒤
            </strong>
          </div>
          <div
            style={{
              backgroundImage:
                'url(/assets/images/png/main_insuranceSection_bg01.png)',
            }}
            className='mb-4 flex flex-col justify-between items-start gap-2 bg-no-repeat bg-center bg-cover pl-4 pr-[10px] pt-[20px] pb-[10px] rounded-[8px] min-h-[140px]'
          >
            <div className='flex flex-col justify-start items-start gap-2'>
              <strong className='text-[16px] leading-[24px] font-[500]'>
                다이렉트 자동차보험
              </strong>
              <p className='flex flex-col justify-start items-start'>
                <span className='text-[11px] leading-[16.5px] font-[300] text-[#555]'>
                  손해보험협회 심의필 제122184호
                </span>
                <span className='text-[11px] leading-[16.5px] font-[300] text-[#555]'>
                  (2024.05.13 ~ 2025.05.12)
                </span>
              </p>
            </div>
            <div className='self-end'>
              {InsuranceBannerOrder.map((el) => {
                const targetCompanyName = el as InsuranceCompany;
                const targetCompanyData =
                  InsuranceCompanyData[targetCompanyName];
                return (
                  <img
                    key={targetCompanyName}
                    className={cn('ml-[-20px] float-left w-9 h-9')}
                    src={targetCompanyData.company.logo.png}
                    alt='보험사 로고'
                  />
                );
              })}
            </div>
          </div>
          <button className='text-[15px] leading-[22.5px] text-white font-[600] w-full flex justify-center items-center p-[13px] bg-[#222222] rounded-[8px] h-[50px]'>
            9개 보험사 한 번에 비교해 보세요
          </button>
        </li>
        <li
          onClick={handleClickEvents.moveToInsurance}
          className='w-full p-4 pt-[20px] bg-[#F3F6F9] rounded-[10px] cursor-pointer'
        >
          <div className='flex justify-start items-center gap-2.5 mb-[20px]'>
            <span className='text-[14px] leading-[14px] font-[600] w-[30px] h-[30px] rounded-full bg-[#E3E7EA] flex justify-center items-center'>
              2
            </span>
            <strong className='text-[16px] leading-[24px] font-[600]'>
              최대 혜택 3만원 받고
            </strong>
          </div>
          <div className='mb-4 w-full min-h-[140px] relative bg-white flex justify-center items-center rounded-[8px] overflow-hidden'>
            <img
              src='/assets/images/png/main_insurance_bg02.png'
              alt=''
              className='relative z-[1] w-[216px] object-contain mb-[15px]'
            />
            <div className='w-full bg-[#E2E4F5] h-[56px] absolute z-0 bottom-0 left-0'></div>
          </div>
          <button className='whitespace-nowrap text-[15px] leading-[22.5px] text-white font-[600] w-full flex justify-center items-center p-[13px] bg-[#7273F9] rounded-[8px] h-[50px]'>
            카사요를 통해 최저가 보험 가입해 보세요
          </button>
        </li>
      </ul>
    </div>
  );
}
