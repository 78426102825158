/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'components/ui/accordion';
import { useEffect, useLayoutEffect, useState } from 'react';
import useSystemStore from 'store/useSystemStore';
import { getPostList, getPost } from 'apis/board';
import ReactHtmlParser from 'react-html-parser';
import dayjs from 'dayjs';
import { PostList, PostDetailInfo, GetPostListDTO } from '@carsayo/types';
import NoData from 'components/common/v3/NoData';
import { Button } from 'components/ui/button';
import { useGetPostList } from './query';
import InfiniteScroll from 'react-infinite-scroll-component';

const takeCount: number = 10;

export default function MypageNotice() {
  const { setTopTabbarState, setIsBottomTabbar } = useSystemStore();
  const [isFetching, setIsFetching] = useState(false);
  const [postList, setPostList] = useState<PostList>();
  const [postDetailInfo, setPostDetailInfo] = useState<{
    [key: number]: PostDetailInfo;
  }>({});

  const {
    data: postListData,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    refetch,
  } = useGetPostList('notice');

  const handleVoidEvents = {
    getPost: async (postId: number) => {
      if (postDetailInfo[postId]) return;
      const post: PostDetailInfo = await getPost({
        boardKey: 'notice',
        postId: postId,
      });
      setPostDetailInfo({ ...postDetailInfo, [postId]: post });
    },
  };

  useEffect(() => {
    if (!postListData) return;

    const mergedPostList = postListData.pages.flatMap(
      (page) => page.searchedPost,
    );

    setPostList((prev) => ({
      ...postListData.pages[0],
      searchedPost: mergedPostList,
    }));
  }, [postListData]);

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      title: '공지사항',
      rightElement: undefined,
      leftElement: 'back',
    });
    setIsBottomTabbar(false);
  }, []);

  if (isFetching) return <></>;
  return (
    <div className='pt-[20px] pb-[50px] h-full overflow-y-hidden'>
      <strong className='px-[20px] text-[15px] leading-[15px] font-[600] inline-block mb-[20px]'>
        총 {postList?.totalCount ?? 0}건
      </strong>
      {postList && postList.totalCount > 0 ? (
        <div id='noticeScroll' className='w-full h-full overflow-y-auto'>
          <InfiniteScroll
            next={fetchNextPage}
            hasMore={hasNextPage}
            loader={
              <div className='font-semibold text-center py-4'>
                정보를 불러오는 중입니다..
              </div>
            }
            dataLength={postList?.searchedPost.length || 0}
            scrollableTarget='noticeScroll'
            scrollThreshold={0.9}
          >
            <Accordion
              key={`notice-accordion`}
              type='single'
              collapsible
              className='w-full'
            >
              {postList.importantPost.map((el, index) => {
                return (
                  <AccordionItem
                    value={`importantPost-${el.id}-${index}`}
                    key={`importantPost-${el.id}-${index}`}
                  >
                    <AccordionTrigger
                      onClick={() => handleVoidEvents.getPost(el.id)}
                      className='px-[20px] flex items-center gap-2.5 border-b border-[#E5EAEE] py-6 text-left'
                    >
                      <div>
                        <div className='flex items-center gap-1'>
                          {/* {dayjs(el.created_at).isAfter(dayjs().add(-7, 'day')) && (
                        <div className='h-5 border border-[rgba(183,28,28,.2)] px-1 text-center text-desc leading-5 text-destructive'>
                          N
                        </div> 
                      )} */}
                          <div className='min-w-[44px] h-[28px] px-[6px] py-[5px] text-[14px] leading-[14px] font-[600] text-[#FF4747] bg-[#FFECEC] inline-flex justify-center items-center rounded-[6px]'>
                            중요
                          </div>
                        </div>
                        <div className='mt-2 text-[16px] leading-[25.6px] font-[600] whitespace-pre-line'>
                          {el.title}
                        </div>
                        <div className='mt-2 text-[#999999] text-[14px] leading-[14px] font-[500]'>
                          {dayjs(el.created_at).format('YYYY.MM.DD')}
                        </div>
                      </div>
                    </AccordionTrigger>
                    <AccordionContent className='prose bg-[#F3F6F9] py-6 px-[20px]'>
                      {ReactHtmlParser(postDetailInfo?.[el.id]?.content_html)}
                    </AccordionContent>
                  </AccordionItem>
                );
              })}

              {postList.searchedPost.map((el, index) => {
                return (
                  <AccordionItem
                    value={`normalPost-${el.id}-${index}`}
                    key={`normalPost-${el.id}-${index}`}
                  >
                    <AccordionTrigger
                      onClick={() => handleVoidEvents.getPost(el.id)}
                      className='px-[20px] flex items-center gap-2.5 border-b border-[#E5EAEE] py-6 text-left'
                    >
                      <div>
                        <div className='text-[16px] leading-[25.6px] font-[600] whitespace-pre-line'>
                          {el.title}
                        </div>
                        <div className='mt-2 text-[#999999] text-[14px] leading-[14px] font-[500]'>
                          {dayjs(el.created_at).format('YYYY.MM.DD')}
                        </div>
                      </div>
                    </AccordionTrigger>
                    <AccordionContent className='ql-editor prose bg-[#F3F6F9] py-6 px-[20px] text-[#222222 ]'>
                      {ReactHtmlParser(postDetailInfo?.[el.id]?.content_html)}
                    </AccordionContent>
                  </AccordionItem>
                );
              })}
            </Accordion>
          </InfiniteScroll>
        </div>
      ) : (
        <div className='w-full min-h-[calc(100dvh-219px)] flex justify-center items-center pb-[20%]'>
          <NoData
            imgUrl='/assets/images/v2/noNotice.png'
            text='공지사항이 없어요'
          />
        </div>
      )}
    </div>
  );
}
