/* eslint-disable @typescript-eslint/no-unused-vars */
import { ArrowLeft, ChevronLeft, X } from 'lucide-react';
import React, {
  ChangeEvent,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';
import { carkeyMenuList } from '../data';
import { cn } from 'utils';
import dayjs from 'dayjs';
import CarkeyMenuCard from './CarkeyMenu';
import { CustomerActionInterface, InsuranceDataInterface } from '../ask';
import InsuranceMenuCard from './InsuranceMenu';
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
} from 'components/ui/drawer';
import { Button } from 'components/ui/button';
import CarsayoButton from 'components/common/CarsayoButton';
import CarsayoBottomSheet from 'components/common/CarsayoBottomSheet';
import DatePicker from 'react-mobile-datepicker-ts';
import InsuranceCompleteCard from './InsuranceComplete';
import useInsuranceDataStore from '../store/store';
import { RegisterCarCreateDTO } from '@carsayo/types';
import { addRegisterCarApi } from '../api';
import { CarsayoToast } from 'components/common/v2/CarsayoToast';
import useMemberStore from 'store/useMemberStore';
import useInsuranceOriginDataStore from '../store/dataStore';

const AskCarkeyMain = () => {
  const { setTopTabbarState, setIsBottomTabbar } = useSystemStore();
  const { loginMember, refreshLoginMember } = useMemberStore();
  const navigate = useNavigate();
  const location = useLocation();
  const nowDate = new Date();
  const nowTime = dayjs(nowDate).format('YYYY년 MM월 DD일');
  const [customerAction, setCustomerAction] =
    useState<CustomerActionInterface | null>(null);
  const [chatTimeLineTitle, setChatTimeLineTitle] = useState<string[]>(
    location.state?.key ? location.state.key : [],
  );
  const [chatTimeLine, setChatTimeLine] = useState<React.ReactNode[]>([]);
  const chatRef = useRef<HTMLDivElement>(null);
  const [insuranceDrawerOpen, setInsuranceDrawerOpen] = useState(false);
  // datepicker 관련
  const minDate = dayjs().subtract(20, 'year').startOf('day').toDate();
  const maxDate = dayjs().add(20, 'year').endOf('day').toDate();
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  // 보험만기일 등록 관련
  const [isInsuranceCompany, setIsInsuranceCompany] = useState(false);
  const [insuranceDate, setInsuranceDate] = useState<Date>(new Date());

  const handleDateChange = (date: Date) => {
    setInsuranceDate(date);
  };

  /** 보험 만기일 등록하는 drawer
   * 컴포넌트로 분리했다가 날짜선택때문에 이 페이지에 넣게 되었습니다. 추후 날짜 선택 컴포넌트 변경시 같이 변경해주면 좋을 것 같습니다.
   *  */
  // const InsuranceAddBox = () => {
  //   const { originInsuranceData } = useInsuranceOriginDataStore();
  //   const {
  //     carNumber,
  //     subscriptionDate,
  //     company,
  //     setCarNumber,
  //     setCompany,
  //     setInsuranceData,
  //     resetInsuranceData,
  //   } = useInsuranceDataStore();
  //   const [insuranceCompany, setInsuranceCompany] = useState<string | null>(
  //     null,
  //   );
  //   const [insuranceCarNumber, setInsuranceCarNumber] = useState('');
  //   const insuranceCarNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
  //     setInsuranceCarNumber(event.target.value);
  //   };
  //   const insuranceDataAdd = async () => {
  //     const companyId = originInsuranceData.find(
  //       (item) => item.name === company,
  //     )?.id;
  //     if (company && carNumber && insuranceDate && companyId) {
  //       const reqData: RegisterCarCreateDTO = {
  //         carName: carNumber,
  //         insurance_start_date: insuranceDate,
  //         insurance_company_id: companyId,
  //         isRepresentative:
  //           loginMember?.customerInfo?.registerCar.list &&
  //           loginMember.customerInfo.registerCar.list.length > 0
  //             ? false
  //             : true,
  //       };
  //       try {
  //         const result = await addRegisterCarApi(reqData);
  //         if (result) {
  //           refreshLoginMember();
  //           resetInsuranceData();
  //           setCustomerAction({
  //             id: 'insuranceAddComplete',
  //             textShow: false,
  //             text: '나의 보험 만기일 등록 완료',
  //             actionReturn: 'card',
  //             time: new Date(),
  //           });
  //           setInsuranceDrawerOpen(false);
  //         } else {
  //           resetInsuranceData();
  //           setInsuranceDrawerOpen(false);
  //         }
  //       } catch (error: any) {
  //         resetInsuranceData();
  //         CarsayoToast.error(error);
  //       }
  //     }
  //   };

  //   return (
  //     <div>
  //       <Drawer open={insuranceDrawerOpen}>
  //         <DrawerContent className='mx-auto flex max-h-[90vh] w-full max-w-sm flex-col'>
  //           <DrawerHeader className='relative'>
  //             <DrawerTitle className='text-center text-lg font-semibold'>
  //               {isInsuranceCompany ? '보험사 선택' : '보험 만기일 등록'}
  //             </DrawerTitle>
  //             <Button
  //               onClick={
  //                 isInsuranceCompany
  //                   ? () => setIsInsuranceCompany(!isInsuranceCompany)
  //                   : () => {
  //                       setInsuranceDrawerOpen(!insuranceDrawerOpen);
  //                       resetInsuranceData();
  //                     }
  //               }
  //               className='absolute right-5 top-4 h-7 w-7 border-0 bg-transparent p-0 text-[#111111]'
  //             >
  //               <X className='h-7 w-7' />
  //             </Button>
  //           </DrawerHeader>
  //           {isInsuranceCompany ? (
  //             // 보험사 선택
  //             <div className='p-4 pt-0'>
  //               <div className='flex justify-between items-end pt-[10px] pb-[20px] border-b-[1px] border-[#F1F1F5]'>
  //                 <div>
  //                   <h4 className='text-[16px] leading-[24px] font-[600] mb-[8px]'>
  //                     보험사를 선택해주세요
  //                   </h4>
  //                   <p className='text-[14px] leading-[20px]'>
  //                     고객님을 위한 보험 만기 알림 및 긴급출동 등 특별한 카
  //                     라이프에 카집사가 함께 하겠습니다.
  //                   </p>
  //                 </div>
  //                 <img
  //                   src='/assets/images/v2/ask-carGipsa02.svg'
  //                   alt='카집사 이미지02'
  //                 />
  //               </div>
  //               <div>
  //                 <ul className='brandAllCheck mt-4 grid grid-cols-3 gap-x-[21.5px] gap-y-4 mb-[20px]'>
  //                   {originInsuranceData.map((el, index) => {
  //                     const targetCompanyName = el.name;
  //                     const targetCompanyData = el;
  //                     return (
  //                       <li
  //                         key={`${targetCompanyName}_${index}`}
  //                         className={`group relative h-[88px] rounded-xl border bg-[#fff] py-4 ${targetCompanyName === insuranceCompany ? 'border-primary' : ''}`}
  //                       >
  //                         <label
  //                           htmlFor={`insuranceRadio_${targetCompanyName}`}
  //                         >
  //                           <input
  //                             type='radio'
  //                             id={`insuranceRadio_${targetCompanyName}`}
  //                             value={targetCompanyName}
  //                             className='absolute h-0 w-0 hidden'
  //                             checked={targetCompanyName === insuranceCompany}
  //                             onChange={(e: ChangeEvent<HTMLInputElement>) => {
  //                               setInsuranceCompany(e.target.value);
  //                             }}
  //                           />
  //                           <div className='mx-auto h-9 w-9'>
  //                             <img
  //                               src={targetCompanyData.logo_url}
  //                               alt={`보험사 로고_${targetCompanyName}`}
  //                             />
  //                           </div>
  //                           <div
  //                             className={`mt-2 text-[#111]  px-1 overflow-hidden text-ellipsis whitespace-nowrap text-center text-[13px] ${targetCompanyName === insuranceCompany ? 'font-[500]' : 'font-[400]'} `}
  //                           >
  //                             {targetCompanyName}
  //                           </div>
  //                         </label>
  //                       </li>
  //                     );
  //                   })}
  //                 </ul>
  //               </div>
  //               <CarsayoButton
  //                 disabled={!insuranceCompany}
  //                 color='purpleBlue'
  //                 onClick={() => {
  //                   setCompany(insuranceCompany ?? 'KB손해보험');
  //                   setIsInsuranceCompany(!isInsuranceCompany);
  //                 }}
  //               >
  //                 보험사 선택
  //               </CarsayoButton>
  //             </div>
  //           ) : (
  //             // 보험 만기일 등록
  //             <div className='p-4 pt-0'>
  //               <div className='flex justify-between items-end pt-[10px] pb-[20px] border-b-[1px] border-[#F1F1F5] mb-[20px]'>
  //                 <div>
  //                   <h4 className='text-[16px] leading-[24px] font-[600] mb-[8px]'>
  //                     가입한 보험을 입력해주세요
  //                   </h4>
  //                   <p className='text-[14px] leading-[20px]'>
  //                     고객님을 위한 보험 만기 알림 및 긴급출동 등 특별한 카
  //                     라이프에 카집사가 함께 하겠습니다.
  //                   </p>
  //                 </div>
  //                 <img
  //                   src='/assets/images/v2/ask-carGipsa.svg'
  //                   alt='카집사 이미지'
  //                 />
  //               </div>
  //               <div className='w-full flex flex-col justify-start items-start gap-6 mb-6'>
  //                 <div className='w-full'>
  //                   <label
  //                     htmlFor='insuranceCarNumber'
  //                     className='block text-[15px] leading-[22px] mb-[6px]'
  //                   >
  //                     차량번호
  //                   </label>
  //                   <input
  //                     type='text'
  //                     id='insuranceCarNumber'
  //                     className='w-full block py-[14px] px-[16px] border border-[#E5E5EC] rounded-[6px] text-[16px] leading-[24px]'
  //                     placeholder='차량 번호를 입력해주세요. (ex. 12가1234)'
  //                     onChange={insuranceCarNumberChange}
  //                     onBlur={() => {
  //                       setCarNumber(insuranceCarNumber);
  //                     }}
  //                     value={
  //                       insuranceCarNumber.length > 0
  //                         ? insuranceCarNumber
  //                         : carNumber
  //                     }
  //                   />
  //                 </div>
  //                 <div className='w-full'>
  //                   <label
  //                     htmlFor='insuranceCompany'
  //                     className='block text-[15px] leading-[22px] mb-[6px]'
  //                   >
  //                     보험사
  //                   </label>
  //                   <button
  //                     className='w-full block py-[14px] px-[16px] border border-[#E5E5EC] rounded-[6px] text-[16px] leading-[24px]'
  //                     onClick={() => {
  //                       company && setInsuranceCompany(company);
  //                       setIsInsuranceCompany(!isInsuranceCompany);
  //                     }}
  //                   >
  //                     <div className='flex justify-between items-center'>
  //                       {company ? (
  //                         <div className='flex justify-start items-center gap-[10px]'>
  //                           <img
  //                             src={
  //                               originInsuranceData.find(
  //                                 (item) => item.name === company,
  //                               )?.logo_url
  //                             }
  //                             alt={`${company}_로고이미지`}
  //                             className='w-[20px] object-contain'
  //                           />
  //                           <span className='text-[#111] text-[16px] leading-[24px]'>
  //                             {company}
  //                           </span>
  //                         </div>
  //                       ) : (
  //                         <div className='flex justify-start items-center gap-[10px]'>
  //                           <img
  //                             src='/assets/images/png/symbol-default.png'
  //                             alt='카사요심볼회색'
  //                             className='w-[20px] object-contain'
  //                           />
  //                           <span className='text-[#767676] text-[16px] leading-[24px]'>
  //                             보험사를 선택해주세요
  //                           </span>
  //                         </div>
  //                       )}

  //                       <img
  //                         src='/assets/images/v2/arrow-left.png'
  //                         alt='우측 화살표'
  //                         className='rotate-[180deg] w-4 h-4 object-contain'
  //                       />
  //                     </div>
  //                   </button>
  //                 </div>
  //                 <div className='w-full'>
  //                   <label
  //                     htmlFor='insuranceDate'
  //                     className='block text-[15px] leading-[22px] mb-[6px]'
  //                   >
  //                     보험 가입일
  //                   </label>
  //                   <div className='relative'>
  //                     <img
  //                       src='/assets/images/png/calendar.png'
  //                       alt='달력 아이콘'
  //                       className='absolute left-4 top-0 bottom-0 my-auto'
  //                     />
  //                     <input
  //                       onClick={() => {
  //                         setInsuranceDrawerOpen(!insuranceDrawerOpen);
  //                         setIsDatePickerOpen(!isDatePickerOpen);
  //                       }}
  //                       readOnly
  //                       type='text'
  //                       id='insuranceDate'
  //                       className='w-full block py-[14px] px-[16px] pl-[48px] border border-[#E5E5EC] rounded-[6px] text-[16px] leading-[24px]'
  //                       value={dayjs(insuranceDate).format('YYYY/MM/DD')}
  //                     />
  //                   </div>
  //                 </div>
  //               </div>
  //               <CarsayoButton
  //                 disabled={
  //                   carNumber && subscriptionDate && company ? false : true
  //                 }
  //                 color='purpleBlue'
  //                 onClick={insuranceDataAdd}
  //               >
  //                 등록
  //               </CarsayoButton>
  //             </div>
  //           )}
  //         </DrawerContent>
  //       </Drawer>
  //     </div>
  //   );
  // };

  const scrollToTop = () => {
    if (chatRef.current) {
      const parent = chatRef.current.parentElement;
      if (parent) {
        parent.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    }
  };

  const actionHandlers: { [key: string]: () => void } = {
    insuranceMenu: () => {
      setChatTimeLine((prevChatTimeLine) => [
        ...prevChatTimeLine,
        <InsuranceMenuCard
          customerAction={customerAction}
          setCustomerAction={setCustomerAction}
          nowTime={new Date()}
        />,
      ]);
    },
    insuranceAddOpen: () => {
      navigate('/ask/carManagement/add', {
        state: {
          key: 'carkeyInsuranceAdd',
        },
      });
    },
    insuranceAddComplete: () => {
      setChatTimeLine((prevChatTimeLine) => [
        ...prevChatTimeLine,
        <InsuranceCompleteCard
          customerAction={customerAction}
          setCustomerAction={setCustomerAction}
        />,
      ]);
      navigate(location.pathname, { replace: true, state: {} });
    },
    reset: () => {
      scrollToTop();
      setCustomerAction(null);
      setChatTimeLine([
        <CarkeyMenuCard
          customerAction={customerAction}
          setCustomerAction={setCustomerAction}
          nowTime={nowDate}
        />,
      ]);
    },
  };
  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      leftElement: 'back',
      title: '카키에게 물어보기',
    });
    setIsBottomTabbar(false);
    // 첫 선택 메뉴 chatTimeLine에 추가
    setChatTimeLine((prevChatTimeLine) => [
      ...prevChatTimeLine,
      <CarkeyMenuCard
        customerAction={customerAction}
        setCustomerAction={setCustomerAction}
        nowTime={nowDate}
      />,
    ]);

    return () => {
      setCustomerAction(null);
      setChatTimeLine([]);
    };
  }, []);

  useEffect(() => {
    if (chatTimeLine.length === 0 && chatTimeLineTitle.length > 0) {
      chatTimeLineTitle.map((item) => actionHandlers[item]());
    }
  }, [chatTimeLine, chatTimeLineTitle]);

  useEffect(() => {
    console.info(customerAction);
    if (customerAction?.id) {
      actionHandlers[customerAction?.id]();
    }
  }, [customerAction, setChatTimeLine]);

  // useEffect(() => {
  //   if (chatRef.current && chatTimeLine.length > 1) {
  //     console.info('확인용    ', chatRef.current.scrollHeight);
  //     chatRef.current.scrollTo({
  //       top: chatRef.current.scrollHeight,
  //       behavior: 'smooth',
  //     });
  //   }
  // }, [chatTimeLine]);
  useEffect(() => {
    // 전체 스크롤로 범위가 변경되어 무조건 하단으로 보냅니다.
    if (chatRef.current && chatTimeLine.length > 1) {
      const parent = chatRef.current.parentElement;
      if (parent) {
        parent.scrollTo({
          top: parent.scrollHeight,
          behavior: 'smooth',
        });
      }
    }
  }, [chatTimeLine]);

  return (
    <section
      className='w-full bg-[#DDEAE4] pt-[30px] px-5 pb-2.5'
      ref={chatRef}
    >
      {/* 카키 상단 영역 */}
      <div className='bg-gradient-to-br mb-[40px] relative overflow-hidden from-[#44A375] to-[#337053] min-h-[140px] rounded-[16px] flex justify-start items-start py-6 px-5'>
        <ul className='relative z-[1] w-full flex flex-col justify-start items-start gap-[6px]'>
          {carkeyMenuList &&
            carkeyMenuList.length > 0 &&
            carkeyMenuList.map((item, index) => {
              return (
                <li
                  key={`askMainCard_${item.text}_${index}`}
                  className={cn(
                    'bg-[#2A7450] shadow-[0px_4px_16px_#0000001A] rounded-[6px] p-[6px] flex justify-start items-center gap-[6px]',
                  )}
                >
                  <img
                    src={item.imgUrl}
                    alt={`askMainCardImg_${item.text}`}
                    className='w-4 object-contain'
                  />
                  <span className='text-[14px] leading-[14px] font-[500] text-white'>
                    {item.text}
                  </span>
                </li>
              );
            })}
        </ul>

        <img
          src={'/assets/images/v2/ask-carkey.svg'}
          alt={`ask-carkey_img`}
          className={`absolute bottom-[0px] right-[17px] z-0 w-[86px] object-contain`}
        />
      </div>
      {/* 실제 카키 컨텐츠 영역 */}
      <div className='w-full pb-[46px] bg-[#DDEAE4]'>
        {/* 이 페이지에 들어온 시간 */}
        <div className='w-full mb-[40px] relative after:content-[""] after:absolute after:left-0 after:top-0 after:bottom-0 after:my-auto after:w-full after:h-[1px] after:bg-[#1111114D] after:z-0'>
          <span className='flex justify-center items-center mx-auto relative z-10 w-[131px] bg-[#DDEAE4] font-[500] text-[14px] leading-[14px] text-[#555]'>
            {nowTime}
          </span>
        </div>
        {/* 채팅영역 */}
        <div className='w-full flex flex-col justify-start items-start gap-[20px]'>
          {chatTimeLine &&
            chatTimeLine.length > 0 &&
            chatTimeLine.map((item) => {
              return item;
            })}
        </div>
      </div>
      {/* {memoizedAddBox && memoizedAddBox} */}
      {/* <InsuranceAddBox /> */}
      {/* 보험 등록일 선택하는 datepicker가 drawer안에서 동작을 안해서 밖으로 뺐습니다. 추후 수정이 필요합니다. */}
      <CarsayoBottomSheet
        isOpen={isDatePickerOpen}
        onClose={() => {
          setInsuranceDate(new Date());
          setIsDatePickerOpen(false);
          setInsuranceDrawerOpen(true);
        }}
      >
        <div className={`w-full relative min-h-[100px]`}>
          <DatePicker
            value={insuranceDate}
            isOpen={isDatePickerOpen}
            onChange={handleDateChange}
            showHeader={false}
            showFooter={false}
            min={minDate ?? undefined}
            max={maxDate ?? undefined}
          />
          <button
            onClick={() => {
              setIsDatePickerOpen(false);
              setInsuranceDrawerOpen(true);
            }}
            className='max-h-[50px] w-full py-[14px] px-4 bg-[#7273F9] text-white rounded-[6px] absolute bottom-0'
          >
            확인
          </button>
        </div>
      </CarsayoBottomSheet>
    </section>
  );
};
export default AskCarkeyMain;
