export default function MyIcon({
  fill,
  width,
  height,
}: {
  fill?: string;
  width?: string;
  height?: string;
}) {
  return (
    <svg
      width={width || '30'}
      height={height || '30'}
      viewBox='0 0 30 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_85_4684)'>
        <path
          d='M10.501 9.85715C10.501 11.0506 10.9751 12.1952 11.819 13.0391C12.6629 13.883 13.8075 14.3571 15.0009 14.3571C16.1944 14.3571 17.339 13.883 18.1829 13.0391C19.0268 12.1952 19.5009 11.0506 19.5009 9.85715C19.5009 8.66368 19.0268 7.5191 18.1829 6.67519C17.339 5.83128 16.1944 5.35718 15.0009 5.35718C13.8075 5.35718 12.6629 5.83128 11.819 6.67519C10.9751 7.5191 10.501 8.66368 10.501 9.85715Z'
          fill={fill || '#B2B7BB'}
          stroke={fill || '#B2B7BB'}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M8.24901 24.6429C7.00638 24.6429 5.99902 23.6355 5.99902 22.3929C5.99902 21.1995 6.63116 20.0549 7.75637 19.211C8.88158 18.367 10.4077 17.8929 11.999 17.8929H17.999C19.5902 17.8929 21.1164 18.367 22.2416 19.211C23.3668 20.0549 23.9989 21.1995 23.9989 22.3929'
          fill={fill || '#B2B7BB'}
        />
        <path
          d='M5.99902 22.3929V22.3929C5.99902 23.6355 7.00638 24.6429 8.24901 24.6429H21.7489C22.9916 24.6429 23.9989 23.6355 23.9989 22.3929V22.3929C23.9989 21.1994 23.3668 20.0549 22.2416 19.211C21.1164 18.367 19.5902 17.8929 17.999 17.8929H11.999C10.4077 17.8929 8.88158 18.367 7.75637 19.211C6.63116 20.0549 5.99902 21.1994 5.99902 22.3929Z'
          stroke={fill || '#B2B7BB'}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M22.9999 24.5H11L22.4999 21L23.9999 22.5L22.9999 24.5Z'
          fill={fill || '#B2B7BB'}
        />
      </g>
      <defs>
        <clipPath id='clip0_85_4684'>
          <rect width='30' height='30' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
