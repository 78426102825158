import CarsayoButton from 'components/common/CarsayoButton';
import { ChevronLeft } from 'lucide-react';
import { useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';

const AccidentMainPage = () => {
  const { setTopTabbarState, setIsBottomTabbar } = useSystemStore();
  const navigate = useNavigate();

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      leftElement: (
        <div
          onClick={() => {
            navigate(-1);
          }}
        >
          <ChevronLeft width={32} height={32} strokeWidth={1.5} />
        </div>
      ),
      title: '사고 접수',
    });
    setIsBottomTabbar(false);
  }, []);
  return (
    <div className='p-4 pt-0'>
      <div className='flex justify-between items-end pt-[10px] pb-[20px] border-b-[1px] border-[#F1F1F5]'>
        <div>
          <h4 className='text-[20px] leading-[30px] font-[600] max-w-[196px] break-keep'>
            사고 접수하시면 신속하게 처리하겠습니다.
          </h4>
        </div>
        <img
          src='/assets/images/v2/ask-carGipsa-accident01.svg'
          alt='카집사보는이미지'
        />
      </div>
      <div className='w-full pt-[20px]'>
        <ul className='w-full flex flex-col justify-start items-start gap-[32px] mb-[49px]'>
          <li className='w-full p-4 rounded-[6px] bg-[#F6F6FC] relative after:absolute after:w-[24px] after:h-[24px] after:bg-next-step after:left-0 after:right-0 after:mx-auto after:bottom-[-28px]'>
            <h4 className='text-[14px] leading-[14px] font-[600] text-[#3156C6] mb-4 px-[6px] py-[4px] rounded-[6px] bg-[#DBE4FE] inline-block'>
              01 카집사 접수
            </h4>
            <div className='flex justify-between items-end gap-[10px]'>
              <p className='flex flex-col justify-start items-start text-[16px] leading-[22.4px] font-[400] text-[#000]'>
                <span>사고접수가 완료되면 카집사가</span>
                <span>내 위치 기반 근처 공업사를 확인합니다.</span>
              </p>
              <img
                className='w-[50px] h-[50px] object-contain'
                src='/assets/images/v2/accident-img01.svg'
                alt='문자 이미지'
              />
            </div>
          </li>
          <li className='w-full p-4 rounded-[6px] bg-[#F6F6FC] relative after:absolute after:w-[24px] after:h-[24px] after:bg-next-step after:left-0 after:right-0 after:mx-auto after:bottom-[-28px]'>
            <h4 className='text-[14px] leading-[14px] font-[600] text-[#3156C6] mb-4 px-[6px] py-[4px] rounded-[6px] bg-[#DBE4FE] inline-block'>
              02 공업사 배정
            </h4>
            <div className='flex justify-between items-end gap-[10px]'>
              <p className='flex flex-col justify-start items-start text-[16px] leading-[22.4px] font-[400] text-[#000]'>
                <span>현재 위치 기반 또는 지역 선택을 통해</span>
                <span>가까운 공업사로 안내해 드립니다.</span>
              </p>
              <img
                className='w-[50px] h-[50px] object-contain'
                src='/assets/images/v2/accident-img02.svg'
                alt='지도 이미지'
              />
            </div>
          </li>
          <li className='w-full p-4 rounded-[6px] bg-[#F6F6FC] relative'>
            <h4 className='text-[14px] leading-[14px] font-[600] text-[#3156C6] mb-4 px-[6px] py-[4px] rounded-[6px] bg-[#DBE4FE] inline-block'>
              03 카집사 안내
            </h4>
            <div className='flex justify-between items-end gap-[10px]'>
              <p className='flex flex-col justify-start items-start text-[16px] leading-[22.4px] font-[400] text-[#000]'>
                <span>카사요 앱에서 카집사가</span>
                <span>사고 수리에 대한 안내를 진행해 드립니다.</span>
              </p>
              <img
                className='w-[50px] h-[50px] object-contain p-[4px]'
                src='/assets/images/v2/accident-img03.svg'
                alt='공구 이미지'
              />
            </div>
          </li>
        </ul>
        <CarsayoButton
          color='purpleBlue'
          onClick={() => navigate('/ask/theme/cargipsa/accidentAdd')}
        >
          접수하기
        </CarsayoButton>
      </div>
    </div>
  );
};
export default AccidentMainPage;
