/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import CarsayoCheckRadio from 'components/common/v2/CarsayoCheckRadio';
import {
  RegisterCar,
  RegisterCarIdDTO,
  RegisterCarUpdateDTO,
} from '@carsayo/types';
import dayjs from 'dayjs';
import { deleteRegisterCarApi, updateRegisterCarApi } from '../api';
import useMemberStore from 'store/useMemberStore';
import { CarsayoToast } from 'components/common/v2/CarsayoToast';
import useInsuranceDataStore from '../store/store';
import Confirm from 'components/ui/confirm';
import V3_Confirm from 'components_v3/dialog/Confirm';
import { useNavigate } from 'react-router-dom';

interface CarBoxLayoutInterface {
  carData: RegisterCar;
  title: string;
  active: boolean;
  setActive: () => void;
  changeOpen: (data: RegisterCar) => void;
}

const CarBoxLayout = ({
  carData,
  title,
  active,
  setActive,
  changeOpen,
}: CarBoxLayoutInterface) => {
  const { carNumber, subscriptionDate, setInsuranceData, resetInsuranceData } =
    useInsuranceDataStore();
  const { refreshLoginMember } = useMemberStore();
  const navigate = useNavigate();
  const [representativeConfirmOpen, setRepresentativeConfirmOpen] =
    useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [selectData, setSelectData] = useState<RegisterCar | null>(null);

  const representativeSelect = async () => {
    if (selectData && selectData.id && selectData.insuranceCompany) {
      const reqData: RegisterCarUpdateDTO = {
        id: selectData.id,
        insurance_company_id: selectData.insuranceCompany.id,
        isRepresentative: !selectData.isRepresentative,
      };
      try {
        const result = await updateRegisterCarApi(reqData);
        if (result) {
          refreshLoginMember();
          CarsayoToast.success(
            `${selectData.carName} 차량을 대표 차량으로 설정했습니다.`,
          );
          setRepresentativeConfirmOpen(!representativeConfirmOpen);
        }
      } catch (error: any) {
        console.error(error);
        CarsayoToast.error(error);
        setRepresentativeConfirmOpen(!representativeConfirmOpen);
      }
    }
  };
  const deleteSelect = async () => {
    console.info('타겟 정보', selectData);
    if (selectData && selectData.id) {
      const reqData: RegisterCarIdDTO = {
        id: selectData.id,
      };
      try {
        await deleteRegisterCarApi(reqData);
        refreshLoginMember();
        resetInsuranceData();
        CarsayoToast.success(`차량정보를 삭제했습니다.`);
        setDeleteConfirmOpen(!deleteConfirmOpen);
      } catch (error: any) {
        console.error(error);
        CarsayoToast.error(error);
        setDeleteConfirmOpen(!deleteConfirmOpen);
      }
    }
  };
  const representativeConfirm = (data: RegisterCar) => () => {
    setSelectData(data);
    setRepresentativeConfirmOpen(!representativeConfirmOpen);
  };
  return (
    <div className='w-full bg-white rounded-[16px] shadow-[0px_6px_20px_#0000000D] p-5 pt-6'>
      <div className='w-full flex justify-start gap-[15px] items-center'>
        <span className='w-[60px] h-[60px] rounded-[8px] flex justify-center items-center bg-[#F3F6F9]'>
          <img
            src='/assets/images/v2/blue_car.png'
            alt='차량이미지'
            className='h-[35px] object-contain'
          />
        </span>
        <div className='flex flex-col justify-start items-start gap-2 flex-1'>
          <div className='flex justify-start items-center gap-2'>
            <strong className='text-[22px] leading-[22px] font-[600]'>
              {title ?? '차량번호'}
            </strong>
            <span
              className={`${carData.isRepresentative ? 'block' : 'hidden'} h-[25px] font-[600] text-[#5455EA] text-[13px] leading-[13px] py-[6px] px-[10px] rounded-full bg-[#E6E6F6]`}
            >
              대표
            </span>
          </div>
          <div className='w-full'>
            <CarsayoCheckRadio
              label='대표 차량'
              checked={carData.isRepresentative}
              onClick={
                carData.isRepresentative
                  ? () => {}
                  : representativeConfirm(carData)
              }
              onChange={() => {}}
            />
          </div>
        </div>
      </div>
      <div className={`w-full pt-[20px]`}>
        <div className='w-full pt-[19px] border-t-[1px] border-[#E5E5EC]'>
          <ul className='w-full flex flex-col justify-start items-start gap-[14px]'>
            <li className='w-full flex justify-between items-center gap-[20px]'>
              <span className='text-[15px] leading-[15px] font-[400] text-[#555]'>
                보험사
              </span>
              <strong className='text-[15px] leading-[15px] font-[500]'>
                {carData.insuranceCompany?.name ?? (
                  <span className='text-[#CCCCCC]'>보험사 없음</span>
                )}
              </strong>
            </li>
            <li className='w-full flex justify-between items-center gap-[20px]'>
              <span className='text-[15px] leading-[15px] font-[400] text-[#555]'>
                보험 가입일
              </span>
              <strong className='text-[15px] leading-[15px] font-[500]'>
                {carData.insurance_start_date ? (
                  `${dayjs(carData.insurance_start_date).format('YYYY.MM.DD')}
                  (${dayjs(carData.insurance_expiration_date).diff(new Date(), 'day')}일 남음)`
                ) : (
                  <span className='text-[#CCCCCC]'>가입일 없음</span>
                )}
              </strong>
            </li>
            <li className='w-full flex justify-between items-center gap-[20px]'>
              <span className='text-[15px] leading-[15px] font-[400] text-[#555]'>
                긴급출동번호
              </span>
              <strong className='text-[15px] leading-[15px] font-[500]'>
                {carData.insuranceCompany?.direct.emergency_contact ? (
                  carData.insuranceCompany?.direct.emergency_contact.replace(
                    /(\d{4})(\d+)/,
                    '$1-$2',
                  )
                ) : (
                  <span className='text-[#CCCCCC]'>번호 없음</span>
                )}
              </strong>
            </li>
          </ul>
        </div>
        <div className='w-full mx-auto'>
          <div className='w-full flex justify-end items-center mt-[30px] gap-[7px]'>
            <button
              className='flex justify-center items-center w-[80px] h-[44px] text-[16px] leading-[16px] font-[500] border border-[#E5E5EC] rounded-[10px]'
              // onClick={() => {
              //   setInsuranceData({
              //     carNumber: carData.carName,
              //     company: carData.insuranceCompany?.name ?? '',
              //     subscriptionDate: carData.insurance_start_date ?? new Date(),
              //   });
              //   changeOpen(carData);
              // }}
              onClick={() =>
                navigate('/ask/carManagement/edit', {
                  state: { id: carData.id },
                })
              }
            >
              수정
            </button>
            <button
              className='flex justify-center items-center w-[80px] h-[44px] text-[16px] leading-[16px] font-[500] border border-[#E5E5EC] rounded-[10px]'
              onClick={() => {
                setInsuranceData({
                  carNumber: carData.carName,
                  company: carData.insuranceCompany?.name ?? '',
                  subscriptionDate: carData.insurance_start_date ?? new Date(),
                });
                setSelectData(carData);
                setDeleteConfirmOpen(true);
              }}
            >
              삭제
            </button>
          </div>
        </div>
      </div>
      <V3_Confirm
        onClose={() => {
          setRepresentativeConfirmOpen(!representativeConfirmOpen);
        }}
        onConfirm={representativeSelect}
        isOpen={representativeConfirmOpen}
        setIsOpen={setRepresentativeConfirmOpen}
        disableBackdropClose={true}
        title={''}
        agreeText={'설정'}
        className='!pb-[50px] !pt-[44px]'
      >
        <div className='flex flex-col items-center justify-center gap-5'>
          <div className='flex flex-col items-center justify-center gap-1.5'>
            <div className='text-[#222] text-center font-[700] text-[16px] leading-[25.6px] tracking-[-0.32px] [font-feature-settings: "liga" off, "clig" off]'>
              대표 차량 설정
            </div>
            <div className='text-[#222] text-center font-[500] text-[15px] leading-[24px] tracking-[-0.3px] [font-feature-settings: "liga" off, "clig" off]'>
              선택하신 차량을
              <br />
              대표차량으로 설정하시겠습니까?
            </div>
          </div>
        </div>
      </V3_Confirm>
      {/* <Confirm
        isOpen={representativeConfirmOpen}
        setIsOpen={setRepresentativeConfirmOpen}
        isCloseButton={true}
        onConfirm={representativeSelect}
        title='대표 차량 설정'
        desc={`선택하신 차량을 대표차량으로 설정하시겠습니까?`}
        confirmText='설정'
      ></Confirm> */}
      <V3_Confirm
        onClose={() => {
          setDeleteConfirmOpen(!deleteConfirmOpen);
        }}
        onConfirm={deleteSelect}
        isOpen={deleteConfirmOpen}
        setIsOpen={deleteSelect}
        disableBackdropClose={true}
        title={''}
        agreeText={'삭제'}
        className='!pb-[50px] !pt-[44px]'
      >
        <div className='flex flex-col items-center justify-center gap-5'>
          <div className='flex flex-col items-center justify-center gap-1.5'>
            <div className='text-[#222] text-center font-[700] text-[16px] leading-[25.6px] tracking-[-0.32px] [font-feature-settings: "liga" off, "clig" off]'>
              차량 삭제
            </div>
            <div className='text-[#222] text-center font-[500] text-[15px] leading-[24px] tracking-[-0.3px] [font-feature-settings: "liga" off, "clig" off]'>
              선택하신 차량을 삭제하시겠습니까?
              <br />
              해당 차량의 부가정보(보험, 사고 접수 등)가
              <br />
              포함된 경우 모든 데이터도 함께 삭제됩니다.
            </div>
          </div>
        </div>
      </V3_Confirm>
      {/* <Confirm
        isOpen={deleteConfirmOpen}
        setIsOpen={setDeleteConfirmOpen}
        isCloseButton={true}
        onConfirm={deleteSelect}
        title='차량 삭제'
        desc={`선택하신 차량을 삭제하시겠습니까?\n해당 차량의 부가정보(보험, 사고 접수 등)가\n포함된 경우 모든 데이터도 함께 삭제됩니다.`}
        confirmText='삭제'
      ></Confirm> */}
    </div>
  );
};

export default CarBoxLayout;
