/* eslint-disable @typescript-eslint/no-unused-vars */
import { AccidentReport } from '@carsayo/types';
import { useQuery } from '@tanstack/react-query';
import { getTotalMemberAccidentReport } from 'apis/accidentReport';
import NoData from 'components/common/v3/NoData';
import MultiImageViewer from 'components_v3/dialog/MultiImageViewer';
import dayjs from 'dayjs';
import { Loader2, X } from 'lucide-react';
import { useLayoutEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';
import { cn } from 'utils';

const parseAccidentReportState = (state: AccidentReport) => {
  if (state.state === 'submitted') return '배정중';
  if (state.state === 'accepted' && !state.info_in) return '배정 완료';
  if (state.state === 'accepted' && state.info_in && !state.info_repair)
    return '입고 완료';
  if (state.state === 'accepted' && state.info_repair) return '수리중';
  if (state.state === 'canceled') return '취소';
  if (state.state === 'finished') return '수리 완료';
  return '';
};

const AccidentReportCard = ({ data }: { data: AccidentReport }) => {
  return (
    <div className='w-full rounded-[16px] bg-white p-[20px] pt-6  shadow-[0px_6px_20px_#0000000D]'>
      <div className='flex w-full items-center justify-start gap-[15px] border-b border-[#E5EAEE] pb-[20px]'>
        <div className='flex h-[60px] w-[60px] items-center justify-center rounded-[8px] bg-[#F3F6F9]'>
          <img
            src='/assets/images/v2/pink_car.png'
            alt='자동차 아이콘'
            className='w-[36px] object-contain'
          />
        </div>
        <div className='flex flex-col items-start justify-start gap-[8px]'>
          <strong className='flex flex-wrap-reverse items-center justify-start gap-2 text-[22px] font-[600] leading-[22px]'>
            {data.carNumber}
          </strong>
          <p className='flex items-center justify-start gap-[6px] text-[15px] font-[400] leading-[15px]'>
            <strong
              className={cn(
                'font-[600]',
                data.insuranceCompany ? 'text-[#222222]' : 'text-[#FF4747]',
              )}
            >
              {data.insuranceCompany.name
                ? data.insuranceCompany.name
                : '보험사 확인 필요'}
            </strong>
            <span>{data.info_name}</span>
          </p>
        </div>
      </div>
      <div className='w-full pt-[20px]'>
        <ul className='flex flex-col items-start justify-start gap-[14px] text-[15px] font-[400] leading-[15px]'>
          <li className='flex items-start justify-start'>
            <span className='min-w-[75px] text-left'>요청일</span>
            <strong className='font-[500]'>
              {dayjs(data.created_at).format('YYYY.MM.DD HH:mm')}
            </strong>
          </li>
          <li className='flex items-start justify-start'>
            <span className='min-w-[75px] text-left'>공업사</span>
            <strong className='font-[500]'>
              {data.repairShop?.name ?? '공업사 미배정'}
            </strong>
          </li>
          <li className='flex items-start justify-start'>
            <span className='min-w-[75px] text-left'>연락처</span>
            <strong className='font-[500]'>
              {data.repairShop?.contact_number ?? '공업사 미배정'}
            </strong>
          </li>
        </ul>

        {data.pictures_url.length > 0 && (
          <div className='w-full overflow-x-auto mt-5'>
            <div className='flex items-center justify-start gap-[6px]'>
              <ul className='flex items-center justify-start gap-[6px] pr-4'>
                {data.pictures_url.map((el, index) => {
                  return (
                    <button
                      key={index}
                      className='relative h-[97px] w-[97px] overflow-hidden rounded-[6px]'
                    >
                      <img
                        src={el}
                        alt={'업로드 이미지'}
                        className='h-full w-full object-cover'
                      />
                    </button>
                  );
                })}
              </ul>
            </div>
          </div>
        )}

        <button className='mt-6 flex w-full justify-center items-center h-[48px] bg-[#7273F9] rounded-[10px] p-4 text-[16px] leading-[16px] font-[600] text-white'>
          {parseAccidentReportState(data)}
        </button>
      </div>
    </div>
  );
};

export default function AccidentReportListPage() {
  const navigate = useNavigate();

  const { setTopTabbarState, setIsBottomTabbar } = useSystemStore();
  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      rightElement: (
        <Link
          to='/accidentReport/init'
          className='text-[15px] leading-[15px] font-[600] text-[#494AF1]'
        >
          등록
        </Link>
      ),
      leftElement: 'back',
      title: '사고 접수',
    });
    setIsBottomTabbar(false);
  }, []);

  const { data: accidentReportList, isLoading } = useQuery({
    queryKey: ['accidentReportList'],
    queryFn: async () => {
      return await getTotalMemberAccidentReport();
    },
  });

  return (
    <div className='h-full py-[30px] px-[20px] bg-[#F3F6F9]'>
      {isLoading && (
        <div className='flex items-center justify-center w-full h-full'>
          <Loader2 className='w-14 h-14 text-blue-500 animate-spin' />
        </div>
      )}
      {accidentReportList && (
        <div className='w-full flex-1 space-y-5 pb-5'>
          {accidentReportList.length > 0 &&
            accidentReportList.map((el) => {
              return (
                <div key={`${el.id}`} className='w-full'>
                  <button
                    className='inlin-flex w-full h-full'
                    onClick={() => navigate(`/accidentReport/detail/${el.id}`)}
                  >
                    <AccidentReportCard data={el} />
                  </button>
                </div>
              );
            })}
          {accidentReportList.length === 0 && (
            <div className='flex min-h-[calc(100dvh-120px)] w-full items-center justify-center pb-[30%]'>
              <NoData text='사고접수 이력이 존재하지 않습니다.' />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
