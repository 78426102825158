export default function AskIcon({
  fill,
  width,
  height,
}: {
  fill?: string;
  width?: string;
  height?: string;
}) {
  return (
    <svg
      width={width || '30'}
      height={height || '30'}
      viewBox='0 0 30 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_85_4638)'>
        <path
          d='M22.1085 5.55566C23.051 5.55566 23.955 5.93009 24.6215 6.59658C25.288 7.26307 25.6624 8.16703 25.6624 9.10959V18.5867C25.6624 19.5293 25.288 20.4332 24.6215 21.0997C23.955 21.7662 23.051 22.1406 22.1085 22.1406H16.1853L10.2621 25.6946V22.1406H7.89279C6.95023 22.1406 6.04628 21.7662 5.37979 21.0997C4.7133 20.4332 4.33887 19.5293 4.33887 18.5867V9.10959C4.33887 8.16703 4.7133 7.26307 5.37979 6.59658C6.04628 5.93009 6.95023 5.55566 7.89279 5.55566H22.1085Z'
          fill={fill || '#B2B7BB'}
          stroke={fill || '#B2B7BB'}
          strokeWidth='1.8'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M10.2617 11.4788H19.7388'
          stroke='white'
          strokeWidth='1.8'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M10.2617 16.2173H17.3696'
          stroke='white'
          strokeWidth='1.8'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_85_4638'>
          <rect width='30' height='30' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
