import { Button } from 'components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../ui/dialog';
import { ChoiceListInterface } from 'pages/v2/ask/component/ChoiceConfirm';
import { cn } from 'utils';

type Props = {
  title: string;
  desc: string;
  isOpen: boolean;
  isCloseButton?: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onConfirm?: () => void;
  confirmText?: string;
  cancelText?: string;
  isChoice?: boolean;
  choiceList?: ChoiceListInterface[];
  align?: 'center' | 'left';
};

export default function Confirm({
  title,
  desc,
  isOpen,
  isCloseButton,
  setIsOpen,
  onConfirm,
  confirmText,
  cancelText,
  isChoice,
  choiceList,
  align,
}: Props) {
  return (
    <Dialog open={isOpen} onOpenChange={(value) => setIsOpen(value)}>
      <DialogContent className='max-w-[315px] !pt-[30px] gap-0 border-none p-0 rounded-[16px] overflow-hidden shadow-[0px_5px_15px_#0A142C26]'>
        <DialogHeader className='px-2 flex min-h-[80px] flex-col items-center justify-center space-y-2 text-center'>
          <DialogTitle
            className={`w-full text-[16px] leading-[25.6px] font-bold ${align === 'left' ? 'pl-[18px]' : 'text-center'}`}
          >
            {title}
          </DialogTitle>
          {desc.length > 0 && (
            <div className='text-center text-[#767676] whitespace-pre-wrap'>
              {desc}
            </div>
          )}
          {choiceList && choiceList.length > 0 && (
            <ul className='w-full flex flex-col justify-center items-center !mt-[30px] gap-2.5 px-6'>
              {choiceList.map((item, index) => {
                const isEven = index % 2 === 0;
                return (
                  <li key={`${item.text}_${index}`} className='w-full'>
                    <button
                      onClick={item.action}
                      className={cn(
                        'flex justify-center items-center w-full h-[54px] rounded-[10px] text-[16px] leading-[16px] font-[500]',
                        isEven ? 'border border-[#E5E5EC]' : 'bg-[#F1F4F7]',
                      )}
                    >
                      {item.text}
                    </button>
                  </li>
                );
              })}
            </ul>
          )}
        </DialogHeader>
        <DialogFooter className='h-[54px] rounded-t-0 mt-[30px] '>
          {isCloseButton && (
            <Button
              className={`h-[54px] rounded-none border-t border-[#EEEEEE] bg-white text-[#555555]`}
              onClick={() => setIsOpen(false)}
            >
              {cancelText ?? '취소'}
            </Button>
          )}
          {!isChoice && (
            <Button
              className='h-[54px] rounded-none border-t border-[#F2F4F9] bg-white text-[#484AF5] border-l'
              onClick={() => {
                setIsOpen(false);
                if (onConfirm) onConfirm();
              }}
            >
              {confirmText ?? '확인'}
            </Button>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
