/* eslint-disable @typescript-eslint/no-unused-vars */
import { InputV2 } from 'components/common/v3/inputv2';
import { ChangeEvent, useLayoutEffect, useMemo, useState } from 'react';
import useInsuranceOriginDataStore from '../store/dataStore';
import Button from 'components_v3/ui/Button';
import { RegisterCar, RegisterCarUpdateDTO } from '@carsayo/types';
import useMemberStore from 'store/useMemberStore';
import { addRegisterCarApi, updateRegisterCarApi } from '../api';
import { CarsayoToast } from 'components/common/v2/CarsayoToast';
import { useLocation, useNavigate } from 'react-router-dom';
import SelectV3, { SelectV3Item } from 'components/common/v3/selectv3';
import useSystemStore from 'store/useSystemStore';
import CustomDatePicker from 'components/common/v3/customDatePicker';

export default function CarManagementEdit() {
  const { setTopTabbarState, setIsBottomTabbar } = useSystemStore();
  const { originInsuranceData } = useInsuranceOriginDataStore();
  const { loginMember, refreshLoginMember } = useMemberStore();
  const navigate = useNavigate();
  const location = useLocation();
  const carId = location.state?.id;
  const carData: RegisterCar | undefined = useMemo(() => {
    return loginMember?.customerInfo?.registerCar.list?.find(
      (item) => item.id === carId,
    );
  }, [loginMember]);
  const [insuranceCarNumber, setInsuranceCarNumber] = useState(
    carData?.carName ?? '',
  );
  const [insuranceCompany, setInsuranceCompany] = useState<number>(
    carData?.insuranceCompany?.id ?? 1001,
  );
  const [insuranceDate, setInsuranceDate] = useState<Date | null>(
    carData?.insurance_start_date
      ? new Date(carData.insurance_start_date)
      : null,
  );
  const insuranceCarNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInsuranceCarNumber(event.target.value);
  };
  const insuranceDataEdit = async () => {
    if (insuranceCarNumber && insuranceCompany && insuranceDate && carId) {
      const reqData: RegisterCarUpdateDTO = {
        id: carId,
        carName: insuranceCarNumber,
        insurance_start_date: insuranceDate,
        insurance_company_id: insuranceCompany,
        isRepresentative: carData?.isRepresentative ?? false,
      };
      try {
        const result = await updateRegisterCarApi(reqData);
        if (result) {
          CarsayoToast.success('차량이 정상적으로 수정되었습니다.');
          refreshLoginMember();
          navigate('/ask/carManagement/main');
        } else {
          CarsayoToast.error('차량 수정을 실패했습니다. 다시 시도해주세요.');
          refreshLoginMember();
        }
      } catch (error: any) {
        refreshLoginMember();
        CarsayoToast.error(error);
      }
    }
  };

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      leftElement: 'back',
      title: '차량 정보 수정',
      rightElement: undefined,
    });
    setIsBottomTabbar(false);
  }, []);

  return (
    <div className='px-5 pt-[50px] pb-[10px] min-h-[calc(100dvh-60px)] flex flex-col justify-between items-start gap-10'>
      <div className='w-full flex flex-col justify-start items-start gap-6'>
        <div className='w-full'>
          <label
            htmlFor='insuranceCarNumber'
            className='block text-[15px] leading-[15px] mb-[12px]'
          >
            차량번호
          </label>
          <InputV2
            id='insuranceCarNumber'
            placeholder='차량번호를 입력해주세요. (ex. 12가1234)'
            onChange={insuranceCarNumberChange}
            value={insuranceCarNumber}
          />
        </div>
        <div className='w-full'>
          <label
            htmlFor='insuranceCompany'
            className='block text-[15px] leading-[15px] mb-[12px]'
          >
            보험사
          </label>
          <SelectV3
            onValueChange={(value) => {
              setInsuranceCompany(Number(value));
            }}
            value={insuranceCompany?.toString() ?? ''}
            renderValue={() => (
              <div className='flex justify-start items-center'>
                <img
                  src={
                    originInsuranceData.find(
                      (item) => item.id === insuranceCompany,
                    )?.logo_url ?? ''
                  }
                  alt={
                    originInsuranceData.find(
                      (item) => item.id === insuranceCompany,
                    )?.name ?? ''
                  }
                  className='w-[28px] h-[28px] mr-2.5'
                />
                <span className='text-[16px] leading-[16px] font-[400]'>
                  {originInsuranceData.find(
                    (item) => item.id === insuranceCompany,
                  )?.name ?? ''}
                </span>
              </div>
            )}
          >
            {originInsuranceData.map((item) => (
              <SelectV3Item value={item.id}>
                <div className='flex items-center'>
                  <img
                    src={item.logo_url ?? ''}
                    alt={item.name ?? ''}
                    className='w-[28px] h-[28px] mr-2.5'
                  />
                  <span className='text-[16px] leading-[16px]'>
                    {item?.name ?? ''}
                  </span>
                </div>
              </SelectV3Item>
            ))}
          </SelectV3>
        </div>
        <div className='w-full'>
          <label
            htmlFor='insuranceDate'
            className='block text-[15px] leading-[15px] mb-[12px]'
          >
            보험 가입일
          </label>
          <div className='relative'>
            <CustomDatePicker
              type='white'
              value={insuranceDate}
              onChange={setInsuranceDate}
              initValue={carData?.insurance_start_date ?? new Date()}
            />
          </div>
        </div>
      </div>
      <Button
        theme='primary'
        disabled={
          insuranceCarNumber && insuranceCompany && insuranceDate ? false : true
        }
        onClick={insuranceDataEdit}
      >
        수정하기
      </Button>
    </div>
  );
}
